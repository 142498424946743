import React from "react";
import {translationInfo} from "../../translations/translations";
import {str} from "../../Scenarios";
import { BUTTON_STYLES, WideButton } from "../WideButton";


export class LanguageScreen extends React.Component {
    constructor(props) {
        super(props);
        this.languageInfo = translationInfo;
    }

    switchLanguage(code) {
        str.setLanguage(code);
        this.props.manager.setScenarioInfo();
        this.props.backDestination();
    }

    render() {
        return(<>
            <h2 className="upper cheddar page-selector">{str.language}</h2>
            <div className="options-body with-top">
                {this.languageInfo.map(function(info, index){
                    return(
                        <WideButton style={BUTTON_STYLES.ENTRY} key={index} onClick={() => this.switchLanguage(info.code)}>
                            {info.name}
                        </WideButton>
                    );
                }, this)}
            </div>
        </>);
    }
}
