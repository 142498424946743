import React from "react";

export class EntryArrow extends React.Component {
    constructor(props) {
        super(props);
        this.timer = null;
        this.state = {mounted: false};
    }
    componentWillUnmount() {
        clearTimeout(this.timer);
    }
    componentDidMount() {
        this.timer = setTimeout(()=>{
            this.setState({mounted: true});
        }, 1500);
    }
    
    render() {
        let el;
        try {
            el = document.querySelector(this.props.item);
        } catch {
            return null;
        }
        let flip = this.props.flip;
        if(!this.state.mounted) {
            return <div
                className={"hidden pulse entry-arrow"+(flip ? " flip" : "")}
                id={this.props.id}
                key={this.props.id}
            >
                Arrow
            </div>
        }
        if(!el) {
            setTimeout(()=>this.setState({}), 300);
            return null;
        }
        let el_rect = el.getBoundingClientRect();
        let parent = el.parentElement.getBoundingClientRect();
        return <div
            className={"pulse entry-arrow"+(flip ? " flip" : "")}
            id={this.props.id}
            key={this.props.id}
            style={{
                top: el_rect.y+(el_rect.height/2)-(125/2)+"px",
                left: (flip ? (el_rect.x-parent.x-171)+"px" : (el_rect.x-parent.x+el_rect.width)+"px")
            }}
        >Arrow
        </div>
    }
}


