import React from "react";
import { BUTTON_STYLES, WideButton } from "./WideButton";

export class EntryForm extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            entryNumber: "",
        };
    }

    onSubmit() {
        this.props.entrySubmitted(this.state.entryNumber);
    }

    onChange(value) {
        if(typeof this.props.entry_input === "undefined") {
            this.setState({entryNumber: value});    
        } else {
            this.props.setInput(value);
        }
    }

    onSubmitForm(event) {
       event.preventDefault();
       this.props.entrySubmitted((typeof this.props.entry_input==="undefined" ? this.state.entryNumber : this.props.entry_input.toString()))
       this.setState({entryNumber: ""});
       return false;
    }
    render() {

        return(
            <form onSubmit={(event)=>this.onSubmitForm(event)}>
                <div className="entry-form">
                    <p className="label">{this.props.label}</p>
                    <div className="form-line">
                        <input
                            className="entry-go poppins"
                            type="number"
                            value={typeof this.props.entry_input==="undefined" ? this.state.entryNumber : this.props.entry_input}
                            id="entry-input"
                            onChange={event => this.onChange(event.target.value)}
                        />
                        <WideButton style={BUTTON_STYLES.SMALL} onClick={()=>{}} type="submit">Go</WideButton>
                    </div>
                    <p className="error">{this.props.formError}</p>
                </div>
            </form>
        );
    }
}
