

export const AUDIO = {
    "bell": require("./audio/TimeUp.mp3"),
    timers: {
        "30": {
            "default": require("./audio/timers/30 Sec/PH3800__002_Explore-30sec.mp3"),
            "3": require("./audio/timers/30 Sec/PH3800__002_Explore-30sec.mp3"),
            "5": require("./audio/timers/30 Sec/Bodkin of Wundor-30sec.mp3"),
            "7": require("./audio/timers/30 Sec/Bodkin of Wundor-30sec.mp3"),
            "9": require("./audio/timers/30 Sec/PH3800__006_Froggy-30sec.mp3"),
            "13": require("./audio/timers/30 Sec/PH3800_001_Spooky-30sec.mp3"),
            "11": require("./audio/timers/30 Sec/FL_TIME_Pg11_outside the club-30sec.mp3"),
            "15": require("./audio/timers/30 Sec/Bodkin of Wundor-30sec.mp3"),
            "17": require("./audio/timers/30 Sec/PH3800__002_Explore-30sec.mp3"),
            "19": require("./audio/timers/30 Sec/PH3800__005_Intense-30 Sec.mp3"),
            "21": require("./audio/timers/30 Sec/PH3800__005_Intense-30 Sec.mp3"),
            "23": require("./audio/timers/30 Sec/PH3800__007_Boss-30sec.mp3"),
            "27": require("./audio/timers/30 Sec/PH3800__005_Intense-30 Sec.mp3"),
            "29": require("./audio/timers/30 Sec/PH3800__007_Boss-30sec.mp3"),
            "31": require("./audio/timers/30 Sec/PH3800__007_Boss-30sec.mp3"),
            "33": require("./audio/timers/30 Sec/I Quaff the Fog Juice-30sec.mp3"),
            "35": require("./audio/timers/30 Sec/Stepping Heedful-30sec.mp3"),
            "37": require("./audio/timers/30 Sec/PH3800__004_Strange-30sec.mp3"),
            "39": require("./audio/timers/30 Sec/PH3800__004_Strange-30sec.mp3"),
            "41": require("./audio/timers/30 Sec/PH3800__002_Explore-30sec.mp3"),
            "43": require("./audio/timers/30 Sec/PH3800__007_Boss-30sec.mp3"),
            "45": require("./audio/timers/30 Sec/I Quaff the Fog Juice-30sec.mp3"),
            "47": require("./audio/timers/30 Sec/Stepping Heedful-30sec.mp3"),
            "49": require("./audio/timers/30 Sec/PH3800__007_Boss-30sec.mp3"),
        },
        "45": {
            "default": require("./audio/timers/45 Sec/PH3800__002_Explore.mp3"),
            "3": require("./audio/timers/45 Sec/PH3800__002_Explore.mp3"),
            "5": require("./audio/timers/45 Sec/Bodkin of Wundor.mp3"),
            "7": require("./audio/timers/45 Sec/Bodkin of Wundor.mp3"),
            "9": require("./audio/timers/45 Sec/PH3800__006_Froggy.mp3"),
            "13": require("./audio/timers/45 Sec/PH3800_001_Spooky.mp3"),
            "15": require("./audio/timers/45 Sec/Bodkin of Wundor.mp3"),
            "17": require("./audio/timers/45 Sec/PH3800__002_Explore.mp3"),
            "19": require("./audio/timers/45 Sec/PH3800__005_Intense.mp3"),
            "21": require("./audio/timers/45 Sec/PH3800__005_Intense.mp3"),
            "23": require("./audio/timers/45 Sec/PH3800__007_Boss.mp3"),
            "27": require("./audio/timers/45 Sec/PH3800__005_Intense.mp3"),
            "29": require("./audio/timers/45 Sec/PH3800__007_Boss.mp3"),
            "31": require("./audio/timers/45 Sec/PH3800__007_Boss.mp3"),
            "33": require("./audio/timers/45 Sec/I Quaff the Fog Juice.mp3"),
            "35": require("./audio/timers/45 Sec/Stepping Heedful.mp3"),
            "37": require("./audio/timers/45 Sec/PH3800__004_Strange.mp3"),
            "39": require("./audio/timers/45 Sec/PH3800__004_Strange.mp3"),
            "41": require("./audio/timers/45 Sec/PH3800__002_Explore.mp3"),
            "43": require("./audio/timers/45 Sec/PH3800__007_Boss.mp3"),
            "45": require("./audio/timers/45 Sec/I Quaff the Fog Juice.mp3"),
            "47": require("./audio/timers/45 Sec/Stepping Heedful.mp3"),
            "49": require("./audio/timers/45 Sec/PH3800__007_Boss.mp3"),
        },
        "60": {
            "default": require("./audio/timers/FL_TIME_Pg09_RiverVillage_LONG.mp3"),
        },
    }
};


export const AUDIO_URLS = {
    'en': {
        "wizards": {
            '3-1': 'audio/voiceover/en/wizards/3-1.mp3',
            '87': 'audio/voiceover/en/wizards/87 Event.mp3',
            "83": "audio/voiceover/en/wizards/83_2_text_value.mp3",
            "900": "audio/voiceover/en/wizards/900_1_text_value.mp3",
            "901": "audio/voiceover/en/wizards/901_1_text_value.mp3",
            "902": "audio/voiceover/en/wizards/902_1_text_value.mp3",
            "903": "audio/voiceover/en/wizards/903_1_text_value.mp3",
            "904": "audio/voiceover/en/wizards/904_1_text_value.mp3",
            "905": "audio/voiceover/en/wizards/905_1_text_value.mp3",
            "906": "audio/voiceover/en/wizards/906_1_text_value.mp3",
            "907": "audio/voiceover/en/wizards/907_1_text_value.mp3",
            "908": "audio/voiceover/en/wizards/908_1_text_value.mp3",
            "909": "audio/voiceover/en/wizards/909_1_text_value.mp3",
            "910": "audio/voiceover/en/wizards/910_1_text_value.mp3",
            "911": "audio/voiceover/en/wizards/911_1_text_value.mp3",
            "912": "audio/voiceover/en/wizards/912_1_text_value.mp3",
            "1900": "audio/voiceover/en/wizards/1900_1_text_value.mp3",
            "1906": "audio/voiceover/en/wizards/1906_1_text_value.mp3",
            "1907": "audio/voiceover/en/wizards/1907_1_text_value.mp3",
            "1908": "audio/voiceover/en/wizards/1908_1_text_value.mp3",
            "1909": "audio/voiceover/en/wizards/1909_1_text_value.mp3",
            "2700": "audio/voiceover/en/wizards/2700_1_text_value.mp3",
            "2701": "audio/voiceover/en/wizards/2701_1_text_value.mp3",
            "2702": "audio/voiceover/en/wizards/2702_1_text_value.mp3",
            "2703": "audio/voiceover/en/wizards/2703_1_text_value.mp3",
            "2704": "audio/voiceover/en/wizards/2704_1_text_value.mp3",
            "2705": "audio/voiceover/en/wizards/2705_1_text_value.mp3",
            "2706": "audio/voiceover/en/wizards/2706_1_text_value.mp3",
            "2707": "audio/voiceover/en/wizards/2707_1_text_value.mp3",
            "2708": "audio/voiceover/en/wizards/2708_1_text_value.mp3",
            "2709": "audio/voiceover/en/wizards/2709_1_text_value.mp3",
            "2710": "audio/voiceover/en/wizards/2710_1_text_value.mp3",
            "2711": "audio/voiceover/en/wizards/2711_1_text_value.mp3",
            "3500": "audio/voiceover/en/wizards/3500_1_text_value.mp3",
            "3501": "audio/voiceover/en/wizards/3501_1_text_value.mp3",
            "3502": "audio/voiceover/en/wizards/3502_1_text_value.mp3",
            "3503": "audio/voiceover/en/wizards/3503_1_text_value.mp3",
            "3504": "audio/voiceover/en/wizards/3504_1_text_value.mp3",
            "3505": "audio/voiceover/en/wizards/3505_1_text_value.mp3",
            "3506": "audio/voiceover/en/wizards/3506_1_text_value.mp3",
            "3507": "audio/voiceover/en/wizards/3507_1_text_value.mp3",
            "3508": "audio/voiceover/en/wizards/3508_1_text_value.mp3",
            "3509": "audio/voiceover/en/wizards/3509_1_text_value.mp3",
            "4100": "audio/voiceover/en/wizards/4100_1_text_value.mp3",
            "4101": "audio/voiceover/en/wizards/4101_1_text_value.mp3",
            "4102": "audio/voiceover/en/wizards/4102_1_text_value.mp3",
            "4103": "audio/voiceover/en/wizards/4103_1_text_value.mp3",
            "4104": "audio/voiceover/en/wizards/4104_1_text_value.mp3",
            "4106": "audio/voiceover/en/wizards/4106_1_text_value.mp3",
            "4107": "audio/voiceover/en/wizards/4107_1_text_value.mp3",
            "4108": "audio/voiceover/en/wizards/4108_1_text_value.mp3",
            "4109": "audio/voiceover/en/wizards/4109_1_text_value.mp3",
            "4110": "audio/voiceover/en/wizards/4110_1_text_value.mp3",
            "4111": "audio/voiceover/en/wizards/4111_1_text_value.mp3",
            "4112": "audio/voiceover/en/wizards/4112_1_text_value.mp3",
            "4900": "audio/voiceover/en/wizards/4900_1_text_value.mp3",
            "4901": "audio/voiceover/en/wizards/4901_1_text_value.mp3",
            "4902": "audio/voiceover/en/wizards/4902_1_text_value.mp3",
            "4903": "audio/voiceover/en/wizards/4903_1_text_value.mp3",
            "4904": "audio/voiceover/en/wizards/4904_1_text_value.mp3",
            "4905": "audio/voiceover/en/wizards/4905_1_text_value.mp3",
            "8989": "audio/voiceover/en/wizards/8989_1_text_value.mp3",
            "901-1": "audio/voiceover/en/wizards/901_subentry_901-1_1_text_value.mp3",
            "901-2": "audio/voiceover/en/wizards/901_subentry_901-2_1_text_value.mp3",
            "901-3": "audio/voiceover/en/wizards/901_subentry_901-3_1_text_value.mp3",
            "902-1-1": "audio/voiceover/en/wizards/902_subentry_902-1_subentry_902-1-1_1_text_value.mp3",
            "902-2": "audio/voiceover/en/wizards/902_subentry_902-2_1_text_value.mp3",
            "902-3": "audio/voiceover/en/wizards/902_subentry_902-3_1_text_value.mp3",
            "904-1": "audio/voiceover/en/wizards/904_subentry_904-1_1_text_value.mp3",
            "904-2": "audio/voiceover/en/wizards/904_subentry_904-2_1_text_value.mp3",
            "908-1": "audio/voiceover/en/wizards/908_subentry_908-1_1_text_value.mp3",
            "909-1": "audio/voiceover/en/wizards/909_subentry_909-1_1_text_value.mp3",
            "909-2": "audio/voiceover/en/wizards/909_subentry_909-2_1_text_value.mp3",
            "908-2": "audio/voiceover/en/wizards/909_subentry_909-2_1_text_value.mp3",
            "911-1": "audio/voiceover/en/wizards/911_subentry_911-1_1_text_value.mp3",
            "911-2-1-1": "audio/voiceover/en/wizards/911_subentry_911-2_subentry_911-2-1_subentry_911-2-1-1_1_text_value.mp3",
            "911-2-1-2": "audio/voiceover/en/wizards/911_subentry_911-2_subentry_911-2-1_subentry_911-2-1-2_1_text_value.mp3",
            "3501-1": "audio/voiceover/en/wizards/3501_subentry_3501-1_1_text_value.mp3",
            "3501-2": "audio/voiceover/en/wizards/3501_subentry_3501-2_1_text_value.mp3",
            "3501-3": "audio/voiceover/en/wizards/3501_subentry_3501-3_1_text_value.mp3",
            "3506-1": "audio/voiceover/en/wizards/3506_subentry_3506-1_1_text_value.mp3",
            "3506-2": "audio/voiceover/en/wizards/3506_subentry_3506-2_1_text_value.mp3",
            "3506-3": "audio/voiceover/en/wizards/3506_subentry_3506-3_1_text_value.mp3",
            "3508-1": "audio/voiceover/en/wizards/3508_subentry_3508-1_1_text_value.mp3",
            "3508-2": "audio/voiceover/en/wizards/3508_subentry_2508-2_1_text_value.mp3",
            "3508-3": "audio/voiceover/en/wizards/3508_subentry_3508-3_1_text_value.mp3",
            "3508-4": "audio/voiceover/en/wizards/3508_subentry_3508-4_1_text_value.mp3",
            "81-1": "audio/voiceover/en/wizards/81_subentry_81-1_1_text_value.mp3",
            "81-1-1-1-2": "audio/voiceover/en/wizards/81_subentry_81-1_subentry_81-1-1_subentry_81-1-1-1_subentry_81-1-1-1-2_1_text_value.mp3",
            "81-1-1-2-1": "audio/voiceover/en/wizards/81_subentry_81-1_subentry_81-1-1_subentry_81-1-1-2_subentry_81-1-1-2-1_1_text_value.mp3",
            "81-2": "audio/voiceover/en/wizards/81_subentry_81-2_1_text_value.mp3",
            "81-2-1": "audio/voiceover/en/wizards/81_subentry_81-2_subentry_81-2-1_1_text_value.mp3",
            "81-2-1-2": "audio/voiceover/en/wizards/81_subentry_81-2_subentry_81-2-1_subentry_81-2-1-2_1_text_value.mp3",
            "81-2-2-1": "audio/voiceover/en/wizards/81_subentry_81-2_subentry_81-2-2_subentry_81-2-2-1_1_text_value.mp3",
            "81-2-2-1-1": "audio/voiceover/en/wizards/81_subentry_81-2_subentry_81-2-2_subentry_81-2-2-1_subentry_81-2-2-1-1_1_text_value.mp3",
            "81-2-2-2": "audio/voiceover/en/wizards/81_subentry_81-2_subentry_81-2-2_subentry_81-2-2-2_1_text_value.mp3",
            "81-2-1-1": "audio/voiceover/en/wizards/81_subentry_81-2_subentry_81-2-2_subentry_81-2-2-2_1_text_value.mp3",
            "81-3": "audio/voiceover/en/wizards/81_subentry_81-3_1_text_value.mp3",
            "81-3-1": "audio/voiceover/en/wizards/81_subentry_81-3_subentry_81-3-1_1_text_value.mp3",
            "83-1-1": "audio/voiceover/en/wizards/83_subentry_83-1_subentry_83-1-1_1_text_value.mp3",
            "83-1-2": "audio/voiceover/en/wizards/83_subentry_83-1_subentry_83-1-2_1_text_value.mp3",
            "83-2-1": "audio/voiceover/en/wizards/83_subentry_83-2_subentry_83-2-1_1_text_value.mp3",
            "83-2-2": "audio/voiceover/en/wizards/83_subentry_83-2_subentry_83-2-2_1_text_value.mp3",
            "83-3-1": "audio/voiceover/en/wizards/83_subentry_83-3_subentry_83-3-1_1_text_value.mp3",
            "83-3-2": "audio/voiceover/en/wizards/83_subentry_83-3_subentry_83-3-2_1_text_value.mp3",
            "1901-2": "audio/voiceover/en/wizards/1901_subentry_1901-2_1_text_value.mp3",
            "1902-1": "audio/voiceover/en/wizards/1902_subentry_1902-1_1_text_value.mp3",
            "1902-2": "audio/voiceover/en/wizards/1902_subentry_1902-2_1_text_value.mp3",
            "1902-2-1": "audio/voiceover/en/wizards/1902_subentry_1902-2_subentry_1902-2-1_1_text_value.mp3",
            "1902-2-1-1": "audio/voiceover/en/wizards/1902_subentry_1902-2_subentry_1902-2-1_subentry_1902-2-1-1_1_text_value.mp3",
            "1902-2-1-2": "audio/voiceover/en/wizards/1902_subentry_1902-2_subentry_1902-2-1_subentry_1902-2-1-2_1_text_value.mp3",
            "1903-1": "audio/voiceover/en/wizards/1903_subentry_1903-1_1_text_value.mp3",
            "1903-2": "audio/voiceover/en/wizards/1903_subentry_1903-2_1_text_value.mp3",
            "1910-1": "audio/voiceover/en/wizards/1910_subentry_1910-1_1_text_value.mp3",
            "1910-2": "audio/voiceover/en/wizards/1910_subentry_1910-2_1_text_value.mp3",
            "1910-2-1-1": "audio/voiceover/en/wizards/1910_subentry_1910-2_subentry_1910-2-1_subentry_1910-2-1-1_1_text_value.mp3",
            "2508-2": "audio/voiceover/en/wizards/3508_subentry_2508-2_1_text_value.mp3",
            "4105-1": "audio/voiceover/en/wizards/4105_subentry_4105-1_1_text_value.wav",
            "4105-1-1": "audio/voiceover/en/wizards/4105_subentry_4105-1_subentry_4105-1-1_1_text_value.wav",
            "4105-1-1-1-2": "audio/voiceover/en/wizards/4105_subentry_4105-1_subentry_4105-1-1_subentry_4105-1-1-1_subentry_4105-1-1-1-1_1_text_value.mp3",
            "4105-1-1-1-1-1": "audio/voiceover/en/wizards/b04003b8_105-1-1_subentry_4105-1-1-1_subentry_4105-1-1-1-1_subentry_4105-1-1-1-1-1_1_text_value.mp3",
            "4105-1-1-2": "audio/voiceover/en/wizards/4105_subentry_4105-1_subentry_4105-1-1_subentry_4105-1-1-2_1_text_value.mp3",
            "4105-1-1-2-1": "audio/voiceover/en/wizards/4105_subentry_4105-1_subentry_4105-1-1_subentry_4105-1-1-2_subentry_4105-1-1-2-1_1_text_value.mp3",
            "4105-1-1-3": "audio/voiceover/en/wizards/4105_subentry_4105-1_subentry_4105-1-1_subentry_4105-1-1-3_1_text_value.mp3",
            "4105-1-1-3-1": "audio/voiceover/en/wizards/4105_subentry_4105-1_subentry_4105-1-1_subentry_4105-1-1-3_subentry_4105-1-1-3-1_1_text_value.mp3",
            "4105-1-1-3-1-1": "audio/voiceover/en/wizards/f5be797_105-1-1_subentry_4105-1-1-3_subentry_4105-1-1-3-1_subentry_4105-1-1-3-1-1_1_text_value.mp3",
            "4105-2": "audio/voiceover/en/wizards/4105_subentry_4105-2_1_text_value.mp3",
            "4105-3": "audio/voiceover/en/wizards/4105_subentry_4105-3_1_text_value.mp3",
            "4105-3-3-3": "audio/voiceover/en/wizards/4105-3-3-3_1_text_value.mp3",
            "4106-1": "audio/voiceover/en/wizards/4106_subentry_4106-1_1_text_value.mp3",
            "4106-2": "audio/voiceover/en/wizards/4106_subentry_4106-2_1_text_value.mp3",
            "4106-3": "audio/voiceover/en/wizards/4106_subentry_4106-3_1_text_value.mp3",
            "4108-1": "audio/voiceover/en/wizards/4108_subentry_4108-1_1_text_value.mp3",
            "4108-2": "audio/voiceover/en/wizards/4108_subentry_4108-2_1_text_value.mp3",
            "4108-2-1": "audio/voiceover/en/wizards/4108_subentry_4108-2_subentry_4108-2-1_1_text_value.mp3",
            "4108-2-2": "audio/voiceover/en/wizards/4108_subentry_4108-2_subentry_4108-2-2_1_text_value.mp3",
            "4108-2-3": "audio/voiceover/en/wizards/4108_subentry_4108-2_subentry_4108-2-3_1_text_value.mp3",
            "4110-1": "audio/voiceover/en/wizards/4110_subentry_4110-1_1_text_value.mp3",
            "4900-2": "audio/voiceover/en/wizards/4900_subentry_4900-2_1_text_value.mp3",
            "4902-1": "audio/voiceover/en/wizards/4902_subentry_4902-1_1_text_value.mp3",
            "4903-2": "audio/voiceover/en/wizards/4903_subentry_4903-2_1_text_value.mp3",
            "4904-1": "audio/voiceover/en/wizards/4904_subentry_4904-1_1_text_value.mp3",
            "4904-2": "audio/voiceover/en/wizards/4904_subentry_4904-2_1_text_value.mp3",
            "4904-3": "audio/voiceover/en/wizards/4904_subentry_4904-3_1_text_value.mp3",
            "4904-4": "audio/voiceover/en/wizards/4904_subentry_4904-4_1_text_value.mp3",
            "4905-1-1": "audio/voiceover/en/wizards/4905_subentry_4905-1_subentry_4905-1-1_1_text_value.mp3",
            "4905-2-1": "audio/voiceover/en/wizards/4905_subentry_4905-2_subentry_4905-2-1_1_text_value.mp3",
            "4905-3-1": "audio/voiceover/en/wizards/4905_subentry_4905-3_subentry_4905-3-1_1_text_value.mp3",
            "4905-4-2": "audio/voiceover/en/wizards/4905_subentry_4905-4_subentry_4905-4-2_1_text_value.mp3",
            "4909-1-1": "audio/voiceover/en/wizards/4909_subentry_4909-1_subentry_4909-1-1_1_text_value.mp3",
            "4909-1-1-1-1": "audio/voiceover/en/wizards/4909_subentry_4909-1_subentry_4909-1-1_subentry_4909-1-1-1_subentry_4909-1-1-1-1_1_text_value.mp3",
            "4909-1-1-1-2": "audio/voiceover/en/wizards/4909_subentry_4909-1_subentry_4909-1-1_subentry_4909-1-1-1_subentry_4909-1-1-1-2_1_text_value.mp3",
            "4909-1-1-1-3": "audio/voiceover/en/wizards/4909_subentry_4909-1_subentry_4909-1-1_subentry_4909-1-1-1_subentry_4909-1-1-1-3_1_text_value.mp3",
            "4909-1-1-1-4-1": "audio/voiceover/en/wizards/639018aa_909-1-1_subentry_4909-1-1-1_subentry_4909-1-1-1-4_subentry_4909-1-1-1-4-1_1_text_value.mp3",
            "4909-1-1-1-4-2": "audio/voiceover/en/wizards/8916c5c8_909-1-1_subentry_4909-1-1-1_subentry_4909-1-1-1-4_subentry_4909-1-1-1-4-2_1_text_value.mp3",
            "4909-1-1-1-5": "audio/voiceover/en/wizards/4909_subentry_4909-1_subentry_4909-1-1_subentry_4909-1-1-1_subentry_4909-1-1-1-5_1_text_value.mp3",
            "4909-1-2": "audio/voiceover/en/wizards/4909_subentry_4909-1_subentry_4909-1-2_1_text_value.mp3",
            "4909-2": "audio/voiceover/en/wizards/4909-2_1_text_value.mp3",
            "4909-2-1": "audio/voiceover/en/wizards/4909-2_subentry_4909-2-1_1_text_value.mp3",
            "4909-2-1-2": "audio/voiceover/en/wizards/4909-2_subentry_4909-2-1_subentry_4909-2-1-2_1_text_value.mp3",
            "Camp-1": "audio/voiceover/en/wizards/Camp_subentry_Camp-1_1_text_value.mp3",
            "Camp-2": "audio/voiceover/en/wizards/Camp_subentry_Camp-2_1_text_value.mp3",
            "Camp-3": "audio/voiceover/en/wizards/Camp_subentry_Camp-3_1_text_value.mp3",
            "Game Over: Dragon Threat": "audio/voiceover/en/wizards/Game Over_ Dragon Threat_1_text_value.mp3",
            "Game Over: Dragon Threat-1-1": "audio/voiceover/en/wizards/733e38a3_subentry_Game Over_ Dragon Threat-1_subentry_Game Over_ Dragon Threat-1-1_1_text_value.mp3",
            "Game Over: Dragon Wounds": "audio/voiceover/en/wizards/Game Over_ Dragon Wounds_1_text_value.mp3",
            "Game Over: Dragon Wounds-1-3": "audio/voiceover/en/wizards/each-is-different-yet-indecision.mp3",
            "Game Over: Out of Wounds": "audio/voiceover/en/wizards/Game Over_ Out of Wounds_1_text_value.mp3",
            "Game Over: Out of Wounds-1-2": "audio/voiceover/en/wizards/b4a7ebd5_subentry_Game Over_ Out of Wounds-1_subentry_Game Over_ Out of Wounds-1-2_1_text_value.mp3",
            "Game Over: Out of Wounds-1-3": "audio/voiceover/en/wizards/Game Over_Out of Wounds-1-3_1_text_value.mp3",
            "Game Over: Threat Max-1-1": "audio/voiceover/en/wizards/f98277be_t Max_subentry_Game Over_ Threat Max-1_subentry_Game Over_ Threat Max-1-1_1_text_value.mp3",
            "Game Over: Threat Max-1-2": "audio/voiceover/en/wizards/1304aadc_t Max_subentry_Game Over_ Threat Max-1_subentry_Game Over_ Threat Max-1-2_1_text_value.mp3",
            "Game Over: Threat Max-1-3": "audio/voiceover/en/wizards/fc561c3d_t Max_subentry_Game Over_ Threat Max-1_subentry_Game Over_ Threat Max-1-3_1_text_value.mp3",
            "Game Over: Threat Max": "audio/voiceover/en/wizards/Game Over_Threat Max_1_text_value.mp3",
            "Game Over: Dragon Wounds-1-3-1": "audio/voiceover/en/wizards/e1249f18__DragonWounds-1_subentry_GameOver_DragonWonds-1-3_sbntry_GmOvr_DrgnWnds-1-3-1_1_txt_vl.mp3",
            "Game Over: Dragon Wounds-1-1-1-1": "audio/voiceover/en/wizards/9c788180_sbntry_GmOvr_DrgnWnds-1-1_sbntry_GmOvr_DrgnWnds-111_sbntry_GmOvr_DrgnWnds1111_1_txt_vl.mp3",
            "Game Over: Dragon Wounds-1-2-1-1": "audio/voiceover/en/wizards/8cc375f1_sbntry_GmOvr_DrgnWnds-1-2_sbntry_GmOvr_DrgnWnds-121_sbntry_GmOvr_DrgnWnds1211_1_txt_vl.mp3",
            "Game Over: Dragon Wounds-1-3-1-1": "audio/voiceover/en/wizards/8355d9de_sbntry_GmOvr_DrgnWnds-1-3_sbntry_GmOvr_DrgnWnds-131_sbntry_GmOvr_DrgnWnds1311_1_txt_vl.mp3",
            "Game Over: Dragon Wounds-1-1-1-1-1": "audio/voiceover/en/wizards/3dfe3520_bntry_GmOvr_DrgnWnds11_sbntry_GmOvr_DrgnWnds111_sbntry_GmOvr_DrgnW1111__GO_DW11111_1__.mp3",
            "Game Over: Dragon Wounds-1-2-1-1-1": "audio/voiceover/en/wizards/a73022bd_bntry_GmOvr_DrgnWnds12_sbntry_GmOvr_DrgnWnds121_sbntry_GmOvr_DrgnW1211__GO_DW12111_1__.mp3",
            "Game Over: Dragon Wounds-1-3-1-1-1": "audio/voiceover/en/wizards/d175d036_bntry_GmOvr_DrgnWnds13_sbntry_GmOvr_DrgnWnds131_sbntry_GmOvr_DrgnW1311__GO_DW13111_1__.mp3",
            "omen-1": "audio/voiceover/en/wizards/omen_subentry_omen-1_1_text_value.mp3",
            "omen-2": "audio/voiceover/en/wizards/omen_subentry_omen-2_1_text_value.mp3",
            "omen-3": "audio/voiceover/en/wizards/omen_subentry_omen-3_1_text_value.mp3",
            "omen-4": "audio/voiceover/en/wizards/omen_subentry_omen-4_1_text_value.mp3",
            "omen-5": "audio/voiceover/en/wizards/omen_subentry_omen-5_1_text_value.mp3",
            "912-1": "audio/voiceover/en/wizards/912_subentry_912-1_1_text_value.mp3",
            "2700-2-1-1-1-1-1": "audio/voiceover/en/wizards/bc83f6ef_ry_2700-2-1-1_sbntry_2700-2-1-1-1_sbntry_2700-2-1-1-1-1_sbntry_2700-2-1-1-111_1_txt_vl.mp3",
            "2700-1": "audio/voiceover/en/wizards/2700_subentry_2700-1_1_text_value.mp3",
            "2700-2-1-1": "audio/voiceover/en/wizards/2700_subentry_2700-2_subentry_2700-2-1_subentry_2700-2-1-1_1_text_value.mp3",
            "2700-2-1-1-1": "audio/voiceover/en/wizards/2700_subentry_2700-2_subentry_2700-2-1_subentry_2700-2-1-1_subentry_2700-2-1-1-1_1_text_value.mp3",
            "2700-2-1-1-1-1": "audio/voiceover/en/wizards/a97eef36_700-2-1_subentry_2700-2-1-1_subentry_2700-2-1-1-1_subentry_2700-2-1-1-1-1_1_text_value.mp3",
            "2701-1": "audio/voiceover/en/wizards/2701_subentry_2701-1_1_text_value.mp3",
            "4905-4-1-1": "audio/voiceover/en/wizards/4905_subentry_4905-4_subentry_4905-4-1_1_text_value.mp3",
            "24-page49-1-2": "audio/voiceover/en/wizards/24_subentry_24-page45-1_subentry_24-page45-1-2_1_text_value.mp3",
            "24-page49-1-1": "audio/voiceover/en/wizards/24_subentry_24-page45-1_subentry_24-page45-1-1_1_text_value.mp3",
            "24-1-2": "audio/voiceover/en/wizards/24_subentry_24-1_subentry_24-1-2_1_text_value.mp3",
            "24-1-1": "audio/voiceover/en/wizards/24_subentry_24-1_subentry_24-1-1_1_text_value.mp3",
            "23-page27-1-2": "audio/voiceover/en/wizards/23_subentry_23-page27-1_subentry_23-page27-1-2_1_text_value.mp3",
            "23-page27-1-1": "audio/voiceover/en/wizards/23_subentry_23-page27-1_subentry_23-page27-1-1_1_text_value.mp3",
            "23-page9-1-1": "audio/voiceover/en/wizards/23_subentry_23-page27-1_subentry_23-page27-1-1_1_text_value.mp3",
            "23-1-2": "audio/voiceover/en/wizards/23_subentry_23-1_subentry_23-1-2_1_text_value.mp3",
            "23-1-1": "audio/voiceover/en/wizards/23_subentry_23-1_subentry_23-1-1_1_text_value.mp3",
            "treasure": "audio/voiceover/en/wizards/treasure_1_text_value.mp3",
            "Tavern-2": "audio/voiceover/en/wizards/Tavern_subentry_Tavern-2_1_text_value.mp3",
            "Tavern-1": "audio/voiceover/en/wizards/Tavern_subentry_Tavern-1_1_text_value.mp3",
            "4909-3": "audio/voiceover/en/wizards/4909-3_1_text_value.mp3",
            "sidequest-g": "audio/voiceover/en/wizards/sidequest_subentry_sidequest-6_1_text_value.mp3",
            "sidequest-e": "audio/voiceover/en/wizards/sidequest_subentry_sidequest-e_1_text_value.mp3",
            "sidequest-c": "audio/voiceover/en/wizards/sidequest_subentry_sidequest-c_1_text_value.mp3",
            "sidequest-b": "audio/voiceover/en/wizards/sidequest_subentry_sidequest-b_1_text_value.mp3",
            "sidequest-a": "audio/voiceover/en/wizards/sidequest_subentry_sidequest-a_1_text_value.mp3",
            "8989-2": "audio/voiceover/en/wizards/8989_subentry_8989-2_1_text_value.mp3",
            "8989-1-1-1-4": "audio/voiceover/en/wizards/8989_subentry_8989-1_subentry_8989-1-1_subentry_8989-1-1-1_subentry_8989-1-1-1-4_1_text_value.mp3",
            "8989-1-1-1-2-2": "audio/voiceover/en/wizards/61769c32_989-1-1_subentry_8989-1-1-1_subentry_8989-1-1-1-2_subentry_8989-1-1-1-2-2_1_text_value.mp3",
            "8989-1-1-1-2-1": "audio/voiceover/en/wizards/8bf04150_989-1-1_subentry_8989-1-1-1_subentry_8989-1-1-1-2_subentry_8989-1-1-1-2-1_1_text_value.mp3",
            "8989-1-1-1-2": "audio/voiceover/en/wizards/8989_subentry_8989-1_subentry_8989-1-1_subentry_8989-1-1-1_subentry_8989-1-1-1-2_1_text_value.mp3",
            "8989-1-1-1-1-4": "audio/voiceover/en/wizards/8989-1-1-1-1-4_1_text_value.mp3",
            "8989-1-1-1-1-3": "audio/voiceover/en/wizards/19b6ec6a_989-1-1_subentry_8989-1-1-1_subentry_8989-1-1-1-1_subentry_8989-1-1-1-1-3_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-3-3": "audio/voiceover/en/wizards/subentry_8989-1-1-1-1-2-1-1-2-1-1-3-3_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-3-2": "audio/voiceover/en/wizards/subentry_8989-1-1-1-1-2-1-1-2-1-1-3-2_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-3-1": "audio/voiceover/en/wizards/subentry_8989-1-1-1-1-2-1-1-2-1-1-3-1_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-3": "audio/voiceover/en/wizards/8989-1-1-1-1-2-1-1-2-1-1-3_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-2-2-2": "audio/voiceover/en/wizards/subentry_8989-1-1-1-1-2-1-1-2-1-1-2-2-2_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-2-2-1": "audio/voiceover/en/wizards/subentry_8989-1-1-1-1-2-1-1-2-1-1-2-2-1_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-2-1-1": "audio/voiceover/en/wizards/subentry_8989-1-1-1-1-2-1-1-2-1-1-2-1-1_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-2-1": "audio/voiceover/en/wizards/subentry_8989-1-1-1-1-2-1-1-2-1-1-2-1_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-1-2": "audio/voiceover/en/wizards/subentry_8989-1-1-1-1-2-1-1-2-1-1-1-2_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-1-1-1": "audio/voiceover/en/wizards/subentry_8989-1-1-1-1-2-1-1-2-1-1-1-1-1_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-1-1-1-1": "audio/voiceover/en/wizards/subentry_8989-1-1-1-1-2-1-1-2-1-1-1-1-1-1_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-1-1-1-2": "audio/voiceover/en/wizards/subentry_8989-1-1-1-1-2-1-1-2-1-1-1-1-1-2_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-1-1": "audio/voiceover/en/wizards/subentry_8989-1-1-1-1-2-1-1-2-1-1-1-1_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-1": "audio/voiceover/en/wizards/subentry_8989-1-1-1-1-2-1-1-2-1-1-1_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1": "audio/voiceover/en/wizards/329588a2_8989111__89891111__898911112__8989111121__89891111211__898911112112__8989111121121_1__.mp3",
            "8989-1-1-1-1-2-1-1-2": "audio/voiceover/en/wizards/89b6e47e_8989111_sbntry_89891111_sbntry_898911112_sbn_8989111121__89891111211__898911112112_1__.mp3",
            "8989-1-1-1-1-2-1-1": "audio/voiceover/en/wizards/d2d42aa4_8989111_sbntry_89891111_sbntry_898911112_sbntry_8989111121_sbntry_89891111211_1_txt_vl.mp3",
            "8989-1-1-1-1-2-1": "audio/voiceover/en/wizards/b701a689_ry_8989-1-1-1_sbntry_8989-1-1-1-1_sbntry_8989-1-1-1-1-2_sbntry_8989-1-1-1-121_1_txt_vl.mp3",
            "8989-1-1-1-1-2": "audio/voiceover/en/wizards/f6e45a8b_989-1-1_subentry_8989-1-1-1_subentry_8989-1-1-1-1_subentry_8989-1-1-1-1-2_1_text_value.mp3",
            "8989-1-1-1-1-1": "audio/voiceover/en/wizards/1c6287e9_989-1-1_subentry_8989-1-1-1_subentry_8989-1-1-1-1_subentry_8989-1-1-1-1-1_1_text_value.mp3",
            "8989-1-1": "audio/voiceover/en/wizards/8989_subentry_8989-1_subentry_8989-1-1_1_text_value.mp3",
            "8989-1": "audio/voiceover/en/wizards/8989_subentry_8989-1_1_text_value.mp3",
            "3-page49-1": "audio/voiceover/en/wizards/3_subentry_3-page45-1_1_text_value.mp3",
            "3-page41-1": "audio/voiceover/en/wizards/3_subentry_3-page41-1_1_text_value.mp3",
            "3-page35-1": "audio/voiceover/en/wizards/3_subentry_3-page35-1_1_text_value.mp3",
            "3-page27-1": "audio/voiceover/en/wizards/3_subentry_3-page27-1_1_text_value.mp3",
            "3-page19-1": "audio/voiceover/en/wizards/3_subentry_3-page19-1_1_text_value.mp3",
            "3-page9-1": "audio/voiceover/en/wizards/3_subentry_3-page9-1_1_text_value.mp3",
            "3-1-1": "audio/voiceover/en/wizards/3_subentry_3-1_subentry_3-1-1_1_text_value.mp3",
            "2-page41-1": "audio/voiceover/en/wizards/2_subentry_2-page41-1_1_text_value.mp3",
            "2-page35-1": "audio/voiceover/en/wizards/2_subentry_2-page35-1_1_text_value.mp3",
            "2-page27-1": "audio/voiceover/en/wizards/2_subentry_2-page27-1_1_text_value.mp3",
            "2-page9-1": "audio/voiceover/en/wizards/2_subentry_2-page9-1_1_text_value.mp3",
            "2-1": "audio/voiceover/en/wizards/2_subentry_2-1_1_text_value.mp3",
            "1-page41-1": "audio/voiceover/en/wizards/1_subentry_1-page41-1_1_text_value.mp3",
            "1-page43-1": "audio/voiceover/en/wizards/1_subentry_1-page43-1_1_text_value.mp3",
            "1-page35-1": "audio/voiceover/en/wizards/1_subentry_1-page35-1_1_text_value.mp3",
            "1-page27-1": "audio/voiceover/en/wizards/1_subentry_1-page27-1_1_text_value.mp3",
            "1-page19-1-1": "audio/voiceover/en/wizards/1_subentry_1-page19-1_subentry_1-page19-1-1_1_text_value.mp3",
            "1-page9-1": "audio/voiceover/en/wizards/1_subentry_1-page9-1_1_text_value.mp3",
            "1-1": "audio/voiceover/en/wizards/1_subentry_1-1_1_text_value.mp3",
            "fortune-1-1-3": "audio/voiceover/en/wizards/56ffa10f_ne-1_subentry_fortune-1-1_subentry_fortune-1-1-3_subentry_fortune-1-1-3-2_1_text_value.mp3",
            "fortune-1-1-2": "audio/voiceover/en/wizards/fortune_subentry_fortune-1_subentry_fortune-1-1_subentry_fortune-1-1-2_1_text_value.mp3",
            "87-2": "audio/voiceover/en/wizards/87_subentry_87-2_1_text_value.mp3",
            "87-1": "audio/voiceover/en/wizards/87_subentry_87-1_1_text_value.mp3",
            "86-2": "audio/voiceover/en/wizards/86_subentry_86-2_1_text_value.mp3",
            "86-1-2": "audio/voiceover/en/wizards/86_subentry_86-1_subentry_86-1-2_1_text_value.mp3",
            "86-1-1": "audio/voiceover/en/wizards/86_subentry_86-1_subentry_86-1-1_1_text_value.mp3",
            "86-1": "audio/voiceover/en/wizards/86_subentry_86-1_1_text_value.mp3",
            "84-2-1": "audio/voiceover/en/wizards/84_subentry_84-2_subentry_84-2-1_1_text_value.mp3",
            "84-2-2": "audio/voiceover/en/wizards/84_subentry_84-2_subentry_84-2-2_1_text_value.mp3",
            "84-2": "audio/voiceover/en/wizards/84_subentry_84-2_1_text_value.mp3",
            "84-1-3-2": "audio/voiceover/en/wizards/84_subentry_84-1_subentry_84-1-3_subentry_84-1-3-2_1_text_value.mp3",
            "84-1-3-1": "audio/voiceover/en/wizards/84_subentry_84-1_subentry_84-1-3_subentry_84-1-3-1_1_text_value.mp3",
            "84-1-2": "audio/voiceover/en/wizards/84_subentry_84-1_subentry_84-1-2_1_text_value.mp3",
            "84-1-1-2": "audio/voiceover/en/wizards/84_subentry_84-1_subentry_84-1-1_subentry_84-1-1-2_1_text_value.mp3",
            "84-1-1-1-1": "audio/voiceover/en/wizards/84_subentry_84-1_subentry_84-1-1_subentry_84-1-1-1_subentry_84-1-1-1-1_1_text_value.mp3",
            "84-1-1-1": "audio/voiceover/en/wizards/84_subentry_84-1_subentry_84-1-1_subentry_84-1-1-1_1_text_value.mp3",
            "84-1": "audio/voiceover/en/wizards/84_subentry_84-1_1_text_value.mp3",
            "2712-2-3-2": "audio/voiceover/en/wizards/2712_subentry_2712-2_subentry_2712-2-3_subentry_2712-2-3-2_1_text_value.mp3",
            "2712-2-3-1": "audio/voiceover/en/wizards/2712_subentry_2712-2_subentry_2712-2-3_subentry_2712-2-3-1_1_text_value.mp3",
            "2712-1": "audio/voiceover/en/wizards/2712_subentry_2712-1_1_text_value.mp3",
            "2705-2": "audio/voiceover/en/wizards/2705_subentry_2705-2_1_text_value.mp3",
            "2705-1": "audio/voiceover/en/wizards/2705_subentry_2705-1_1_text_value.mp3",
            "2703-2-1-1-1": "audio/voiceover/en/wizards/2703_subentry_2703-2_subentry_2703-2-1_subentry_2703-2-1-1_subentry_2703-2-1-1-1_1_text_value.mp3",
            "2703-2-1-1": "audio/voiceover/en/wizards/2703_subentry_2703-2_subentry_2703-2-1_subentry_2703-2-1-1_1_text_value.mp3",
            "2702-2": "audio/voiceover/en/wizards/2702_subentry_2702-2_1_text_value.mp3",
            "2702-1": "audio/voiceover/en/wizards/2702_subentry_2702-1_1_text_value.mp3",
            "2701-2": "audio/voiceover/en/wizards/2701_subentry_2701-2_1_text_value.mp3",
            "Game Over: Dragon Threat-1-3": "audio/voiceover/en/wizards/76ea5320_subentry_Game Over_ Dragon Threat-1_subentry_Game Over_ Dragon Threat-1-3_1_text_value.mp3",
            "Game Over: Dragon Wounds-1-2-1": "audio/voiceover/en/wizards/ee758a67__DragonWounds-1_subentry_GameOver_DragonWonds-1-2_sbntry_GmOvr_DrgnWnds-1-2-1_1_txt_vl.mp3",
            "1901-1": "audio/voiceover/en/wizards/1901_subentry_1901-1_1_text_value.mp3",
            "2712-2-3-3": "audio/voiceover/en/wizards/2712_subentry_2712-2_subentry_2712-2-3_subentry_2712-2-3-3_1_text_value.mp3",
            "4105-1-1-1": "audio/voiceover/en/wizards/4105_subentry_4105-1_subentry_4105-1-1_subentry_4105-1-1-1_1_text_value.mp3",
            "4105-1-1-1-1": "audio/voiceover/en/wizards/4105_subentry_4105-1_subentry_4105-1-1_subentry_4105-1-1-1_subentry_4105-1-1-1-1_1_text_value.mp3",
            "4902-2": "audio/voiceover/en/wizards/4902_subentry_4902-2_1_text_value.mp3",
            "4905-4-1": "audio/voiceover/en/wizards/4905_subentry_4905-4_subentry_4905-4-1_1_text_value.mp3",
            "4909-1-1-1-6": "audio/voiceover/en/wizards/4909_subentry_4909-1_subentry_4909-1-1_subentry_4909-1-1-1_subentry_4909-1-1-1-6_1_text_value.mp3",
            "Game Over: Dragon Wounds-1-1": "audio/voiceover/en/wizards/91030d38_subentry_Game Over_ Dragon Wounds-1_subentry_Game Over_ Dragon Wounds-1-1_1_text_value.mp3",
            "Game Over: Dragon Wounds-1-2": "audio/voiceover/en/wizards/7b85d05a_subentry_Game Over_ Dragon Wounds-1_subentry_Game Over_ Dragon Wounds-1-2_1_text_value.mp3",
            "902-1": "audio/voiceover/en/wizards/902_subentry_902-1_1_text_value.mp3",
            "2712-2-3-3-1": "audio/voiceover/en/wizards/2712-2-3-3-1.mp3",
            "4903-1": "audio/voiceover/en/wizards/4903-1.mp3",
            "Game Over: Dragon Wounds-1-1-1": "audio/voiceover/en/wizards/Game Over_ Dragon Wounds-1-1-1.mp3",
            "Game Over: Out of Wounds-1-1": "audio/voiceover/en/wizards/Game Over- Out of Wounds-1-1.mp3",
            "fortune-1-1-3-2": "audio/voiceover/en/wizards/56ffa10f_ne-1_subentry_fortune-1-1_subentry_fortune-1-1-3_subentry_fortune-1-1-3-2_1_text_value.mp3",
            "fortune": "audio/voiceover/en/wizards/fortune_1_text_value.mp3",
            "fortune-1-1": "audio/voiceover/en/wizards/fortune_subentry_fortune-1_subentry_fortune-1-1_1_text_value.mp3",
            "fortune-1-1-1-1": "audio/voiceover/en/wizards/f890e717_ne-1_subentry_fortune-1-1_subentry_fortune-1-1-1_subentry_fortune-1-1-1-1_1_text_value.mp3",
            "fortune-1-1-3-1": "audio/voiceover/en/wizards/bc797c6d_ne-1_subentry_fortune-1-1_subentry_fortune-1-1-3_subentry_fortune-1-1-3-1_1_text_value.mp3",
            "81-2-2": "audio/voiceover/en/wizards/81_subentry_81-2_subentry_81-2-2_1_text_value.mp3",
            "4909-2-1-1": "audio/voiceover/en/wizards/4909-2_subentry_4909-2-1_subentry_4909-2-1-1_1_text_value.mp3",
            "Game Over: Dragon Threat-1-2": "audio/voiceover/en/wizards/99b8e5c1_subentry_Game Over_ Dragon Threat-1_subentry_Game Over_ Dragon Threat-1-2_1_text_value.mp3",
            "fortune-1-1-1-2": "audio/voiceover/en/wizards/12163a75_ne-1_subentry_fortune-1-1_subentry_fortune-1-1-1_subentry_fortune-1-1-1-2_1_text_value.mp3"
        },
        "generic": {
            "law-1-2-3-1-3-1-3-1-1": "audio/voiceover/en/generic/law__123131311_1__.mp3",
            "law-1-2-3-1-3-1-3-1-2": "audio/voiceover/en/generic/law__123131312_1__.mp3",
            "sidequest-2-1-1-1-1-2-1-1-2-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-2-1-1-1-1-2-1-1-2-1-1-1_1_text_value.mp3",
            "chaos-4-1-2": "audio/voiceover/en/wizards/chaos_subentry_chaos-1_subentry_chaos-1-1_subentry_chaos-1-1-2_1_text_value.mp3",
            "chaos-4-1-1": "audio/voiceover/en/generic/chaos_subentry_chaos-4_subentry_chaos-4-1_subentry_chaos-4-1-1_1_text_value.mp3.mp3",
            "chaos-4": "audio/voiceover/en/wizards/chaos_subentry_chaos-1_1_text_value.mp3",
            "camp-1": "audio/voiceover/en/generic/camp_subentry_camp-1_1_text_value.mp3",
            "camp-2": "audio/voiceover/en/generic/camp_subentry_camp-2_1_text_value.mp3",
            "camp-3": "audio/voiceover/en/generic/camp_subentry_camp-3_1_text_value.mp3",
            "chaos-1": "audio/voiceover/en/generic/chaos_subentry_chaos-1_1_text_value.mp3",
            "chaos-1-3-1": "audio/voiceover/en/generic/chaos_subentry_chaos-1_subentry_chaos-1-3_subentry_chaos-1-3-1_1_text_value.mp3",
            "chaos-1-3-2": "audio/voiceover/en/generic/chaos_subentry_chaos-1_subentry_chaos-1-3_subentry_chaos-1-3-2_1_text_value.mp3",
            "chaos-2": "audio/voiceover/en/generic/chaos_subentry_chaos-2_1_text_value.mp3",
            "chaos-2-1-1": "audio/voiceover/en/generic/chaos_subentry_chaos-2_subentry_chaos-2-1_subentry_chaos-2-1-1_1_text_value.mp3",
            "chaos-2-1-2": "audio/voiceover/en/generic/chaos_subentry_chaos-2_subentry_chaos-2-1_subentry_chaos-2-1-2_1_text_value.mp3",
            "chaos-3-1-1-1-1": "audio/voiceover/en/generic/c48d6a18_-3-1_subentry_chaos-3-1-1_subentry_chaos-3-1-1-1_subentry_chaos-3-1-1-1-1_1_text_value.mp3",
            "chaos-3-1-2-2": "audio/voiceover/en/generic/281d5eda_ry_chaos-3_subentry_chaos-3-1_subentry_chaos-3-1-2_subentry_chaos-3-1-2-2_1_text_value.mp3",
            "chaos-3-1-4-1": "audio/voiceover/en/generic/5b5a7768_ry_chaos-3_subentry_chaos-3-1_subentry_chaos-3-1-4_subentry_chaos-3-1-4-1_1_text_value.mp3",
            "chaos-3-1-5-1": "audio/voiceover/en/generic/d62adc8f_ry_chaos-3_subentry_chaos-3-1_subentry_chaos-3-1-5_subentry_chaos-3-1-5-1_1_text_value.mp3",
            "chaos-3-1-5-2": "audio/voiceover/en/generic/3cac01ed_ry_chaos-3_subentry_chaos-3-1_subentry_chaos-3-1-5_subentry_chaos-3-1-5-2_1_text_value.mp3",
            "chaos-5-1-1-2": "audio/voiceover/en/generic/88ff675f_ry_chaos-5_subentry_chaos-5-1_subentry_chaos-5-1-1_subentry_chaos-5-1-1-2_1_text_value.mp3",
            "chaos-5-1-2": "audio/voiceover/en/generic/chaos_subentry_chaos-5_subentry_chaos-5-1_subentry_chaos-5-1-2_1_text_value.mp3",
            "chaos-5-1-3": "audio/voiceover/en/generic/chaos_subentry_chaos-5_subentry_chaos-5-1_subentry_chaos-5-1-3_1_text_value.mp3",
            "crossroad-job-artificer-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-artificer-1_1_text_value.mp3",
            "crossroad-job-artificer-1-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-artificer-1_subentry_crossroad-job-artificer-1-1_1_text_value.mp3",
            "crossroad-job-artificer-1-1-1": "audio/voiceover/en/generic/61ec2f4d_ficer-1_subentry_crossroad-job-artificer-1-1_subentry_crossrod-jb-rtfcr-1-1-1_1_txt_vl.mp3",
            "crossroad-job-artificer-1-1-2": "audio/voiceover/en/generic/1d8d0a96_ficer-1_subentry_crossroad-job-artificer-1-1_subentry_crossrod-jb-rtfcr-1-1-2_1_txt_vl.mp3",
            "crossroad-job-artificer-1-1-3": "audio/voiceover/en/generic/8082ebe0_ficer-1_subentry_crossroad-job-artificer-1-1_subentry_crossrod-jb-rtfcr-1-1-3_1_txt_vl.mp3",
            "crossroad-job-artificer-1-2": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-artificer-1_subentry_crossroad-job-artificer-1-2_1_text_value.L.mp3",
            "crossroad-job-artificer-1-2-1": "audio/voiceover/en/generic/f3af8610_ficer-1_subentry_crossroad-job-artificer-1-2_subentry_crossrod-jb-rtfcr-1-2-1_1_txt_vl.mp3",
            "crossroad-job-artificer-1-2-2": "audio/voiceover/en/generic/8fcea3cb_ficer-1_subentry_crossroad-job-artificer-1-2_subentry_crossrod-jb-rtfcr-1-2-2_1_txt_vl.mp3",
            "crossroad-job-artificer-1-2-3": "audio/voiceover/en/generic/12c142bd_ficer-1_subentry_crossroad-job-artificer-1-2_subentry_crossrod-jb-rtfcr-1-2-3_1_txt_vl.mp3",
            "crossroad-job-artificer-1-3": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-artificer-1_subentry_crossroad-job-artificer-1-3_1_text_value.mp3",
            "crossroad-job-artificer-1-3-1": "audio/voiceover/en/generic/34be1ce4_ficer-1_subentry_crossroad-job-artificer-1-3_subentry_crossrod-jb-rtfcr-1-3-1_1_txt_vl.mp3",
            "crossroad-job-artificer-1-3-2": "audio/voiceover/en/generic/48df393f_ficer-1_subentry_crossroad-job-artificer-1-3_subentry_crossrod-jb-rtfcr-1-3-2_1_txt_vl.mp3",
            "crossroad-job-artificer-1-3-3": "audio/voiceover/en/generic/d5d0d849_ficer-1_subentry_crossroad-job-artificer-1-3_subentry_crossrod-jb-rtfcr-1-3-3_1_txt_vl.mp3",
            "crossroad-job-barberian-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-barberian-1_1_text_value.mp3",
            "crossroad-job-barberian-1-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-barberian-1_subentry_crossroad-job-barberian-1-1_1_text_value.mp3",
            "crossroad-job-barberian-1-2": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-barberian-1_subentry_crossroad-job-barberian-1-2_1_text_value.mp3",
            "crossroad-job-barberian-1-3": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-barberian-1_subentry_crossroad-job-barberian-1-3_1_text_value.mp3",
            "crossroad-job-bard-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-bard-1_1_text_value.mp3",
            "crossroad-job-bard-1-1-1": "audio/voiceover/en/generic/beee2cf2_-bard-1_subentry_crossroad-job-bard-1-1_subentry_crossroad-job-bard-1-1-1_1_text_value.mp3",
            "crossroad-job-bard-1-2-1": "audio/voiceover/en/generic/ab12652f_-bard-1_subentry_crossroad-job-bard-1-2_subentry_crossroad-job-bard-1-2-1_1_text_value.mp3",
            "crossroad-job-clamdigger-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-clamdigger-1_1_text_value.mp3",
            "crossroad-job-clamdigger-1-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-clamdigger-1_subentry_crossroad-job-clamdigger-1-1_1_text_value.mp3",
            "crossroad-job-clamdigger-1-1-1": "audio/voiceover/en/generic/b91281c1_digger-1_subentry_crossroad-job-clamdigger-1-1_sbntry_crssrd-jb-clmdggr-1-1-1_1_txt_vl.mp3",
            "crossroad-job-clamdigger-1-1-2": "audio/voiceover/en/generic/c573a41a_digger-1_subentry_crossroad-job-clamdigger-1-1_sbntry_crssrd-jb-clmdggr-1-1-2_1_txt_vl.mp3",
            "crossroad-job-clamdigger-1-2": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-clamdigger-1_subentry_crossroad-job-clamdigger-1-2_1_text_value.mp3",
            "crossroad-job-disgracednoble-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-disgracednoble-1_1_text_value.mp3",
            "crossroad-job-disgracednoble-1-1": "audio/voiceover/en/generic/af85396__crossroad-job-disgracednoble-1_subentry_crossroad-job-disgracednoble-1-1_1_text_value.mp3",
            "crossroad-job-divorcee-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-divorcee-1_1_text_value.mp3",
            "crossroad-job-dungfarmer-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-dungfarmer-1_1_text_value.mp3",
            "crossroad-job-dungfarmer-1-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-dungfarmer-1_subentry_crossroad-job-dungfarmer-1-1_1_text_value.mp3",
            "crossroad-job-dungfarmer-1-1-1": "audio/voiceover/en/generic/ec2b287d_farmer-1_subentry_crossroad-job-dungfarmer-1-1_sbntry_crssrd-jb-dngfrmr-1-1-1_1_txt_vl.mp3",
            "crossroad-job-dungfarmer-1-1-2": "audio/voiceover/en/generic/904a0da6_farmer-1_subentry_crossroad-job-dungfarmer-1-1_sbntry_crssrd-jb-dngfrmr-1-1-2_1_txt_vl.mp3",
            "crossroad-job-dungfarmer-1-2": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-dungfarmer-1_subentry_crossroad-job-dungfarmer-1-2_1_text_value.mp3",
            "crossroad-job-friar-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-friar-1_1_text_value.mp3",
            "crossroad-job-friar-1-1-1": "audio/voiceover/en/generic/628d07b6_iar-1_subentry_crossroad-job-friar-1-1_subentry_crossroad-job-friar-1-1-1_1_text_value.mp3",
            "crossroad-job-gunslinger-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-gunslinger-1_1_text_value.mp3",
            "crossroad-job-gunslinger-1-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-gunslinger-1_subentry_crossroad-job-gunslinger-1-1_1_text_value.mp3",
            "crossroad-job-gunslinger-1-1-1": "audio/voiceover/en/generic/a96cc16b_linger-1_subentry_crossroad-job-gunslinger-1-1_sbntry_crssrd-jb-gnslngr-1-1-1_1_txt_vl.mp3",
            "crossroad-job-gunslinger-1-1-2": "audio/voiceover/en/generic/d50de4b0_linger-1_subentry_crossroad-job-gunslinger-1-1_sbntry_crssrd-jb-gnslngr-1-1-2_1_txt_vl.mp3",
            "crossroad-job-gunslinger-1-2": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-gunslinger-1_subentry_crossroad-job-gunslinger-1-2_1_text_value.mp3",
            "crossroad-job-gunslinger-1-2-1": "audio/voiceover/en/generic/bf6f0b0c_linger-1_subentry_crossroad-job-gunslinger-1-2_sbntry_crssrd-jb-gnslngr-1-2-1_1_txt_vl.mp3",
            "crossroad-job-gunslinger-1-2-2": "audio/voiceover/en/generic/c30e2ed7_linger-1_subentry_crossroad-job-gunslinger-1-2_sbntry_crssrd-jb-gnslngr-1-2-2_1_txt_vl.mp3",
            "crossroad-job-gunslinger-1-3": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-gunslinger-1_subentry_crossroad-job-gunslinger-1-3_1_text_value.mp3",
            "crossroad-job-gunslinger-1-3-1": "audio/voiceover/en/generic/b291b2d1_linger-1_subentry_crossroad-job-gunslinger-1-3_sbntry_crssrd-jb-gnslngr-1-3-1_1_txt_vl.mp3",
            "crossroad-job-gunslinger-1-3-2": "audio/voiceover/en/generic/cef0970a_linger-1_subentry_crossroad-job-gunslinger-1-3_sbntry_crssrd-jb-gnslngr-1-3-2_1_txt_vl.mp3",
            "crossroad-job-gunslinger-1-3-3": "audio/voiceover/en/generic/53ff767c_linger-1_subentry_crossroad-job-gunslinger-1-3_sbntry_crssrd-jb-gnslngr-1-3-3_1_txt_vl.mp3",
            "crossroad-job-judge-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-judge-1_1_text_value.mp3",
            "crossroad-job-judge-1-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-judge-1_subentry_crossroad-job-judge-1-1_1_text_value.mp3",
            "crossroad-job-judge-1-3": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-judge-1_subentry_crossroad-job-judge-1-3_1_text_value.mp3",
            "crossroad-job-knight-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-knight-1_1_text_value.mp3",
            "crossroad-job-knight-1-1-1": "audio/voiceover/en/generic/88641bdf_ht-1_subentry_crossroad-job-knight-1-1_subentry_crossroad-job-knight-1-1-1_1_text_valu.mp3",
            "crossroad-job-knight-1-1-2": "audio/voiceover/en/generic/dbfe405b_ht-1_subentry_crossroad-job-knight-1-1_subentry_crossroad-job-knight-1-1-2_1_text_valu.mp3",
            "crossroad-job-knight-1-2-1": "audio/voiceover/en/generic/a85b4db5_ht-1_subentry_crossroad-job-knight-1-2_subentry_crossroad-job-knight-1-2-1_1_text_valu.mp3",
            "crossroad-job-knight-1-2-2": "audio/voiceover/en/generic/fbc11631_ht-1_subentry_crossroad-job-knight-1-2_subentry_crossroad-job-knight-1-2-2_1_text_valu.mp3",
            "crossroad-job-knight-1-3": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-knight-1_subentry_crossroad-job-knight-1-3_1_text_value.mp3",
            "crossroad-job-paladin-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-paladin-1_1_text_value.mp3",
            "crossroad-job-paladin-1-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-paladin-1_subentry_crossroad-job-paladin-1-1_1_text_value.mp3",
            "crossroad-job-paladin-1-1-1": "audio/voiceover/en/generic/7f5b08a8_din-1_subentry_crossroad-job-paladin-1-1_subentry_crossroad-job-paladin-1-1-1_1_txt_vl.mp3",
            "crossroad-job-paladin-1-1-2": "audio/voiceover/en/generic/33a2d73_din-1_subentry_crossroad-job-paladin-1-1_subentry_crossroad-job-paladin-1-1-2_1_txt_vl.mp3",
            "crossroad-job-paladin-1-1-3": "audio/voiceover/en/generic/9e35cc05_din-1_subentry_crossroad-job-paladin-1-1_subentry_crossroad-job-paladin-1-1-3_1_txt_vl.mp3",
            "crossroad-job-paladin-1-2": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-paladin-1_subentry_crossroad-job-paladin-1-2_1_text_value.mp3",
            "crossroad-job-paladin-1-3": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-paladin-1_subentry_crossroad-job-paladin-1-3_1_text_value.mp3",
            "crossroad-job-pirate-1-2": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-pirate-1_subentry_crossroad-job-pirate-1-2_1_text_value.mp3",
            "crossroad-job-pirate-1-3": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-pirate-1_subentry_crossroad-job-pirate-1-3_1_text_value.mp3",
            "crossroad-job-privateeye-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-privateeye-1_1_text_value.mp3",
            "crossroad-job-privateeye-1-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-privateeye-1_subentry_crossroad-job-privateeye-1-1_1_text_value.mp3",
            "crossroad-job-privateeye-1-2": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-privateeye-1_subentry_crossroad-job-privateeye-1-2_1_text_value.mp3",
            "crossroad-job-ranger-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-ranger-1_1_text_value.mp3",
            "crossroad-job-ranger-1-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-ranger-1_subentry_crossroad-job-ranger-1-1_1_text_value.mp3",
            "crossroad-job-ranger-1-1-1": "audio/voiceover/en/generic/d134aa92_er-1_subentry_crossroad-job-ranger-1-1_subentry_crossroad-job-ranger-1-1-1_1_text_valu.mp3",
            "crossroad-job-ranger-1-1-2": "audio/voiceover/en/generic/82aef116_er-1_subentry_crossroad-job-ranger-1-1_subentry_crossroad-job-ranger-1-1-2_1_text_valu.mp3",
            "crossroad-job-ranger-1-2": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-ranger-1_subentry_crossroad-job-ranger-1-2_1_text_value.mp3",
            "crossroad-job-ranger-1-3": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-ranger-1_subentry_crossroad-job-ranger-1-3_1_text_value.mp3",
            "crossroad-job-skinchanger-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-skinchanger-1_1_text_value.mp3",
            "crossroad-job-skinchanger-1-1": "audio/voiceover/en/generic/4b64bf87_bentry_crossroad-job-skinchanger-1_subentry_crossroad-job-skinchanger-1-1_1_text_value.mp3",
            "crossroad-job-skinchanger-1-2": "audio/voiceover/en/generic/a1e262e5_bentry_crossroad-job-skinchanger-1_subentry_crossroad-job-skinchanger-1-2_1_text_value.mp3",
            "crossroad-job-skinchanger-1-3": "audio/voiceover/en/generic/4eb0d404_bentry_crossroad-job-skinchanger-1_subentry_crossroad-job-skinchanger-1-3_1_text_value.mp3",
            "crossroad-job-skinchanger-1-1-1": "audio/voiceover/en/generic/acd5dd9_changer-1_subentry_crossroad-job-sknchngr-1-1_sbntry_crssrd-jb-sknchngr-1-1-1_1_txt_vl.mp3",
            "crossroad-job-skinchanger-1-1-2": "audio/voiceover/en/generic/76ac7802_changer-1_subentry_crossroad-job-sknchngr-1-1_sbntry_crssrd-jb-sknchngr-1-1-2_1_txt_vl.mp3",
            "crossroad-job-skinchanger-1-2-1": "audio/voiceover/en/generic/a35f459e_changer-1_subentry_crossroad-job-sknchngr-1-2_sbntry_crssrd-jb-sknchngr-1-2-1_1_txt_vl.mp3",
            "crossroad-job-skinchanger-1-2-2": "audio/voiceover/en/generic/df3e6045_changer-1_subentry_crossroad-job-sknchngr-1-2_sbntry_crssrd-jb-sknchngr-1-2-2_1_txt_vl.mp3",
            "crossroad-job-skinchanger-1-3-1": "audio/voiceover/en/generic/72fe4f9c_changer-1_subentry_crossroad-job-sknchngr-1-3_sbntry_crssrd-jb-sknchngr-1-3-1_1_txt_vl.mp3",
            "crossroad-job-skinchanger-1-3-2": "audio/voiceover/en/generic/e9f6a47_changer-1_subentry_crossroad-job-sknchngr-1-3_sbntry_crssrd-jb-sknchngr-1-3-2_1_txt_vl.mp3",
            "crossroad-job-spellsword-1-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-spellsword-1_subentry_crossroad-job-spellsword-1-1_1_text_value.mp3",
            "crossroad-job-spellsword-1-2": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-spellsword-1_subentry_crossroad-job-spellsword-1-2_1_text_value.mp3",
            "crossroad-job-spellsword-1-3": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-spellsword-1_subentry_crossroad-job-spellsword-1-3_1_text_value.mp3",
            "crossroad-job-theif-1-2": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-theif-1_subentry_crossroad-job-theif-1-2_1_text_value.mp3",
            "crossroad-job-theif-1-2-1": "audio/voiceover/en/generic/a1b729a3_eif-1_subentry_crossroad-job-theif-1-2_subentry_crossroad-job-theif-1-2-1_1_text_value.mp3",
            "crossroad-job-theif-1-2-2": "audio/voiceover/en/generic/4b31f4c1_eif-1_subentry_crossroad-job-theif-1-2_subentry_crossroad-job-theif-1-2-2_1_text_value.mp3",
            "crossroad-job-theif-1-2-3": "audio/voiceover/en/generic/a4634220_eif-1_subentry_crossroad-job-theif-1-2_subentry_crossroad-job-theif-1-2-3_1_text_value.mp3",
            "crossroad-job-witch-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-witch-1_1_text_value.mp3",
            "crossroad-job-witch-1-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-witch-1_subentry_crossroad-job-witch-1-1_1_text_value.mp3",
            "crossroad-job-witch-1-2": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-witch-1_subentry_crossroad-job-witch-1-2_1_text_value.mp3",
            "crossroad-job-witch-1-3": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-witch-1_subentry_crossroad-job-witch-1-3_1_text_value.mp3",
            "crossroad-species-dwarf-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-dwarf-1_1_text_value.mp3",
            "crossroad-species-dwarf-1-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-dwarf-1_subentry_crossroad-species-dwarf-1-1_1_text_value.mp3",
            "crossroad-species-dwarf-1-2": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-dwarf-1_subentry_crossroad-species-dwarf-1-2_1_text_value.mp3",
            "crossroad-job-friar-1-1-1-1": "audio/voiceover/en/generic/3d55bc5f_try_crssrd-jb-frr-1-1_sbntry_crssrd-jb-frr-1-1-1_sbntry_crssrd-jb-frr-1-1-1-1_1_txt_vl.mp3",
            "crossroad-job-dungfarmer-1-1-2-1": "audio/voiceover/en/generic/6cb2bef4_try_crssrd-jb-dngfrmr-11_sbntry_crssrdjbdngfrmr112_sbntry_crssrdjbdngfrmr1121_1_txt_vl.mp3",
            "crossroad-job-dungfarmer-1-1-2-2": "audio/voiceover/en/generic/10d39b2f_try_crssrd-jb-dngfrmr-11_sbntry_crssrdjbdngfrmr112_sbntry_crssrdjbdngfrmr1122_1_txt_vl.mp3",
            "crossroad-job-dungfarmer-1-3-3-1": "audio/voiceover/en/generic/c0c5c909_try_crssrd-jb-gnslngr-13_sbntry_crssrdjbgnslngr133_sbntry_crssrdjbgnslngr1331_1_txt_vl.mp3",
            "crossroad-job-knight-1-1-2-1": "audio/voiceover/en/generic/760fd71d_y_crssrd-jb-knght-1-1_sbntry_crssrd-jb-knght-1-1-2_sbntry_crssrd-jb-knght1121_1_txt_vl.mp3",
            "crossroad-job-knight-1-1-2-2": "audio/voiceover/en/generic/a6ef2c6_y_crssrd-jb-knght-1-1_sbntry_crssrd-jb-knght-1-1-2_sbntry_crssrd-jb-knght1122_1_txt_vl.mp3",
            "crossroad-job-knight-1-1-2-3": "audio/voiceover/en/generic/976113b0_y_crssrd-jb-knght-1-1_sbntry_crssrd-jb-knght-1-1-2_sbntry_crssrd-jb-knght1123_1_txt_vl.mp3",
            "crossroad-job-paladin-1-1-1-1": "audio/voiceover/en/generic/b04984ef__crssrd-jb-pldn-1-1_sbntry_crssrd-jb-pldn-1-1-1_sbntry_crssrd-jb-pldn-1-1-1-1_1_txt_vl.mp3",
            "crossroad-job-paladin-1-1-1-3": "audio/voiceover/en/generic/51274042__crssrd-jb-pldn-1-1_sbntry_crssrd-jb-pldn-1-1-1_sbntry_crssrd-jb-pldn-1-1-1-3_1_txt_vl.mp3",
            "crossroad-job-ranger-1-1-1-1": "audio/voiceover/en/generic/5703cb19__crssrd-jb-rngr-1-1_sbntry_crssrd-jb-rngr-1-1-1_sbntry_crssrd-jb-rngr-1-1-1-1_1_txt_vl.mp3",
            "crossroad-job-ranger-1-1-1-2": "audio/voiceover/en/generic/2b62eec2__crssrd-jb-rngr-1-1_sbntry_crssrd-jb-rngr-1-1-1_sbntry_crssrd-jb-rngr-1-1-1-2_1_txt_vl.mp3",
            "crossroad-job-artificer-1-1-1-1": "audio/voiceover/en/generic/85980280_y_crssrd-jb-rtfcr-1-1_sbntry_crssrd-jb-rtfcr-1-1-1_sbntry_crssrd-jb-rtfcr1111_1_txt_vl.mp3",
            "crossroad-job-artificer-1-1-1-2": "audio/voiceover/en/generic/f9f9275b_y_crssrd-jb-rtfcr-1-1_sbntry_crssrd-jb-rtfcr-1-1-1_sbntry_crssrd-jb-rtfcr1112_1_txt_vl.mp3",
            "crossroad-job-artificer-1-1-2-1": "audio/voiceover/en/generic/77acdf5b_y_crssrd-jb-rtfcr-1-1_sbntry_crssrd-jb-rtfcr-1-1-2_sbntry_crssrd-jb-rtfcr1121_1_txt_vl.mp3",
            "crossroad-job-artificer-1-1-2-2": "audio/voiceover/en/generic/bcdfa80_y_crssrd-jb-rtfcr-1-1_sbntry_crssrd-jb-rtfcr-1-1-2_sbntry_crssrd-jb-rtfcr1122_1_txt_vl.mp3",
            "crossroad-job-artificer-1-1-3-1": "audio/voiceover/en/generic/26409412_y_crssrd-jb-rtfcr-1-1_sbntry_crssrd-jb-rtfcr-1-1-3_sbntry_crssrd-jb-rtfcr1131_1_txt_vl.mp3",
            "crossroad-job-artificer-1-1-3-2": "audio/voiceover/en/generic/5a21b1c9_y_crssrd-jb-rtfcr-1-1_sbntry_crssrd-jb-rtfcr-1-1-3_sbntry_crssrd-jb-rtfcr1132_1_txt_vl.mp3",
            "crossroad-job-artificer-1-2-1-1": "audio/voiceover/en/generic/8ecee5c2_y_crssrd-jb-rtfcr-1-2_sbntry_crssrd-jb-rtfcr-1-2-1_sbntry_crssrd-jb-rtfcr1211_1_txt_vl.mp3",
            "crossroad-job-artificer-1-2-1-2": "audio/voiceover/en/generic/f2afc019_y_crssrd-jb-rtfcr-1-2_sbntry_crssrd-jb-rtfcr-1-2-1_sbntry_crssrd-jb-rtfcr1212_1_txt_vl.mp3",
            "crossroad-job-artificer-1-2-2-1": "audio/voiceover/en/generic/7cfa3819_y_crssrd-jb-rtfcr-1-2_sbntry_crssrd-jb-rtfcr-1-2-2_sbntry_crssrd-jb-rtfcr1221_1_txt_vl.mp3",
            "crossroad-job-artificer-1-2-2-2": "audio/voiceover/en/generic/9b1dc2_y_crssrd-jb-rtfcr-1-2_sbntry_crssrd-jb-rtfcr-1-2-2_sbntry_crssrd-jb-rtfcr1222_1_txt_vl.mp3",
            "crossroad-job-artificer-1-2-3-1": "audio/voiceover/en/generic/2d167350_y_crssrd-jb-rtfcr-1-2_sbntry_crssrd-jb-rtfcr-1-2-3_sbntry_crssrd-jb-rtfcr1231_1_txt_vl.mp3",
            "crossroad-job-artificer-1-2-3-2": "audio/voiceover/en/generic/5177568b_y_crssrd-jb-rtfcr-1-2_sbntry_crssrd-jb-rtfcr-1-2-3_sbntry_crssrd-jb-rtfcr1232_1_txt_vl.mp3",
            "crossroad-job-artificer-1-3-1-1": "audio/voiceover/en/generic/3ed3bac3_y_crssrd-jb-rtfcr-1-3_sbntry_crssrd-jb-rtfcr-1-3-1_sbntry_crssrd-jb-rtfcr1311_1_txt_vl.mp3",
            "crossroad-job-artificer-1-3-1-2": "audio/voiceover/en/generic/42b29f18_y_crssrd-jb-rtfcr-1-3_sbntry_crssrd-jb-rtfcr-1-3-1_sbntry_crssrd-jb-rtfcr1312_1_txt_vl.mp3",
            "crossroad-job-artificer-1-3-2-1": "audio/voiceover/en/generic/cce76718_y_crssrd-jb-rtfcr-1-3_sbntry_crssrd-jb-rtfcr-1-3-2_sbntry_crssrd-jb-rtfcr1321_1_txt_vl.mp3",
            "crossroad-job-artificer-1-3-2-2": "audio/voiceover/en/generic/b08642c3_y_crssrd-jb-rtfcr-1-3_sbntry_crssrd-jb-rtfcr-1-3-2_sbntry_crssrd-jb-rtfcr1322_1_txt_vl.mp3",
            "crossroad-job-artificer-1-3-3-1": "audio/voiceover/en/generic/9d0b2c51_y_crssrd-jb-rtfcr-1-3_sbntry_crssrd-jb-rtfcr-1-3-3_sbntry_crssrd-jb-rtfcr1331_1_txt_vl.mp3",
            "crossroad-job-artificer-1-3-3-2": "audio/voiceover/en/generic/e16a098a_y_crssrd-jb-rtfcr-1-3_sbntry_crssrd-jb-rtfcr-1-3-3_sbntry_crssrd-jb-rtfcr1332_1_txt_vl.mp3",
            "chaos-3-1-1-1-1-1-2-1-1": "audio/voiceover/en/generic/subentry_chaos-3-1-1-1-1-1-2-1-1_1_text_value.mp3",
            "chaos-3-1-1-1-1-1-2-1-2": "audio/voiceover/en/generic/subentry_chaos-3-1-1-1-1-1-2-1-2_1_text_value.mp3",
            "crossroad-job-divorcee-1-1-1-1-1-1-2-1-1": "audio/voiceover/en/generic/subentry_crossroad-job-divorcee-1-1-1-1-1-1-2-1-1_1_text_value.mp3",
            "crossroad-job-divorcee-1-1-1-1-1-1-2-1-2": "audio/voiceover/en/generic/subentry_crossroad-job-divorcee-1-1-1-1-1-1-2-1-2_1_text_value.mp3",
            "crossroad-job-divorcee-1-1-1-1-1-1-2-1-3": "audio/voiceover/en/generic/subentry_crossroad-job-divorcee-1-1-1-1-1-1-2-1-3_1_text_value.mp3",
            "crossroad-job-divorcee-1-1-1-1-1-1-2-1-4": "audio/voiceover/en/generic/subentry_crossroad-job-divorcee-1-1-1-1-1-1-2-1-4_1_text_value.mp3",
            "crossroad-job-divorcee-1-1-1-1-1-1-2-1-5": "audio/voiceover/en/generic/subentry_crossroad-job-divorcee-1-1-1-1-1-1-2-1-5_1_text_value.mp3",
            "crossroad-job-pirate-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-pirate-1_1_text_value.mp3",
            "crossroad-species-elf-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-elf-1_1_text_value.mp3",
            "crossroad-species-elf-1-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-elf-1_subentry_crossroad-species-elf-1-1_1_text_value.mp3",
            "crossroad-species-elf-1-2": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-elf-1_subentry_crossroad-species-elf-1-2_1_text_value.mp3",
            "crossroad-species-goblin-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-goblin-1_1_text_value.mp3",
            "crossroad-species-goblin-1-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-goblin-1_subentry_crossroad-species-goblin-1-1_1_text_value.mp3",
            "crossroad-species-goblin-1-2": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-goblin-1_subentry_crossroad-species-goblin-1-2_1_text_value.mp3",
            "crossroad-species-goblin-1-3": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-goblin-1_subentry_crossroad-species-goblin-1-3_1_text_value.mp3",
            "crossroad-species-goblin-1-3-1": "audio/voiceover/en/generic/1092d01a_goblin-1_subentry_crossroad-species-goblin-1-3_subntry_crssrd-spcs-gbln-1-3-1_1_txt_vl.mp3",
            "crossroad-species-goblin-1-3-2": "audio/voiceover/en/generic/6cf3f5c1_goblin-1_subentry_crossroad-species-goblin-1-3_subntry_crssrd-spcs-gbln-1-3-2_1_txt_vl.mp3",
            "crossroad-species-hound-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-hound-1_1_text_value.mp3",
            "crossroad-species-hound-1-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-hound-1_subentry_crossroad-species-hound-1-1_1_text_value.mp3",
            "crossroad-species-hound-1-1-1": "audio/voiceover/en/generic/69538ddc_hound-1_subentry_crossroad-species-hound-1-1_subentry_crossrod-spcs-hnd-1-1-1_1_txt_vl.mp3",
            "crossroad-species-hound-1-1-2": "audio/voiceover/en/generic/1532a807_hound-1_subentry_crossroad-species-hound-1-1_subentry_crossrod-spcs-hnd-1-1-2_1_txt_vl.mp3",
            "crossroad-species-hound-1-2": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-hound-1_subentry_crossroad-species-hound-1-2_1_text_value.mp3",
            "crossroad-species-hound-1-3": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-hound-1_subentry_crossroad-species-hound-1-3_1_text_value.mp3",
            "crossroad-species-hound-1-3-1": "audio/voiceover/en/generic/3c01be75_hound-1_subentry_crossroad-species-hound-1-3_subentry_crossrod-spcs-hnd-1-3-1_1_txt_vl.mp3",
            "crossroad-species-hound-1-3-2": "audio/voiceover/en/generic/40609bae_hound-1_subentry_crossroad-species-hound-1-3_subentry_crossrod-spcs-hnd-1-3-2_1_txt_vl.mp3",
            "crossroad-species-imp-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-imp-1_1_text_value.mp3",
            "crossroad-species-imp-1-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-imp-1_subentry_crossroad-species-imp-1-1_1_text_value.mp3",
            "crossroad-species-imp-1-1-1": "audio/voiceover/en/generic/a2720738_imp-1_subentry_crossroad-species-imp-1-1_subentry_crossroad-species-imp-1-1-1_1_txt_vl.mp3",
            "crossroad-species-imp-1-1-2": "audio/voiceover/en/generic/de1322e3_imp-1_subentry_crossroad-species-imp-1-1_subentry_crossroad-species-imp-1-1-2_1_txt_vl.mp3",
            "crossroad-species-imp-1-2": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-imp-1_subentry_crossroad-species-imp-1-2_1_text_value.mp3",
            "crossroad-species-merfolk-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-merfolk-1_1_text_value.mp3",
            "crossroad-species-merfolk-1-1": "audio/voiceover/en/generic/7b674767_bentry_crossroad-species-merfolk-1_subentry_crossroad-species-merfolk-1-1_1_text_value.mp3",
            "crossroad-species-merfolk-1-2": "audio/voiceover/en/generic/91e19a05_bentry_crossroad-species-merfolk-1_subentry_crossroad-species-merfolk-1-2_1_text_value.mp3",
            "crossroad-species-merfolk-1-3": "audio/voiceover/en/generic/7eb32ce4_bentry_crossroad-species-merfolk-1_subentry_crossroad-species-merfolk-1-3_1_text_value.mp3",
            "crossroad-species-mouser-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-mouser-1_1_text_value.mp3",
            "crossroad-species-mouser-1-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-mouser-1_subentry_crossroad-species-mouser-1-1_1_text_value.mp3",
            "crossroad-species-mouser-1-2": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-mouser-1_subentry_crossroad-species-mouser-1-2_1_text_value.mp3",
            "crossroad-species-stiltkin-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-stiltkin-1_1_text_value.mp3",
            "crossroad-species-stiltkin-1-1": "audio/voiceover/en/generic/bf6b32e5_ntry_crossroad-species-stiltkin-1_subentry_crossroad-species-stiltkin-1-1_1_text_value.mp3",
            "crossroad-species-stiltkin-1-2": "audio/voiceover/en/generic/55edef87_ntry_crossroad-species-stiltkin-1_subentry_crossroad-species-stiltkin-1-2_1_text_value.mp3",
            "crossroad-species-stiltkin-1-3": "audio/voiceover/en/generic/babf5966_ntry_crossroad-species-stiltkin-1_subentry_crossroad-species-stiltkin-1-3_1_text_value.mp3",
            "crossroad-species-stiltkin-1-4": "audio/voiceover/en/generic/5b915302_ntry_crossroad-species-stiltkin-1_subentry_crossroad-species-stiltkin-1-4_1_text_value.mp3",
            "crossroad-species-stiltkin-2": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-stiltkin-2_1_text_value.mp3",
            "crossroad-species-stiltkin-2-1": "audio/voiceover/en/generic/cf8b6f9a_ntry_crossroad-species-stiltkin-2_subentry_crossroad-species-stiltkin-2-1_1_text_value.mp3",
            "crossroad-species-stiltkin-2-2": "audio/voiceover/en/generic/250db2f8_ntry_crossroad-species-stiltkin-2_subentry_crossroad-species-stiltkin-2-2_1_text_value.mp3",
            "crossroad-species-stiltkin-2-3": "audio/voiceover/en/generic/ca5f0419_ntry_crossroad-species-stiltkin-2_subentry_crossroad-species-stiltkin-2-3_1_text_value.mp3",
            "crossroad-species-stiltkin-2-4": "audio/voiceover/en/generic/2b710e7d_ntry_crossroad-species-stiltkin-2_subentry_crossroad-species-stiltkin-2-4_1_text_value.mp3",
            "crossroad-species-stiltkin-2-5": "audio/voiceover/en/generic/c423b89c_ntry_crossroad-species-stiltkin-2_subentry_crossroad-species-stiltkin-2-5_1_text_value.mp3",
            "crossroad-species-stiltkin-2-6": "audio/voiceover/en/generic/2ea565fe_ntry_crossroad-species-stiltkin-2_subentry_crossroad-species-stiltkin-2-6_1_text_value.mp3",
            "crossroad-species-troll-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-troll-1_1_text_value.mp3",
            "crossroad-species-troll-1-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-troll-1_subentry_crossroad-species-troll-1-1_1_text_value.mp3",
            "crossroad-species-troll-1-2": "audio/voiceover/en/generic/crossroad_subentry_crossroad-species-troll-1_subentry_crossroad-species-troll-1-2_1_text_value.mp3",
            "crossroad-species-hound-1-3-2-1": "audio/voiceover/en/generic/6bf38f39_y_crssrd-spcs-hnd-1-3_sbntry_crssrd-spcs-hnd-1-3-2_sbntry_crssrd-spcs-hnd1321_1_txt_vl.mp3",
            "crossroad-species-hound-1-3-2-2": "audio/voiceover/en/generic/1792aae2_y_crssrd-spcs-hnd-1-3_sbntry_crssrd-spcs-hnd-1-3-2_sbntry_crssrd-spcs-hnd1322_1_txt_vl.mp3",
            "crossroad-species-imp-1-1-1-1": "audio/voiceover/en/generic/df9241a0__crssrd-spcs-mp-1-1_sbntry_crssrd-spcs-mp-1-1-1_sbntry_crssrd-spcs-mp-1-1-1-1_1_txt_vl.mp3",
            "crossroad-species-imp-1-1-1-2": "audio/voiceover/en/generic/a3f3647b__crssrd-spcs-mp-1-1_sbntry_crssrd-spcs-mp-1-1-1_sbntry_crssrd-spcs-mp-1-1-1-2_1_txt_vl.mp3",
            "crossroad-species-imp-1-1-1-1-2": "audio/voiceover/en/generic/26d46a17_ssrdspcsmp11_sbntry_crssrdspcsmp111_sbntry_crssrdspcsmp1111_sbntry_crssrdspcs11112_1__.mp3",
            "crossroad-species-imp-1-1-1-1-3": "audio/voiceover/en/generic/31af7e54_ssrdspcsmp11_sbntry_crssrdspcsmp111_sbntry_crssrdspcsmp1111_sbntry_crssrdspcs11113_1__.mp3",
            "duel-1": "audio/voiceover/en/generic/duel_subentry_duel-1_1_text_value.mp3",
            "duel-1-1": "audio/voiceover/en/generic/duel_subentry_duel-1_subentry_duel-1-1_1_text_value.R.mp3",
            "duel-1-2-1-1": "audio/voiceover/en/generic/duel_subentry_duel-1_subentry_duel-1-2_subentry_duel-1-2-1_subentry_duel-1-2-1-1_1_text_value.mp3",
            "duel-1-2-1-2": "audio/voiceover/en/generic/duel_subentry_duel-1_subentry_duel-1-2_subentry_duel-1-2-1_subentry_duel-1-2-1-2_1_text_value.mp3",
            "duel-1-2-2": "audio/voiceover/en/generic/duel_subentry_duel-1_subentry_duel-1-2_subentry_duel-1-2-2_1_text_value.mp3",
            "duel-2": "audio/voiceover/en/generic/duel_subentry_duel-2_1_text_value.mp3",
            "duel-2-1": "audio/voiceover/en/generic/duel_subentry_duel-2_subentry_duel-2-1_1_text_value.mp3",
            "duel-2-2": "audio/voiceover/en/generic/duel_subentry_duel-2_subentry_duel-2-2_1_text_value.mp3",
            "duel-2-2-1": "audio/voiceover/en/generic/duel_subentry_duel-2_subentry_duel-2-2_subentry_duel-2-2-1_1_text_value.mp3",
            "duel-2-2-2": "audio/voiceover/en/generic/duel_subentry_duel-2_subentry_duel-2-2_subentry_duel-2-2-2_1_text_value.mp3",
            "duel-3": "audio/voiceover/en/generic/duel_subentry_duel-3_1_text_value.mp3",
            "duel-3-1": "audio/voiceover/en/generic/duel_subentry_duel-3_subentry_duel-3-1_1_text_value.mp3",
            "duel-3-2": "audio/voiceover/en/generic/duel_subentry_duel-3_subentry_duel-3-2_1_text_value.mp3",
            "duel-4": "audio/voiceover/en/generic/duel_subentry_duel-4_1_text_value.mp3",
            "duel-4-1": "audio/voiceover/en/generic/duel_subentry_duel-4_subentry_duel-4-1_1_text_value.mp3",
            "duel-4-2": "audio/voiceover/en/generic/duel_subentry_duel-4_subentry_duel-4-2_1_text_value.mp3",
            "duel-4-2-1-1": "audio/voiceover/en/generic/duel_subentry_duel-4_subentry_duel-4-2_subentry_duel-4-2-1_subentry_duel-4-2-1-1_1_text_value.mp3",
            "duel-4-2-1-2": "audio/voiceover/en/generic/duel_subentry_duel-4_subentry_duel-4-2_subentry_duel-4-2-1_subentry_duel-4-2-1-2_1_text_value.mp3",
            "duel-4-2-2": "audio/voiceover/en/generic/duel_subentry_duel-4_subentry_duel-4-2_subentry_duel-4-2-2_1_text_value.mp3",
            "duel-4-2-2-1": "audio/voiceover/en/generic/duel_subentry_duel-4_subentry_duel-4-2_subentry_duel-4-2-2_subentry_duel-4-2-2-1_1_text_value.mp3",
            "duel-4-2-2-2": "audio/voiceover/en/generic/duel_subentry_duel-4_subentry_duel-4-2_subentry_duel-4-2-2_subentry_duel-4-2-2-2_1_text_value.mp3",
            "duel-5-1": "audio/voiceover/en/generic/duel_subentry_duel-5_subentry_duel-5-1_1_text_value.mp3",
            "duel-5-2": "audio/voiceover/en/generic/duel_subentry_duel-5_subentry_duel-5-2_1_text_value.mp3",
            "duel-6": "audio/voiceover/en/generic/duel_subentry_duel-6_1_text_value.mp3",
            "duel-6-1": "audio/voiceover/en/generic/duel_subentry_duel-6_subentry_duel-6-1_1_text_value.mp3",
            "duel-6-2": "audio/voiceover/en/generic/duel_subentry_duel-6_subentry_duel-6-2_1_text_value.mp3",
            "duel-7": "audio/voiceover/en/generic/duel_subentry_duel-7_1_text_value.mp3",
            "duel-8": "audio/voiceover/en/generic/duel_subentry_duel-8_1_text_value.mp3",
            "duel-9": "audio/voiceover/en/generic/duel_subentry_duel-9_1_text_value.mp3",
            "duel-9-1": "audio/voiceover/en/generic/duel_subentry_duel-9_subentry_duel-9-1_1_text_value.mp3",
            "duel-9-2": "audio/voiceover/en/generic/duel_subentry_duel-9_subentry_duel-9-2_1_text_value.mp3",
            "duel-10": "audio/voiceover/en/generic/duel_subentry_duel-10_1_text_value.mp3",
            "duel-11": "audio/voiceover/en/generic/duel_subentry_duel-11_1_text_value.mp3",
            "duel-11-1": "audio/voiceover/en/generic/duel_subentry_duel-11_subentry_duel-11-1_1_text_value.mp3",
            "duel-11-2": "audio/voiceover/en/generic/duel_subentry_duel-11_subentry_duel-11-2_1_text_value.mp3",
            "duel-12": "audio/voiceover/en/generic/duel_subentry_duel-12_1_text_value.mp3",
            "duel-13": "audio/voiceover/en/generic/duel_subentry_duel-13_1_text_value.mp3",
            "duel-13-1": "audio/voiceover/en/generic/duel_subentry_duel-13_subentry_duel-13-1_1_text_value.mp3",
            "duel-13-2": "audio/voiceover/en/generic/duel_subentry_duel-13_subentry_duel-13-2_1_text_value.mp3",
            "duel-14": "audio/voiceover/en/generic/duel_subentry_duel-14_1_text_value.mp3",
            "duel-15": "audio/voiceover/en/generic/duel_subentry_duel-15_1_text_value.mp3",
            "duel-15-1": "audio/voiceover/en/generic/duel_subentry_duel-15_subentry_duel-15-1_1_text_value.mp3",
            "duel-15-2": "audio/voiceover/en/generic/duel_subentry_duel-15_subentry_duel-15-2_1_text_value.mp3",
            "duel-16": "audio/voiceover/en/generic/duel_subentry_duel-16_1_text_value.mp3",
            "duel-17": "audio/voiceover/en/generic/duel_subentry_duel-17_1_text_value.mp3",
            "duel-17-1": "audio/voiceover/en/generic/duel_subentry_duel-17_subentry_duel-17-1_1_text_value.mp3",
            "duel-17-2": "audio/voiceover/en/generic/duel_subentry_duel-17_subentry_duel-17-2_1_text_value.mp3",
            "duel-18": "audio/voiceover/en/generic/duel_subentry_duel-18_1_text_value.mp3",
            "duel-19": "audio/voiceover/en/generic/duel_subentry_duel-19_1_text_value.mp3",
            "duel-20": "audio/voiceover/en/generic/duel_subentry_duel-20_1_text_value.mp3",
            "duel-20-1": "audio/voiceover/en/generic/duel_subentry_duel-20_subentry_duel-20-1_1_text_value.mp3",
            "duel-20-1-1": "audio/voiceover/en/generic/duel_subentry_duel-20_subentry_duel-20-1_subentry_duel-20-1-1_1_text_value.mp3",
            "duel-20-1-2": "audio/voiceover/en/generic/duel_subentry_duel-20_subentry_duel-20-1_subentry_duel-20-1-2_1_text_value.mp3",
            "duel-20-1-3-1": "audio/voiceover/en/generic/32eb10c_ry_duel-20_subentry_duel-20-1_subentry_duel-20-1-3_subentry_duel-20-1-3-1_1_text_value.mp3",
            "duel-20-1-3-2": "audio/voiceover/en/generic/e9a86c6e_ry_duel-20_subentry_duel-20-1_subentry_duel-20-1-3_subentry_duel-20-1-3-2_1_text_value.mp3",
            "duel-20-2": "audio/voiceover/en/generic/duel_subentry_duel-20_subentry_duel-20-2_1_text_value.mp3",
            "duel-20-2-1": "audio/voiceover/en/generic/duel_subentry_duel-20_subentry_duel-20-2_subentry_duel-20-2-1_1_text_value.mp3",
            "duel-20-2-2": "audio/voiceover/en/generic/duel_subentry_duel-20_subentry_duel-20-2_subentry_duel-20-2-2_1_text_value.mp3",
            "duel-20-2-3-1": "audio/voiceover/en/generic/f23f2190_ry_duel-20_subentry_duel-20-2_subentry_duel-20-2-3_subentry_duel-20-2-3-1_1_text_value.mp3",
            "duel-20-2-3-2": "audio/voiceover/en/generic/18b9fcf2_ry_duel-20_subentry_duel-20-2_subentry_duel-20-2-3_subentry_duel-20-2-3-2_1_text_value.mp3",
            "duel-20-2-4": "audio/voiceover/en/generic/duel_subentry_duel-20_subentry_duel-20-2_subentry_duel-20-2-4_1_text_value.mp3",
            "duel-20-3": "audio/voiceover/en/generic/duel_subentry_duel-20_subentry_duel-20-3_1_text_value.mp3",
            "sidequest-1-1-1-1-1-1": "audio/voiceover/en/generic/c98535ef__sbntry_sdqst-1-1-1_sbntry_sdqst-1-1-1-1_sbntry_sdqst11111_sbntry_sdqst111111_1_txt_vl.mp3",
            "sidequest-2-1-1-1-1-1": "audio/voiceover/en/generic/3307462b__sbntry_sdqst-2-1-1_sbntry_sdqst-2-1-1-1_sbntry_sdqst21111_sbntry_sdqst211111_1_txt_vl.mp3",
            "sidequest-2-1-1-1-1-2": "audio/voiceover/en/generic/4f6663f0__sbntry_sdqst-2-1-1_sbntry_sdqst-2-1-1-1_sbntry_sdqst21111_sbntry_sdqst211112_1_txt_vl.mp3",
            "sidequest-3-1-1-1-1-1": "audio/voiceover/en/generic/65796897__sbntry_sdqst-3-1-1_sbntry_sdqst-3-1-1-1_sbntry_sdqst31111_sbntry_sdqst311111_1_txt_vl.mp3",
            "sidequest-1-1-1-2-1-1-1": "audio/voiceover/en/generic/2100f724_ntry_sdqst111_sbntry_sdqst1112_sbntry_sdqst11121_sbntry_sdqst111211_sbntry_1112111_1__.mp3",
            "sidequest-2-1-1-1-1-1-1": "audio/voiceover/en/generic/30d0487_ntry_sdqst211_sbntry_sdqst2111_sbntry_sdqst21111_sbntry_sdqst211111_sbntry_2111111_1__.mp3",
            "sidequest-2-1-1-1-1-1-2": "audio/voiceover/en/generic/3a803842_ntry_sdqst211_sbntry_sdqst2111_sbntry_sdqst21111_sbntry_sdqst211111_sbntry_2111112_1__.mp3",
            "sidequest-2-1-1-1-1-2-1": "audio/voiceover/en/generic/b02416ac_ntry_sdqst211_sbntry_sdqst2111_sbntry_sdqst21111_sbntry_sdqst211112_sbntry_2111121_1__.mp3",
            "sidequest-3-1-1-1-1-1-1": "audio/voiceover/en/generic/8084c87c_ntry_sdqst311_sbntry_sdqst3111_sbntry_sdqst31111_sbntry_sdqst311111_sbntry_3111111_1__.mp3",
            "sidequest-1-1-1-2-1": "audio/voiceover/en/generic/ec406aea_ry_sideqst-1-1_sbntry_sdqst-1-1-1_sbntry_sdqst-1-1-1-2_sbntry_sdqst-1-1-1-2-1_1_txt_vl.mp3",
            "sidequest-1-1": "audio/voiceover/en/generic/sidequest_subentry_sidequest-1_subentry_sidequest-1-1_1_text_value.mp3",
            "sidequest-1-1-1-2": "audio/voiceover/en/generic/4327107b_ubentry_sidequest-1-1_subentry_sidequest-1-1-1_subentry_sidequest-1-1-1-2_1_text_value.mp3",
            "sidequest-1-1-1-3": "audio/voiceover/en/generic/ac75a69a_ubentry_sidequest-1-1_subentry_sidequest-1-1-1_subentry_sidequest-1-1-1-3_1_text_value.mp3",
            "sidequest-2-1": "audio/voiceover/en/generic/sidequest_subentry_sidequest-2_subentry_sidequest-2-1_1_text_value.mp3",
            "sidequest-2-1-1": "audio/voiceover/en/generic/sidequest_subentry_sidequest-2_subentry_sidequest-2-1_subentry_sidequest-2-1-1_1_text_value.mp3",
            "sidequest-2-1-1-1": "audio/voiceover/en/generic/6018cf93_ubentry_sidequest-2-1_subentry_sidequest-2-1-1_subentry_sidequest-2-1-1-1_1_text_value.mp3",
            "sidequest-3-1": "audio/voiceover/en/generic/sidequest_subentry_sidequest-3_subentry_sidequest-3-1_1_text_value.mp3",
            "sidequest-3-1-1": "audio/voiceover/en/generic/sidequest_subentry_sidequest-3_subentry_sidequest-3-1_subentry_sidequest-3-1-1_1_text_value.mp3",
            "sidequest-1-1-1-2-1-1-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-1-1-1-2-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-1-1-1-2-1-1-1-1-1-2": "audio/voiceover/en/generic/subentry_sidequest-1-1-1-2-1-1-1-1-1-2_1_text_value.mp3",
            "sidequest-1-1-1-2-1-1-1-1-1-2-1": "audio/voiceover/en/generic/subentry_sidequest-1-1-1-2-1-1-1-1-1-2-1_1_text_value.mp3",
            "sidequest-1-1-1-2-1-1-1-1-1-2-1-1": "audio/voiceover/en/generic/subentry_sidequest-1-1-1-2-1-1-1-1-1-2-1-1_1_text_value.mp3",
            "sidequest-1-1-1-2-1-1-1-1-1-2-1-2-1-1": "audio/voiceover/en/generic/subentry_sidequest-1-1-1-2-1-1-1-1-1-2-1-2-1-1_1_text_value.mp3",
            "sidequest-1-1-1-2-1-1-1-1-1-2-1-2-1-2": "audio/voiceover/en/generic/subentry_sidequest-1-1-1-2-1-1-1-1-1-2-1-2-1-2_1_text_value.mp3",
            "sidequest-1-1-1-2-1-1-2": "audio/voiceover/en/generic/subentry_sidequest-1-1-1-2-1-1-2_1_text_value.mp3",
            "sidequest-2-1-1-1-1-2-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-2-1-1-1-1-2-1-1-1_1_text_value.mp3",
            "sidequest-2-1-1-1-1-2-1-1-2": "audio/voiceover/en/generic/subentry_sidequest-2-1-1-1-1-2-1-1-2_1_text_value.mp3",
            "sidequest-2-1-1-1-1-2-1-1-2-1": "audio/voiceover/en/generic/subentry_sidequest-2-1-1-1-1-2-1-1-2-1_1_text_value.mp3",
            "sidequest-2-1-1-1-1-2-1-1-2-1-1-2": "audio/voiceover/en/generic/subentry_sidequest-2-1-1-1-1-2-1-1-2-1-1-2_1_text_value.mp3",
            "sidequest-2-1-1-1-1-2-1-1-2-1-1-2-1": "audio/voiceover/en/generic/subentry_sidequest-2-1-1-1-1-2-1-1-2-1-1-2-1_1_text_value.mp3",
            "sidequest-2-1-1-1-1-2-1-1-3": "audio/voiceover/en/generic/subentry_sidequest-2-1-1-1-1-2-1-1-3_1_text_value.mp3",
            "sidequest-3-1-1-1-1-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-3-1-1-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-3-1-1-1-1-1-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-3-1-1-1-1-1-1-1-1-1_1_text_value.mp3",
            "1-1": "audio/voiceover/en/generic/1_subentry_1-1_1_text_value.mp3",
            "1-page23-1": "audio/voiceover/en/generic/1_subentry_1-page23-1_1_text_value.mp3",
            "1-page27-1": "audio/voiceover/en/generic/1_subentry_1-page27-1_1_text_value.mp3",
            "1-page49-1": "audio/voiceover/en/generic/1_subentry_1-page49-1_1_text_value.mp3",
            "2-1": "audio/voiceover/en/generic/2_subentry_2-1_1_text_value.mp3",
            "2-page23-1": "audio/voiceover/en/generic/2_subentry_2-page23-1_1_text_value.mp3",
            "2-page27-1": "audio/voiceover/en/generic/2_subentry_2-page27-1_1_text_value.mp3",
            "2-page49-1": "audio/voiceover/en/generic/2_subentry_2-page49-1_1_text_value.mp3",
            "3-1": "audio/voiceover/en/generic/3_subentry_3-1_1_text_value.mp3",
            "3-page23-1": "audio/voiceover/en/generic/3_subentry_3-page23-1_1_text_value.mp3",
            "3-page27-1": "audio/voiceover/en/generic/3_subentry_3-page27-1_1_text_value.mp3",
            "3-page49-1": "audio/voiceover/en/generic/3_subentry_3-page49-1_1_text_value.mp3",
            "4-1-1": "audio/voiceover/en/generic/4_subentry_4-1_subentry_4-1-1_1_text_value.mp3",
            "4-1-2": "audio/voiceover/en/generic/4_subentry_4-1_subentry_4-1-2_1_text_value.mp3",
            "4-page19-1-1": "audio/voiceover/en/generic/4_subentry_4-page19-1_subentry_4-page19-1-1_1_text_value.mp3",
            "4-page19-1-2": "audio/voiceover/en/generic/4_subentry_4-page19-1_subentry_4-page19-1-2_1_text_value.mp3",
            "23-1": "audio/voiceover/en/generic/4_subentry_4-page23-1_1_text_value.mp3",
            "27-1": "audio/voiceover/en/generic/4_subentry_4-page27-1_1_text_value.mp3",
            "5-1-1": "audio/voiceover/en/generic/5_subentry_5-1_subentry_5-1-1_1_text_value.mp3",
            "5-1-2": "audio/voiceover/en/generic/5_subentry_5-1_subentry_5-1-2_1_text_value.mp3",
            "5-page19-1-2": "audio/voiceover/en/generic/5_subentry_5-page19-1_subentry_5-page19-1-2_1_text_value.mp3",
            "5-page23-1": "audio/voiceover/en/generic/5_subentry_5-page23-1_1_text_value.mp3",
            "4-page23-1": "audio/voiceover/en/generic/5_subentry_5-page23-1_1_text_value.mp3",
            "9-page23-1": "audio/voiceover/en/generic/5_subentry_5-page23-1_1_text_value.mp3",
            "10-page23-1": "audio/voiceover/en/generic/5_subentry_5-page23-1_1_text_value.mp3",
            "5-page27-1": "audio/voiceover/en/generic/5_subentry_5-page27-1_1_text_value.mp3",
            "5-page49-1": "audio/voiceover/en/generic/5_subentry_5-page49-1_1_text_value.mp3",
            "4-page49-1": "audio/voiceover/en/generic/5_subentry_5-page49-1_1_text_value.mp3",
            "16-page49-1": "audio/voiceover/en/generic/5_subentry_5-page49-1_1_text_value.mp3",
            "6-1-1": "audio/voiceover/en/generic/6_subentry_6-1_subentry_6-1-1_1_text_value.mp3",
            "6-page6-1-2": "audio/voiceover/en/generic/6_subentry_6-1_subentry_6-1-2_1_text_value.mp3",
            "6-page19-1": "audio/voiceover/en/generic/6_subentry_6-page19-1_1_text_value.mp3",
            "6-page21-1": "audio/voiceover/en/generic/6_subentry_6-page21-1_1_text_value.mp3",
            "6-page23-1-1": "audio/voiceover/en/generic/6_subentry_6-page23-1_subentry_6-page23-1-1_1_text_value.mp3",
            "6-page23-1-2": "audio/voiceover/en/generic/6_subentry_6-page23-1_subentry_6-page23-1-2_1_text_value.mp3",
            "6-page27-1": "audio/voiceover/en/generic/6_subentry_6-page27-1_1_text_value.mp3",
            "4-page27-1": "audio/voiceover/en/generic/6_subentry_6-page27-1_1_text_value.mp3",
            "24-page27-1": "audio/voiceover/en/generic/6_subentry_6-page27-1_1_text_value.mp3",
            "6-page49-1-1": "audio/voiceover/en/generic/6_subentry_6-page49-1_subentry_6-page49-1-1_1_text_value.mp3",
            "6-page49-1-2": "audio/voiceover/en/generic/6_subentry_6-page49-1_subentry_6-page49-1-2_1_text_value.mp3",
            "7-1-1": "audio/voiceover/en/generic/7_subentry_7-1_subentry_7-1-1_1_text_value.mp3",
            "7-1-2": "audio/voiceover/en/generic/7_subentry_7-1_subentry_7-1-2_1_text_value.mp3",
            "7-page19-1-1": "audio/voiceover/en/generic/7_subentry_7-page19-1_subentry_7-page19-1-1_1_text_value.mp3",
            "7-page19-1-2": "audio/voiceover/en/generic/7_subentry_7-page19-1_subentry_7-page19-1-2_1_text_value.mp3",
            "7-page21-1": "audio/voiceover/en/generic/7_subentry_7-page21-1_1_text_value.mp3",
            "7-page23-1": "audio/voiceover/en/generic/7_subentry_7-page23-1_1_text_value.mp3",
            "7-page27-1": "audio/voiceover/en/generic/7_subentry_7-page27-1_1_text_value.mp3",
            "7-page49-1": "audio/voiceover/en/generic/7_subentry_7-page49-1_1_text_value.mp3",
            "8-1-1": "audio/voiceover/en/generic/8_subentry_8-1_subentry_8-1-1_1_text_value.mp3",
            "8-1-2": "audio/voiceover/en/generic/8_subentry_8-1_subentry_8-1-2_1_text_value.mp3",
            "8-1-2-1": "audio/voiceover/en/generic/8_subentry_8-1_subentry_8-1-2_subentry_8-1-2-1_1_text_value.mp3",
            "8-1-2-1-1": "audio/voiceover/en/generic/8_subentry_8-1_subentry_8-1-2_subentry_8-1-2-1_subentry_8-1-2-1-1_1_text_value.mp3",
            "8-1-2-1-2": "audio/voiceover/en/generic/8_subentry_8-1_subentry_8-1-2_subentry_8-1-2-1_subentry_8-1-2-1-2_1_text_value.mp3",
            "8-1-2-1-2-1": "audio/voiceover/en/generic/58311985_1_subentry_8-1-2_subentry_8-1-2-1_subentry_8-1-2-1-2_subentry_8-1-2-1-2-1_1_text_value.mp3",
            "8-1-2-1-2-1-1": "audio/voiceover/en/generic/b50af8d8_ry_8-1-2-1_subentry_8-1-2-1-2_subentry_8-1-2-1-2-1_subentry_8-1-2-1-2-1-1_1_text_value.mp3",
            "8-1-2-1-2-1-2": "audio/voiceover/en/generic/5f8c25ba_ry_8-1-2-1_subentry_8-1-2-1-2_subentry_8-1-2-1-2-1_subentry_8-1-2-1-2-1-2_1_text_value.mp3",
            "8-page19-1-1": "audio/voiceover/en/generic/8_subentry_8-page19-1_subentry_8-page19-1-1_1_text_value.mp3",
            "8-page19-1-2": "audio/voiceover/en/generic/8_subentry_8-page19-1_subentry_8-page19-1-2_1_text_value.mp3",
            "8-page21-1": "audio/voiceover/en/generic/8_subentry_8-page21-1_1_text_value.mp3",
            "8-page23-1": "audio/voiceover/en/generic/8_subentry_8-page23-1_1_text_value.mp3",
            "8-page27-1": "audio/voiceover/en/generic/8_subentry_8-page27-1_1_text_value.mp3",
            "8-page49-1": "audio/voiceover/en/generic/8_subentry_8-page49-1_1_text_value.mp3",
            "9-1-1": "audio/voiceover/en/generic/9_subentry_9-1_subentry_9-1-1_1_text_value.mp3",
            "9-1-2": "audio/voiceover/en/generic/9_subentry_9-1_subentry_9-1-2_1_text_value.mp3",
            "9-page19-1-1": "audio/voiceover/en/generic/9_subentry_9-page19-1_subentry_9-page19-1-1_1_text_value.mp3",
            "9-page19-1-2": "audio/voiceover/en/generic/9_subentry_9-page19-1_subentry_9-page19-1-2_1_text_value.mp3",
            "9-page21-1": "audio/voiceover/en/generic/9_subentry_9-page21-1_1_text_value.mp3",
            "9-page27-1": "audio/voiceover/en/generic/9_subentry_9-page27-1_1_text_value.mp3",
            "9-page49-1-1": "audio/voiceover/en/generic/9_subentry_9-page49-1_subentry_9-page49-1-1_1_text_value.mp3",
            "9-page49-1-2": "audio/voiceover/en/generic/9_subentry_9-page49-1_subentry_9-page49-1-2_1_text_value.mp3",
            "10-1-2-1-1-1-1-1": "audio/voiceover/en/generic/b689df1e_0-1-2-1_sbntry_10-1-2-1-1_sbntry_10-1-2-1-11_sbntry_10121111_sbntry_101211111_1_txt_vl.mp3",
            "10-1-2-1-1-1-1-1-1-1": "audio/voiceover/en/generic/759d3d58_1_sbntry_101211_sbntry_1012111_sbntry_10121111__101211111__1012111111__10121111111_1__.mp3",
            "10-1-2-1-1-1-1-1-1-2": "audio/voiceover/en/generic/4c10019d_1_sbntry_101211_sbntry_1012111_sbntry_10121111__101211111__1012111111__10121111112_1__.mp3",
            "10-1-1": "audio/voiceover/en/generic/10_subentry_10-1_subentry_10-1-1_1_text_value.mp3",
            "10-1-2": "audio/voiceover/en/generic/10_subentry_10-1_subentry_10-1-2_1_text_value.mp3",
            "10-1-2-1": "audio/voiceover/en/generic/10_subentry_10-1_subentry_10-1-2_subentry_10-1-2-1_1_text_value.mp3",
            "10-1-2-1-1": "audio/voiceover/en/generic/10_subentry_10-1_subentry_10-1-2_subentry_10-1-2-1_subentry_10-1-2-1-1_1_text_value.mp3",
            "10-1-2-1-1-1": "audio/voiceover/en/generic/951d78c6_bentry_10-1-2_subentry_10-1-2-1_subentry_10-1-2-1-1_subentry_10-1-2-1-1-1_1_text_value.mp3",
            "10-1-2-2": "audio/voiceover/en/generic/10_subentry_10-1_subentry_10-1-2_subentry_10-1-2-2_1_text_value.mp3",
            "10-page19-1": "audio/voiceover/en/generic/10_subentry_10-page19-1_1_text_value.mp3",
            "10-page21-1": "audio/voiceover/en/generic/10_subentry_10-page21-1_1_text_value.mp3",
            "10-page27-1": "audio/voiceover/en/generic/10_subentry_10-page27-1_1_text_value.mp3",
            "10-page49-1": "audio/voiceover/en/generic/10_subentry_10-page49-1_1_text_value.mp3",
            "11-1-1": "audio/voiceover/en/generic/11_subentry_11-1_subentry_11-1-1_1_text_value.mp3",
            "11-1-2": "audio/voiceover/en/generic/11_subentry_11-1_subentry_11-1-2_1_text_value.mp3",
            "11-page19-1-1": "audio/voiceover/en/generic/11_subentry_11-page19-1_subentry_11-page19-1-1_1_text_value.mp3",
            "11-page19-1-2": "audio/voiceover/en/generic/11_subentry_11-page19-1_subentry_11-page19-1-2_1_text_value.mp3",
            "11-page21-1-1": "audio/voiceover/en/generic/11_subentry_11-page21-1_subentry_11-page21-1-1_1_text_value.mp3",
            "11-page21-1-2": "audio/voiceover/en/generic/11_subentry_11-page21-1_subentry_11-page21-1-2_1_text_value.mp3",
            "11-page23-1": "audio/voiceover/en/generic/11_subentry_11-page23-1_1_text_value.mp3",
            "11-page27-1-1": "audio/voiceover/en/generic/11_subentry_11-page27-1_subentry_11-page27-1-1_1_text_value.mp3",
            "11-page49-1-2": "audio/voiceover/en/generic/11_subentry_11-page49-1_subentry_11-page49-1-2_1_text_value.mp3",
            "12-1-1": "audio/voiceover/en/generic/12_subentry_12-1_subentry_12-1-1_1_text_value.mp3",
            "12-1-2": "audio/voiceover/en/generic/12_subentry_12-1_subentry_12-1-2_1_text_value.mp3",
            "12-page19-1-1": "audio/voiceover/en/generic/12_subentry_12-page19-1_subentry_12-page19-1-1_1_text_value.mp3",
            "12-page19-1-2": "audio/voiceover/en/generic/12_subentry_12-page19-1_subentry_12-page19-1-2_1_text_value.mp3",
            "12-page21-1": "audio/voiceover/en/generic/12_subentry_12-page21-1_1_text_value.mp3",
            "12-page23-1": "audio/voiceover/en/generic/12_subentry_12-page23-1_1_text_value.mp3",
            "12-page27-1": "audio/voiceover/en/generic/12_subentry_12-page27-1_1_text_value.mp3",
            "12-page49-1": "audio/voiceover/en/generic/12_subentry_12-page49-1_1_text_value.mp3",
            "13-1-1": "audio/voiceover/en/generic/13_subentry_13-1_subentry_13-1-1_1_text_value.mp3",
            "13-1-2": "audio/voiceover/en/generic/13_subentry_13-1_subentry_13-1-2_1_text_value.mp3",
            "13-page19-1-1": "audio/voiceover/en/generic/13_subentry_13-page19-1_subentry_13-page19-1-1_1_text_value.mp3",
            "13-page19-1-2": "audio/voiceover/en/generic/13_subentry_13-page19-1_subentry_13-page19-1-2_1_text_value.mp3",
            "13-page21-1-1": "audio/voiceover/en/generic/13_subentry_13-page21-1_subentry_13-page21-1-1_1_text_value.mp3",
            "13-page21-1-2": "audio/voiceover/en/generic/13_subentry_13-page21-1_subentry_13-page21-1-2_1_text_value.mp3",
            "13-page23-1": "audio/voiceover/en/generic/13_subentry_13-page23-1_1_text_value.mp3",
            "13-page27-1-1": "audio/voiceover/en/generic/13_subentry_13-page27-1_subentry_13-page27-1-1_1_text_value.mp3",
            "13-page27-1-2": "audio/voiceover/en/generic/13_subentry_13-page27-1_subentry_13-page27-1-2_1_text_value.mp3",
            "13-page49-1-1": "audio/voiceover/en/generic/13_subentry_13-page49-1_subentry_13-page49-1-1_1_text_value.mp3",
            "13-page49-1-2": "audio/voiceover/en/generic/13_subentry_13-page49-1_subentry_13-page49-1-2_1_text_value.mp3",
            "14-1-1": "audio/voiceover/en/generic/14_subentry_14-1_subentry_14-1-1_1_text_value.mp3",
            "14-1-2": "audio/voiceover/en/generic/14_subentry_14-1_subentry_14-1-2_1_text_value.mp3",
            "14-1-2-1": "audio/voiceover/en/generic/14_subentry_14-1_subentry_14-1-2_subentry_14-1-2-1_1_text_value.mp3",
            "14-1-2-2-2": "audio/voiceover/en/generic/14_subentry_14-1_subentry_14-1-2_subentry_14-1-2-2_subentry_14-1-2-2-2_1_text_value.mp3",
            "14-1-2-2-3": "audio/voiceover/en/generic/14_subentry_14-1_subentry_14-1-2_subentry_14-1-2-2_subentry_14-1-2-2-3_1_text_value.mp3",
            "14-1-2-2-4": "audio/voiceover/en/generic/14_subentry_14-1_subentry_14-1-2_subentry_14-1-2-2_subentry_14-1-2-2-4_1_text_value.mp3",
            "14-page19-1-1": "audio/voiceover/en/generic/14_subentry_14-page19-1_subentry_14-page19-1-1_1_text_value.mp3",
            "14-page19-1-2": "audio/voiceover/en/generic/14_subentry_14-page19-1_subentry_14-page19-1-2_1_text_value.mp3",
            "14-page21-1": "audio/voiceover/en/generic/14_subentry_14-page21-1_1_text_value.mp3",
            "14-page23-1-1": "audio/voiceover/en/generic/14_subentry_14-page23-1_subentry_14-page23-1-1_1_text_value.mp3",
            "14-page23-1-2": "audio/voiceover/en/generic/14_subentry_14-page23-1_subentry_14-page23-1-2_1_text_value.mp3",
            "14-page27-1": "audio/voiceover/en/generic/14_subentry_14-page27-1_1_text_value.mp3",
            "14-page49-1": "audio/voiceover/en/generic/14_subentry_14-page49-1_1_text_value.mp3",
            "15-1-1": "audio/voiceover/en/generic/15_subentry_15-1_subentry_15-1-1_1_text_value.mp3",
            "15-1-2": "audio/voiceover/en/generic/15_subentry_15-1_subentry_15-1-2_1_text_value.mp3",
            "15-1-2-1": "audio/voiceover/en/generic/15_subentry_15-1_subentry_15-1-2_subentry_15-1-2-1_1_text_value.mp3",
            "15-1-2-2": "audio/voiceover/en/generic/15_subentry_15-1_subentry_15-1-2_subentry_15-1-2-2_1_text_value.mp3",
            "15-page19-1": "audio/voiceover/en/generic/15_subentry_15-page19-1_1_text_value.mp3",
            "15-page21-1": "audio/voiceover/en/generic/15_subentry_15-page21-1_1_text_value.mp3",
            "15-page23-1": "audio/voiceover/en/generic/15_subentry_15-page23-1_1_text_value.mp3",
            "15-page27-1": "audio/voiceover/en/generic/15_subentry_15-page27-1_1_text_value.mp3",
            "15-page49-1-1": "audio/voiceover/en/generic/15_subentry_15-page49-1_subentry_15-page49-1-1_1_text_value.mp3",
            "15-page49-1-2": "audio/voiceover/en/generic/15_subentry_15-page49-1_subentry_15-page49-1-2_1_text_value.mp3",
            "16-1-1": "audio/voiceover/en/generic/16_subentry_16-1_subentry_16-1-1_1_text_value.mp3",
            "16-1-2": "audio/voiceover/en/generic/16_subentry_16-1_subentry_16-1-2_1_text_value.mp3",
            "16-page19-1": "audio/voiceover/en/generic/16_subentry_16-page19-1_1_text_value.mp3",
            "16-page21-1": "audio/voiceover/en/generic/16_subentry_16-page21-1_1_text_value.mp3",
            "16-page23-1": "audio/voiceover/en/generic/16_subentry_16-page23-1_1_text_value.mp3",
            "16-page27-1": "audio/voiceover/en/generic/16_subentry_16-page27-1_1_text_value.mp3",
            "17-1-1": "audio/voiceover/en/generic/17_subentry_17-1_subentry_17-1-1_1_text_value.mp3",
            "17-1-2": "audio/voiceover/en/generic/17_subentry_17-1_subentry_17-1-2_1_text_value.mp3",
            "17-1-2-1-1-1": "audio/voiceover/en/generic/5abf06e3_bentry_17-1-2_subentry_17-1-2-1_subentry_17-1-2-1-1_subentry_17-1-2-1-1-1_1_text_value.mp3",
            "17-1-2-1-1-2": "audio/voiceover/en/generic/b039db81_bentry_17-1-2_subentry_17-1-2-1_subentry_17-1-2-1-1_subentry_17-1-2-1-1-2_1_text_value.mp3",
            "17-1-2-2": "audio/voiceover/en/generic/17_subentry_17-1_subentry_17-1-2_subentry_17-1-2-2_1_text_value.mp3",
            "17-page19-1": "audio/voiceover/en/generic/17_subentry_17-page19-1_1_text_value.mp3",
            "17-page21-1": "audio/voiceover/en/generic/17_subentry_17-page21-1_1_text_value.mp3",
            "17-page23-1": "audio/voiceover/en/generic/17_subentry_17-page23-1_1_text_value.mp3",
            "17-page27-1": "audio/voiceover/en/generic/17_subentry_17-page27-1_1_text_value.mp3",
            "17-page49-1": "audio/voiceover/en/generic/17_subentry_17-page49-1_1_text_value.mp3",
            "18-1-1": "audio/voiceover/en/generic/18_subentry_18-1_subentry_18-1-1_1_text_value.mp3",
            "18-1-2": "audio/voiceover/en/generic/18_subentry_18-1_subentry_18-1-2_1_text_value.mp3",
            "18-1-2-1": "audio/voiceover/en/generic/18_subentry_18-1_subentry_18-1-2_subentry_18-1-2-1_1_text_value.mp3",
            "18-1-2-2": "audio/voiceover/en/generic/18_subentry_18-1_subentry_18-1-2_subentry_18-1-2-2_1_text_value.mp3",
            "18-page19-1": "audio/voiceover/en/generic/18_subentry_18-page19-1_1_text_value.mp3",
            "18-page21-1": "audio/voiceover/en/generic/18_subentry_18-page21-1_1_text_value.mp3",
            "18-page23-1": "audio/voiceover/en/generic/18_subentry_18-page23-1_1_text_value.mp3",
            "18-page27-1": "audio/voiceover/en/generic/18_subentry_18-page27-1_1_text_value.mp3",
            "18-page49-1": "audio/voiceover/en/generic/18_subentry_18-page49-1_1_text_value.mp3",
            "19-1-1": "audio/voiceover/en/generic/19_subentry_19-1_subentry_19-1-1_1_text_value.mp3",
            "19-1-2": "audio/voiceover/en/generic/19_subentry_19-1_subentry_19-1-2_1_text_value.mp3",
            "19-1-2-1": "audio/voiceover/en/generic/19_subentry_19-1_subentry_19-1-2_subentry_19-1-2-1_1_text_value.mp3",
            "19-1-2-2": "audio/voiceover/en/generic/19_subentry_19-1_subentry_19-1-2_subentry_19-1-2-2_1_text_value.mp3",
            "19-page19-1-1": "audio/voiceover/en/generic/19_subentry_19-page19-1_subentry_19-page19-1-1_1_text_value.mp3",
            "19-page19-1-2": "audio/voiceover/en/generic/19_subentry_19-page19-1_subentry_19-page19-1-2_1_text_value.mp3",
            "19-page21-1": "audio/voiceover/en/generic/19_subentry_19-page21-1_1_text_value.mp3",
            "19-page23-1": "audio/voiceover/en/generic/19_subentry_19-page23-1_1_text_value.mp3",
            "19-page27-1": "audio/voiceover/en/generic/19_subentry_19-page27-1_1_text_value.mp3",
            "19-page49-1-1": "audio/voiceover/en/generic/19_subentry_19-page49-1_subentry_19-page49-1-1_1_text_value.mp3",
            "19-page49-1-2": "audio/voiceover/en/generic/19_subentry_19-page49-1_subentry_19-page49-1-2_1_text_value.mp3",
            "20-1-1": "audio/voiceover/en/generic/20_subentry_20-1_subentry_20-1-1_1_text_value.mp3",
            "20-1-2": "audio/voiceover/en/generic/20_subentry_20-1_subentry_20-1-2_1_text_value.mp3",
            "20-page19-1": "audio/voiceover/en/generic/20_subentry_20-page19-1_1_text_value.mp3",
            "20-page21-1": "audio/voiceover/en/generic/20_subentry_20-page21-1_1_text_value.mp3",
            "20-page23-1": "audio/voiceover/en/generic/20_subentry_20-page23-1_1_text_value.mp3",
            "20-page27-1": "audio/voiceover/en/generic/20_subentry_20-page27-1_1_text_value.mp3",
            "20-page49-1-1": "audio/voiceover/en/generic/20_subentry_20-page49-1_subentry_20-page49-1-1_1_text_value.mp3",
            "20-page49-1-2": "audio/voiceover/en/generic/20_subentry_20-page49-1_subentry_20-page49-1-2_1_text_value.mp3",
            "21-1-1": "audio/voiceover/en/generic/21_subentry_21-1_subentry_21-1-1_1_text_value.mp3",
            "21-1-2": "audio/voiceover/en/generic/21_subentry_21-1_subentry_21-1-2_1_text_value.mp3",
            "21-page19-1-1": "audio/voiceover/en/generic/21_subentry_21-page19-1_subentry_21-page19-1-1_1_text_value.mp3",
            "21-page19-1-2": "audio/voiceover/en/generic/21_subentry_21-page19-1_subentry_21-page19-1-2_1_text_value.mp3",
            "21-page21-1-1": "audio/voiceover/en/generic/21_subentry_21-page21-1_subentry_21-page21-1-1_1_text_value.mp3",
            "21-page21-1-2": "audio/voiceover/en/generic/21_subentry_21-page21-1_subentry_21-page21-1-2_1_text_value.mp3",
            "21-page23-1": "audio/voiceover/en/generic/21_subentry_21-page23-1_1_text_value.mp3",
            "21-page27-1-1": "audio/voiceover/en/generic/21_subentry_21-page27-1_subentry_21-page27-1-1_1_text_value.mp3",
            "21-page27-1-2": "audio/voiceover/en/generic/21_subentry_21-page27-1_subentry_21-page27-1-2_1_text_value.mp3",
            "21-page49-1-1": "audio/voiceover/en/generic/21_subentry_21-page49-1_subentry_21-page49-1-1_1_text_value.mp3",
            "21-page49-1-2": "audio/voiceover/en/generic/21_subentry_21-page49-1_subentry_21-page49-1-2_1_text_value.mp3",
            "22-1-1": "audio/voiceover/en/generic/22_subentry_22-1_subentry_22-1-1_1_text_value.mp3",
            "22-1-2": "audio/voiceover/en/generic/22_subentry_22-1_subentry_22-1-2_1_text_value.mp3",
            "22-page21-1-1": "audio/voiceover/en/generic/22_subentry_22-page21-1_subentry_22-page21-1-1_1_text_value.mp3",
            "22-page21-1-2": "audio/voiceover/en/generic/22_subentry_22-page21-1_subentry_22-page21-1-2_1_text_value.mp3",
            "22-page23-1": "audio/voiceover/en/generic/22_subentry_22-page23-1_1_text_value.mp3",
            "22-page27-1-1": "audio/voiceover/en/generic/22_subentry_22-page27-1_subentry_22-page27-1-1_1_text_value.mp3",
            "22-page27-1-2": "audio/voiceover/en/generic/22_subentry_22-page27-1_subentry_22-page27-1-2_1_text_value.mp3",
            "22-page49-1-1": "audio/voiceover/en/generic/22_subentry_22-page49-1_subentry_22-page49-1-1_1_text_value.mp3",
            "22-page49-1-2": "audio/voiceover/en/generic/22_subentry_22-page49-1_subentry_22-page49-1-2_1_text_value.mp3",
            "23-1-1": "audio/voiceover/en/generic/23_subentry_23-1_subentry_23-1-1_1_text_value.mp3",
            "23-1-2": "audio/voiceover/en/generic/23_subentry_23-1_subentry_23-1-2_1_text_value.mp3",
            "23-page9-1-1": "audio/voiceover/en/generic/23_subentry_23-page9-1_subentry_23-page9-1-1_1_text_value.mp3",
            "23-page9-1-2": "audio/voiceover/en/generic/23_subentry_23-page9-1_subentry_23-page9-1-2_1_text_value.mp3",
            "23-page19-1": "audio/voiceover/en/generic/23_subentry_23-page19-1_1_text_value.mp3",
            "23-page21-1": "audio/voiceover/en/generic/23_subentry_23-page21-1_1_text_value.mp3",
            "23-page23-1": "audio/voiceover/en/generic/23_subentry_23-page23-1_1_text_value.mp3",
            "23-page27-1": "audio/voiceover/en/generic/23_subentry_23-page27-1_1_text_value.mp3",
            "23-page49-1": "audio/voiceover/en/generic/23_subentry_23-page49-1_1_text_value.mp3",
            "24-1-1": "audio/voiceover/en/generic/24_subentry_24-1_subentry_24-1-1_1_text_value.mp3",
            "24-1-1-1": "audio/voiceover/en/generic/24_subentry_24-1_subentry_24-1-1_subentry_24-1-1-1_1_text_value.mp3",
            "24-1-1-2": "audio/voiceover/en/generic/24_subentry_24-1_subentry_24-1-1_subentry_24-1-1-2_1_text_value.mp3",
            "24-1-1-3": "audio/voiceover/en/generic/24_subentry_24-1_subentry_24-1-1_subentry_24-1-1-3_1_text_value.mp3",
            "24-1-1-3-1": "audio/voiceover/en/generic/24_subentry_24-1_subentry_24-1-1_subentry_24-1-1-3_subentry_24-1-1-3-1_1_text_value.mp3",
            "24-1-1-3-2": "audio/voiceover/en/generic/24_subentry_24-1_subentry_24-1-1_subentry_24-1-1-3_subentry_24-1-1-3-2_1_text_value.mp3",
            "24-1-1-4": "audio/voiceover/en/generic/24_subentry_24-1_subentry_24-1-1_subentry_24-1-1-4_1_text_value.mp3",
            "24-1-2": "audio/voiceover/en/generic/24_subentry_24-1_subentry_24-1-2_1_text_value.mp3",
            "24-page19-1": "audio/voiceover/en/generic/24_subentry_24-page19-1_1_text_value.mp3",
            "24-page21-1": "audio/voiceover/en/generic/24_subentry_24-page21-1_1_text_value.mp3",
            "24-page23-1": "audio/voiceover/en/generic/24_subentry_24-page23-1_1_text_value.mp3",
            "24-page49-1-1": "audio/voiceover/en/generic/24_subentry_24-page49-1_subentry_24-page49-1-1_1_text_value.mp3",
            "24-page49-1-2": "audio/voiceover/en/generic/24_subentry_24-page49-1_subentry_24-page49-1-2_1_text_value.mp3",
            "26-1-1": "audio/voiceover/en/generic/26_subentry_26-1_subentry_26-1-1_1_text_value.mp3",
            "26-1-2": "audio/voiceover/en/generic/26_subentry_26-1_subentry_26-1-2_1_text_value.mp3",
            "26-page19-1-1": "audio/voiceover/en/generic/26_subentry_26-page19-1_subentry_26-page19-1-1_1_text_value.mp3",
            "26-page19-1-2": "audio/voiceover/en/generic/26_subentry_26-page19-1_subentry_26-page19-1-2_1_text_value.mp3",
            "26-page21-1-1": "audio/voiceover/en/generic/26_subentry_26-page21-1_subentry_26-page21-1-1_1_text_value.mp3",
            "26-page21-1-2": "audio/voiceover/en/generic/26_subentry_26-page21-1_subentry_26-page21-1-2_1_text_value.mp3",
            "26-page27-1-1": "audio/voiceover/en/generic/26_subentry_26-page27-1_subentry_26-page27-1-1_1_text_value.mp3",
            "26-page27-1-2": "audio/voiceover/en/generic/26_subentry_26-page27-1_subentry_26-page27-1-2_1_text_value.mp3",
            "26-page39-1": "audio/voiceover/en/generic/26_subentry_26-page39-1_1_text_value.mp3",
            "26-page39-1-1": "audio/voiceover/en/generic/26_subentry_26-page39-1_subentry_26-page39-1-1_1_text_value.mp3",
            "chaos-3-1-3-2": "audio/voiceover/en/generic/a56df53d_ry_chaos-3_subentry_chaos-3-1_subentry_chaos-3-1-3_subentry_chaos-3-1-3-2_1_text_value.mp3",
            "crossroad-job-bard-1-3-1": "audio/voiceover/en/generic/a7b9a264_-bard-1_subentry_crossroad-job-bard-1-3_subentry_crossroad-job-bard-1-3-1_1_text_value.mp3",
            "crossroad-job-disgracednoble-1-2": "audio/voiceover/en/generic/e07e8ef4__crossroad-job-disgracednoble-1_subentry_crossroad-job-disgracednoble-1-2_1_text_value.mp3",
            "crossroad-job-privateeye-1-3": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-privateeye-1_subentry_crossroad-job-privateeye-1-3_1_text_value.mp3",
            "crossroad-job-warrior-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-warrior-1_1_text_value.mp3",
            "crossroad-job-warrior-1-1-2": "audio/voiceover/en/generic/fb4ee9d5_ior-1_subentry_crossroad-job-warrior-1-1_subentry_crossroad-job-warrior-1-1-2_1_txt_vl.mp3",
            "crossroad-job-warrior-1-2": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-warrior-1_subentry_crossroad-job-warrior-1-2_1_text_value.mp3",
            "crossroad-job-warrior-1-2-1": "audio/voiceover/en/generic/2bd56a08_ior-1_subentry_crossroad-job-warrior-1-2_subentry_crossroad-job-warrior-1-2-1_1_txt_vl.mp3",
            "fortune-1": "audio/voiceover/en/generic/fortune_subentry_fortune-1_1_text_value.mp3",
            "law-1-1-1": "audio/voiceover/en/generic/law_subentry_law-1_subentry_law-1-1_subentry_law-1-1-1_1_text_value.mp3",
            "law-1-1-1-1-1": "audio/voiceover/en/generic/21653ad4_ry_law-1-1_subentry_law-1-1-1_subentry_law-1-1-1-1_subentry_law-1-1-1-1-1_1_text_value.mp3",
            "law-1-1-1-1-2": "audio/voiceover/en/generic/cbe3e7b6_ry_law-1-1_subentry_law-1-1-1_subentry_law-1-1-1-1_subentry_law-1-1-1-1-2_1_text_value.mp3",
            "law-1-1-1-2": "audio/voiceover/en/generic/law_subentry_law-1_subentry_law-1-1_subentry_law-1-1-1_subentry_law-1-1-1-2_1_text_value.mp3",
            "law-1-2": "audio/voiceover/en/generic/law_subentry_law-1_subentry_law-1-2_1_text_value.mp3",
            "law-1-2-1": "audio/voiceover/en/generic/law_subentry_law-1_subentry_law-1-2_subentry_law-1-2-1_1_text_value.mp3",
            "law-1-2-2": "audio/voiceover/en/generic/law_subentry_law-1_subentry_law-1-2_subentry_law-1-2-2_1_text_value.mp3",
            "law-1-2-3": "audio/voiceover/en/generic/law_subentry_law-1_subentry_law-1-2_subentry_law-1-2-3_1_text_value.mp3",
            "law-1-2-3-1-1": "audio/voiceover/en/generic/aded3ad5_ry_law-1-2_subentry_law-1-2-3_subentry_law-1-2-3-1_subentry_law-1-2-3-1-1_1_text_value.mp3",
            "law-1-2-3-1-2": "audio/voiceover/en/generic/476be7b7_ry_law-1-2_subentry_law-1-2-3_subentry_law-1-2-3-1_subentry_law-1-2-3-1-2_1_text_value.mp3",
            "law-1-2-3-1-3": "audio/voiceover/en/generic/a8395156_ry_law-1-2_subentry_law-1-2-3_subentry_law-1-2-3-1_subentry_law-1-2-3-1-3_1_text_value.mp3",
            "law-2": "audio/voiceover/en/generic/law_subentry_law-2_1_text_value.mp3",
            "law-2-1": "audio/voiceover/en/generic/law_subentry_law-2_subentry_law-2-1_1_text_value.mp3",
            "law-2-1-1": "audio/voiceover/en/generic/law_subentry_law-2_subentry_law-2-1_subentry_law-2-1-1_1_text_value.mp3",
            "law-2-1-1-1": "audio/voiceover/en/generic/law_subentry_law-2_subentry_law-2-1_subentry_law-2-1-1_subentry_law-2-1-1-1_1_text_value.mp3",
            "law-2-1-1-2": "audio/voiceover/en/generic/law_subentry_law-2_subentry_law-2-1_subentry_law-2-1-1_subentry_law-2-1-1-2_1_text_value.mp3",
            "law-2-1-1-2-1": "audio/voiceover/en/generic/4bad0531_ry_law-2-1_subentry_law-2-1-1_subentry_law-2-1-1-2_subentry_law-2-1-1-2-1_1_text_value.mp3",
            "law-2-1-1-3": "audio/voiceover/en/generic/law_subentry_law-2_subentry_law-2-1_subentry_law-2-1-1_subentry_law-2-1-1-3_1_text_value.mp3",
            "law-2-1-2": "audio/voiceover/en/generic/law_subentry_law-2_subentry_law-2-1_subentry_law-2-1-2_1_text_value.mp3",
            "law-2-1-2-1-1": "audio/voiceover/en/generic/f65c6fc5_ry_law-2-1_subentry_law-2-1-2_subentry_law-2-1-2-1_subentry_law-2-1-2-1-1_1_text_value.mp3",
            "law-2-1-2-1-2": "audio/voiceover/en/generic/1cdab2a7_ry_law-2-1_subentry_law-2-1-2_subentry_law-2-1-2-1_subentry_law-2-1-2-1-2_1_text_value.mp3",
            "law-3-1": "audio/voiceover/en/generic/law_subentry_law-3_subentry_law-3-1_1_text_value.mp3",
            "law-4": "audio/voiceover/en/generic/law_subentry_law-4_1_text_value.mp3",
            "law-4-1": "audio/voiceover/en/generic/law_subentry_law-4_subentry_law-4-1_1_text_value.mp3",
            "law-4-1-1": "audio/voiceover/en/generic/law_subentry_law-4_subentry_law-4-1_subentry_law-4-1-1_1_text_value.mp3",
            "law-4-1-2-1": "audio/voiceover/en/generic/law_subentry_law-4_subentry_law-4-1_subentry_law-4-1-2_subentry_law-4-1-2-1_1_text_value.mp3",
            "law-4-1-2-2": "audio/voiceover/en/generic/law_subentry_law-4_subentry_law-4-1_subentry_law-4-1-2_subentry_law-4-1-2-2_1_text_value.mp3",
            "law-4-1-2-2-1": "audio/voiceover/en/generic/f6ed1eb7_ry_law-4-1_subentry_law-4-1-2_subentry_law-4-1-2-2_subentry_law-4-1-2-2-1_1_text_value.mp3",
            "law-4-1-2-2-2": "audio/voiceover/en/generic/1c6bc3d5_ry_law-4-1_subentry_law-4-1-2_subentry_law-4-1-2-2_subentry_law-4-1-2-2-2_1_text_value.mp3",
            "law-4-1-2-2-3": "audio/voiceover/en/generic/f3397534_ry_law-4-1_subentry_law-4-1-2_subentry_law-4-1-2-2_subentry_law-4-1-2-2-3_1_text_value.mp3",
            "law-4-1-2-2-4": "audio/voiceover/en/generic/12177f50_ry_law-4-1_subentry_law-4-1-2_subentry_law-4-1-2-2_subentry_law-4-1-2-2-4_1_text_value.mp3",
            "law-5": "audio/voiceover/en/generic/law_subentry_law-5_1_text_value.mp3",
            "law-5-1": "audio/voiceover/en/generic/law_subentry_law-5_subentry_law-5-1_1_text_value.mp3",
            "law-5-2": "audio/voiceover/en/generic/law_subentry_law-5_subentry_law-5-2_1_text_value.mp3",
            "law-5-2-1": "audio/voiceover/en/generic/law_subentry_law-5_subentry_law-5-2_subentry_law-5-2-1_1_text_value.mp3",
            "law-5-2-2": "audio/voiceover/en/generic/law_subentry_law-5_subentry_law-5-2_subentry_law-5-2-2_1_text_value.mp3",
            "law-5-2-3": "audio/voiceover/en/generic/law_subentry_law-5_subentry_law-5-2_subentry_law-5-2-3_1_text_value.mp3",
            "law-2-1-1-2-1-1-1": "audio/voiceover/en/generic/853661e5_w-2-1-1_sbntry_lw-2-1-1-2_sbntry_lw-2-1-1-21_sbntry_lw211211_sbntry_lw2112111_1_txt_vl.mp3",
            "law-2-1-1-2-1-1-1-1": "audio/voiceover/en/generic/6439a720_1_sbntry_lw2112_sbntry_lw21121_sbntry_lw211211_sbntry_lw2112111_sbntry_lw21121111_1_t_.mp3",
            "sidequest-4-1-1-1-1-1": "audio/voiceover/en/generic/1d72a7e2__sbntry_sdqst-4-1-1_sbntry_sdqst-4-1-1-1_sbntry_sdqst41111_sbntry_sdqst411111_1_txt_vl.mp3",
            "sidequest-6-1-1-1-1-1": "audio/voiceover/en/generic/b18efa9a__sbntry_sdqst-6-1-1_sbntry_sdqst-6-1-1-1_sbntry_sdqst61111_sbntry_sdqst611111_1_txt_vl.mp3",
            "sidequest-6-1-1-1-1-1-2": "audio/voiceover/en/generic/5934016d_ntry_sdqst611_sbntry_sdqst6111_sbntry_sdqst61111_sbntry_sdqst611111_sbntry_6111112_1__.mp3",
            "sidequest-3-1-1-2": "audio/voiceover/en/generic/7b261148_ubentry_sidequest-3-1_subentry_sidequest-3-1-1_subentry_sidequest-3-1-1-2_1_text_value.mp3",
            "sidequest-4-1-1-1-1": "audio/voiceover/en/generic/f2653c83_ry_sideqst-4-1_sbntry_sdqst-4-1-1_sbntry_sdqst-4-1-1-1_sbntry_sdqst-4-1-1-1-1_1_txt_vl.mp3",
            "sidequest-4-1": "audio/voiceover/en/generic/sidequest_subentry_sidequest-4_subentry_sidequest-4-1_1_text_value.mp3",
            "sidequest-4-1-1-1": "audio/voiceover/en/generic/281bccc6_ubentry_sidequest-4-1_subentry_sidequest-4-1-1_subentry_sidequest-4-1-1-1_1_text_value.mp3",
            "sidequest-5-1-1-1-1": "audio/voiceover/en/generic/3579acce_ry_sideqst-5-1_sbntry_sdqst-5-1-1_sbntry_sdqst-5-1-1-1_sbntry_sdqst-5-1-1-1-1_1_txt_vl.mp3",
            "sidequest-5-1-1-2-2": "audio/voiceover/en/generic/3ab10287_ry_sideqst-5-1_sbntry_sdqst-5-1-1_sbntry_sdqst-5-1-1-2_sbntry_sdqst-5-1-1-2-2_1_txt_vl.mp3",
            "sidequest-5-1": "audio/voiceover/en/generic/sidequest_subentry_sidequest-5_subentry_sidequest-5-1_1_text_value.mp3",
            "sidequest-5-1-1-1": "audio/voiceover/en/generic/d9a3cf7f_ubentry_sidequest-5-1_subentry_sidequest-5-1-1_subentry_sidequest-5-1-1-1_1_text_value.mp3",
            "sidequest-5-1-1-2": "audio/voiceover/en/generic/3325121d_ubentry_sidequest-5-1_subentry_sidequest-5-1-1_subentry_sidequest-5-1-1-2_1_text_value.mp3",
            "sidequest-6-1-1": "audio/voiceover/en/generic/sidequest_subentry_sidequest-6_subentry_sidequest-6-1_subentry_sidequest-6-1-1_1_text_value.mp3",
            "10-1-2-1-1-1-1-1-1-1-2": "audio/voiceover/en/generic/subentry_10-1-2-1-1-1-1-1-1-1-2_1_text_value.mp3",
            "law-1-2-3-1-3-1-1": "audio/voiceover/en/generic/subentry_law-1-2-3-1-3-1-1_1_text_value.mp3",
            "law-1-2-3-1-3-1-3": "audio/voiceover/en/generic/subentry_law-1-2-3-1-3-1-3_1_text_value.mp3",
            "sidequest-3-1-1-1-1-1-1-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-3-1-1-1-1-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-3-1-1-1-1-1-1-1-1-2": "audio/voiceover/en/generic/subentry_sidequest-3-1-1-1-1-1-1-1-1-2_1_text_value.mp3",
            "sidequest-3-1-1-1-1-1-2": "audio/voiceover/en/generic/subentry_sidequest-3-1-1-1-1-1-2_1_text_value.mp3",
            "sidequest-4-1-1-1-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-4-1-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-4-1-1-1-1-1-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-4-1-1-1-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-4-1-1-1-1-1-1-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-4-1-1-1-1-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-4-1-1-1-1-1-1-1-1-1-2": "audio/voiceover/en/generic/subentry_sidequest-4-1-1-1-1-1-1-1-1-1-2_1_text_value.mp3",
            "sidequest-4-1-1-1-1-1-1-1-1-1-2-1": "audio/voiceover/en/generic/subentry_sidequest-4-1-1-1-1-1-1-1-1-1-2-1_1_text_value.mp3",
            "sidequest-4-1-1-1-1-1-1-2-1-1": "audio/voiceover/en/generic/subentry_sidequest-4-1-1-1-1-1-1-2-1-1_1_text_value.mp3",
            "sidequest-5-1-1-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-5-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-5-1-1-1-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-5-1-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-5-1-1-1-1-1-1-2": "audio/voiceover/en/generic/subentry_sidequest-5-1-1-1-1-1-1-2_1_text_value.mp3",
            "sidequest-5-1-1-1-1-1-1-3-1-1": "audio/voiceover/en/generic/subentry_sidequest-5-1-1-1-1-1-1-3-1-1_1_text_value.mp3",
            "sidequest-5-1-1-1-1-1-1-3-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-5-1-1-1-1-1-1-3-1-1-1_1_text_value.mp3",
            "sidequest-5-1-1-1-1-1-1-3-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-5-1-1-1-1-1-1-3-1-1-1-1_1_text_value.mp3",
            "sidequest-6-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-6-1-1-1_1_text_value.mp3",
            "sidequest-6-1-1-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-6-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-6-1-1-1-1-1-2-1": "audio/voiceover/en/generic/subentry_sidequest-6-1-1-1-1-1-2-1_1_text_value.mp3",
            "sidequest-6-1-1-1-1-1-2-1-1": "audio/voiceover/en/generic/subentry_sidequest-6-1-1-1-1-1-2-1-1_1_text_value.mp3",
            "sidequest-6-1-1-1-1-1-2-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-6-1-1-1-1-1-2-1-1-1_1_text_value.mp3",
            "sidequest-6-1-1-1-1-1-2-1-1-2-1-1": "audio/voiceover/en/generic/subentry_sidequest-6-1-1-1-1-1-2-1-1-2-1-1_1_text_value.mp3",
            "sidequest-6-1-1-1-1-1-2-1-1-2-3": "audio/voiceover/en/generic/subentry_sidequest-6-1-1-1-1-1-2-1-1-2-3_1_text_value.mp3",
            "sidequest-6-1-1-1-1-1-2-1-1-2-3-1": "audio/voiceover/en/generic/subentry_sidequest-6-1-1-1-1-1-2-1-1-2-3-1_1_text_value.mp3",
            "sidequest-6-1-1-1-1-1-2-1-1-2-3-1-1": "audio/voiceover/en/generic/subentry_sidequest-6-1-1-1-1-1-2-1-1-2-3-1-1_1_text_value.mp3",
            "sidequest-6-1-1-1-1-1-2-1-1-2-3-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-6-1-1-1-1-1-2-1-1-2-3-1-1-1_1_text_value.mp3",
            "sidequest-6-1-1-1-1-1-2-1-1-2-3-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-6-1-1-1-1-1-2-1-1-2-3-1-1-1-1_1_text_value.mp3",
            "sidequest-6-1-1-1-1-1-2-1-1-2-3-1-1-2-1": "audio/voiceover/en/generic/subentry_sidequest-6-1-1-1-1-1-2-1-1-2-3-1-1-2-1_1_text_value.mp3",
            "sidequest-6-1-1-1-1-1-2-1-1-2-3-1-1-2-2": "audio/voiceover/en/generic/subentry_sidequest-6-1-1-1-1-1-2-1-1-2-3-1-1-2-2_1_text_value.mp3",
            "sidequest-6-1-1-1-1-2-1": "audio/voiceover/en/generic/subentry_sidequest-6-1-1-1-1-2-1_1_text_value.mp3",
            "sidequest-6-1-1-1-2-1": "audio/voiceover/en/generic/subentry_sidequest-6-1-1-1-2-1_1_text_value.mp3",
            "sidequest-6-1-1-1-2-2": "audio/voiceover/en/generic/subentry_sidequest-6-1-1-1-2-2_1_text_value.mp3",
            "sidequest-6-1-1-1-3": "audio/voiceover/en/generic/subentry_sidequest-6-1-1-1-3_1_text_value.mp3",
            "sidequestn-1-1-1-2-1-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequestn-1-1-1-2-1-1-1-1-1_1_text_value.mp3",
            "tavern-10-1-1-1-1-1-1-1-1": "audio/voiceover/en/generic/subentry_tavern-10-1-1-1-1-1-1-1-1_1_text_value.mp3",
            "tavern-10-1-1-1-1-1-1-1-1-1": "audio/voiceover/en/generic/subentry_tavern-10-1-1-1-1-1-1-1-1-1_1_text_value.mp3",
            "tavern-10-1-1-1-1-1-1-1-1-1-1": "audio/voiceover/en/generic/subentry_tavern-10-1-1-1-1-1-1-1-1-1-1_1_text_value.mp3",
            "tavern-10-1-1-1-1-1-1-1-1-1-2": "audio/voiceover/en/generic/subentry_tavern-10-1-1-1-1-1-1-1-1-1-2_1_text_value.mp3",
            "tavern-10-1-1-1-1-1-1-1-1-1-2-1": "audio/voiceover/en/generic/subentry_tavern-10-1-1-1-1-1-1-1-1-1-2-1_1_text_value.mp3",
            "tavern-10-1-1-1-1-1-1-1-1-1-2-1-1": "audio/voiceover/en/generic/subentry_tavern-10-1-1-1-1-1-1-1-1-1-2-1-1_1_text_value.mp3",
            "tavern-10-1-1-1-1-1-1-1-1-1-2-1-2": "audio/voiceover/en/generic/subentry_tavern-10-1-1-1-1-1-1-1-1-1-2-1-2_1_text_value.mp3",
            "tavern-1": "audio/voiceover/en/generic/tavern_subentry_tavern-1_1_text_value.mp3",
            "tavern-2": "audio/voiceover/en/generic/tavern_subentry_tavern-2_1_text_value.mp3",
            "tavern-3": "audio/voiceover/en/generic/tavern_subentry_tavern-3_1_text_value.mp3",
            "tavern-4": "audio/voiceover/en/generic/tavern_subentry_tavern-4_1_text_value.mp3",
            "tavern-5": "audio/voiceover/en/generic/tavern_subentry_tavern-5_1_text_value.mp3",
            "tavern-8": "audio/voiceover/en/generic/tavern_subentry_tavern-8_1_text_value.mp3",
            "tavern-9": "audio/voiceover/en/generic/tavern_subentry_tavern-9_1_text_value.mp3",
            "tavern-10": "audio/voiceover/en/generic/tavern_subentry_tavern-10_1_text_value.mp3",
            "tavern-10-1-1-1-1": "audio/voiceover/en/generic/c1afbe29_vern-10-1_subentry_tavern-10-1-1_subntry_tvrn-10-1-1-1_sbntry_tvrn-10-1-1-1-1_1_txt_vl.mp3",
            "tavern-10-3": "audio/voiceover/en/generic/tavern_subentry_tavern-10_subentry_tavern-10-3_1_text_value.mp3",
            "tavern-11": "audio/voiceover/en/generic/tavern_subentry_tavern-11_1_text_value.mp3",
            "tavern-12": "audio/voiceover/en/generic/tavern_subentry_tavern-12_1_text_value.mp3",
            "tavern-13": "audio/voiceover/en/generic/tavern_subentry_tavern-13_1_text_value.mp3",
            "tavern-14": "audio/voiceover/en/generic/tavern_subentry_tavern-14_1_text_value.mp3",
            "tavern-15": "audio/voiceover/en/generic/tavern_subentry_tavern-15_1_text_value.mp3",
            "tavern-16": "audio/voiceover/en/generic/tavern_subentry_tavern-16_1_text_value.mp3",
            "tavern-17": "audio/voiceover/en/generic/tavern_subentry_tavern-17_1_text_value.mp3",
            "tavern-18": "audio/voiceover/en/generic/tavern_subentry_tavern-18_1_text_value.mp3",
            "tavern-19": "audio/voiceover/en/generic/tavern_subentry_tavern-19_1_text_value.mp3",
            "trading-1": "audio/voiceover/en/generic/trading_subentry_trading-1_1_text_value.mp3",
            "trading-2": "audio/voiceover/en/generic/trading_subentry_trading-2_1_text_value.mp3",
            "trading-3": "audio/voiceover/en/generic/trading_subentry_trading-3_1_text_value.mp3",
            "trading-4": "audio/voiceover/en/generic/trading_subentry_trading-4_1_text_value.mp3",
            "trading-5": "audio/voiceover/en/generic/trading_subentry_trading-5_1_text_value.mp3",
            "trading-6": "audio/voiceover/en/generic/trading_subentry_trading-6_1_text_value.mp3",
            "trading-7": "audio/voiceover/en/generic/trading_subentry_trading-7_1_text_value.mp3",
            "trading-8": "audio/voiceover/en/generic/trading_subentry_trading-8_1_text_value.mp3",
            "trading-9": "audio/voiceover/en/generic/trading_subentry_trading-9_1_text_value.mp3",
            "trading-10": "audio/voiceover/en/generic/trading_subentry_trading-10_1_text_value.mp3",
            "trading-11": "audio/voiceover/en/generic/trading_subentry_trading-11_1_text_value.mp3",
            "trading-12": "audio/voiceover/en/generic/trading_subentry_trading-12_1_text_value.mp3",
            "trading-12-1-1": "audio/voiceover/en/generic/trading_subentry_trading-12_subentry_trading-12-1_subentry_trading-12-1-1_1_text_value.mp3",
            "trading-12-1-2": "audio/voiceover/en/generic/trading_subentry_trading-12_subentry_trading-12-1_subentry_trading-12-1-2_1_text_value.mp3",
            "trading-12-1-3": "audio/voiceover/en/generic/trading_subentry_trading-12_subentry_trading-12-1_subentry_trading-12-1-3_1_text_value.mp3",
            "trading-12-2": "audio/voiceover/en/generic/trading_subentry_trading-12_subentry_trading-12-2_1_text_value.mp3",
            "trading-12-3": "audio/voiceover/en/generic/trading_subentry_trading-12_subentry_trading-12-3_1_text_value.mp3",
            "trading-13": "audio/voiceover/en/generic/trading_subentry_trading-13_1_text_value.mp3",
            "trading-14": "audio/voiceover/en/generic/trading_subentry_trading-14_1_text_value.mp3",
            "trading-15": "audio/voiceover/en/generic/trading_subentry_trading-15_1_text_value.mp3",
            "trading-16": "audio/voiceover/en/generic/trading_subentry_trading-16_1_text_value.mp3",
            "trading-17": "audio/voiceover/en/generic/trading_subentry_trading-17_1_text_value.mp3",
            "trading-18": "audio/voiceover/en/generic/trading_subentry_trading-18_1_text_value.mp3",
            "trading-19": "audio/voiceover/en/generic/trading_subentry_trading-19_1_text_value.mp3",
            "trading-20": "audio/voiceover/en/generic/trading_subentry_trading-20_1_text_value.mp3",
            "tavern-10-1-1-1-1-1": "audio/voiceover/en/generic/ff76d8e3_sbntry_tvrn-10-1-1_sbntry_tvrn-10-1-1-1_sbntry_tvrn-101111_sbntry_tvrn1011111_1_txt_vl.mp3",
            "crossroad-job-judge-1-2": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-judge-1_subentry_crossroad-job-judge-1-2_1_text_value.mp3",
            "crossroad-job-paladin-1-1-1-2": "audio/voiceover/en/generic/98eeacbc__crssrd-jb-pldn-1-1_sbntry_crssrd-jb-pldn-1-1-1_sbntry_crssrd-jb-pldn-1-1-1-2_1_txt_vl.mp3",
            "6-1-2": "audio/voiceover/en/generic/6_subentry_6-1_subentry_6-1-2_1_text_value.mp3",
            "14-1-2-2": "audio/voiceover/en/generic/14_subentry_14-1_subentry_14-1-2_subentry_14-1-2-2_1_text_value.mp3",
            "chaos-2-2": "audio/voiceover/en/generic/chaos_subentry_chaos-2_subentry_chaos-2-2_1_text_value.mp3",
            "chaos-2-2-1": "audio/voiceover/en/generic/chaos_subentry_chaos-2_subentry_chaos-2-2_subentry_chaos-2-2-1_1_text_value.mp3",
            "chaos-3-1": "audio/voiceover/en/generic/chaos_subentry_chaos-3_subentry_chaos-3-1_1_text_value.mp3",
            "chaos-3-1-1-1": "audio/voiceover/en/generic/8e7b79d0_ry_chaos-3_subentry_chaos-3-1_subentry_chaos-3-1-1_subentry_chaos-3-1-1-1_1_text_value.mp3",
            "chaos-5-1": "audio/voiceover/en/generic/chaos_subentry_chaos-5_subentry_chaos-5-1_1_text_value.mp3",
            "crossroad-job-theif-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-theif-1_subentry_crossroad-job-theif-1-1_1_text_value.mp3",
            "chaos-2-2-2": "audio/voiceover/en/generic/gen chaos-2-2-2 .mp3",
            "crossroad-job-friar-1-1": "audio/voiceover/en/generic/gen crossroad-job-friar-1-1.mp3",
            "crossroad-job-gunslinger-1-3-3-1": "audio/voiceover/en/generic/gen crossroad-job-gunslinger-1-3-3-1.mp3",
            "crossroad-job-theif-1-1": "audio/voiceover/en/generic/gen crossroad-job-theif-1-1 crossroad_subentry_crossroad-job-theif-1_1_text_value.mp3",
            "law-1": "audio/voiceover/en/generic/gen law-1 .mp3",
            "law-1-2-3-1-3-2": "audio/voiceover/en/generic/gen law-1-2-3-1-3-1-2 .mp3",
            "sidequest-3-1-1-1": "audio/voiceover/en/generic/gen sidequest-3-1-1-1 .mp3",
            "sidequest-5-1-1-2-1": "audio/voiceover/en/generic/gen sidequest-5-1-1-2-1.mp3",
            "tavern-6": "audio/voiceover/en/generic/gen tavern-6.mp3",
            "tavern-10-1-1-1-1-1-1-1": "audio/voiceover/en/generic/gen tavern-10-1-1-1-1-1-1-1.mp3",
            "sidequest-5-1-1-1-2": "audio/voiceover/en/generic/49188915_ry_sideqst-5-1_sbntry_sdqst-5-1-1_sbntry_sdqst-5-1-1-1_sbntry_sdqst-5-1-1-1-2_1_txt_vl.mp3",
            "chaos-3-1-1-1-1-1-3": "audio/voiceover/en/generic/subentry_chaos-3-1-1-1-1-1-3_1_text_value.mp3",
            "5-page19-1-1": "audio/voiceover/en/generic/5_subentry_5-page19-1_subentry_5-page19-1-1_1_text_value.mp3",
            "15-1-2-3": "audio/voiceover/en/generic/15_subentry_15-1_subentry_15-1-2_subentry_15-1-2-3_1_text_value.mp3",
            "crossroad-job-spellsword-1": "audio/voiceover/en/generic/crossroad_subentry_crossroad-job-spellsword-1_1_text_value.mp3",
            "crossroad-job-warrior-1-1-1": "audio/voiceover/en/generic/c08d6215_1_subentry_crossroad-job-warrior-1-1_subentry_crossroad-job-warrior-1-1-1_1_text_value.mp3",
            "duel-5": "audio/voiceover/en/generic/duel_subentry_duel-5_1_text_value.mp3",
            "14-1-2-2-1": "audio/voiceover/en/generic/gen 14-1-2-2-1.mp3",
            "crossroad-job-disgracednoble-1-1-1": "audio/voiceover/en/generic/gen crossroad-job-disgracednoble-1-1-1.mp3",
            "crossroad-job-pirate-1-1": "audio/voiceover/en/generic/gen crossroad-job-pirate-1-1.mp3",
            "sidequest-4-1-1-1-2": "audio/voiceover/en/generic/gen sidequest-4-1-1-1-2.mp3",
            "sidequest-6-1": "audio/voiceover/en/generic/sidequest_subentry_sidequest-6_subentry_sidequest-6-1_1_text_value.mp3",
            "law-1-2-3-1-3-1-3-1": "audio/voiceover/en/generic/subentry_law-1-2-3-1-3-1-3-1_1_text_value.mp3",
            "sidequest-4-1-1-1-1-1-1-2-2-1": "audio/voiceover/en/generic/subentry_sidequest-4-1-1-1-1-1-1-2-2-1_1_text_value.mp3",
            "sidequest-5-1-1-1-1-1-1-3": "audio/voiceover/en/generic/subentry_sidequest-5-1-1-1-1-1-1-3_1_text_value.mp3",
            "sidequest-5-1-1-1-1-1-1-3-1-1-2": "audio/voiceover/en/generic/subentry_sidequest-5-1-1-1-1-1-1-3-1-1-2_1_text_value.mp3",
            "tavern-7": "audio/voiceover/en/generic/tavern_subentry_tavern-7_1_text_value.mp3",
            "tavern-20": "audio/voiceover/en/generic/tavern_subentry_tavern-20_1_text_value.mp3",
            "tavern-10-1-1-1-1-1-2-1": "audio/voiceover/en/generic/tavern-10-1-1-1-1-1-2-1_1_text_value.mp3",
            "chaos-6-2": "audio/voiceover/en/generic/Chaos 6-2.mp3",
            "chaos-6-3": "audio/voiceover/en/generic/Chaos 6-3.mp3",
            "chaos-6-1": "audio/voiceover/en/generic/chaos-6-1.mp3",
            "chaos-6": "audio/voiceover/en/generic/chaos-6.mp3",
            "duel-1-2": "audio/voiceover/en/generic/Duel-1-2.mp3",
            "9-page37-1-1": "audio/voiceover/en/generic/9 - New Scav_subentry_9 - New Scav-page37-1_subentry_9 - New Scav-page37-1-1_1_text_value.mp3",
            "9-page37-1-2": "audio/voiceover/en/generic/9 - New Scav_subentry_9 - New Scav-page37-1_subentry_9 - New Scav-page37-1-2_1_text_value.mp3",
            "10-page7-1-1": "audio/voiceover/en/generic/10 - New Harold_subentry_10 - New Harold-page7-1_subentry_10 - New Harold-page7-1-1_1_text_value.mp3",
            "10-page7-1-2": "audio/voiceover/en/generic/10 - New Harold_subentry_10 - New Harold-page7-1_subentry_10 - New Harold-page7-1-2_1_text_value.mp3",
            "11-page5-1-1": "audio/voiceover/en/generic/11 - New Clara_subentry_11 - New Clara-page5-1_subentry_11 - New Clara-page5-1-1_1_text_value.mp3",
            "11-page5-1-2": "audio/voiceover/en/generic/11 - New Clara_subentry_11 - New Clara-page5-1_subentry_11 - New Clara-page5-1-2_1_text_value.mp3",
            "16-page39-1-1": "audio/voiceover/en/generic/16 - New Gabby_subentry_16 - New Gabby-page39-1_subentry_16 - New Gabby-page39-1-1_1_text_value.mp3",
            "16-page39-1-2": "audio/voiceover/en/generic/16 - New Gabby_subentry_16 - New Gabby-page39-1_subentry_16 - New Gabby-page39-1-2_1_text_value.mp3",
            "17-page56-1-2": "audio/voiceover/en/generic/78fcd621_n_subentry_17 - New Tik-Ton-page45-1_subentry_17 - New Tik-Ton-page45-1-2_1_text_value.mp3",
            "20-page54-1-1": "audio/voiceover/en/generic/20 - New Serpa_subentry_20 - New Serpa-page43-1_subentry_20 - New Serpa-page43-1-1_1_text_value.mp3",
            "20-page43-1-2": "audio/voiceover/en/generic/20 - New Serpa_subentry_20 - New Serpa-page43-1_subentry_20 - New Serpa-page43-1-2_1_text_value.mp3",
            "26-page47-1-1": "audio/voiceover/en/generic/37feb9a4_NewMysteriousStranger-page47-1_subentry_26-NewMysteriousStranger-page47-1-1_1_text_val.mp3",
            "26-page47-1-2": "audio/voiceover/en/generic/1e360d56_NewMysteriousStranger-page47-1_subentry_26-NewMysteriousStranger-page47-1-2_1_text_val.mp3",
            "sidequest-12-1-1-3-1-1-1": "audio/voiceover/en/generic/46733177_-1-3_sbntry_sdqst-12-1-1-3-1_sbntry_sdqst-12-1-1-3-1-1_sbntry_sdqst-12-113111_1_txt_vl.mp3",
            "sidequest-12-1-1-3-1-1-1-1-1-1-1": "audio/voiceover/en/generic/d1480084_31111_sbntry_sdqst1211311111_sbntry_sdqst12113111111_sbntry_sdqst121131111111_1_txt_vl.mp3",
            "sidequest-7-1-1-1-2-1": "audio/voiceover/en/generic/ad9a0f4a__sbntry_sdqst-7-1-1_sbntry_sdqst-7-1-1-1_sbntry_sdqst71112_sbntry_sdqst711121_1_txt_vl.mp3",
            "sidequest-9-1-1-1-1-1": "audio/voiceover/en/generic/17e74acc__sbntry_sdqst-9-1-1_sbntry_sdqst-9-1-1-1_sbntry_sdqst91111_sbntry_sdqst911111_1_txt_vl.mp3",
            "sidequest-10-1-1-1-1-1": "audio/voiceover/en/generic/624b3cb_-1_sbntry_sdqst-1011_sbntry_sdqst10111_sbntry_sdqst101111_sbntry_sdqst1011111_1_txt_vl.mp3",
            "sidequest-11-1-1-1-1-1": "audio/voiceover/en/generic/3057e008_-1_sbntry_sdqst-1111_sbntry_sdqst11111_sbntry_sdqst111111_sbntry_sdqst1111111_1_txt_vl.mp3",
            "sidequest-11-1-1-1-1-2": "audio/voiceover/en/generic/4c36c5d3_-1_sbntry_sdqst-1111_sbntry_sdqst11111_sbntry_sdqst111111_sbntry_sdqst1111112_1_txt_vl.mp3",
            "sidequest-12-11-3-1-1": "audio/voiceover/en/generic/549e787d_-1_sbntry_sdqst-1211_sbntry_sdqst12113_sbntry_sdqst121131_sbntry_sdqst1211311_1_txt_vl.mp3",
            "sidequest-13-1-1-1-1-1": "audio/voiceover/en/generic/5cb1478e_-1_sbntry_sdqst-1311_sbntry_sdqst13111_sbntry_sdqst131111_sbntry_sdqst1311111_1_txt_vl.mp3",
            "sidequest-14-1-1-1-1-1": "audio/voiceover/en/generic/dfe9fcc7_-1_sbntry_sdqst-1411_sbntry_sdqst14111_sbntry_sdqst141111_sbntry_sdqst1411111_1_txt_vl.mp3",
            "sidequest-8-1-1-1-1-1-2": "audio/voiceover/en/generic/218ad5ab_ntry_sdqst811_sbntry_sdqst8111_sbntry_sdqst81111_sbntry_sdqst811111_sbntry_8111112_1__.mp3",
            "sidequest-9-1-1-1-1-1-1": "audio/voiceover/en/generic/9b8e2595_ntry_sdqst911_sbntry_sdqst9111_sbntry_sdqst91111_sbntry_sdqst911111_sbntry_9111111_1__.mp3",
            "sidequest-10-1-1-1-1-1-1": "audio/voiceover/en/generic/e5712387_sbntry_sdqst1011_sbntry_sdqst10111_sbntry_sdqst101111_sbntry_sdqs1011111__10111111_1__.mp3",
            "sidequest-7-1-1-1-1": "audio/voiceover/en/generic/60318a15_ry_sideqst-7-1_sbntry_sdqst-7-1-1_sbntry_sdqst-7-1-1-1_sbntry_sdqst-7-1-1-1-1_1_txt_vl.mp3",
            "sidequest-7-1-1-1-2": "audio/voiceover/en/generic/1c50afce_ry_sideqst-7-1_sbntry_sdqst-7-1-1_sbntry_sdqst-7-1-1-1_sbntry_sdqst-7-1-1-1-2_1_txt_vl.mp3",
            "sidequest-7-1": "audio/voiceover/en/generic/sidequest_subentry_sidequest-7_subentry_sidequest-7-1_1_text_value.mp3",
            "sidequest-7-1-1-2": "audio/voiceover/en/generic/b24132e_ubentry_sidequest-7-1_subentry_sidequest-7-1-1_subentry_sidequest-7-1-1-2_1_text_value.mp3",
            "sidequest-8-1-1-1-1": "audio/voiceover/en/generic/d6a4ec18_ry_sideqst-8-1_sbntry_sdqst-8-1-1_sbntry_sdqst-8-1-1-1_sbntry_sdqst-8-1-1-1-1_1_txt_vl.mp3",
            "sidequest-8-1": "audio/voiceover/en/generic/sidequest_subentry_sidequest-8_subentry_sidequest-8-1_1_text_value.mp3",
            "sidequest-8-1-1-1": "audio/voiceover/en/generic/b81dca6c_ubentry_sidequest-8-1_subentry_sidequest-8-1-1_subentry_sidequest-8-1-1-1_1_text_value.mp3",
            "sidequest-8-1-1-1-1-1-2-2-2": "audio/voiceover/en/generic/529b170e_ubentry_sidequest-8-1_subentry_sidequest-8-1-1_subentry_sidequest-8-1-1-2_1_text_value.mp3",
            "sidequest-9-1-1-1-1": "audio/voiceover/en/generic/11b87c55_ry_sideqst-9-1_sbntry_sdqst-9-1-1_sbntry_sdqst-9-1-1-1_sbntry_sdqst-9-1-1-1-1_1_txt_vl.mp3",
            "sidequest-9-1": "audio/voiceover/en/generic/sidequest_subentry_sidequest-9_subentry_sidequest-9-1_1_text_value.mp3",
            "sidequest-9-1-1": "audio/voiceover/en/generic/sidequest_subentry_sidequest-9_subentry_sidequest-9-1_subentry_sidequest-9-1-1_1_text_value.mp3",
            "sidequest-9-1-1-1": "audio/voiceover/en/generic/49a5c9d5_ubentry_sidequest-9-1_subentry_sidequest-9-1-1_subentry_sidequest-9-1-1-1_1_text_value.mp3",
            "sidequest-9-1-1-2": "audio/voiceover/en/generic/a32314b7_ubentry_sidequest-9-1_subentry_sidequest-9-1-1_subentry_sidequest-9-1-1-2_1_text_value.mp3",
            "sidequest-10-1": "audio/voiceover/en/generic/sidequest_subentry_sidequest-10_subentry_sidequest-10-1_1_text_value.mp3",
            "sidequest-10-1-1": "audio/voiceover/en/generic/sidequest_subentry_sidequest-10_subentry_sidequest-10-1_subentry_sidequest-10-1-1_1_text_value.mp3",
            "sidequest-10-1-1-1": "audio/voiceover/en/generic/d724e899_ntry_sidequest-10-1_subentry_sidequest-10-1-1_subentry_sidequest-10-1-1-1_1_text_value.mp3",
            "sidequest-10-1-1-2": "audio/voiceover/en/generic/3da235fb_ntry_sidequest-10-1_subentry_sidequest-10-1-1_subentry_sidequest-10-1-1-2_1_text_value.mp3",
            "sidequest-11-1": "audio/voiceover/en/generic/sidequest_subentry_sidequest-11_subentry_sidequest-11-1_1_text_value.mp3",
            "sidequest-11-1-1": "audio/voiceover/en/generic/sidequest_subentry_sidequest-11_subentry_sidequest-11-1_subentry_sidequest-11-1-1_1_text_value.mp3",
            "sidequest-11-1-1-1": "audio/voiceover/en/generic/ea79f8d6_ntry_sidequest-11-1_subentry_sidequest-11-1-1_subentry_sidequest-11-1-1-1_1_text_value.mp3",
            "sidequest-11-1-1-2": "audio/voiceover/en/generic/ff25b4_ntry_sidequest-11-1_subentry_sidequest-11-1-1_subentry_sidequest-11-1-1-2_1_text_value.mp3",
            "sidequest-12-1": "audio/voiceover/en/generic/sidequest_subentry_sidequest-12_subentry_sidequest-12-1_1_text_value.mp3",
            "sidequest-12-1-1": "audio/voiceover/en/generic/sidequest_subentry_sidequest-12_subentry_sidequest-12-1_subentry_sidequest-12-1-1_1_text_value.mp3",
            "sidequest-12-1-1-1": "audio/voiceover/en/generic/ad9ec807_ntry_sidequest-12-1_subentry_sidequest-12-1-1_subentry_sidequest-12-1-1-1_1_text_value.mp3",
            "sidequest-12-1-1-2": "audio/voiceover/en/generic/47181565_ntry_sidequest-12-1_subentry_sidequest-12-1-1_subentry_sidequest-12-1-1-2_1_text_value.mp3",
            "sidequest-12-1-1-3-1-2": "audio/voiceover/en/generic/3bf3e133_st-12-1-1-3_subentry_sidequest-12-1-1-3-1_subentry_sidequest-12-1-1-3-1-2_1_text_value.mp3",
            "sidequest-13-1": "audio/voiceover/en/generic/sidequest_subentry_sidequest-13_subentry_sidequest-13-1_1_text_value.mp3",
            "sidequest-13-1-1": "audio/voiceover/en/generic/sidequest_subentry_sidequest-13_subentry_sidequest-13-1_subentry_sidequest-13-1-1_1_text_value.mp3",
            "sidequest-14-1": "audio/voiceover/en/generic/sidequest_subentry_sidequest-14_subentry_sidequest-14-1_1_text_value.mp3",
            "sidequest-14-1-1": "audio/voiceover/en/generic/sidequest_subentry_sidequest-14_subentry_sidequest-14-1_subentry_sidequest-14-1-1_1_text_value.mp3",
            "sidequest-14-1-1-1": "audio/voiceover/en/generic/2250a9a5_ntry_sidequest-14-1_subentry_sidequest-14-1-1_subentry_sidequest-14-1-1-1_1_text_value.mp3",
            "sidequest-10-1-1-1-1": "audio/voiceover/en/generic/f68c5775__sdqst-10-1_sbntry_sdqst-10-1-1_sbntry_sdqst-10-1-1-1_sbntry_sdqst-10-1-1-1-1_1_txt_vl.mp3",
            "sidequest-11-1-1-1-1": "audio/voiceover/en/generic/e71d5250__sdqst-11-1_sbntry_sdqst-11-1-1_sbntry_sdqst-11-1-1-1_sbntry_sdqst-11-1-1-1-1_1_txt_vl.mp3",
            "sidequest-12-1-1-1-1": "audio/voiceover/en/generic/d5ae5d3f__sdqst-12-1_sbntry_sdqst-12-1-1_sbntry_sdqst-12-1-1-1_sbntry_sdqst-12-1-1-1-1_1_txt_vl.mp3",
            "sidequest-12-1-1-1-2": "audio/voiceover/en/generic/a9cf78e4__sdqst-12-1_sbntry_sdqst-12-1-1_sbntry_sdqst-12-1-1-1_sbntry_sdqst-12-1-1-1-2_1_txt_vl.mp3",
            "sidequest-12-1-1-2-1": "audio/voiceover/en/generic/b18ee9aa__sdqst-12-1_sbntry_sdqst-12-1-1_sbntry_sdqst-12-1-1-2_sbntry_sdqst-12-1-1-2-1_1_txt_vl.mp3",
            "sidequest-12-1-1-2-2": "audio/voiceover/en/generic/cdefcc71__sdqst-12-1_sbntry_sdqst-12-1-1_sbntry_sdqst-12-1-1-2_sbntry_sdqst-12-1-1-2-2_1_txt_vl.mp3",
            "sidequest-12-1-1-3-1": "audio/voiceover/en/generic/926e85d9__sdqst-12-1_sbntry_sdqst-12-1-1_sbntry_sdqst-12-1-1-3_sbntry_sdqst-12-1-1-3-1_1_txt_vl.mp3",
            "sidequest-12-1-1-3-2": "audio/voiceover/en/generic/ee0fa002__sdqst-12-1_sbntry_sdqst-12-1-1_sbntry_sdqst-12-1-1-3_sbntry_sdqst-12-1-1-3-2_1_txt_vl.mp3",
            "sidequest-13-1-1-1-1": "audio/voiceover/en/generic/c43f581a__sdqst-13-1_sbntry_sdqst-13-1-1_sbntry_sdqst-13-1-1-1_sbntry_sdqst-13-1-1-1-1_1_txt_vl.mp3",
            "sidequest-13-1-1-1-2": "audio/voiceover/en/generic/b85e7dc1__sdqst-13-1_sbntry_sdqst-13-1-1_sbntry_sdqst-13-1-1-1_sbntry_sdqst-13-1-1-1-2_1_txt_vl.mp3",
            "sidequest-13-1-1-2-2": "audio/voiceover/en/generic/dc7ec954__sdqst-13-1_sbntry_sdqst-13-1-1_sbntry_sdqst-13-1-1-2_sbntry_sdqst-13-1-1-2-2_1_txt_vl.mp3",
            "sidequest-13-1-1-3-1": "audio/voiceover/en/generic/83ff80fc__sdqst-13-1_sbntry_sdqst-13-1-1_sbntry_sdqst-13-1-1-3_sbntry_sdqst-13-1-1-3-1_1_txt_vl.mp3",
            "sidequest-13-1-1-3-2": "audio/voiceover/en/generic/ff9ea527__sdqst-13-1_sbntry_sdqst-13-1-1_sbntry_sdqst-13-1-1-3_sbntry_sdqst-13-1-1-3-2_1_txt_vl.mp3",
            "sidequest-14-1-1-1-1": "audio/voiceover/en/generic/b0c843e1__sdqst-14-1_sbntry_sdqst-14-1-1_sbntry_sdqst-14-1-1-1_sbntry_sdqst-14-1-1-1-1_1_txt_vl.mp3",
            "sidequest-8-1-1-2": "audio/voiceover/en/generic/sidequest-8-1_subentry_sidequest-8-1-1_subentry_sidequest-8-1-1-2_1_text_value.mp3",
            "sidequest-8-1-1-1-1-1-1-1-1-2": "audio/voiceover/en/generic/sidequest-8-1-1-1-1-1-1-1-1_subentry_sidequest-8-1-1-1-1-1-1-1-1-2_1_text_value.mp3",
            "sidequest-9-1-1-1-1-1-1-1": "audio/voiceover/en/generic/sidequest-9-1-1-1-1-1-1_subentry_sidequest-9-1-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-10-1-1-1-1-1-1-1": "audio/voiceover/en/generic/b8a30454_1-1_subentry_sidequest-10-1-1-1-1-1-1_subentry_sidequest-10-1-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-13-1-1-1-1-1-1-2-1-1-1": "audio/voiceover/en/generic/e3b3961f__sidequest-13-1-1-1-1-1-1-2-1-1_subentry_sidequest-13-1-1-1-1-1-1-2-1-1-1_1_text_value.mp3",
            "sidequest-7-1-1-1-2-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-7-1-1-1-2-1-1_subentry_sidequest-7-1-1-1-2-1-1-1_1_text_value.mp3",
            "sidequest-7-1-1-1-2-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-7-1-1-1-2-1-1-1_subentry_sidequest-7-1-1-1-2-1-1-1-1_1_text_value.mp3",
            "sidequest-7-1-1-1-2-1-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-7-1-1-1-2-1-1-1-1_subentry_sidequest-7-1-1-1-2-1-1-1-1-1_1_text_value.mp3",
            "sidequest-7-1-1-1-2-1-1-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-7-1-1-1-2-1-1-1-1-1_subentry_sidequest-7-1-1-1-2-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-7-1-1-1-2-1-1-1-1-1-2": "audio/voiceover/en/generic/subentry_sidequest-7-1-1-1-2-1-1-1-1-1_subentry_sidequest-7-1-1-1-2-1-1-1-1-1-2_1_text_value.mp3",
            "sidequest-8-1-1-1-1-1-2-1": "audio/voiceover/en/generic/c8c52e9d__sidequst-8-1-1-1-1-1_sbntry_sdqst-8-1-1-1-1-1-2_sbntry_sdqst-8-1-1-1-1-1-2-1_1_txt_vl.mp3",
            "sidequest-8-1-1-1-1-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-8-1-1-1-1-1_subentry_sidequest-8-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-8-1-1-1-1-1-1-1": "audio/voiceover/en/generic/496860d7_1-1-1_subentry_sidequest-8-1-1-1-1-1-1_subentry_sidequest-8-1-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-8-1-1-1-1-1-2-2": "audio/voiceover/en/generic/8f2e20bc_1-1-1_subentry_sidequest-8-1-1-1-1-1-2_subentry_sidequest-8-1-1-1-1-1-2-2_1_text_value.mp3",
            "sidequest-8-1-1-1-1-1-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-8-1-1-1-1-1-1-1-1_subentry_sidequest-8-1-1-1-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-8-1-1-1-1-1-1-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-8-1-1-1-1-1-1-1-1-1_subentry_sidequest-8-1-1-1-1-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-8-1-1-1-1-1-2-2-1": "audio/voiceover/en/generic/455996fd_2_subentry_sidequest-8-1-1-1-1-1-2-2_subentry_sidequest-8-1-1-1-1-1-2-2-1_1_text_value.mp3",
            "sidequest-9-1-1-1-1-1-2": "audio/voiceover/en/generic/subentry_sidequest-9-1-1-1-1-1_subentry_sidequest-9-1-1-1-1-1-2_1_text_value.mp3",
            "sidequest-9-1-1-1-1-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-9-1-1-1-1-1-1-1_subentry_sidequest-9-1-1-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-9-1-1-1-1-1-1-1-1-1": "audio/voiceover/en/generic/ec110b0a_bentry_sidequest-9-1-1-1-1-1-1-1-1_subentry_sidequest-9-1-1-1-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-9-1-1-1-1-1-1-1-1-2": "audio/voiceover/en/generic/subentry_sidequest-9-1-1-1-1-1-1-1-1_subentry_sidequest-9-1-1-1-1-1-1-1-1-2_1_text_value.mp3",
            "sidequest-9-1-1-1-1-1-1-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-9-1-1-1-1-1-1-1-1-1_subentry_sidequest-9-1-1-1-1-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-10-1-1-1-1-1-2": "audio/voiceover/en/generic/d90a5f06_sidequest-10-1-1-1-1_subentry_sidqst-10-1-1-1-1-1_sbntry_sdqst-10-1-1-1-1-1-2_1_txt_vl.mp3",
            "sidequest-10-1-1-1-1-1-1-1-1": "audio/voiceover/en/generic/e414fdb6_subentry_sidequest-10-1-1-1-1-1-1-1_subentry_sidequest-10-1-1-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-10-1-1-1-1-1-1-1-1-1": "audio/voiceover/en/generic/bbcffbae_ubentry_sidequest-10-1-1-1-1-1-1-1-1_subentry_sidequest-10-1-1-1-1-1-1-1-1-1_1_text_vl.mp3",
            "sidequest-10-1-1-1-1-1-1-1-1-2": "audio/voiceover/en/generic/aab291d7_ubentry_sidequest-10-1-1-1-1-1-1-1-1_subentry_sidequest-10-1-1-1-1-1-1-1-1-2_1_text_vl.mp3",
            "sidequest-11--1-1-1-1-1-1-1": "audio/voiceover/en/generic/ead5197e_1-1_subentry_sidequest-11-1-1-1-1-1-1_subentry_sidequest-11-1-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-11-1-1-1-1-1-1-2": "audio/voiceover/en/generic/subentry_sidequest-11-1-1-1-1-1-1_subentry_sidequest-11-1-1-1-1-1-1-2_1_text_value.mp3",
            "sidequest-12-1-1-3-1-1-1-1-1": "audio/voiceover/en/generic/155a2329_subentry_sidequest-12-1-1-3-1-1-1-1_subentry_sidequest-12-1-1-3-1-1-1-1-1_1_text_value.mp3",
            "sidequest-12-1-1-3-1-1-1-1-1-1": "audio/voiceover/en/generic/f440069d_ubentry_sidequest-12-1-1-3-1-1-1-1-1_subentry_sidequest-12-1-1-3-1-1-1-1-1-1_1_text_vl.mp3",
            "sidequest-12-1-1-3-1-1-1-1-2": "audio/voiceover/en/generic/subentry_sidequest-12-1-1-3-1-1-1-1_subentry_sidequest-12-1-1-3-1-1-1-1-2_1_text_value.mp3",
            "sidequest-12-1-1-3-1-1-1-1-1-1-4": "audio/voiceover/en/generic/2d86cffe__subentry_sidequest-12-1-1-3-1-1-1-1-1-1_subntry_sdqst-12-1-1-3-1-1-1-1-1-1-4_1_txt_vl.mp3",
            "sidequest-13-1-1-1-1-1-1-2-2": "audio/voiceover/en/generic/77fb0766_subentry_sidequest-13-1-1-1-1-1-1-1_subentry_sidequest-13-1-1-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-1-1-1-1-1-1-2-2": "audio/voiceover/en/generic/146ba3b7_subentry_sidequest-13-1-1-1-1-1-1-2_subentry_sidequest-13-1-1-1-1-1-1-2-2_1_text_value.mp3",
            "sidequest-13-1-1-1-1-1-1-1-2-1-1": "audio/voiceover/en/generic/e733efa2_ubentry_sidequest-13-1-1-1-1-1-1-2-1_subentry_sidequest-13-1-1-1-1-1-1-2-1-1_1_text_vl.mp3",
            "sidequest-13-1-1-1-1-1-1-2-1": "audio/voiceover/en/generic/subentry_sidequest-13-1-1-1-1-1-1-2-1_1_text_value.mp3",
            "sidequest-13-1-1-1-1-1-1-2-1-1-1-2": "audio/voiceover/en/generic/c9464d48_-1_subntry_sdqst-13-1-1-1-1-1-1-2-1-1-1_sbntry_sdqst-13-1-1-1-1-1-1-2-1-1-1-2_1_txt_vl.mp3",
            "sidequest-13-1-1-1-1-1-1-2-1-1-1-3": "audio/voiceover/en/generic/5449ac3e_-1_subntry_sdqst-13-1-1-1-1-1-1-2-1-1-1_sbntry_sdqst-13-1-1-1-1-1-1-2-1-1-1-3_1_txt_vl.mp3",
            "sidequest-13-1-1-1-1-1-1-2-1-1-1-1": "audio/voiceover/en/generic/3ef55cf5_equest-13-1-1-1-1-1-1-2-1-1-1_subentry_sidequest-13-1-1-1-1-1-1-2-1-1-1-1_1_text_value.mp3",
            "sidequest-14-1-1-1-1-1-1": "audio/voiceover/en/generic/f12ae335_1-1-1-1_subentry_sidequest-14-1-1-1-1-1_subentry_sidequest-14-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-14-1-1-1-1-1-2": "audio/voiceover/en/generic/1bac3e57_1-1-1-1_subentry_sidequest-14-1-1-1-1-1_subentry_sidequest-14-1-1-1-1-1-2_1_text_value.mp3",
            "sidequest-14-1-1-1-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-14-1-1-1-1-1-1_subentry_sidequest-14-1-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-14-1-1-1-1-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-14-1-1-1-1-1-1-1_subentry_sidequest-14-1-1-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-14-1-1-1-1-1-1-1-1-2": "audio/voiceover/en/generic/6c688b90_ubentry_sidequest-14-1-1-1-1-1-1-1-1_subentry_sidequest-14-1-1-1-1-1-1-1-1-2_1_text_vl.mp3",
            "sidequest-14-1-1-1-1-1-1-1-1-3": "audio/voiceover/en/generic/d5935078_ubentry_sidequest-14-1-1-1-1-1-1-1-1_subentry_sidequest-14-1-1-1-1-1-1-1-1-3_1_text_vl.mp3",
            "sidequest-14-1-1-1-1-1-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-14-1-1-1-1-1-1-1-1_subentry_sidequest-14-1-1-1-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-14-1-1-1-1-1-1-1-1-1-1": "audio/voiceover/en/generic/ca69b78c__subentry_sidequest-14-1-1-1-1-1-1-1-1-1_subntry_sdqst-14-1-1-1-1-1-1-1-1-1-1_1_txt_vl.mp3",
            "sidequest-13-1-1-1-1-1-1-2-1-1-1-1-1": "audio/voiceover/en/generic/5073764e_sbntry_sdqst-13-1-1-1-1-1-1-2-1-1-1-1_sbntry_sdqst-13-1-1-1-1-1-1-2-1-1-1-1-1_1_txt_vl.mp3",
            "sidequest-13-1-1-1-1-1-1-2-1-1": "audio/voiceover/en/generic/gen sidequest-13-1-1-1-1-1-1-2-1-1.mp3",
            "sidequest-8-1-1-1-1-1-1": "audio/voiceover/en/generic/sidequest-8-1-1-1-1-1-1.mp3",
            "sidequest-11-1-1-1-1-1-1": "audio/voiceover/en/generic/sidequest-11-1-1-1-1-1-1.mp3",
            "sidequest-12-1-1-3-1-1-1-1-1-1-2": "audio/voiceover/en/generic/sidequest-12-1-1-3-1-1-1-1-1-1-2_1_text_value.mp3",
            "sidequest-12-1-1-3-1-1-1-1-1-1-3": "audio/voiceover/en/generic/sidequest-12-1-1-3-1-1-1-1-1-1-3_1_text_value.mp3",
            "sidequest-12-1-1-3-1-1": "audio/voiceover/en/generic/sidequest-12-1-1-3-1-1.mp3",
            "sidequest-13-1-1-1-1-1-1-1-1": "audio/voiceover/en/generic/sidequest-13-1-1-1-1-1-1-1-1.mp3",
            "sidequest-14-1-1-2": "audio/voiceover/en/generic/sidequest-14-1-1-2.mp3",
            "sidequest-11-1-1-1-1-1-1-1": "audio/voiceover/en/generic/subentry_sidequest-11-1-1-1-1-1-1_subentry_sidequest-11-1-1-1-1-1-1-1_1_text_value.mp3"
        },
        "sisters": {
            "70": "audio/voiceover/en/sisters/70.mp3",
            "83": "audio/voiceover/en/sisters/83_1_text_value.mp3",
            "1100": "audio/voiceover/en/sisters/1100_1_text_value.mp3",
            "1101": "audio/voiceover/en/sisters/1101_1_text_value.mp3",
            "1102": "audio/voiceover/en/sisters/1102_1_text_value.mp3",
            "1103": "audio/voiceover/en/sisters/1103_1_text_value.mp3",
            "1104": "audio/voiceover/en/sisters/1104_1_text_value.mp3",
            "1300": "audio/voiceover/en/sisters/1300_1_text_value.mp3",
            "1301": "audio/voiceover/en/sisters/1301_1_text_value.mp3",
            "1302": "audio/voiceover/en/sisters/1302_1_text_value.mp3",
            "1303": "audio/voiceover/en/sisters/1303_1_text_value.mp3",
            "1304": "audio/voiceover/en/sisters/1304_1_text_value.mp3",
            "1305": "audio/voiceover/en/sisters/1305_1_text_value.mp3",
            "1306": "audio/voiceover/en/sisters/1306_1_text_value.mp3",
            "1307": "audio/voiceover/en/sisters/1307_1_text_value.mp3",
            "1308": "audio/voiceover/en/sisters/1308_1_text_value.mp3",
            "1309": "audio/voiceover/en/sisters/1309_1_text_value.mp3",
            "1500": "audio/voiceover/en/sisters/1500_1_text_value.mp3",
            "1501": "audio/voiceover/en/sisters/1501_1_text_value.mp3",
            "1502": "audio/voiceover/en/sisters/1502_1_text_value.mp3",
            "1503": "audio/voiceover/en/sisters/1503_1_text_value.mp3",
            "1504": "audio/voiceover/en/sisters/1504_1_text_value.mp3",
            "1505": "audio/voiceover/en/sisters/1505_1_text_value.mp3",
            "1506": "audio/voiceover/en/sisters/1506_1_text_value.mp3",
            "1507": "audio/voiceover/en/sisters/1507_1_text_value.mp3",
            "1510": "audio/voiceover/en/sisters/1510_1_text_value.mp3",
            "1700": "audio/voiceover/en/sisters/1700_1_text_value.mp3",
            "1701": "audio/voiceover/en/sisters/1701_1_text_value.mp3",
            "1702": "audio/voiceover/en/sisters/1702_1_text_value.mp3",
            "1703": "audio/voiceover/en/sisters/1703_1_text_value.mp3",
            "1704": "audio/voiceover/en/sisters/1704_1_text_value.mp3",
            "1705": "audio/voiceover/en/sisters/1705_1_text_value.mp3",
            "1706": "audio/voiceover/en/sisters/1706_1_text_value.mp3",
            "1707": "audio/voiceover/en/sisters/1707_1_text_value.mp3",
            "1708": "audio/voiceover/en/sisters/1708_1_text_value.mp3",
            "1709": "audio/voiceover/en/sisters/1709_1_text_value.mp3",
            "1710": "audio/voiceover/en/sisters/1710_1_text_value.mp3",
            "1711": "audio/voiceover/en/sisters/1711_1_text_value.mp3",
            "1900": "audio/voiceover/en/sisters/1900_1_text_value.mp3",
            "1901": "audio/voiceover/en/sisters/1901_1_text_value.mp3",
            "1903": "audio/voiceover/en/sisters/1903_1_text_value.mp3",
            "1904": "audio/voiceover/en/sisters/1904_1_text_value.mp3",
            "1905": "audio/voiceover/en/sisters/1905_1_text_value.mp3",
            "1906": "audio/voiceover/en/sisters/1906_1_text_value.mp3",
            "1907": "audio/voiceover/en/sisters/1907_1_text_value.mp3",
            "1908": "audio/voiceover/en/sisters/1908_1_text_value.mp3",
            "1909": "audio/voiceover/en/sisters/1909_1_text_value.mp3",
            "2100": "audio/voiceover/en/sisters/2100_1_text_value.mp3",
            "2101": "audio/voiceover/en/sisters/2101_1_text_value.mp3",
            "2102": "audio/voiceover/en/sisters/2102_1_text_value.mp3",
            "2103": "audio/voiceover/en/sisters/2103_1_text_value.mp3",
            "2104": "audio/voiceover/en/sisters/2104_1_text_value.mp3",
            "2105": "audio/voiceover/en/sisters/2105_1_text_value.mp3",
            "2300": "audio/voiceover/en/sisters/2300_1_text_value.mp3",
            "2302": "audio/voiceover/en/sisters/2302_1_text_value.mp3",
            "2304": "audio/voiceover/en/sisters/2304_1_text_value.mp3",
            "2305": "audio/voiceover/en/sisters/2305_1_text_value.mp3",
            "2306": "audio/voiceover/en/sisters/2306_1_text_value.mp3",
            "2307": "audio/voiceover/en/sisters/2307_1_text_value.mp3",
            "2500": "audio/voiceover/en/sisters/2500_1_text_value.mp3",
            "2504": "audio/voiceover/en/sisters/2504_1_text_value.mp3",
            "2505": "audio/voiceover/en/sisters/2505_1_text_value.mp3",
            "2506": "audio/voiceover/en/sisters/2506_1_text_value.mp3",
            "2507": "audio/voiceover/en/sisters/2507_1_text_value.mp3",
            "2508": "audio/voiceover/en/sisters/2508_1_text_value.mp3",
            "2509": "audio/voiceover/en/sisters/2509_1_text_value.mp3",
            "2510": "audio/voiceover/en/sisters/2510_1_text_value.mp3",
            "2700": "audio/voiceover/en/sisters/2700_1_text_value.mp3",
            "2701": "audio/voiceover/en/sisters/2701_1_text_value.mp3",
            "2702": "audio/voiceover/en/sisters/2702_1_text_value.mp3",
            "2704": "audio/voiceover/en/sisters/2704_1_text_value.mp3",
            "2705": "audio/voiceover/en/sisters/2705_1_text_value.mp3",
            "2706": "audio/voiceover/en/sisters/2706_1_text_value.mp3",
            "2707": "audio/voiceover/en/sisters/2707_1_text_value.mp3",
            "2708": "audio/voiceover/en/sisters/2708_1_text_value.mp3",
            "2709": "audio/voiceover/en/sisters/2709_1_text_value.mp3",
            "2710": "audio/voiceover/en/sisters/2710_1_text_value.mp3",
            "2711": "audio/voiceover/en/sisters/2711_1_text_value.mp3",
            "8989": "audio/voiceover/en/sisters/8989_1_text_value.mp3",
            "1-1": "audio/voiceover/en/sisters/1_subentry_1-1_1_text_value.mp3",
            "83-1": "audio/voiceover/en/sisters/83_subentry_83-1_1_text_value.mp3",
            "83-2-1": "audio/voiceover/en/sisters/83_subentry_83-2_subentry_83-2-1_1_text_value.mp3",
            "83-2-2": "audio/voiceover/en/sisters/83_subentry_83-2_subentry_83-2-2_1_text_value.mp3",
            "1100-1-1": "audio/voiceover/en/sisters/1100_subentry_1100-1_subentry_1100-1-1_1_text_value.mp3",
            "1100-1-1-1": "audio/voiceover/en/sisters/1100_subentry_1100-1_subentry_1100-1-1_subentry_1100-1-1-1_1_text_value.mp3",
            "1100-1-1-2": "audio/voiceover/en/sisters/1100_subentry_1100-1_subentry_1100-1-1_subentry_1100-1-1-2_1_text_value.mp3",
            "1101-1-1": "audio/voiceover/en/sisters/1101_subentry_1101-1_subentry_1101-1-1_1_text_value.mp3",
            "1101-1-2": "audio/voiceover/en/sisters/1101_subentry_1101-1_subentry_1101-1-2_1_text_value.mp3",
            "1101-2-1": "audio/voiceover/en/sisters/1101_subentry_1101-2_subentry_1101-2-1_1_text_value.mp3",
            "1101-2-2": "audio/voiceover/en/sisters/1101_subentry_1101-2_subentry_1101-2-2_1_text_value.mp3",
            "1101-3": "audio/voiceover/en/sisters/1101_subentry_1101-3_1_text_value.mp3",
            "1101-5": "audio/voiceover/en/sisters/1101_subentry_1101-5_1_text_value.mp3",
            "1101-5-1-1": "audio/voiceover/en/sisters/1101_subentry_1101-5_subentry_1101-5-1_subentry_1101-5-1-1_1_text_value.mp3",
            "1101-5-1-2": "audio/voiceover/en/sisters/1101_subentry_1101-5_subentry_1101-5-1_subentry_1101-5-1-2_1_text_value.mp3",
            "1101-5-2": "audio/voiceover/en/sisters/1101_subentry_1101-5_subentry_1101-5-2_1_text_value.mp3",
            "1102-2-1-1-1-1-1": "audio/voiceover/en/sisters/2153cf65_ry_1102-2-1-1_sbntry_1102-2-1-1-1_sbntry_1102-2-1-1-1-1_sbntry_1102-2-1-1-111_1_txt_vl.mp3",
            "1102-2-1-1-1-1-2": "audio/voiceover/en/sisters/5d32eabe_ry_1102-2-1-1_sbntry_1102-2-1-1-1_sbntry_1102-2-1-1-1-1_sbntry_1102-2-1-1-112_1_txt_vl.mp3",
            "1102-1": "audio/voiceover/en/sisters/1102_subentry_1102-1_1_text_value.mp3",
            "1102-2": "audio/voiceover/en/sisters/1102_subentry_1102-2_1_text_value.mp3",
            "1102-2-1-1-1": "audio/voiceover/en/sisters/1102_subentry_1102-2_subentry_1102-2-1_subentry_1102-2-1-1_subentry_1102-2-1-1-1_1_text_value.mp3",
            "1102-2-1-1-1-2": "audio/voiceover/en/sisters/6428b549_102-2-1_subentry_1102-2-1-1_subentry_1102-2-1-1-1_subentry_1102-2-1-1-1-2_1_text_value.mp3",
            "1102-2-1-2": "audio/voiceover/en/sisters/1102_subentry_1102-2_subentry_1102-2-1_subentry_1102-2-1-2_1_text_value.mp3",
            "1103-1": "audio/voiceover/en/sisters/1103_subentry_1103-1_1_text_value.mp3",
            "1103-2": "audio/voiceover/en/sisters/1103_subentry_1103-2_1_text_value.mp3",
            "1103-2-1": "audio/voiceover/en/sisters/1103_subentry_1103-2_subentry_1103-2-1_1_text_value.mp3",
            "1103-2-2-1-1": "audio/voiceover/en/sisters/1103_subentry_1103-2_subentry_1103-2-2_subentry_1103-2-2-1_subentry_1103-2-2-1-1_1_text_value.mp3",
            "1104-1-1-1": "audio/voiceover/en/sisters/1104_subentry_1104-1_subentry_1104-1-1_subentry_1104-1-1-1_1_text_value.mp3",
            "1104-1-1-2": "audio/voiceover/en/sisters/1104_subentry_1104-1_subentry_1104-1-1_subentry_1104-1-1-2_1_text_value.mp3",
            "1303-1": "audio/voiceover/en/sisters/1303_subentry_1303-1_1_text_value.mp3",
            "1303-1-1": "audio/voiceover/en/sisters/1303_subentry_1303-1_subentry_1303-1-1_1_text_value.mp3",
            "1303-1-2": "audio/voiceover/en/sisters/1303_subentry_1303-1_subentry_1303-1-2_1_text_value.mp3",
            "1303-1-3": "audio/voiceover/en/sisters/1303_subentry_1303-1_subentry_1303-1-3_1_text_value.mp3",
            "1303-2-1": "audio/voiceover/en/sisters/1303_subentry_1303-2_subentry_1303-2-1_1_text_value.mp3",
            "1303-2-2": "audio/voiceover/en/sisters/1303_subentry_1303-2_subentry_1303-2-2_1_text_value.mp3",
            "1305-1": "audio/voiceover/en/sisters/1305_subentry_1305-1_1_text_value.mp3",
            "1305-2": "audio/voiceover/en/sisters/1305_subentry_1305-2_1_text_value.mp3",
            "1306-1": "audio/voiceover/en/sisters/1306_subentry_1306-1_1_text_value.mp3",
            "1306-2": "audio/voiceover/en/sisters/1306_subentry_1306-2_1_text_value.mp3",
            "1307-1": "audio/voiceover/en/sisters/1307_subentry_1307-1_1_text_value.mp3",
            "1307-2": "audio/voiceover/en/sisters/1307_subentry_1307-2_1_text_value.mp3",
            "1310-1": "audio/voiceover/en/sisters/1310_subentry_1310-1_1_text_value.mp3",
            "1310-1-1-1-1": "audio/voiceover/en/sisters/1310_subentry_1310-1_subentry_1310-1-1_subentry_1310-1-1-1_subentry_1310-1-1-1-1_1_text_value.mp3",
            "1310-1-1-1-2": "audio/voiceover/en/sisters/1310_subentry_1310-1_subentry_1310-1-1_subentry_1310-1-1-1_subentry_1310-1-1-1-2_1_text_value.mp3",
            "1310-2": "audio/voiceover/en/sisters/1310_subentry_1310-2_1_text_value.mp3",
            "1310-2-1-1": "audio/voiceover/en/sisters/1310_subentry_1310-2_subentry_1310-2-1_subentry_1310-2-1-1_1_text_value.mp3",
            "1310-2-1-2": "audio/voiceover/en/sisters/1310_subentry_1310-2_subentry_1310-2-1_subentry_1310-2-1-2_1_text_value.mp3",
            "1310-2-1-3": "audio/voiceover/en/sisters/1310_subentry_1310-2_subentry_1310-2-1_subentry_1310-2-1-3_1_text_value.mp3",
            "1501-1": "audio/voiceover/en/sisters/1501_subentry_1501-1_1_text_value.mp3",
            "1501-2": "audio/voiceover/en/sisters/1501_subentry_1501-2_1_text_value.mp3",
            "1507-1": "audio/voiceover/en/sisters/1507_subentry_1507-1_1_text_value.mp3",
            "1507-2": "audio/voiceover/en/sisters/1507_subentry_1507-2_1_text_value.mp3",
            "1508-1": "audio/voiceover/en/sisters/1508_subentry_1508-1_1_text_value.mp3",
            "1508-2": "audio/voiceover/en/sisters/1508_subentry_1508-2_1_text_value.mp3",
            "1508-2-1": "audio/voiceover/en/sisters/1508_subentry_1508-2_subentry_1508-2-1_1_text_value.mp3",
            "1508-2-2": "audio/voiceover/en/sisters/1508_subentry_1508-2_subentry_1508-2-2_1_text_value.mp3",
            "1508-2-3": "audio/voiceover/en/sisters/1508_subentry_1508-2_subentry_1508-2-3_1_text_value.mp3",
            "1509-2": "audio/voiceover/en/sisters/1509_subentry_1509-2_1_text_value.mp3",
            "1511-1": "audio/voiceover/en/sisters/1511_subentry_1511-1_1_text_value.mp3",
            "1511-2": "audio/voiceover/en/sisters/1511_subentry_1511-2_1_text_value.mp3",
            "1700-1": "audio/voiceover/en/sisters/1700_subentry_1700-1_1_text_value.mp3",
            "1702-1-1-1": "audio/voiceover/en/sisters/1702_subentry_1702-1_subentry_1702-1-1_subentry_1702-1-1-1_1_text_value.mp3",
            "1702-1-1-2": "audio/voiceover/en/sisters/1702_subentry_1702-1_subentry_1702-1-1_subentry_1702-1-1-2_1_text_value.mp3",
            "1705-1": "audio/voiceover/en/sisters/1705_subentry_1705-1_1_text_value.mp3",
            "1705-2": "audio/voiceover/en/sisters/1705_subentry_1705-2_1_text_value.mp3",
            "1706-1": "audio/voiceover/en/sisters/1706_subentry_1706-1_1_text_value.mp3",
            "1706-1-1": "audio/voiceover/en/sisters/1706_subentry_1706-1_subentry_1706-1-1_1_text_value.mp3",
            "1706-1-2": "audio/voiceover/en/sisters/1706_subentry_1706-1_subentry_1706-1-2_1_text_value.mp3",
            "1706-2": "audio/voiceover/en/sisters/1706_subentry_1706-2_1_text_value.mp3",
            "1910-1": "audio/voiceover/en/sisters/1910_subentry_1910-1_1_text_value.mp3",
            "2102-1": "audio/voiceover/en/sisters/2102_subentry_2102-1_1_text_value.mp3",
            "2102-2": "audio/voiceover/en/sisters/2102_subentry_2102-2_1_text_value.mp3",
            "2103-1": "audio/voiceover/en/sisters/2103_subentry_2103-1_1_text_value.mp3",
            "2103-2": "audio/voiceover/en/sisters/2103_subentry_2103-2_1_text_value.mp3",
            "2106-1": "audio/voiceover/en/sisters/2106_subentry_2106-1_1_text_value.mp3",
            "2106-1-1": "audio/voiceover/en/sisters/2106_subentry_2106-1_subentry_2106-1-1_1_text_value.mp3",
            "2106-2-1": "audio/voiceover/en/sisters/2106_subentry_2106-2_subentry_2106-2-1_1_text_value.mp3",
            "2106-2-2": "audio/voiceover/en/sisters/2106_subentry_2106-2_subentry_2106-2-2_1_text_value.mp3",
            "2301-1-1": "audio/voiceover/en/sisters/2301_subentry_2301-1_subentry_2301-1-1_1_text_value.mp3",
            "2301-1-2": "audio/voiceover/en/sisters/2301_subentry_2301-1_subentry_2301-1-2_1_text_value.mp3",
            "2301-3": "audio/voiceover/en/sisters/2301_subentry_2301-3_1_text_value.mp3",
            "2301-3-1-1": "audio/voiceover/en/sisters/2301_subentry_2301-3_subentry_2301-3-1_subentry_2301-3-1-1_1_text_value.mp3",
            "2301-3-1-2": "audio/voiceover/en/sisters/2301_subentry_2301-3_subentry_2301-3-1_subentry_2301-3-1-2_1_text_value.mp3",
            "2301-4": "audio/voiceover/en/sisters/2301_subentry_2301-4_1_text_value.mp3",
            "2302-1-1-2-1": "audio/voiceover/en/sisters/2302_subentry_2302-1_subentry_2302-1-1_subentry_2302-1-1-2_subentry_2302-1-1-2-1_1_text_value.mp3",
            "2302-2": "audio/voiceover/en/sisters/2302_subentry_2302-2_1_text_value.mp3",
            "2303-1": "audio/voiceover/en/sisters/2303_subentry_2303-1_1_text_value.mp3",
            "2303-2-1-1": "audio/voiceover/en/sisters/2303_subentry_2303-2_subentry_2303-2-1_subentry_2303-2-1-1_1_text_value.mp3",
            "2303-2-1-2": "audio/voiceover/en/sisters/2303_subentry_2303-2_subentry_2303-2-1_subentry_2303-2-1-2_1_text_value.mp3",
            "2307-1": "audio/voiceover/en/sisters/2307_subentry_2307-1_1_text_value.mp3",
            "2307-2": "audio/voiceover/en/sisters/2307_subentry_2307-2_1_text_value.mp3",
            "2308-1": "audio/voiceover/en/sisters/2308_subentry_2308-1_1_text_value.mp3",
            "2308-2-1": "audio/voiceover/en/sisters/2308_subentry_2308-2_subentry_2308-2-1_1_text_value.mp3",
            "2308-2-2": "audio/voiceover/en/sisters/2308_subentry_2308-2_subentry_2308-2-2_1_text_value.mp3",
            "2500-1": "audio/voiceover/en/sisters/2500_subentry_2500-1_1_text_value.mp3",
            "2500-2": "audio/voiceover/en/sisters/2500_subentry_2500-2_1_text_value.mp3",
            "2500-2-1-1": "audio/voiceover/en/sisters/2500_subentry_2500-2_subentry_2500-2-1_subentry_2500-2-1-1_1_text_value.mp3",
            "2500-2-1-2": "audio/voiceover/en/sisters/2500_subentry_2500-2_subentry_2500-2-1_subentry_2500-2-1-2_1_text_value.mp3",
            "2501-1": "audio/voiceover/en/sisters/2501_subentry_2501-1_1_text_value.mp3",
            "2501-2": "audio/voiceover/en/sisters/2501_subentry_2501-2_1_text_value.mp3",
            "2502-1": "audio/voiceover/en/sisters/2502_subentry_2502-1_1_text_value.mp3",
            "2502-2": "audio/voiceover/en/sisters/2502_subentry_2502-2_1_text_value.mp3",
            "2503-1-1": "audio/voiceover/en/sisters/2503_subentry_2503-1_subentry_2503-1-1_1_text_value.mp3",
            "2503-1-1-1": "audio/voiceover/en/sisters/2503_subentry_2503-1_subentry_2503-1-1_subentry_2503-1-1-1_1_text_value.mp3",
            "2503-1-1-2": "audio/voiceover/en/sisters/2503_subentry_2503-1_subentry_2503-1-1_subentry_2503-1-1-2_1_text_value.mp3",
            "2503-1-2": "audio/voiceover/en/sisters/2503_subentry_2503-1_subentry_2503-1-2_1_text_value.mp3",
            "2503-2": "audio/voiceover/en/sisters/2503_subentry_2503-2_1_text_value.mp3",
            "2503-3": "audio/voiceover/en/sisters/2503_subentry_2503-3_1_text_value.mp3",
            "2503-4": "audio/voiceover/en/sisters/2503_subentry_2503-4_1_text_value.mp3",
            "2503-5": "audio/voiceover/en/sisters/2503_subentry_2503-5_1_text_value.mp3",
            "2503-5-1": "audio/voiceover/en/sisters/2503_subentry_2503-5_subentry_2503-5-1_1_text_value.mp3",
            "2503-5-2": "audio/voiceover/en/sisters/2503_subentry_2503-5_subentry_2503-5-2_1_text_value.mp3",
            "2503-5-3": "audio/voiceover/en/sisters/2503_subentry_2503-5_subentry_2503-5-3_1_text_value.mp3",
            "2505-1": "audio/voiceover/en/sisters/2505_subentry_2505-1_1_text_value.mp3",
            "2505-2": "audio/voiceover/en/sisters/2505_subentry_2505-2_1_text_value.mp3",
            "2507-1": "audio/voiceover/en/sisters/2507_subentry_2507-1_1_text_value.mp3",
            "2507-1-1": "audio/voiceover/en/sisters/2507_subentry_2507-1_subentry_2507-1-1_1_text_value.mp3",
            "2507-1-2": "audio/voiceover/en/sisters/2507_subentry_2507-1_subentry_2507-1-2_1_text_value.mp3",
            "2507-2": "audio/voiceover/en/sisters/2507_subentry_2507-2_1_text_value.mp3",
            "2507-2-1": "audio/voiceover/en/sisters/2507_subentry_2507-2_subentry_2507-2-1_1_text_value.mp3",
            "2507-2-2": "audio/voiceover/en/sisters/2507_subentry_2507-2_subentry_2507-2-2_1_text_value.mp3",
            "2507-3": "audio/voiceover/en/sisters/2507_subentry_2507-3_1_text_value.mp3",
            "2507-4": "audio/voiceover/en/sisters/2507_subentry_2507-4_1_text_value.mp3",
            "2507-4-1": "audio/voiceover/en/sisters/2507_subentry_2507-4_subentry_2507-4-1_1_text_value.mp3",
            "2507-4-2": "audio/voiceover/en/sisters/2507_subentry_2507-4_subentry_2507-4-2_1_text_value.mp3",
            "2508-1": "audio/voiceover/en/sisters/2508_subentry_2508-1_1_text_value.mp3",
            "2508-2": "audio/voiceover/en/sisters/2508_subentry_2508-2_1_text_value.mp3",
            "2511-1": "audio/voiceover/en/sisters/2511_subentry_2511-1_1_text_value.mp3",
            "2511-1-1-1": "audio/voiceover/en/sisters/2511_subentry_2511-1_subentry_2511-1-1_subentry_2511-1-1-1_1_text_value.mp3",
            "2511-1-1-1-1": "audio/voiceover/en/sisters/2511_subentry_2511-1_subentry_2511-1-1_subentry_2511-1-1-1_subentry_2511-1-1-1-1_1_text_value.mp3",
            "2511-1-1-1-2": "audio/voiceover/en/sisters/2511_subentry_2511-1_subentry_2511-1-1_subentry_2511-1-1-1_subentry_2511-1-1-1-2_1_text_value.mp3",
            "2511-2-1-1": "audio/voiceover/en/sisters/2511_subentry_2511-2_subentry_2511-2-1_subentry_2511-2-1-1_1_text_value.mp3",
            "2511-2-1-2": "audio/voiceover/en/sisters/2511_subentry_2511-2_subentry_2511-2-1_subentry_2511-2-1-2_1_text_value.mp3",
            "2511-2-1-2-1-1": "audio/voiceover/en/sisters/9bf2d691_511-2-1_subentry_2511-2-1-2_subentry_2511-2-1-2-1_subentry_2511-2-1-2-1-1_1_text_value.mp3",
            "2511-2-1-3": "audio/voiceover/en/sisters/2511_subentry_2511-2_subentry_2511-2-1_subentry_2511-2-1-3_1_text_value.mp3",
            "2511-2-1-4": "audio/voiceover/en/sisters/2511_subentry_2511-2_subentry_2511-2-1_subentry_2511-2-1-4_1_text_value.mp3",
            "2511-2-1-5": "audio/voiceover/en/sisters/2511_subentry_2511-2_subentry_2511-2-1_subentry_2511-2-1-5_1_text_value.mp3",
            "2511-1-1-1-1-1-1": "audio/voiceover/en/sisters/2511-1-1-1-1-1-1.mp3",
            "2703-1": "audio/voiceover/en/sisters/2703_subentry_2703-1_1_text_value.mp3",
            "2703-2": "audio/voiceover/en/sisters/2703_subentry_2703-2_1_text_value.mp3",
            "2703-2-1": "audio/voiceover/en/sisters/2703_subentry_2703-2_subentry_2703-2-1_1_text_value.mp3",
            "2703-2-2": "audio/voiceover/en/sisters/2703_subentry_2703-2_subentry_2703-2-2_1_text_value.mp3",
            "2705-1": "audio/voiceover/en/sisters/2705_subentry_2705-1_1_text_value.mp3",
            "2705-2": "audio/voiceover/en/sisters/2705_subentry_2705-2_1_text_value.mp3",
            "2712-1": "audio/voiceover/en/sisters/2712_subentry_2712-1_1_text_value.mp3",
            "2712-1-1": "audio/voiceover/en/sisters/2712_subentry_2712-1_subentry_2712-1-1_1_text_value.mp3",
            "2712-2": "audio/voiceover/en/sisters/2712_subentry_2712-2_1_text_value.mp3",
            "8989-1-1-1-1-2-1": "audio/voiceover/en/sisters/b701a689_ry_8989-1-1-1_sbntry_8989-1-1-1-1_sbntry_8989-1-1-1-1-2_sbntry_8989-1-1-1-121_1_txt_vl.mp3",
            "8989-1-1-1-2-1-1-2-1": "audio/voiceover/en/sisters/329588a2_8989111__89891111__898911112__8989111121__89891111211__898911112112__8989111121121_1__.mp3",
            "8989-1-1-1-1-2-1-1-2": "audio/voiceover/en/sisters/89b6e47e_8989111_sbntry_89891111_sbntry_898911112_sbn_8989111121__89891111211__898911112112_1__.mp3",
            "8989-1-1-1-1-2-1-1": "audio/voiceover/en/sisters/d2d42aa4_8989111_sbntry_89891111_sbntry_898911112_sbntry_8989111121_sbntry_89891111211_1_txt_vl.mp3",
            "8989-1": "audio/voiceover/en/sisters/8989_subentry_8989-1_1_text_value.mp3",
            "8989-1-1": "audio/voiceover/en/sisters/8989_subentry_8989-1_subentry_8989-1-1_1_text_value.mp3",
            "8989-1-1-1-1-1": "audio/voiceover/en/sisters/1c6287e9_989-1-1_subentry_8989-1-1-1_subentry_8989-1-1-1-1_subentry_8989-1-1-1-1-1_1_text_value.mp3",
            "8989-1-1-1-1-2": "audio/voiceover/en/sisters/f6e45a8b_989-1-1_subentry_8989-1-1-1_subentry_8989-1-1-1-1_subentry_8989-1-1-1-1-2_1_text_value.mp3",
            "8989-1-1-1-1-3": "audio/voiceover/en/sisters/19b6ec6a_989-1-1_subentry_8989-1-1-1_subentry_8989-1-1-1-1_subentry_8989-1-1-1-1-3_1_text_value.mp3",
            "8989-1-1-1-2": "audio/voiceover/en/sisters/8989_subentry_8989-1_subentry_8989-1-1_subentry_8989-1-1-1_subentry_8989-1-1-1-2_1_text_value.mp3",
            "8989-1-1-1-2-1": "audio/voiceover/en/sisters/8bf04150_989-1-1_subentry_8989-1-1-1_subentry_8989-1-1-1-2_subentry_8989-1-1-1-2-1_1_text_value.mp3",
            "8989-1-1-1-2-2": "audio/voiceover/en/sisters/61769c32_989-1-1_subentry_8989-1-1-1_subentry_8989-1-1-1-2_subentry_8989-1-1-1-2-2_1_text_value.mp3",
            "8989-1-1-1-4": "audio/voiceover/en/sisters/8989_subentry_8989-1_subentry_8989-1-1_subentry_8989-1-1-1_subentry_8989-1-1-1-4_1_text_value.mp3",
            "8989-2": "audio/voiceover/en/sisters/8989_subentry_8989-2_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-3": "audio/voiceover/en/sisters/8989-1-1-1-1-2-1-1-2-1-1-3_1_text_value.mp3",
            "8989-1-1-1-1-4": "audio/voiceover/en/sisters/8989-1-1-1-1-4_1_text_value.mp3",
            "camp-1": "audio/voiceover/en/sisters/camp_subentry_camp-1_1_text_value.mp3",
            "camp-2": "audio/voiceover/en/sisters/camp_subentry_camp-2_1_text_value.mp3",
            "camp-3": "audio/voiceover/en/sisters/camp_subentry_camp-3_1_text_value.mp3",
            "fortune": "audio/voiceover/en/sisters/fortune_1_text_value.mp3",
            "fortune-1": "audio/voiceover/en/sisters/fortune_subentry_fortune-1_1_text_value.mp3",
            "fortune-1-1": "audio/voiceover/en/sisters/fortune_subentry_fortune-1_subentry_fortune-1-1_1_text_value.mp3",
            "fortune-1-1-1": "audio/voiceover/en/sisters/fortune_subentry_fortune-1_subentry_fortune-1-1_subentry_fortune-1-1-1_1_text_value.mp3",
            "fortune-1-1-2": "audio/voiceover/en/sisters/fortune_subentry_fortune-1_subentry_fortune-1-1_subentry_fortune-1-1-2_1_text_value.mp3",
            "fortune-1-1-3": "audio/voiceover/en/sisters/fortune_subentry_fortune-1_subentry_fortune-1-1_subentry_fortune-1-1-3_1_text_value.mp3",
            "fortune-2": "audio/voiceover/en/sisters/fortune-2.mp3",
            "omen-1": "audio/voiceover/en/sisters/omen_subentry_omen-1_1_text_value.mp3",
            "omen-2": "audio/voiceover/en/sisters/omen_subentry_omen-2_1_text_value.mp3",
            "omen-3": "audio/voiceover/en/sisters/omen_subentry_omen-3_1_text_value.mp3",
            "2511-2-1-2-1-2-2-1": "audio/voiceover/en/sisters/subentry_2511-2-1-2-1-2-2-1_1_text_value.mp3",
            "2511-2-1-2-1-2-2-2": "audio/voiceover/en/sisters/subentry_2511-2-1-2-1-2-2-2_1_text_value.mp3",
            "2511-2-1-2-1-2-3": "audio/voiceover/en/sisters/subentry_2511-2-1-2-1-2-3_1_text_value.mp3",
            "2511-2-1-2-1-2-4": "audio/voiceover/en/sisters/subentry_2511-2-1-2-1-2-4_1_text_value.mp3",
            "2511-2-1-2-1-2-5": "audio/voiceover/en/sisters/subentry_2511-2-1-2-1-2-5_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-1": "audio/voiceover/en/sisters/subentry_8989-1-1-1-1-2-1-1-2-1-1-1_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-1-1": "audio/voiceover/en/sisters/subentry_8989-1-1-1-1-2-1-1-2-1-1-1-1_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-1-1-1": "audio/voiceover/en/sisters/subentry_8989-1-1-1-1-2-1-1-2-1-1-1-1-1_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-1-1-1-1": "audio/voiceover/en/sisters/subentry_8989-1-1-1-1-2-1-1-2-1-1-1-1-1-1_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-1-1-1-2": "audio/voiceover/en/sisters/subentry_8989-1-1-1-1-2-1-1-2-1-1-1-1-1-2_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-1-2": "audio/voiceover/en/sisters/subentry_8989-1-1-1-1-2-1-1-2-1-1-1-2_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-2-1": "audio/voiceover/en/sisters/subentry_8989-1-1-1-1-2-1-1-2-1-1-2-1_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-2-1-1": "audio/voiceover/en/sisters/subentry_8989-1-1-1-1-2-1-1-2-1-1-2-1-1_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-2-2-1": "audio/voiceover/en/sisters/subentry_8989-1-1-1-1-2-1-1-2-1-1-2-2-1_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-2-2-2": "audio/voiceover/en/sisters/subentry_8989-1-1-1-1-2-1-1-2-1-1-2-2-2_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-3-1": "audio/voiceover/en/sisters/subentry_8989-1-1-1-1-2-1-1-2-1-1-3-1_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-3-2": "audio/voiceover/en/sisters/subentry_8989-1-1-1-1-2-1-1-2-1-1-3-2_1_text_value.mp3",
            "8989-1-1-1-1-2-1-1-2-1-1-3-3": "audio/voiceover/en/sisters/subentry_8989-1-1-1-1-2-1-1-2-1-1-3-3_1_text_value.mp3",
            "1103-3": "audio/voiceover/en/sisters/1103_subentry_1103-3_1_text_value.mp3",
            "1702-2": "audio/voiceover/en/sisters/1702-2.mp3",
            "1902-2": "audio/voiceover/en/sisters/1902_subentry_1902-2_1_text_value.mp3",
            "1902-1": "audio/voiceover/en/sisters/1902-2.mp3",
            "2302-1": "audio/voiceover/en/sisters/2302-1.mp3",
            "2511-1-1-1-1-1-2-1": "audio/voiceover/en/sisters/2511-1-1-1-1-1-2-1.mp3",
            "2511-2-1-2-1-2-1": "audio/voiceover/en/sisters/subentry_2511-2-1-2-1-2-1_1_text_value.mp3",
            "75-1-1": "audio/voiceover/en/sisters/75-1-1.mp3",
            "75-1": "audio/voiceover/en/sisters/75-1.mp3",
            "75-page25-1": "audio/voiceover/en/sisters/75-page25-1.mp3",
            "1103-2-2-1-2": "audio/voiceover/en/sisters/1103-2-2-1-2.mp3"
        },
        "prologue": {
            "81": "audio/voiceover/en/prologue/81_1_text_value.mp3",
            "700": "audio/voiceover/en/prologue/700_1_text_value.mp3",
            "701": "audio/voiceover/en/prologue/701_1_text_value.mp3",
            "702": "audio/voiceover/en/prologue/702_1_text_value.mp3",
            "703": "audio/voiceover/en/prologue/703_1_text_value.mp3",
            "704": "audio/voiceover/en/prologue/704_1_text_value.mp3",
            "705": "audio/voiceover/en/prologue/705_1_text_value.mp3",
            "706": "audio/voiceover/en/prologue/706_1_text_value.mp3",
            "707": "audio/voiceover/en/prologue/707_1_text_value.mp3",
            "708": "audio/voiceover/en/prologue/708_1_text_value.mp3",
            "2100": "audio/voiceover/en/prologue/2100_1_text_value.mp3",
            "2101": "audio/voiceover/en/prologue/2101_1_text_value.mp3",
            "2102": "audio/voiceover/en/prologue/2102_1_text_value.mp3",
            "2103": "audio/voiceover/en/prologue/2103_1_text_value.mp3",
            "2104": "audio/voiceover/en/prologue/2104_1_text_value.mp3",
            "2105": "audio/voiceover/en/prologue/2105_1_text_value.mp3",
            "2106": "audio/voiceover/en/prologue/2106_1_text_value.mp3",
            "81-1-1": "audio/voiceover/en/prologue/81_subentry_81-1_subentry_81-1-1_1_text_value.mp3",
            "81-1-2": "audio/voiceover/en/prologue/81_subentry_81-1_subentry_81-1-2_1_text_value.mp3",
            "701-1": "audio/voiceover/en/prologue/701_subentry_701-1_1_text_value.mp3",
            "701-2-1": "audio/voiceover/en/prologue/701_subentry_701-2_subentry_701-2-1_1_text_value.mp3",
            "701-2-2": "audio/voiceover/en/prologue/701_subentry_701-2_subentry_701-2-2_1_text_value.mp3",
            "701-3-1": "audio/voiceover/en/prologue/701_subentry_701-3_subentry_701-3-1_1_text_value.mp3",
            "701-3-2": "audio/voiceover/en/prologue/701_subentry_701-3_subentry_701-3-2_1_text_value.mp3",
            "709-1": "audio/voiceover/en/prologue/709_subentry_709-1_1_text_value.mp3",
            "709-1-1": "audio/voiceover/en/prologue/709_subentry_709-1_subentry_709-1-1_1_text_value.mp3",
            "709-1-1-1": "audio/voiceover/en/prologue/709_subentry_709-1_subentry_709-1-1_subentry_709-1-1-1_1_text_value.mp3",
            "709-2": "audio/voiceover/en/prologue/709_subentry_709-2_1_text_value.mp3",
            "709-2-1": "audio/voiceover/en/prologue/709_subentry_709-2_subentry_709-2-1_1_text_value.mp3",
            "709-2-1-1": "audio/voiceover/en/prologue/709_subentry_709-2_subentry_709-2-1_subentry_709-2-1-1_1_text_value.mp3",
            "2100-2": "audio/voiceover/en/prologue/2100_subentry_2100-2_1_text_value.mp3",
            "2100-3-1": "audio/voiceover/en/prologue/2100_subentry_2100-3_subentry_2100-3-1_1_text_value.mp3",
            "2100-3-2": "audio/voiceover/en/prologue/2100_subentry_2100-3_subentry_2100-3-2_1_text_value.mp3",
            "2100-3-2-1": "audio/voiceover/en/prologue/2100_subentry_2100-3_subentry_2100-3-2_subentry_2100-3-2-1_1_text_value.mp3",
            "2102-1": "audio/voiceover/en/prologue/2102_subentry_2102-1_1_text_value.mp3",
            "2102-2": "audio/voiceover/en/prologue/2102_subentry_2102-2_1_text_value.mp3",
            "dungeon-1-1-1-1-1": "audio/voiceover/en/prologue/be0d82ff_y_dungeon-1-1_subentry_dungen-1-1-1_sbntry_dngn-1-1-1-1_sbntry_dngn-1-1-1-1-1_1_txt_vl.mp3",
            "dungeon-1-1-1-1-2": "audio/voiceover/en/prologue/c26ca724_y_dungeon-1-1_subentry_dungen-1-1-1_sbntry_dngn-1-1-1-1_sbntry_dngn-1-1-1-1-2_1_txt_vl.mp3",
            "dungeon-1-1-1-4": "audio/voiceover/en/prologue/412ffd30_on-1_subentry_dungeon-1-1_subentry_dungeon-1-1-1_subentry_dungeon-1-1-1-4_1_text_value.mp3",
            "ending-1": "audio/voiceover/en/prologue/ending_subentry_ending-1_1_text_value.mp3",
            "fortune": "audio/voiceover/en/prologue/fortune_1_text_value.mp3",
            "fortune-1": "audio/voiceover/en/prologue/fortune_subentry_fortune-1_1_text_value.mp3",
            "fortune-1-2": "audio/voiceover/en/prologue/fortune_subentry_fortune-1_subentry_fortune-1-2_1_text_value.mp3",
            "fortune-1-3": "audio/voiceover/en/prologue/fortune_subentry_fortune-1_subentry_fortune-1-3_1_text_value.mp3",
            "fortune-1-4": "audio/voiceover/en/prologue/fortune_subentry_fortune-1_subentry_fortune-1-4_1_text_value.mp3",
            "fortune-1-5": "audio/voiceover/en/prologue/fortune_subentry_fortune-1_subentry_fortune-1-5_1_text_value.mp3",
            "fortune-1-5-1": "audio/voiceover/en/prologue/fortune_subentry_fortune-1_subentry_fortune-1-5_subentry_fortune-1-5-1_1_text_value.mp3",
            "fortune-1-5-1-1": "audio/voiceover/en/prologue/bb2d6dd9_ne-1_subentry_fortune-1-5_subentry_fortune-1-5-1_subentry_fortune-1-5-1-1_1_text_value.mp3",
            "fortune-1-5-1-2": "audio/voiceover/en/prologue/51abb0bb_ne-1_subentry_fortune-1-5_subentry_fortune-1-5-1_subentry_fortune-1-5-1-2_1_text_value.mp3",
            "omen": "audio/voiceover/en/prologue/omen_1_text_value.mp3",
            "omen-1": "audio/voiceover/en/prologue/omen_subentry_omen-1_1_text_value.mp3",
            "dungeon-1-1-1-2-2-1-1-1-1": "audio/voiceover/en/prologue/subentry_dungeon-1-1-1-2-2-1-1-1-1_1_text_value.mp3",
            "dungeon-1-1-1-2-2-1-1-1-1-1": "audio/voiceover/en/prologue/subentry_dungeon-1-1-1-2-2-1-1-1-1-1_1_text_value.mp3",
            "dungeon-1-1-1-2-2-1-1-1-1-1-1-1-1": "audio/voiceover/en/prologue/subentry_dungeon-1-1-1-2-2-1-1-1-1-1-1-1-1_1_text_value.mp3",
            "dungeon-1-1-1-2-2-1-1-1-1-1-1-1-2": "audio/voiceover/en/prologue/subentry_dungeon-1-1-1-2-2-1-1-1-1-1-1-1-2_1_text_value.mp3",
            "dungeon-1-1-1-2-2-1-1-1-1-1-1-1-2-1": "audio/voiceover/en/prologue/subentry_dungeon-1-1-1-2-2-1-1-1-1-1-1-1-2-1_1_text_value.mp3",
            "dungeon-1-1-1-2-2-1-1-1-1-1-1-2-1": "audio/voiceover/en/prologue/subentry_dungeon-1-1-1-2-2-1-1-1-1-1-1-2-1_1_text_value.mp3",
            "dungeon-1-1-1-2-2-1-1-1-1-1-1-2-2": "audio/voiceover/en/prologue/subentry_dungeon-1-1-1-2-2-1-1-1-1-1-1-2-2_1_text_value.mp3",
            "dungeon-1-1-1-2-2-1-1-1-2": "audio/voiceover/en/prologue/subentry_dungeon-1-1-1-2-2-1-1-1-2_1_text_value.mp3",
            "dungeon-1-1-1-2-2-1-1-2-1": "audio/voiceover/en/prologue/subentry_dungeon-1-1-1-2-2-1-1-2-1_1_text_value.mp3",
            "dungeon-1-1-1-2-2-1-1-2-2": "audio/voiceover/en/prologue/subentry_dungeon-1-1-1-2-2-1-1-2-2_1_text_value.mp3",
            "dungeon-1-1-1-2-2-1-1-3-1": "audio/voiceover/en/prologue/subentry_dungeon-1-1-1-2-2-1-1-3-1_1_text_value.mp3",
            "dungeon-1-1-1-2-2-1-1-3-2": "audio/voiceover/en/prologue/subentry_dungeon-1-1-1-2-2-1-1-3-2_1_text_value.mp3",
            "dungeon-1-1-1-2-3": "audio/voiceover/en/prologue/subentry_dungeon-1-1-1-2-3_1_text_value.mp3",
            "dungeon-1-1-1-3": "audio/voiceover/en/prologue/subentry_dungeon-1-1-1-3_1_text_value.mp3",
            "2106-1-1": "audio/voiceover/en/prologue/2106_1_-1.mp3",
            "2106-1": "audio/voiceover/en/prologue/2106-1.mp3",
            "2106-2": "audio/voiceover/en/prologue/2106-2.mp3",
            "dungeon": "audio/voiceover/en/prologue/dungeon_1_text_value.mp3",
            "dungeon-1-1": "audio/voiceover/en/prologue/dungeon_subentry_dungeon-1_subentry_dungeon-1-1_1_text_value.mp3",
            "dungeon-1-1-1-2-1": "audio/voiceover/en/prologue/f3254df3_y_dungeon-1-1_subentry_dungen-1-1-1_sbntry_dngn-1-1-1-2_sbntry_dngn-1-1-1-2-1_1_txt_vl.mp3",
            "dungeon-1-1-1-2-2-1": "audio/voiceover/en/prologue/8f446828_y_dungeon-1-1_subentry_dungen-1-1-1_sbntry_dngn-1-1-1-2_sbntry_dngn-1-1-1-2-2_1_txt_vl.mp3",
            "ending-2": "audio/voiceover/en/prologue/ending_subentry_ending-2_1_text_value.mp3",
            "ending-2-1": "audio/voiceover/en/prologue/ending_subentry_ending-2_subentry_ending-2-1_1_text_value.mp3",
            "ending-2-2": "audio/voiceover/en/prologue/ending_subentry_ending-2_subentry_ending-2-2_1_text_value.mp3"
        },
        "heroes": {
            '3-1': 'audio/voiceover/en/heroes/3_subentry_3-1_1_text_value.mp3',
            '82-1': 'audio/voiceover/en/heroes/82_subentry_82-1_1_text_value.mp3',
            '85-1': 'audio/voiceover/en/heroes/85_subentry_85-1_1_text_value.mp3',
            '85-1-1-1': 'audio/voiceover/en/heroes/85_subentry_85-1_subentry_85-1-1_subentry_85-1-1-1_1_text_value.mp3',
            '85-1-1-1-1-1-1': 'audio/voiceover/en/heroes/sbntry_85-1-1-1-1-1-1_1_txt_vl.mp3',
            '86': 'audio/voiceover/en/heroes/86_1_text_value.mp3',
            '88': 'audio/voiceover/en/heroes/88_1_text_value.mp3',
            '1300 - Cemetary': 'audio/voiceover/en/heroes/1300 - Cemetary_1_text_value.mp3',
            '1906': 'audio/voiceover/en/heroes/1906_1_text_value.mp3',
            '1907': 'audio/voiceover/en/heroes/1907_1_text_value.mp3',
            '1908': 'audio/voiceover/en/heroes/1908_1_text_value.mp3',
            '3100 - The Lich': 'audio/voiceover/en/heroes/3100 - The Lich_1_text_value.mp3',
            '3101-2-1-1-1-1': 'audio/voiceover/en/heroes/subentry_3101-2-1-1-1-1_1_text_value.mp3',
            '3700 - The Wastes': 'audio/voiceover/en/heroes/3700 - The Wastes .mp3',
            '3900 - The Highways': 'audio/voiceover/en/heroes/3900 - The Highways_1_text_value.mp3',
            '3903': 'audio/voiceover/en/heroes/3903_1_text_value.mp3',
            '3905-2': 'audio/voiceover/en/heroes/3905_subentry_3905-2_1_text_value.mp3',
            '3905-3': 'audio/voiceover/en/heroes/3905_subentry_3905-3_1_text_value.mp3',
            '3913-1': 'audio/voiceover/en/heroes/3913_subentry_3913-1_1_text_value.mp3',
            '3-page31-1': 'audio/voiceover/en/heroes/3-page31-1.mp3',
            '3-page47-1': 'audio/voiceover/en/heroes/3-page47-1.mp3',
            '25-page19-1-2': 'audio/voiceover/en/heroes/25-page19-1-2.mp3',
            '25-page31-1-2': 'audio/voiceover/en/heroes/25-page31-1-2.mp3',
            '3901-1-2': 'audio/voiceover/en/heroes/3901-1-2.mp3',
            '3901-2': 'audio/voiceover/en/heroes/3901-2.mp3',
            '4704-1-2': 'audio/voiceover/en/heroes/4704-1-2.mp3',
            '4708-1-2': 'audio/voiceover/en/heroes/4708-1-2.mp3',
            "1301": "audio/voiceover/en/heroes/1301_1_text_value.mp3",
            "1302": "audio/voiceover/en/heroes/1302_1_text_value.mp3",
            "1303": "audio/voiceover/en/heroes/1303_1_text_value.mp3",
            "1304": "audio/voiceover/en/heroes/1304_1_text_value.mp3",
            "1305": "audio/voiceover/en/heroes/1305_1_text_value.mp3",
            "1306": "audio/voiceover/en/heroes/1306_1_text_value.mp3",
            "1307": "audio/voiceover/en/heroes/1307_1_text_value.mp3",
            "1308": "audio/voiceover/en/heroes/1308_1_text_value.mp3",
            "1309": "audio/voiceover/en/heroes/1309_1_text_value.mp3",
            "1310": "audio/voiceover/en/heroes/1310_1_text_value.mp3",
            "1904": "audio/voiceover/en/heroes/1904_1_text_value.mp3",
            "1905": "audio/voiceover/en/heroes/1905_1_text_value.mp3",
            "1909": "audio/voiceover/en/heroes/1909_1_text_value.mp3",
            "3103": "audio/voiceover/en/heroes/3103_1_text_value.mp3",
            "3104": "audio/voiceover/en/heroes/3104_1_text_value.mp3",
            "3500": "audio/voiceover/en/heroes/3500_1_text_value.mp3",
            "3501": "audio/voiceover/en/heroes/3501_1_text_value.mp3",
            "3502": "audio/voiceover/en/heroes/3502_1_text_value.mp3",
            "3503": "audio/voiceover/en/heroes/3503_1_text_value.mp3",
            "3504": "audio/voiceover/en/heroes/3504_1_text_value.mp3",
            "3505": "audio/voiceover/en/heroes/3505_1_text_value.mp3",
            "3506": "audio/voiceover/en/heroes/3506_1_text_value.mp3",
            "3507": "audio/voiceover/en/heroes/3507_1_text_value.mp3",
            "3508": "audio/voiceover/en/heroes/3508_1_text_value.mp3",
            "3509": "audio/voiceover/en/heroes/3509_1_text_value.mp3",
            "3701": "audio/voiceover/en/heroes/3701_1_text_value.mp3",
            "3702": "audio/voiceover/en/heroes/3702_1_text_value.mp3",
            "3703": "audio/voiceover/en/heroes/3703_1_text_value.mp3",
            "3704": "audio/voiceover/en/heroes/3704_1_text_value.mp3",
            "3705": "audio/voiceover/en/heroes/3705_1_text_value.mp3",
            "3706": "audio/voiceover/en/heroes/3706_1_text_value.mp3",
            "3901": "audio/voiceover/en/heroes/3901_1_text_value.mp3",
            "3902": "audio/voiceover/en/heroes/3902_1_text_value.mp3",
            "3904": "audio/voiceover/en/heroes/3904_1_text_value.mp3",
            "3905": "audio/voiceover/en/heroes/3905_1_text_value.mp3",
            "3906": "audio/voiceover/en/heroes/3906_1_text_value.mp3",
            "3907": "audio/voiceover/en/heroes/3907_1_text_value.mp3",
            "3908": "audio/voiceover/en/heroes/3908_1_text_value.mp3",
            "3909": "audio/voiceover/en/heroes/3909_1_text_value.mp3",
            "3910": "audio/voiceover/en/heroes/3910_1_text_value.mp3",
            "3911": "audio/voiceover/en/heroes/3911_1_text_value.mp3",
            "3912": "audio/voiceover/en/heroes/3912_1_text_value.mp3",
            "4705": "audio/voiceover/en/heroes/4705_1_text_value.mp3",
            "4706": "audio/voiceover/en/heroes/4706_1_text_value.mp3",
            "4707": "audio/voiceover/en/heroes/4707_1_text_value.mp3",
            "4708": "audio/voiceover/en/heroes/4708_1_text_value.mp3",
            "1-page19-1-1": "audio/voiceover/en/heroes/1_subentry_1-page19-1_subentry_1-page19-1-1_1_text_value.mp3",
            "75-1": "audio/voiceover/en/heroes/75_subentry_75-1_1_text_value.mp3",
            "75-1-1": "audio/voiceover/en/heroes/75_subentry_75-1_subentry_75-1-1_1_text_value.mp3",
            "75-1-2": "audio/voiceover/en/heroes/75_subentry_75-1_subentry_75-1-2_1_text_value.mp3",
            "81-1-1-2-1-1-1-1": "audio/voiceover/en/heroes/b7319034_1-1-2_sbntry_81-1-1-2-1_sbntry_81-1-1-2-1-1_sbntry_81-112111_sbntry_811121111_1_txt_vl.mp3",
            "81-1": "audio/voiceover/en/heroes/81_subentry_81-1_1_text_value.mp3",
            "81-1-1": "audio/voiceover/en/heroes/81_subentry_81-1_subentry_81-1-1_1_text_value.mp3",
            "81-1-1-1-1-1": "audio/voiceover/en/heroes/162f091d_bentry_81-1-1_subentry_81-1-1-1_subentry_81-1-1-1-1_subentry_81-1-1-1-1-1_1_text_value.mp3",
            "81-1-1-1-1-2": "audio/voiceover/en/heroes/fca9d47f_bentry_81-1-1_subentry_81-1-1-1_subentry_81-1-1-1-1_subentry_81-1-1-1-1-2_1_text_value.mp3",
            "81-1-1-2": "audio/voiceover/en/heroes/81_subentry_81-1_subentry_81-1-1_subentry_81-1-1-2_1_text_value.mp3",
            "81-1-1-2-1": "audio/voiceover/en/heroes/81_subentry_81-1_subentry_81-1-1_subentry_81-1-1-2_subentry_81-1-1-2-1_1_text_value.mp3",
            "81-1-1-2-1-1": "audio/voiceover/en/heroes/9369bafe_bentry_81-1-1_subentry_81-1-1-2_subentry_81-1-1-2-1_subentry_81-1-1-2-1-1_1_text_value.mp3",
            "81-1-1-2-1-1-1": "audio/voiceover/en/heroes/233f4ded_ry_81-1-1-2_subentry_81-1-1-2-1_subentry_81-1-1-2-1-1_subentry_81-1-1-2-1-1-1_1_txt_vl.mp3",
            "81-1-1-3": "audio/voiceover/en/heroes/81_subentry_81-1_subentry_81-1-1_subentry_81-1-1-3_1_text_value.mp3",
            "81-1-1-3-1": "audio/voiceover/en/heroes/81_subentry_81-1_subentry_81-1-1_subentry_81-1-1-3_subentry_81-1-1-3-1_1_text_value.mp3",
            "81-1-1-3-2": "audio/voiceover/en/heroes/81_subentry_81-1_subentry_81-1-1_subentry_81-1-1-3_subentry_81-1-1-3-2_1_text_value.mp3",
            "82-1-1": "audio/voiceover/en/heroes/82_subentry_82-1_subentry_82-1-1_1_text_value.mp3",
            "82-1-1-1": "audio/voiceover/en/heroes/82_subentry_82-1_subentry_82-1-1_subentry_82-1-1-1_1_text_value.mp3",
            "82-1-1-2": "audio/voiceover/en/heroes/82_subentry_82-1_subentry_82-1-1_subentry_82-1-1-2_1_text_value.mp3",
            "82-1-2-1-1": "audio/voiceover/en/heroes/82_subentry_82-1_subentry_82-1-2_subentry_82-1-2-1_subentry_82-1-2-1-1_1_text_value.mp3",
            "82-1-2-1-1-1": "audio/voiceover/en/heroes/d9b1c50e_bentry_82-1-2_subentry_82-1-2-1_subentry_82-1-2-1-1_subentry_82-1-2-1-1-1_1_text_value.mp3",
            "82-1-2-1-1-2": "audio/voiceover/en/heroes/3337186c_bentry_82-1-2_subentry_82-1-2-1_subentry_82-1-2-1-1_subentry_82-1-2-1-1-2_1_text_value.mp3",
            "82-1-2-1-2": "audio/voiceover/en/heroes/82_subentry_82-1_subentry_82-1-2_subentry_82-1-2-1_subentry_82-1-2-1-2_1_text_value.mp3",
            "82-1-3-1-1": "audio/voiceover/en/heroes/82_subentry_82-1_subentry_82-1-3_subentry_82-1-3-1_subentry_82-1-3-1-1_1_text_value.mp3",
            "82-2": "audio/voiceover/en/heroes/82_subentry_82-2_1_text_value.mp3",
            "82-2-1-1": "audio/voiceover/en/heroes/82_subentry_82-2_subentry_82-2-1_subentry_82-2-1-1_1_text_value.mp3",
            "82-2-1-2": "audio/voiceover/en/heroes/82_subentry_82-2_subentry_82-2-1_subentry_82-2-1-2_1_text_value.mp3",
            "82-2-1-2-1": "audio/voiceover/en/heroes/82_subentry_82-2_subentry_82-2-1_subentry_82-2-1-2_subentry_82-2-1-2-1_1_text_value.mp3",
            "82-2-1-2-2": "audio/voiceover/en/heroes/82_subentry_82-2_subentry_82-2-1_subentry_82-2-1-2_subentry_82-2-1-2-2_1_text_value.mp3",
            "82-2-1-3-1-1": "audio/voiceover/en/heroes/6a9fd1df_bentry_82-2-1_subentry_82-2-1-3_subentry_82-2-1-3-1_subentry_82-2-1-3-1-1_1_text_value.mp3",
            "82-2-1-3-1-2": "audio/voiceover/en/heroes/80190cbd_bentry_82-2-1_subentry_82-2-1-3_subentry_82-2-1-3-1_subentry_82-2-1-3-1-2_1_text_value.mp3",
            "82-2-1-3-2": "audio/voiceover/en/heroes/82_subentry_82-2_subentry_82-2-1_subentry_82-2-1-3_subentry_82-2-1-3-2_1_text_value.mp3",
            "85-1-1-1-1-1-2-1-1": "audio/voiceover/en/heroes/270d504c_sbntry_851111_sbntry_8511111_sbntry_85111112_sbntry_851111121_sbntry_8511111211_1_txt_.mp3",
            "85-1-1-1-2": "audio/voiceover/en/heroes/85_subentry_85-1_subentry_85-1-1_subentry_85-1-1-1_subentry_85-1-1-1-2_1_text_value.mp3",
            "85-1-2-1-1": "audio/voiceover/en/heroes/85_subentry_85-1_subentry_85-1-2_subentry_85-1-2-1_subentry_85-1-2-1-1_1_text_value.mp3",
            "85-1-2-1-2": "audio/voiceover/en/heroes/85_subentry_85-1_subentry_85-1-2_subentry_85-1-2-1_subentry_85-1-2-1-2_1_text_value.mp3",
            "85-2": "audio/voiceover/en/heroes/85_subentry_85-2_1_text_value.mp3",
            "85-2-1-1-1": "audio/voiceover/en/heroes/85_subentry_85-2_subentry_85-2-1_subentry_85-2-1-1_subentry_85-2-1-1-1_1_text_value.mp3",
            "85-2-1-1-2": "audio/voiceover/en/heroes/85_subentry_85-2_subentry_85-2-1_subentry_85-2-1-1_subentry_85-2-1-1-2_1_text_value.mp3",
            "85-2-1-2-1": "audio/voiceover/en/heroes/85_subentry_85-2_subentry_85-2-1_subentry_85-2-1-2_subentry_85-2-1-2-1_1_text_value.mp3",
            "85-2-1-2-2": "audio/voiceover/en/heroes/85_subentry_85-2_subentry_85-2-1_subentry_85-2-1-2_subentry_85-2-1-2-2_1_text_value.mp3",
            "70-1": "audio/voiceover/en/heroes/70-1_1_text_value.mp3",
            "1300 - Cemetary-1-1": "audio/voiceover/en/heroes/1300 - Cemetary_subentry_1300 - Cemetary-1_subentry_1300 - Cemetary-1-1_1_text_value.mp3",
            "1300 - Cemetary-1-2": "audio/voiceover/en/heroes/1300 - Cemetary_subentry_1300 - Cemetary-1_subentry_1300 - Cemetary-1-2_1_text_value.mp3",
            "1306-4-1": "audio/voiceover/en/heroes/1306_subentry_1306-4_subentry_1306-4-1_1_text_value.mp3",
            "1306-4-1-1": "audio/voiceover/en/heroes/1306_subentry_1306-4_subentry_1306-4-1_subentry_1306-4-1-1_1_text_value.mp3",
            "1306-4-1-1-1": "audio/voiceover/en/heroes/1306_subentry_1306-4_subentry_1306-4-1_subentry_1306-4-1-1_subentry_1306-4-1-1-1_1_text_value.mp3",
            "1306-4-1-1-2": "audio/voiceover/en/heroes/1306_subentry_1306-4_subentry_1306-4-1_subentry_1306-4-1-1_subentry_1306-4-1-1-2_1_text_value.mp3",
            "1306-4-1-2": "audio/voiceover/en/heroes/1306_subentry_1306-4_subentry_1306-4-1_subentry_1306-4-1-2_1_text_value.mp3",
            "1306-4-2": "audio/voiceover/en/heroes/1306_subentry_1306-4_subentry_1306-4-2_1_text_value.mp3",
            "1306-4-2-1": "audio/voiceover/en/heroes/1306_subentry_1306-4_subentry_1306-4-2_subentry_1306-4-2-1_1_text_value.mp3",
            "1306-4-2-2": "audio/voiceover/en/heroes/1306_subentry_1306-4_subentry_1306-4-2_subentry_1306-4-2-2_1_text_value.mp3",
            "1306-4-2-3": "audio/voiceover/en/heroes/1306_subentry_1306-4_subentry_1306-4-2_subentry_1306-4-2-3_1_text_value.mp3",
            "1306-4-3": "audio/voiceover/en/heroes/1306_subentry_1306-4_subentry_1306-4-3_1_text_value.mp3",
            "1306-5": "audio/voiceover/en/heroes/1306_subentry_1306-5_1_text_value.mp3",
            "1900 - Treasure Golem": "audio/voiceover/en/heroes/1900 - Treasure Golem_1_text_value.mp3",
            "1901-1": "audio/voiceover/en/heroes/1901_subentry_1901-1_1_text_value.mp3",
            "1901-2": "audio/voiceover/en/heroes/1901_subentry_1901-2_1_text_value.mp3",
            "1902-1": "audio/voiceover/en/heroes/1902_subentry_1902-1_1_text_value.mp3",
            "1902-2": "audio/voiceover/en/heroes/1902_subentry_1902-2_1_text_value.mp3",
            "1902-2-1": "audio/voiceover/en/heroes/1902_subentry_1902-2_subentry_1902-2-1_1_text_value.mp3",
            "1902-2-2": "audio/voiceover/en/heroes/1902_subentry_1902-2_subentry_1902-2-2_1_text_value.mp3",
            "1902-3": "audio/voiceover/en/heroes/1902_subentry_1902-3_1_text_value.mp3",
            "1903-1": "audio/voiceover/en/heroes/1903_subentry_1903-1_1_text_value.mp3",
            "1903-2": "audio/voiceover/en/heroes/1903_subentry_1903-2_1_text_value.mp3",
            "1910-1": "audio/voiceover/en/heroes/1910_subentry_1910-1_1_text_value.mp3",
            "1910-2": "audio/voiceover/en/heroes/1910_subentry_1910-2_1_text_value.mp3",
            "3101-2-1-1-1-1-1": "audio/voiceover/en/heroes/f89a4587_ry_3101-2-1-1_sbntry_3101-2-1-1-1_sbntry_3101-2-1-1-1-1_sbntry_3101-2-1-1-111_1_txt_vl.mp3",
            "3101-2-1-1-1-1-1-1-1-1": "audio/voiceover/en/heroes/73f65a05_3101211__31012111__310121111__3101211111__31012111111__310121111111__3101211111111_1__.mp3",
            "3101-2-1-1-1-1-1-1-2-1": "audio/voiceover/en/heroes/c0df482e_3101211__31012111__310121111__3101211111__31012111111__310121111112__3101211111121_1__.mp3",
            "3101-2-1-1-1-1-1-1-1": "audio/voiceover/en/heroes/fbd1fd3_3101211_sbntry_31012111_sbntry_310121111_sbn_3101211111__31012111111__310121111111_1__.mp3",
            "3101-2-1-1-1-1-1-1-2": "audio/voiceover/en/heroes/36302316_3101211_sbntry_31012111_sbntry_310121111_sbn_3101211111__31012111111__310121111112_1__.mp3",
            "3101-1": "audio/voiceover/en/heroes/3101_subentry_3101-1_1_text_value.mp3",
            "3101-2": "audio/voiceover/en/heroes/3101_subentry_3101-2_1_text_value.mp3",
            "3101-2-1": "audio/voiceover/en/heroes/3101_subentry_3101-2_subentry_3101-2-1_1_text_value.mp3",
            "3101-2-1-1-1": "audio/voiceover/en/heroes/3101_subentry_3101-2_subentry_3101-2-1_subentry_3101-2-1-1_subentry_3101-2-1-1-1_1_text_value.mp3",
            "3101-2-1-1-2": "audio/voiceover/en/heroes/3101_subentry_3101-2_subentry_3101-2-1_subentry_3101-2-1-1_subentry_3101-2-1-1-2_1_text_value.mp3",
            "3101-2-2": "audio/voiceover/en/heroes/3101_subentry_3101-2_subentry_3101-2-2_1_text_value.mp3",
            "3101-2-2-1": "audio/voiceover/en/heroes/3101_subentry_3101-2_subentry_3101-2-2_subentry_3101-2-2-1_1_text_value.mp3",
            "3102-1": "audio/voiceover/en/heroes/3102_subentry_3102-1_1_text_value.mp3",
            "3102-1-1": "audio/voiceover/en/heroes/3102_subentry_3102-1_subentry_3102-1-1_1_text_value.mp3",
            "3102-1-1-1": "audio/voiceover/en/heroes/3102_subentry_3102-1_subentry_3102-1-1_subentry_3102-1-1-1_1_text_value.mp3",
            "3102-1-1-1-1": "audio/voiceover/en/heroes/3102_subentry_3102-1_subentry_3102-1-1_subentry_3102-1-1-1_subentry_3102-1-1-1-1_1_text_value.mp3",
            "3102-1-1-1-1-1": "audio/voiceover/en/heroes/e07a5115_102-1-1_subentry_3102-1-1-1_subentry_3102-1-1-1-1_subentry_3102-1-1-1-1-1_1_text_value.mp3",
            "3103-1": "audio/voiceover/en/heroes/3103_subentry_3103-1_1_text_value.mp3",
            "3103-2": "audio/voiceover/en/heroes/3103_subentry_3103-2_1_text_value.mp3",
            "3104-1": "audio/voiceover/en/heroes/3104_subentry_3104-1_1_text_value.mp3",
            "3104-2": "audio/voiceover/en/heroes/3104_subentry_3104-2_1_text_value.mp3",
            "3104-3": "audio/voiceover/en/heroes/3104_subentry_3104-3_1_text_value.mp3",
            "3105-1-1": "audio/voiceover/en/heroes/3105_subentry_3105-1_subentry_3105-1-1_1_text_value.mp3",
            "3105-1-1-1": "audio/voiceover/en/heroes/3105_subentry_3105-1_subentry_3105-1-1_subentry_3105-1-1-1_1_text_value.mp3",
            "3105-1-2": "audio/voiceover/en/heroes/3105_subentry_3105-1_subentry_3105-1-2_1_text_value.mp3",
            "3105-2": "audio/voiceover/en/heroes/3105_subentry_3105-2_1_text_value.mp3",
            "3105-2-1": "audio/voiceover/en/heroes/3105_subentry_3105-2_subentry_3105-2-1_1_text_value.mp3",
            "3500-1-1": "audio/voiceover/en/heroes/3500_subentry_3500-1_subentry_3500-1-1_1_text_value.mp3",
            "3500-1-1-1": "audio/voiceover/en/heroes/3500_subentry_3500-1_subentry_3500-1-1_subentry_3500-1-1-1_1_text_value.mp3",
            "3500-1-2": "audio/voiceover/en/heroes/3500_subentry_3500-1_subentry_3500-1-2_1_text_value.mp3",
            "3500-1-2-1": "audio/voiceover/en/heroes/3500_subentry_3500-1_subentry_3500-1-2_subentry_3500-1-2-1_1_text_value.mp3",
            "3501-1": "audio/voiceover/en/heroes/3501_subentry_3501-1_1_text_value.mp3",
            "3501-2": "audio/voiceover/en/heroes/3501_subentry_3501-2_1_text_value.mp3",
            "3501-3": "audio/voiceover/en/heroes/3501_subentry_3501-3_1_text_value.mp3",
            "3502-1": "audio/voiceover/en/heroes/3502_subentry_3502-1_1_text_value.mp3",
            "3502-2": "audio/voiceover/en/heroes/3502_subentry_3502-2_1_text_value.mp3",
            "3502-2-1": "audio/voiceover/en/heroes/3502_subentry_3502-2_subentry_3502-2-1_1_text_value.mp3",
            "3502-2-2": "audio/voiceover/en/heroes/3502_subentry_3502-2_subentry_3502-2-2_1_text_value.mp3",
            "3502-2-3": "audio/voiceover/en/heroes/3502_subentry_3502-2_subentry_3502-2-3_1_text_value.mp3",
            "3502-2-4": "audio/voiceover/en/heroes/3502_subentry_3502-2_subentry_3502-2-4_1_text_value.mp3",
            "3502-3": "audio/voiceover/en/heroes/3502_subentry_3502-3_1_text_value.mp3",
            "3502-4": "audio/voiceover/en/heroes/3502_subentry_3502-4_1_text_value.mp3",
            "3505-1": "audio/voiceover/en/heroes/3505_subentry_3505-1_1_text_value.mp3",
            "3505-2": "audio/voiceover/en/heroes/3505_subentry_3505-2_1_text_value.mp3",
            "3505-3": "audio/voiceover/en/heroes/3505_subentry_3505-3_1_text_value.mp3",
            "3507-1": "audio/voiceover/en/heroes/3507_subentry_3507-1_1_text_value.mp3",
            "3507-2": "audio/voiceover/en/heroes/3507_subentry_3507-2_1_text_value.mp3",
            "3508-1": "audio/voiceover/en/heroes/3508_subentry_3508-1_1_text_value.mp3",
            "3508-2": "audio/voiceover/en/heroes/3508_subentry_3508-2_1_text_value.mp3",
            "3704-1": "audio/voiceover/en/heroes/3704_subentry_3704-1_1_text_value.mp3",
            "3704-2-1": "audio/voiceover/en/heroes/3704_subentry_3704-2_subentry_3704-2-1_1_text_value.mp3",
            "3704-2-2": "audio/voiceover/en/heroes/3704_subentry_3704-2_subentry_3704-2-2_1_text_value.mp3",
            "3704-3-1": "audio/voiceover/en/heroes/3704_subentry_3704-3_subentry_3704-3-1_1_text_value.mp3",
            "3704-3-2": "audio/voiceover/en/heroes/3704_subentry_3704-3_subentry_3704-3-2_1_text_value.mp3",
            "3705-1": "audio/voiceover/en/heroes/3705_subentry_3705-1_1_text_value.mp3",
            "3705-1-1": "audio/voiceover/en/heroes/3705_subentry_3705-1_subentry_3705-1-1_1_text_value.mp3",
            "3705-1-2": "audio/voiceover/en/heroes/3705_subentry_3705-1_subentry_3705-1-2_1_text_value.mp3",
            "3705-1-3": "audio/voiceover/en/heroes/3705_subentry_3705-1_subentry_3705-1-3_1_text_value.mp3",
            "3705-2": "audio/voiceover/en/heroes/3705_subentry_3705-2_1_text_value.mp3",
            "3705-3-1": "audio/voiceover/en/heroes/3705_subentry_3705-3_subentry_3705-3-1_1_text_value.mp3",
            "3705-3-2": "audio/voiceover/en/heroes/3705_subentry_3705-3_subentry_3705-3-2_1_text_value.mp3",
            "3705-3-3": "audio/voiceover/en/heroes/3705_subentry_3705-3_subentry_3705-3-3_1_text_value.mp3",
            "3705-3-4": "audio/voiceover/en/heroes/3705_subentry_3705-3_subentry_3705-3-4_1_text_value.mp3",
            "3900 - The Highways-1": "audio/voiceover/en/heroes/3900 - The Highways_subentry_3900 - The Highways-1_1_text_value.mp3",
            "3900 - The Highways-1-1": "audio/voiceover/en/heroes/3900 - The Highways_subentry_3900 - The Highways-1_subentry_3900 - The Highways-1-1_1_text_value.mp3",
            "3900 - The Highways-1-2": "audio/voiceover/en/heroes/3900 - The Highways_subentry_3900 - The Highways-1_subentry_3900 - The Highways-1-2_1_text_value.mp3",
            "3901-1": "audio/voiceover/en/heroes/3901_subentry_3901-1_1_text_value.mp3",
            "3901-1-1": "audio/voiceover/en/heroes/3901_subentry_3901-1_subentry_3901-1-1_1_text_value.mp3",
            "3904-1": "audio/voiceover/en/heroes/3904_subentry_3904-1_1_text_value.mp3",
            "3904-2": "audio/voiceover/en/heroes/3904_subentry_3904-2_1_text_value.mp3",
            "3905-1": "audio/voiceover/en/heroes/3905_subentry_3905-1_1_text_value.mp3",
            "3907-1": "audio/voiceover/en/heroes/3907_subentry_3907-1_1_text_value.mp3",
            "3907-2": "audio/voiceover/en/heroes/3907_subentry_3907-2_1_text_value.mp3",
            "3913-2": "audio/voiceover/en/heroes/3913_subentry_3913-2_1_text_value.mp3",
            "4700 - Trap Room": "audio/voiceover/en/heroes/4700 - Trap Room_1_text_value.mp3",
            "4700 - Trap Room-1-1": "audio/voiceover/en/heroes/4700 - Trap Room_subentry_4700 - Trap Room-1_subentry_4700 - Trap Room-1-1_1_text_value.mp3",
            "4700 - Trap Room-1-2": "audio/voiceover/en/heroes/4700 - Trap Room_subentry_4700 - Trap Room-1_subentry_4700 - Trap Room-1-2_1_text_value.mp3",
            "4700 - Trap Room-1-2-1-1": "audio/voiceover/en/heroes/f81d220_subentry_4700-TrapRoom-1-2_subntry_4700-TrpRm-1-2-1_sbntry_4700-TrpRm-1-2-1-1_1_txt_vl.mp3",
            "4700 - Trap Room-1-2-1-2": "audio/voiceover/en/heroes/73e0f7fb_subentry_4700-TrapRoom-1-2_subntry_4700-TrpRm-1-2-1_sbntry_4700-TrpRm-1-2-1-2_1_txt_vl.mp3",
            "4700 - Trap Room-1-2-1-1-1": "audio/voiceover/en/heroes/ce4baebf_700-TrpRm-1-2_sbntry_4700-TrpRm121_sbntry_4700TrpRm1211_sbntry_4700TrpRm12111_1_txt_vl.mp3",
            "4700 - Trap Room-1-2-1-1-2": "audio/voiceover/en/heroes/b22a8b64_700-TrpRm-1-2_sbntry_4700-TrpRm121_sbntry_4700TrpRm1211_sbntry_4700TrpRm12112_1_txt_vl.mp3",
            "4703-2-1": "audio/voiceover/en/heroes/4703_subentry_4703-2_subentry_4703-2-1_1_text_value.mp3",
            "4704-1": "audio/voiceover/en/heroes/4704_1_text_value.mp3",
            "4707-1": "audio/voiceover/en/heroes/4707_subentry_4707-1_1_text_value.mp3",
            "4707-2": "audio/voiceover/en/heroes/4707_subentry_4707-2_1_text_value.mp3",
            "4708-1": "audio/voiceover/en/heroes/4708_subentry_4708-1_1_text_value.mp3",
            "4708-2": "audio/voiceover/en/heroes/4708_subentry_4708-2_1_text_value.mp3",
            "camp-1": "audio/voiceover/en/heroes/camp_subentry_camp-1_1_text_value.mp3",
            "camp-2": "audio/voiceover/en/heroes/camp_subentry_camp-2_1_text_value.mp3",
            "camp-3": "audio/voiceover/en/heroes/camp_subentry_camp-3_1_text_value.mp3",
            "omen-1": "audio/voiceover/en/heroes/omen_subentry_omen-1_1_text_value.mp3",
            "omen-2": "audio/voiceover/en/heroes/omen_subentry_omen-2_1_text_value.mp3",
            "2-page31-1": "audio/voiceover/en/heroes/2_subentry_2-page31-1_1_text_value.mp3",
            "25-1-1": "audio/voiceover/en/heroes/25_subentry_25-1_subentry_25-1-1_1_text_value.mp3",
            "25-1-2": "audio/voiceover/en/heroes/25_subentry_25-1_subentry_25-1-2_1_text_value.mp3",
            "25-1-2-1": "audio/voiceover/en/heroes/25_subentry_25-1_subentry_25-1-2_subentry_25-1-2-1_1_text_value.mp3",
            "25-1-2-2": "audio/voiceover/en/heroes/25_subentry_25-1_subentry_25-1-2_subentry_25-1-2-2_1_text_value.mp3",
            "25-2-1": "audio/voiceover/en/heroes/25_subentry_25-2_subentry_25-2-1_1_text_value.mp3",
            "25-2-2": "audio/voiceover/en/heroes/25_subentry_25-2_subentry_25-2-2_1_text_value.mp3",
            "25-page19-1-1": "audio/voiceover/en/heroes/25_subentry_25-page19-1_subentry_25-page19-1-1_1_text_value.mp3",
            "25-page31-1-1": "audio/voiceover/en/heroes/25_subentry_25-page31-1_subentry_25-page31-1-1_1_text_value.mp3",
            "25-page47-1-1": "audio/voiceover/en/heroes/25_subentry_25-page47-1_subentry_25-page47-1-1_1_text_value.mp3",
            "25-page47-1-2": "audio/voiceover/en/heroes/25_subentry_25-page47-1_subentry_25-page47-1-2_1_text_value.mp3",
            "1303-1": "audio/voiceover/en/heroes/1303_subentry_1303-1_1_text_value.mp3",
            "1303-2": "audio/voiceover/en/heroes/1303_subentry_1303-2_1_text_value.mp3",
            "1910-1-1-2": "audio/voiceover/en/heroes/1910_subentry_1910-1_subentry_1910-1-1_subentry_1910-1-1-2_1_text_value.mp3",
            "3102-1-1-1-1-1-1": "audio/voiceover/en/heroes/33781d83_3102-1-1_sbntry_3102-1-1-1_sbntry_31021111_sbntry_310211111_sbntry_3102111111_1_txt_vl.mp3",
            "3102-1-1-1-1-1-1-1": "audio/voiceover/en/heroes/80553c63_10211_sbntry_3102111_sbntry_31021111_sbntry_310211111_sbnt_3102111111__31021111111_1__.mp3",
            "3102-1-1-1-1-1-1-2": "audio/voiceover/en/heroes/b9d800a6_10211_sbntry_3102111_sbntry_31021111_sbntry_310211111_sbnt_3102111111__31021111112_1__.mp3",
            "3102-1-1-2": "audio/voiceover/en/heroes/3102_subentry_3102-1_subentry_3102-1-1_subentry_3102-1-1-2_1_text_value.mp3",
            "3102-1-2": "audio/voiceover/en/heroes/3102_subentry_3102-1_subentry_3102-1-2_1_text_value.mp3",
            "3102-2": "audio/voiceover/en/heroes/3102_subentry_3102-2_1_text_value.mp3",
            "3705-3": "audio/voiceover/en/heroes/3705_subentry_3705-3_1_text_value.mp3",
            "4701-1": "audio/voiceover/en/heroes/4701_subentry_4701-1_1_text_value.mp3",
            "4701-1-1": "audio/voiceover/en/heroes/4701_subentry_4701-1_subentry_4701-1-1_1_text_value.mp3",
            "4701-1-2": "audio/voiceover/en/heroes/4701_subentry_4701-1_subentry_4701-1-2_1_text_value.mp3",
            "4701-1-2-1": "audio/voiceover/en/heroes/4701_subentry_4701-1_subentry_4701-1-2_subentry_4701-1-2-1_1_text_value.mp3",
            "4701-2": "audio/voiceover/en/heroes/4701_subentry_4701-2_1_text_value.mp3",
            "4702-1": "audio/voiceover/en/heroes/4702_subentry_4702-1_1_text_value.mp3",
            "4702-1-1": "audio/voiceover/en/heroes/4702_subentry_4702-1_subentry_4702-1-1_1_text_value.mp3",
            "4702-1-2": "audio/voiceover/en/heroes/4702_subentry_4702-1_subentry_4702-1-2_1_text_value.mp3",
            "4702-2": "audio/voiceover/en/heroes/4702_subentry_4702-2_1_text_value.mp3",
            "4703-1": "audio/voiceover/en/heroes/4703_subentry_4703-1_1_text_value.mp3",
            "4703-2": "audio/voiceover/en/heroes/4703_subentry_4703-2_1_text_value.mp3",
            "4703-2-2": "audio/voiceover/en/heroes/4703_subentry_4703-2_subentry_4703-2-2_1_text_value.mp3",
            "4703-2-2-1": "audio/voiceover/en/heroes/4703_subentry_4703-2_subentry_4703-2-2_subentry_4703-2-2-1_1_text_value.mp3",
            "4702-1-2-1": "audio/voiceover/en/heroes/4703_subentry_4703-2_subentry_4703-2-2_subentry_4703-2-2-1_1_text_value.mp3",
            "omen-3": "audio/voiceover/en/heroes/omen_subentry_omen-3_1_text_value.mp3",
            "1-1": "audio/voiceover/en/heroes/1_subentry_1-1_1_text_value.mp3",
            "2-1": "audio/voiceover/en/heroes/2_subentry_2-1_1_text_value.mp3",
            "Wrong Code": "audio/voiceover/en/heroes/Wrong Code_1_text_value.mp3",
            "fortune": "audio/voiceover/en/heroes/fortune_1_text_value.mp3",
            "fortune-1": "audio/voiceover/en/heroes/fortune_subentry_fortune-1_1_text_value.mp3",
            "fortune-2": "audio/voiceover/en/heroes/fortune_subentry_fortune-2_1_text_value.mp3",
            "fortune-2-1": "audio/voiceover/en/heroes/fortune_subentry_fortune-2_subentry_fortune-2-1_1_text_value.mp3",
            "fortune-2-2": "audio/voiceover/en/heroes/fortune_subentry_fortune-2_subentry_fortune-2-2_1_text_value.mp3",
            "fortune-2-3": "audio/voiceover/en/heroes/fortune_subentry_fortune-2_subentry_fortune-2-3_1_text_value.mp3",
            "4705-2": "audio/voiceover/en/heroes/4705_subentry_4705-2_1_text_value.mp3",
            "4705-2-1": "audio/voiceover/en/heroes/4705_subentry_4705-2_subentry_4705-2-1_1_text_value.mp3",
            "4705-2-2": "audio/voiceover/en/heroes/4705_subentry_4705-2_subentry_4705-2-2_1_text_value.mp3",
            "4704-2-1": "audio/voiceover/en/heroes/4704_subentry_4704-2_subentry_4704-2-1_1_text_value.mp3",
            "4704-1-1": "audio/voiceover/en/heroes/4704-1-1.mp3",
            "4704-2-2": "audio/voiceover/en/heroes/4704-2-2.mp3",
            "4704-2": "audio/voiceover/en/heroes/4704-2.mp3",
            "4705-1": "audio/voiceover/en/heroes/4705_1_text_value.mp3",
            "4708-2-1": "audio/voiceover/en/heroes/4708-2-1.mp3",
            "1-page47-1": "audio/voiceover/en/heroes/1_subentry_1-page47-1_1_text_value-01.mp3",
            "1-page31-1": "audio/voiceover/en/heroes/1_subentry_1-page31-1_1_text_value.mp3",
            "1-page35-1": "audio/voiceover/en/heroes/1_subentry_1-page35-1_1_text_value.mp3"
        },
        "relics": {
            "1": "audio/voiceover/en/relics/1_1_text_value.mp3",
            "2": "audio/voiceover/en/relics/2_1_text_value.mp3",
            "3": "audio/voiceover/en/relics/3_1_text_value.mp3",
            "70": "audio/voiceover/en/relics/70_1_text_value.mp3",
            "75": "audio/voiceover/en/relics/75_1_text_value.mp3",
            "301": "audio/voiceover/en/relics/301_1_text_value.mp3",
            "302": "audio/voiceover/en/relics/302_1_text_value.mp3",
            "303": "audio/voiceover/en/relics/303_1_text_value.mp3",
            "304": "audio/voiceover/en/relics/304_1_text_value.mp3",
            "305": "audio/voiceover/en/relics/305_1_text_value.mp3",
            "306": "audio/voiceover/en/relics/306_1_text_value.mp3",
            "307": "audio/voiceover/en/relics/307_1_text_value.mp3",
            "501": "audio/voiceover/en/relics/501_1_text_value.mp3",
            "502": "audio/voiceover/en/relics/502_1_text_value.mp3",
            "503": "audio/voiceover/en/relics/503_1_text_value.mp3",
            "504": "audio/voiceover/en/relics/504_1_text_value.mp3",
            "505": "audio/voiceover/en/relics/505_1_text_value.mp3",
            "506": "audio/voiceover/en/relics/506_1_text_value.mp3",
            "507": "audio/voiceover/en/relics/507_1_text_value.mp3",
            "508": "audio/voiceover/en/relics/508_1_text_value.mp3",
            "509": "audio/voiceover/en/relics/509_1_text_value.mp3",
            "701": "audio/voiceover/en/relics/701_1_text_value.mp3",
            "704": "audio/voiceover/en/relics/704_1_text_value.mp3",
            "705": "audio/voiceover/en/relics/705_1_text_value.mp3",
            "706": "audio/voiceover/en/relics/706_1_text_value.mp3",
            "707": "audio/voiceover/en/relics/707_1_text_value.mp3",
            "708": "audio/voiceover/en/relics/708_1_text_value.mp3",
            "901": "audio/voiceover/en/relics/901_1_text_value.mp3",
            "902": "audio/voiceover/en/relics/902_1_text_value.mp3",
            "904": "audio/voiceover/en/relics/904_1_text_value.mp3",
            "905": "audio/voiceover/en/relics/905_1_text_value.mp3",
            "906": "audio/voiceover/en/relics/906_1_text_value.mp3",
            "907": "audio/voiceover/en/relics/907_1_text_value.mp3",
            "908": "audio/voiceover/en/relics/908_1_text_value.mp3",
            "909": "audio/voiceover/en/relics/909_1_text_value.mp3",
            "910": "audio/voiceover/en/relics/910_1_text_value.mp3",
            "911": "audio/voiceover/en/relics/911_1_text_value.mp3",
            "1101": "audio/voiceover/en/relics/1101_1_text_value.mp3",
            "1102": "audio/voiceover/en/relics/1102_1_text_value.mp3",
            "1103": "audio/voiceover/en/relics/1103_1_text_value-01.mp3",
            "1901": "audio/voiceover/en/relics/1901_1_text_value.mp3",
            "1902": "audio/voiceover/en/relics/1902_1_text_value.mp3",
            "1903": "audio/voiceover/en/relics/1903_1_text_value.mp3",
            "1904": "audio/voiceover/en/relics/1904_1_text_value.mp3",
            "1905": "audio/voiceover/en/relics/1905_1_text_value.mp3",
            "1907": "audio/voiceover/en/relics/1907_1_text_value.mp3",
            "1908": "audio/voiceover/en/relics/1908_1_text_value.mp3",
            "1909": "audio/voiceover/en/relics/1909_1_text_value.mp3",
            "3301": "audio/voiceover/en/relics/3301_1_text_value.mp3",
            "3302": "audio/voiceover/en/relics/3302_1_text_value.mp3",
            "3303": "audio/voiceover/en/relics/3303_1_text_value.mp3",
            "3304": "audio/voiceover/en/relics/3304_1_text_value.mp3",
            "3305": "audio/voiceover/en/relics/3305_1_text_value.mp3",
            "3306": "audio/voiceover/en/relics/3306_1_text_value.mp3",
            "3307": "audio/voiceover/en/relics/3307_1_text_value.mp3",
            "3308": "audio/voiceover/en/relics/3308_1_text_value.mp3",
            "3309": "audio/voiceover/en/relics/3309_1_text_value.mp3",
            "3310": "audio/voiceover/en/relics/3310_1_text_value.mp3",
            "3311": "audio/voiceover/en/relics/3311_1_text_value.mp3",
            "3312": "audio/voiceover/en/relics/3312_1_text_value.mp3",
            "3313": "audio/voiceover/en/relics/3313_1_text_value.mp3",
            "3314": "audio/voiceover/en/relics/3314_1_text_value.mp3",
            "3315": "audio/voiceover/en/relics/3315_1_text_value.mp3",
            "3316": "audio/voiceover/en/relics/3316_1_text_value.mp3",
            "4301": "audio/voiceover/en/relics/4301_1_text_value.mp3",
            "4302": "audio/voiceover/en/relics/4302_1_text_value.mp3",
            "4303": "audio/voiceover/en/relics/4303_1_text_value.mp3",
            "4304": "audio/voiceover/en/relics/4304_1_text_value.mp3",
            "4305": "audio/voiceover/en/relics/4305_1_text_value.mp3",
            "8888": "audio/voiceover/en/relics/8888.mp3",
            "304-1-1": "audio/voiceover/en/relics/_304-1-1.mp3",
            "307-1-1": "audio/voiceover/en/relics/_307-1-1.mp3",
            "3-1-1-1-1-1-1-1-2-1-1-2-2-2-1-3": "audio/voiceover/en/relics/3-1-1-1-1-1-1-1-2-1-1-2-2-2-1-3.mp3",
            "70-1-1": "audio/voiceover/en/relics/70_subentry_70-1_subentry_70-1-1_1_text_value.mp3",
            "70-1-2": "audio/voiceover/en/relics/70_subentry_70-1_subentry_70-1-2_1_text_value.mp3",
            "83-1-1-1-1-1-2-2": "audio/voiceover/en/relics/dfc70013_1-1-1_sbntry_83-1-1-1-1_sbntry_83-1-1-1-1-1_sbntry_83-111112_sbntry_831111122_1_txt_vl.mp3",
            "83-1-1-1-1-1-1-1-1-1": "audio/voiceover/en/relics/4ee22af9_sbntry_831111_sbntry_8311111_sbntry_83111111_sb_831111111__8311111111__83111111111_1__.mp3",
            "83-1-1-1-1-1-1-1-2-1": "audio/voiceover/en/relics/9cad3cba_sbntry_831111_sbntry_8311111_sbntry_83111111_sb_831111111__8311111112__83111111121_1__.mp3",
            "83-1-1-1-1-1-1-1-2-2": "audio/voiceover/en/relics/a520007f_sbntry_831111_sbntry_8311111_sbntry_83111111_sb_831111111__8311111112__83111111122_1__.mp3",
            "83-1-1-1-1-1-1-1-1": "audio/voiceover/en/relics/858a81f1_sbntry_831111_sbntry_8311111_sbntry_83111111_sbntry_831111111_sbntry_8311111111_1_txt_.mp3",
            "83-1": "audio/voiceover/en/relics/83_subentry_83-1_1_text_value.mp3",
            "83-1-1": "audio/voiceover/en/relics/83_subentry_83-1_subentry_83-1-1_1_text_value.mp3",
            "83-1-1-1": "audio/voiceover/en/relics/83_subentry_83-1_subentry_83-1-1_subentry_83-1-1-1_1_text_value.mp3",
            "83-1-1-1-1": "audio/voiceover/en/relics/83_subentry_83-1_subentry_83-1-1_subentry_83-1-1-1_subentry_83-1-1-1-1_1_text_value.mp3",
            "83-1-1-1-1-1-1": "audio/voiceover/en/relics/9bc28d1_ry_83-1-1-1_subentry_83-1-1-1-1_subentry_83-1-1-1-1-1_subentry_83-1-1-1-1-1-1_1_txt_vl.mp3",
            "83-1-1-1-1-1-1-1-2-1-2": "audio/voiceover/en/relics/c9fcea44_1-1-1-1-1-2_subentry_83-1-1-1-1-1-1-1-2-1_subentry_83-1-1-1-1-1-1-1-2-1-2_1_text_value.mp3",
            "83-1-1-1-1-1-2": "audio/voiceover/en/relics/aabb193a_3-1-1-1_subentry_83-1-1-1-1_subentry_83-1-1-1-1-1_subentry_83-1-1-1-1-1-2_1_text_value.mp3",
            "83-1-1-1-2": "audio/voiceover/en/relics/83_subentry_83-1_subentry_83-1-1_subentry_83-1-1-1_subentry_83-1-1-1-2_1_text_value.mp3",
            "83-1-1-2": "audio/voiceover/en/relics/83_subentry_83-1_subentry_83-1-1_subentry_83-1-1-2_1_text_value.mp3",
            "83-1-1-2-1": "audio/voiceover/en/relics/83_subentry_83-1_subentry_83-1-1_subentry_83-1-1-2_subentry_83-1-1-2-1_1_text_value.mp3",
            "83-1-1-2-2": "audio/voiceover/en/relics/83_subentry_83-1_subentry_83-1-1_subentry_83-1-1-2_subentry_83-1-1-2-2_1_text_value.mp3",
            "83-2": "audio/voiceover/en/relics/83_subentry_83-2_1_text_value.mp3",
            "83-1-1-1-1-1-1-1-2-1-1-2-1": "audio/voiceover/en/relics/83-1-1-1-1-1-1-1-2-1-1-2-1.mp3",
            "83-1-1-1-1-1-1-1-2-1-1-2-2-1-1-1-1-1": "audio/voiceover/en/relics/83-1-1-1-1-1-1-1-2-1-1-2-2-1-1-1-1-1.mp3",
            "83-1-1-1-1-1-1-1-2-1-1-2-2-1-1-1-1-2": "audio/voiceover/en/relics/83-1-1-1-1-1-1-1-2-1-1-2-2-1-1-1-1-2.mp3",
            "83-1-1-1-1-1-1-1-2-1-1-2-2-1": "audio/voiceover/en/relics/83-1-1-1-1-1-1-1-2-1-1-2-2-1.mp3",
            "83-1-1-1-1-1-1-1-2-1-1-2-2-2-1": "audio/voiceover/en/relics/83-1-1-1-1-1-1-1-2-1-1-2-2-2-1.mp3",
            "83-1-1-1-1-1-1-1-2-1-1-2-2-1-1": "audio/voiceover/en/relics/83-1-1-1-1-1-1-1-2-1-1-2-2-2-1.mp3",
            "83-1-1-1-1-1-1-1-2-1-1-2-2-2": "audio/voiceover/en/relics/83-1-1-1-1-1-1-1-2-1-1-2-2-2.mp3",
            "83-1-1-1-1-1-1-1-2-1-1-2": "audio/voiceover/en/relics/83-1-1-1-1-1-1-1-2-1-1-2.mp3",
            "86-1-1-2-1-1-1-1": "audio/voiceover/en/relics/60102b5_1-1-2_sbntry_86-1-1-2-1_sbntry_86-1-1-2-1-1_sbntry_86-112111_sbntry_861121111_1_txt_vl.mp3",
            "86-1": "audio/voiceover/en/relics/86_subentry_86-1_1_text_value.mp3",
            "86-1-1": "audio/voiceover/en/relics/86_subentry_86-1_subentry_86-1-1_1_text_value.mp3",
            "86-1-1-1": "audio/voiceover/en/relics/86_subentry_86-1_subentry_86-1-1_subentry_86-1-1-1_1_text_value.mp3",
            "86-1-1-2-1": "audio/voiceover/en/relics/86_subentry_86-1_subentry_86-1-1_subentry_86-1-1-2_subentry_86-1-1-2-1_1_text_value.mp3",
            "86-1-1-2-1-1": "audio/voiceover/en/relics/5ccbc4db_bentry_86-1-1_subentry_86-1-1-2_subentry_86-1-1-2-1_subentry_86-1-1-2-1-1_1_text_value.mp3",
            "86-1-1-2-1-1-1": "audio/voiceover/en/relics/f188a984_ry_86-1-1-2_subentry_86-1-1-2-1_subentry_86-1-1-2-1-1_subentry_86-1-1-2-1-1-1_1_txt_vl.mp3",
            "86-1-1-2-1-1-2": "audio/voiceover/en/relics/8de98c5f_ry_86-1-1-2_subentry_86-1-1-2-1_subentry_86-1-1-2-1-1_subentry_86-1-1-2-1-1-2_1_txt_vl.mp3",
            "86-1-1-2-1-1-3": "audio/voiceover/en/relics/10e66d29_ry_86-1-1-2_subentry_86-1-1-2-1_subentry_86-1-1-2-1-1_subentry_86-1-1-2-1-1-3_1_txt_vl.mp3",
            "86-1-1-2-1-1-4": "audio/voiceover/en/relics/752bc7e9_ry_86-1-1-2_subentry_86-1-1-2-1_subentry_86-1-1-2-1-1_subentry_86-1-1-2-1-1-4_1_txt_vl.mp3",
            "86-1-1-2-1-1-5": "audio/voiceover/en/relics/e824269f_ry_86-1-1-2_subentry_86-1-1-2-1_subentry_86-1-1-2-1-1_subentry_86-1-1-2-1-1-5_1_txt_vl.mp3",
            "86-1-1-2-1-1-6": "audio/voiceover/en/relics/94450344_ry_86-1-1-2_subentry_86-1-1-2-1_subentry_86-1-1-2-1-1_subentry_86-1-1-2-1-1-6_1_txt_vl.mp3",
            "86-1-1-2-1-2": "audio/voiceover/en/relics/b64d19b9_bentry_86-1-1_subentry_86-1-1-2_subentry_86-1-1-2-1_subentry_86-1-1-2-1-2_1_text_value.mp3",
            "86-1-1-2-2": "audio/voiceover/en/relics/86_subentry_86-1_subentry_86-1-1_subentry_86-1-1-2_subentry_86-1-1-2-2_1_text_value.mp3",
            "86-2": "audio/voiceover/en/relics/86_subentry_86-2_1_text_value-01.mp3",
            "88-1": "audio/voiceover/en/relics/88_subentry_88-1_1_text_value.mp3",
            "88-1-1": "audio/voiceover/en/relics/88_subentry_88-1_subentry_88-1-1_1_text_value.mp3",
            "88-1-1-1": "audio/voiceover/en/relics/88_subentry_88-1_subentry_88-1-1_subentry_88-1-1-1_1_text_value.mp3",
            "88-1-1-1-1": "audio/voiceover/en/relics/88_subentry_88-1_subentry_88-1-1_subentry_88-1-1-1_subentry_88-1-1-1-1_1_text_value.mp3",
            "88-1-1-2": "audio/voiceover/en/relics/88_subentry_88-1_subentry_88-1-1_subentry_88-1-1-2_1_text_value.mp3",
            "88-2": "audio/voiceover/en/relics/88_subentry_88-2_1_text_value.mp3",
            "300 Hub Slums": "audio/voiceover/en/relics/300 Hub Slums_1_text_value.mp3",
            "300 Hub Slums-1": "audio/voiceover/en/relics/300 Hub Slums_subentry_300 Hub Slums-1_1_text_value.mp3",
            "300 Hub Slums-1-1": "audio/voiceover/en/relics/300 Hub Slums_subentry_300 Hub Slums-1_subentry_300 Hub Slums-1-1_1_text_value.mp3",
            "300 Hub Slums-1-1-1": "audio/voiceover/en/relics/fe7ce2c2_y_300 Hub Slums-1_subentry_300 Hub Slums-1-1_subentry_300 Hub Slums-1-1-1_1_text_value.mp3",
            "300 Hub Slums-1-2": "audio/voiceover/en/relics/300 Hub Slums_subentry_300 Hub Slums-1_subentry_300 Hub Slums-1-2_1_text_value.mp3",
            "300 Hub Slums-1-1-1-1": "audio/voiceover/en/relics/8abe641_ntry_300HubSlums-1-1_subentry_300HubSlums-1-1-1_subentry_300HubSlums-1-1-1-1_1_text_vl.mp3",
            "304-1": "audio/voiceover/en/relics/304_subentry_304-1_1_text_value.mp3",
            "304-2-1": "audio/voiceover/en/relics/304_subentry_304-2_subentry_304-2-1_1_text_value.mp3",
            "304-2-1-1": "audio/voiceover/en/relics/304_subentry_304-2_subentry_304-2-1_subentry_304-2-1-1_1_text_value.mp3",
            "304-2-1-2": "audio/voiceover/en/relics/304_subentry_304-2_subentry_304-2-1_subentry_304-2-1-2_1_text_value.mp3",
            "304-2-1-3": "audio/voiceover/en/relics/304_subentry_304-2_subentry_304-2-1_subentry_304-2-1-3_1_text_value.mp3",
            "304-2-2": "audio/voiceover/en/relics/304_subentry_304-2_subentry_304-2-2_1_text_value.mp3",
            "500 Driftlands": "audio/voiceover/en/relics/500 Driftlands_1_text_value.mp3",
            "505-1": "audio/voiceover/en/relics/505_subentry_505-1_1_text_value.mp3",
            "505-1-1": "audio/voiceover/en/relics/505_subentry_505-1_subentry_505-1-1_1_text_value.mp3",
            "505-1-2": "audio/voiceover/en/relics/505_subentry_505-1_subentry_505-1-2_1_text_value.mp3",
            "505-2": "audio/voiceover/en/relics/505_subentry_505-2_1_text_value.mp3",
            "505-3": "audio/voiceover/en/relics/505_subentry_505-3_1_text_value.mp3",
            "510-1": "audio/voiceover/en/relics/510_subentry_510-1_1_text_value.mp3",
            "510-2": "audio/voiceover/en/relics/510_subentry_510-2_1_text_value.mp3",
            "700 Hub Gilded District": "audio/voiceover/en/relics/700 Hub Gilded District_1_text_value.mp3",
            "700 Hub Gilded District-1": "audio/voiceover/en/relics/700 Hub Gilded District_subentry_700 Hub Gilded District-1_1_text_value.mp3",
            "701-1": "audio/voiceover/en/relics/701_subentry_701-1_1_text_value.mp3",
            "701-2": "audio/voiceover/en/relics/701_subentry_701-2_1_text_value.mp3",
            "702-1": "audio/voiceover/en/relics/702_subentry_702-1_1_text_value.mp3",
            "702-2": "audio/voiceover/en/relics/702_subentry_702-2_1_text_value.mp3",
            "703-1": "audio/voiceover/en/relics/703_subentry_703-1_1_text_value.mp3",
            "706-1": "audio/voiceover/en/relics/706_subentry_706-1_1_text_value.mp3",
            "706-1-1": "audio/voiceover/en/relics/706_subentry_706-1_subentry_706-1-1_1_text_value.mp3",
            "706-1-2": "audio/voiceover/en/relics/706_subentry_706-1_subentry_706-1-2_1_text_value.mp3",
            "706-1-3": "audio/voiceover/en/relics/706_subentry_706-1_subentry_706-1-3_1_text_value.mp3",
            "706-2": "audio/voiceover/en/relics/706_subentry_706-2_1_text_value.mp3",
            "709-1": "audio/voiceover/en/relics/709_subentry_709-1_1_text_value.mp3",
            "709-1-1": "audio/voiceover/en/relics/709_subentry_709-1_subentry_709-1-1_1_text_value.mp3",
            "709-1-2": "audio/voiceover/en/relics/709_subentry_709-1_subentry_709-1-2_1_text_value.mp3",
            "709-2-1": "audio/voiceover/en/relics/709_subentry_709-2_subentry_709-2-1_1_text_value.mp3",
            "709-2-1-1": "audio/voiceover/en/relics/709_subentry_709-2_subentry_709-2-1_subentry_709-2-1-1_1_text_value.mp3",
            "709-2-1-1-1": "audio/voiceover/en/relics/709_subentry_709-2_subentry_709-2-1_subentry_709-2-1-1_subentry_709-2-1-1-1_1_text_value.mp3",
            "709-2-1-1-1-1": "audio/voiceover/en/relics/5303c48e_ry_709-2-1_subentry_709-2-1-1_subentry_709-2-1-1-1_subentry_709-2-1-1-1-1_1_text_value.mp3",
            "709-2-1-1-1-2": "audio/voiceover/en/relics/b98519ec_ry_709-2-1_subentry_709-2-1-1_subentry_709-2-1-1-1_subentry_709-2-1-1-1-2_1_text_value.mp3",
            "709-2-1-1-2": "audio/voiceover/en/relics/709_subentry_709-2_subentry_709-2-1_subentry_709-2-1-1_subentry_709-2-1-1-2_1_text_value.mp3",
            "709-2-1-1-2-1": "audio/voiceover/en/relics/1fe33ee6_ry_709-2-1_subentry_709-2-1-1_subentry_709-2-1-1-2_subentry_709-2-1-1-2-1_1_text_value.mp3",
            "709-2-1-1-2-2": "audio/voiceover/en/relics/f565e384_ry_709-2-1_subentry_709-2-1-1_subentry_709-2-1-1-2_subentry_709-2-1-1-2-2_1_text_value.mp3",
            "709-2-2": "audio/voiceover/en/relics/709_subentry_709-2_subentry_709-2-2_1_text_value.mp3",
            "900 River Village": "audio/voiceover/en/relics/900 River Village_1_text_value.mp3",
            "901-1": "audio/voiceover/en/relics/901_subentry_901-1_1_text_value.mp3",
            "901-2": "audio/voiceover/en/relics/901_subentry_901-2_1_text_value.mp3",
            "901-3": "audio/voiceover/en/relics/901_subentry_901-3_1_text_value.mp3",
            "902-1": "audio/voiceover/en/relics/902_subentry_902-1_1_text_value.mp3",
            "902-2": "audio/voiceover/en/relics/902_subentry_902-2_1_text_value.mp3",
            "902-3": "audio/voiceover/en/relics/902_subentry_902-3_1_text_value.mp3",
            "902-3-1": "audio/voiceover/en/relics/902_subentry_902-3_subentry_902-3-1_1_text_value.mp3",
            "902-3-2": "audio/voiceover/en/relics/902_subentry_902-3_subentry_902-3-2_1_text_value.mp3",
            "903-1": "audio/voiceover/en/relics/903_subentry_903-1_1_text_value.mp3",
            "903-2": "audio/voiceover/en/relics/903_subentry_903-2_1_text_value.mp3",
            "903-2-1": "audio/voiceover/en/relics/903_subentry_903-2_subentry_903-2-1_1_text_value.mp3",
            "903-2-2": "audio/voiceover/en/relics/903_subentry_903-2_subentry_903-2-2_1_text_value.mp3",
            "903-2-3": "audio/voiceover/en/relics/903_subentry_903-2_subentry_903-2-3_1_text_value.mp3",
            "904-1": "audio/voiceover/en/relics/904_subentry_904-1_1_text_value.mp3",
            "904-2": "audio/voiceover/en/relics/904_subentry_904-2_1_text_value.mp3",
            "904-3": "audio/voiceover/en/relics/904_subentry_904-3_1_text_value.mp3",
            "905-1": "audio/voiceover/en/relics/905_subentry_905-1_1_text_value.mp3",
            "905-2": "audio/voiceover/en/relics/905_subentry_905-2_1_text_value.mp3",
            "907-1": "audio/voiceover/en/relics/907_subentry_907-1_1_text_value.mp3",
            "907-2": "audio/voiceover/en/relics/907_subentry_907-2_1_text_value.mp3",
            "907-3": "audio/voiceover/en/relics/907_subentry_907-3_1_text_value.mp3",
            "912-1": "audio/voiceover/en/relics/912_subentry_912-1_1_text_value.mp3",
            "912-1-1": "audio/voiceover/en/relics/912_subentry_912-1_subentry_912-1-1_1_text_value.mp3",
            "912-2": "audio/voiceover/en/relics/912_subentry_912-2_1_text_value.mp3",
            "912-2-1": "audio/voiceover/en/relics/912_subentry_912-2_subentry_912-2-1_1_text_value.mp3",
            "912-2-2": "audio/voiceover/en/relics/912_subentry_912-2_subentry_912-2-2_1_text_value.mp3",
            "1100 Hub Entertainment District": "audio/voiceover/en/relics/1100 Hub Entertainment District_1_text_value.mp3",
            "1101-1": "audio/voiceover/en/relics/1101_subentry_1101-1_1_text_value.mp3",
            "1101-2": "audio/voiceover/en/relics/1101_subentry_1101-2_1_text_value.mp3",
            "1101-3": "audio/voiceover/en/relics/1101_subentry_1101-3_1_text_value.mp3",
            "1102-1-1": "audio/voiceover/en/relics/1102_subentry_1102-1_subentry_1102-1-1_1_text_value.mp3",
            "1102-1-2": "audio/voiceover/en/relics/1102_subentry_1102-1_subentry_1102-1-2_1_text_value.mp3",
            "1102-2": "audio/voiceover/en/relics/1102_subentry_1102-2_1_text_value.mp3",
            "1103-1": "audio/voiceover/en/relics/1103_subentry_1103-1_1_text_value.mp3",
            "1103-1-1-1": "audio/voiceover/en/relics/1103_subentry_1103-1_subentry_1103-1-1_subentry_1103-1-1-1_1_text_value.mp3",
            "1103-1-1-2": "audio/voiceover/en/relics/1103_subentry_1103-1_subentry_1103-1-1_subentry_1103-1-1-2_1_text_value.mp3",
            "1103-1-2-1": "audio/voiceover/en/relics/1103_subentry_1103-1_subentry_1103-1-2_subentry_1103-1-2-1_1_text_value.mp3",
            "1103-1-2-2": "audio/voiceover/en/relics/1103_subentry_1103-1_subentry_1103-1-2_subentry_1103-1-2-2_1_text_value.mp3",
            "1103-2": "audio/voiceover/en/relics/1103_subentry_1103-2_1_text_value.mp3",
            "1104-1": "audio/voiceover/en/relics/1104_subentry_1104-1_1_text_value.mp3",
            "1104-1-1": "audio/voiceover/en/relics/1104_subentry_1104-1_subentry_1104-1-1_1_text_value.mp3",
            "1104-1-2": "audio/voiceover/en/relics/1104_subentry_1104-1_subentry_1104-1-2_1_text_value.mp3",
            "1104-2": "audio/voiceover/en/relics/1104_subentry_1104-2_1_text_value.mp3",
            "1104-2-1": "audio/voiceover/en/relics/1104_subentry_1104-2_subentry_1104-2-1_1_text_value.mp3",
            "1104-2-2": "audio/voiceover/en/relics/1104_subentry_1104-2_subentry_1104-2-2_1_text_value.mp3",
            "1900 Treasure Golem": "audio/voiceover/en/relics/1900 Treasure Golem_1_text_value.mp3",
            "1910-1": "audio/voiceover/en/relics/1910_subentry_1910-1_1_text_value.mp3",
            "1910-2-1": "audio/voiceover/en/relics/1910_subentry_1910-2_subentry_1910-2-1_1_text_value.mp3",
            "1910-2-2": "audio/voiceover/en/relics/1910_subentry_1910-2_subentry_1910-2-2_1_text_value.mp3",
            "3300 Faerie Ridge": "audio/voiceover/en/relics/3300 Faerie Ridge_1_text_value.mp3",
            "3303-1": "audio/voiceover/en/relics/3303_subentry_3303-1_1_text_value.mp3",
            "3303-2": "audio/voiceover/en/relics/3303_subentry_3303-2_1_text_value.mp3",
            "3305-1": "audio/voiceover/en/relics/3305_subentry_3305-1_1_text_value.mp3",
            "3305-2": "audio/voiceover/en/relics/3305_subentry_3305-2_1_text_value.mp3",
            "3312-1": "audio/voiceover/en/relics/3312_subentry_3312-1_1_text_value.mp3",
            "3312-2": "audio/voiceover/en/relics/3312_subentry_3312-2_1_text_value.mp3",
            "4300 Paz, The Displayer": "audio/voiceover/en/relics/4300 Paz, The Displayer_1_text_value.mp3",
            "4300 Paz, The Displayer-1": "audio/voiceover/en/relics/4300 Paz, The Displayer_subentry_4300 Paz, The Displayer-1_1_text_value.mp3",
            "4300 Paz, The Displayer-1-1": "audio/voiceover/en/relics/dc398789_r_subentry_4300 Paz, The Displayer-1_subentry_4300 Paz, The Displayer-1-1_1_text_value.mp3",
            "4300 Paz, The Displayer-2": "audio/voiceover/en/relics/4300 Paz, The Displayer_subentry_4300 Paz, The Displayer-2_1_text_value.mp3",
            "4306-1-1": "audio/voiceover/en/relics/4306_subentry_4306-1_subentry_4306-1-1_1_text_value.mp3",
            "4306-1-2": "audio/voiceover/en/relics/4306_subentry_4306-1_subentry_4306-1-2_1_text_value.mp3",
            "4306-2": "audio/voiceover/en/relics/4306_subentry_4306-2_1_text_value.mp3",
            "4309-1-1": "audio/voiceover/en/relics/4309_subentry_4309-1_subentry_4309-1-1_1_text_value.mp3",
            "4309-1-2": "audio/voiceover/en/relics/4309_subentry_4309-1_subentry_4309-1-2_1_text_value.mp3",
            "4309-2-1": "audio/voiceover/en/relics/4309_subentry_4309-2_subentry_4309-2-1_1_text_value.mp3",
            "4309-2-2": "audio/voiceover/en/relics/4309_subentry_4309-2_subentry_4309-2-2_1_text_value.mp3",
            "4310-1-1": "audio/voiceover/en/relics/4310_subentry_4310-1_subentry_4310-1-1_1_text_value.mp3",
            "4310-1-2": "audio/voiceover/en/relics/4310_subentry_4310-1_subentry_4310-1-2_1_text_value.mp3",
            "4310-2": "audio/voiceover/en/relics/4310_subentry_4310-2_1_text_value.mp3",
            "4311-1": "audio/voiceover/en/relics/4311_subentry_4311-1_1_text_value.mp3",
            "4311-1-1": "audio/voiceover/en/relics/4311_subentry_4311-1_subentry_4311-1-1_1_text_value.mp3",
            "4311-1-1-2": "audio/voiceover/en/relics/4311_subentry_4311-1_subentry_4311-1-1_subentry_4311-1-1-2_1_text_value.mp3",
            "4311-1-2": "audio/voiceover/en/relics/4311_subentry_4311-1_subentry_4311-1-2_1_text_value.mp3",
            "4311-1-2-1": "audio/voiceover/en/relics/4311_subentry_4311-1_subentry_4311-1-2_subentry_4311-1-2-1_1_text_value.mp3",
            "4311-2": "audio/voiceover/en/relics/4311_subentry_4311-2_1_text_value.mp3",
            "8888-1-1": "audio/voiceover/en/relics/8888-1-1.mp3",
            "8888-2": "audio/voiceover/en/relics/8888-2.mp3",
            "Camp-1": "audio/voiceover/en/relics/Camp_subentry_Camp-1_1_text_value.mp3",
            "Camp-2": "audio/voiceover/en/relics/Camp_subentry_Camp-2_1_text_value.mp3",
            "Fortune": "audio/voiceover/en/relics/Fortune_1_text_value.mp3",
            "Fortune-1": "audio/voiceover/en/relics/Fortune_subentry_Fortune-1_1_text_value.mp3",
            "Omen-1": "audio/voiceover/en/relics/Omen_subentry_Omen-1_1_text_value.mp3",
            "Omen-2": "audio/voiceover/en/relics/Omen_subentry_Omen-2_1_text_value.mp3",
            "Omen-3": "audio/voiceover/en/relics/Omen_subentry_Omen-3_1_text_value.mp3",
            "special duel-1-1-1": "audio/voiceover/en/relics/Special Duel 1 - Pass - Pt 1.mp3",
            "special duel-1-1-2": "audio/voiceover/en/relics/Special Duel 1 - Pass - Shock.mp3",
            "special duel-1-1-3": "audio/voiceover/en/relics/Special Duel 1 Fail.mp3",
            "special duel-1-1-1-1": "audio/voiceover/en/relics/Special Duel 1 Pass Pt 2.mp3",
            "special duel-1-1": "audio/voiceover/en/relics/Special Duel 1.mp3",
            "special duel-2-1-2": "audio/voiceover/en/relics/Special Duel 2 Fail.mp3",
            "special duel-2-1-1": "audio/voiceover/en/relics/Special Duel 2 Pass .mp3",
            "special duel-2-1": "audio/voiceover/en/relics/Special Duel 2.mp3",
            "83-1-1-1-1-1-1-1-1-1-1": "audio/voiceover/en/relics/subentry_83-1-1-1-1-1-1-1-1-1-1_1_text_value.mp3",
            "83-1-1-1-1-1-1-1-1-1-1-1": "audio/voiceover/en/relics/subentry_83-1-1-1-1-1-1-1-1-1-1-1_1_text_value.mp3",
            "83-1-1-1-1-1-1-1-1-1-1-1-1": "audio/voiceover/en/relics/subentry_83-1-1-1-1-1-1-1-1-1-1-1-1_1_text_value.mp3",
            "83-1-1-1-1-1-1-1-1-1-1-2": "audio/voiceover/en/relics/subentry_83-1-1-1-1-1-1-1-1-1-1-2_1_text_value.mp3",
            "83-1-1-1-1-1-1-1-1-2": "audio/voiceover/en/relics/subentry_83-1-1-1-1-1-1-1-1-2_1_text_value.mp3",
            "83-1-1-1-1-1-1-1-2": "audio/voiceover/en/relics/subentry_83-1-1-1-1-1-1-1-2_1_text_value.mp3",
            "83-1-1-1-1-1-1-1-2-1-1": "audio/voiceover/en/relics/subentry_83-1-1-1-1-1-1-1-2-1-1_1_text_value.mp3",
            "83-1-1-1-1-1-1-1-2-1-1-1": "audio/voiceover/en/relics/subentry_83-1-1-1-1-1-1-1-2-1-1-1_1_text_value.mp3",
            "83-1-1-1-1-1-1-1-2-1-2-2": "audio/voiceover/en/relics/subentry_83-1-1-1-1-1-1-1-2-1-2-2_1_text_value.mp3",
            "83-1-1-1-1-1-1-1-2-1-2-2-1": "audio/voiceover/en/relics/subentry_83-1-1-1-1-1-1-1-2-1-2-2-1_1_text_value.mp3",
            "83-1-1-1-1-1-1-1-2-1-2-2-2": "audio/voiceover/en/relics/subentry_83-1-1-1-1-1-1-1-2-1-2-2-2_1_text_value.mp3",
            "83-1-1-1-1-1-2-1": "audio/voiceover/en/relics/subentry_83-1-1-1-1-1-2-1_1_text_value.mp3",
            "307-1-1-1": "audio/voiceover/en/relics/_307-1-1.mp3",
            "83-1-1-1-1-1-1-1-2-1-1-2-2-2-1-3": "audio/voiceover/en/relics/83-1-1-1-1-1-1-1-2-1-1-2-2-2-1-2.mp3",
            '83-1-1-1-1-1-1-1-2-1-1-2-2-1-1-1': 'audio/voiceover/en/relics/83-1-1-1-1-1-1-1-2-1-1-2-2-1-1-1.mp3',
            '83-1-1-1-1-1-1-1-2-1-1-2-2-2-1-1': 'audio/voiceover/en/relics/83-1-1-1-1-1-1-1-2-1-1-2-2-2-1-1.mp3',
            '83-1-1-1-1-1-1-1-2-1-1-2-2-2-1-2': 'audio/voiceover/en/relics/83-1-1-1-1-1-1-1-2-1-1-2-2-2-1-2.mp3',
            '307-1': 'audio/voiceover/en/relics/307-1.mp3',
            '307-2': 'audio/voiceover/en/relics/307-2.mp3',
        },
        "deeper": {
            "87": "audio/voiceover/en/deeper/87_1_text_value.mp3",
            "89": "audio/voiceover/en/deeper/89_2_text_value.mp3",
            "91": "audio/voiceover/en/deeper/91.mp3",
            "1700": "audio/voiceover/en/deeper/1700_1_text_value.mp3",
            "1701": "audio/voiceover/en/deeper/1701_1_text_value.mp3",
            "1702": "audio/voiceover/en/deeper/1702_1_text_value.mp3",
            "1703": "audio/voiceover/en/deeper/1703_1_text_value.mp3",
            "1704": "audio/voiceover/en/deeper/1704_1_text_value.mp3",
            "1705": "audio/voiceover/en/deeper/1705_1_text_value.mp3",
            "1706": "audio/voiceover/en/deeper/1706_1_text_value.mp3",
            "1707": "audio/voiceover/en/deeper/1707_1_text_value.mp3",
            "1708": "audio/voiceover/en/deeper/1708.mp3",
            "1709": "audio/voiceover/en/deeper/1709_1_text_value.mp3",
            "1710": "audio/voiceover/en/deeper/1710_1_text_value.mp3",
            "1711": "audio/voiceover/en/deeper/1711_1_text_value.mp3",
            "1904": "audio/voiceover/en/deeper/1904_1_text_value.mp3",
            "1905": "audio/voiceover/en/deeper/1905_1_text_value.mp3",
            "1906": "audio/voiceover/en/deeper/1906_1_text_value.mp3",
            "1907": "audio/voiceover/en/deeper/1907_1_text_value.mp3",
            "1908": "audio/voiceover/en/deeper/1908_1_text_value.mp3",
            "1909": "audio/voiceover/en/deeper/1909_1_text_value.mp3",
            "2100": "audio/voiceover/en/deeper/2100_1_text_value.mp3",
            "2101": "audio/voiceover/en/deeper/2101_1_text_value.mp3",
            "2104": "audio/voiceover/en/deeper/2104_1_text_value.mp3",
            "2105": "audio/voiceover/en/deeper/2105_1_text_value.mp3",
            "2701": "audio/voiceover/en/deeper/2701_1_text_value.mp3",
            "2702": "audio/voiceover/en/deeper/2702_1_text_value.mp3",
            "2704": "audio/voiceover/en/deeper/2704_1_text_value.mp3",
            "2705": "audio/voiceover/en/deeper/2705_1_text_value.mp3",
            "2706": "audio/voiceover/en/deeper/2706_1_text_value.mp3",
            "2707": "audio/voiceover/en/deeper/2707_1_text_value.mp3",
            "2708": "audio/voiceover/en/deeper/2708_1_text_value.mp3",
            "2709": "audio/voiceover/en/deeper/2709_1_text_value.mp3",
            "2710": "audio/voiceover/en/deeper/2710_1_text_value.mp3",
            "2711": "audio/voiceover/en/deeper/2711_1_text_value.mp3",
            "2900": "audio/voiceover/en/deeper/2900_1_text_value.mp3",
            "4100": "audio/voiceover/en/deeper/4100_1_text_value.mp3",
            "4101": "audio/voiceover/en/deeper/4101_1_text_value.mp3",
            "4102": "audio/voiceover/en/deeper/4102_1_text_value.mp3",
            "4103": "audio/voiceover/en/deeper/4103_1_text_value.mp3",
            "4104": "audio/voiceover/en/deeper/4104_1_text_value.mp3",
            "4105": "audio/voiceover/en/deeper/4105_1_text_value.mp3",
            "4106": "audio/voiceover/en/deeper/4106_1_text_value.mp3",
            "4107": "audio/voiceover/en/deeper/4107_1_text_value.mp3",
            "4108": "audio/voiceover/en/deeper/4108_1_text_value.mp3",
            "4109": "audio/voiceover/en/deeper/4109_1_text_value.mp3",
            "4110": "audio/voiceover/en/deeper/4110_1_text_value.mp3",
            "4111": "audio/voiceover/en/deeper/4111_1_text_value.mp3",
            "4500": "audio/voiceover/en/deeper/4500_1_text_value.mp3",
            "4501": "audio/voiceover/en/deeper/4501_1_text_value.mp3",
            "4502": "audio/voiceover/en/deeper/4502_1_text_value.mp3",
            "4503": "audio/voiceover/en/deeper/4503_1_text_value.mp3",
            "4504": "audio/voiceover/en/deeper/4504_1_text_value.mp3",
            "4505": "audio/voiceover/en/deeper/4505_1_text_value.mp3",
            "4506": "audio/voiceover/en/deeper/4506_1_text_value.mp3",
            "4507": "audio/voiceover/en/deeper/4507_1_text_value.mp3",
            "4509": "audio/voiceover/en/deeper/4509_1_text_value.mp3",
            "4700": "audio/voiceover/en/deeper/4700_1_text_value.mp3",
            "4701": "audio/voiceover/en/deeper/4701_1_text_value.mp3",
            "4702": "audio/voiceover/en/deeper/4702_1_text_value.mp3",
            "4706": "audio/voiceover/en/deeper/4706_1_text_value.mp3",
            "4707": "audio/voiceover/en/deeper/4707_1_text_value.mp3",
            "1-1": "audio/voiceover/en/deeper/1_subentry_1-1_1_text_value.mp3",
            "1-page19-1": "audio/voiceover/en/deeper/1_subentry_1-page19-1_1_text_value.mp3",
            "1-page21-1": "audio/voiceover/en/deeper/1_subentry_1-page21-1_1_text_value.mp3",
            "1-page27-1": "audio/voiceover/en/deeper/1_subentry_1-page27-1_1_text_value.mp3",
            "1-page29-1": "audio/voiceover/en/deeper/1_subentry_1-page29-1_1_text_value.mp3",
            "1-page41-1": "audio/voiceover/en/deeper/1_subentry_1-page41-1_1_text_value.mp3",
            "1-page45-1": "audio/voiceover/en/deeper/1_subentry_1-page45-1_1_text_value.mp3",
            "2-1-1": "audio/voiceover/en/deeper/2_subentry_2-1_subentry_2-1-1_1_text_value.mp3",
            "2-page21-1": "audio/voiceover/en/deeper/2_subentry_2-page21-1_1_text_value.mp3",
            "2-page27-1": "audio/voiceover/en/deeper/2_subentry_2-page27-1_1_text_value.mp3",
            "2-page29-1": "audio/voiceover/en/deeper/2_subentry_2-page29-1_1_text_value.mp3",
            "2-page41-1": "audio/voiceover/en/deeper/2_subentry_2-page41-1_1_text_value.mp3",
            "2-page45-1": "audio/voiceover/en/deeper/2_subentry_2-page45-1_1_text_value.mp3",
            "3-page21-1": "audio/voiceover/en/deeper/3_subentry_3-page21-1_1_text_value-01.mp3",
            "3-page27-1": "audio/voiceover/en/deeper/3_subentry_3-page27-1_1_text_value.mp3",
            "3-page29-1": "audio/voiceover/en/deeper/3_subentry_3-page29-1_1_text_value.mp3",
            "4-page41-1-1": "audio/voiceover/en/deeper/4_subentry_4-page41-1_subentry_4-page41-1-1_1_text_value.mp3",
            "4-page41-1-2": "audio/voiceover/en/deeper/4_subentry_4-page41-1_subentry_4-page41-1-2_1_text_value.mp3",
            "4-page45-1-1": "audio/voiceover/en/deeper/4_subentry_4-page45-1_subentry_4-page45-1-1_1_text_value.mp3",
            "4-page45-1-2": "audio/voiceover/en/deeper/4_subentry_4-page45-1_subentry_4-page45-1-2_1_text_value.mp3",
            "4-page27-1": "audio/voiceover/en/deeper/5_subentry_5-page21-1_1_text_value.mp3",
            "4-page21-1": "audio/voiceover/en/deeper/5_subentry_5-page21-1_1_text_value.mp3",
            "5-page21-1": "audio/voiceover/en/deeper/5_subentry_5-page21-1_1_text_value.mp3",
            "5-page27-1": "audio/voiceover/en/deeper/5_subentry_5-page27-1_1_text_value.mp3",
            "4-page29-1": "audio/voiceover/en/deeper/5_subentry_5-page29-1_1_text_value.mp3",
            "5-page29-1": "audio/voiceover/en/deeper/5_subentry_5-page29-1_1_text_value.mp3",
            "6-page21-1": "audio/voiceover/en/deeper/6_subentry_6-page21-1_1_text_value.mp3",
            "6-page27-1": "audio/voiceover/en/deeper/6_subentry_6-page27-1_1_text_value.mp3",
            "6-page29-1": "audio/voiceover/en/deeper/6_subentry_6-page29-1_1_text_value.mp3",
            "7-page21-1": "audio/voiceover/en/deeper/7_subentry_7-page21-1_1_text_value.mp3",
            "7-page27-1": "audio/voiceover/en/deeper/7_subentry_7-page27-1_1_text_value.mp3",
            "7-page29-1": "audio/voiceover/en/deeper/7_subentry_7-page29-1_1_text_value.mp3",
            "8-page21-1": "audio/voiceover/en/deeper/8_subentry_8-page21-1_1_text_value.mp3",
            "8-page27-1": "audio/voiceover/en/deeper/8_subentry_8-page27-1_1_text_value.mp3",
            "8-page29-1": "audio/voiceover/en/deeper/8_subentry_8-page29-1_1_text_value.mp3",
            "9-page21-1": "audio/voiceover/en/deeper/9_subentry_9-page21-1_1_text_value.mp3",
            "9-page27-1": "audio/voiceover/en/deeper/9_subentry_9-page27-1_1_text_value.mp3",
            "9-page29-1": "audio/voiceover/en/deeper/9_subentry_9-page29-1_1_text_value.mp3",
            "10-page21-1": "audio/voiceover/en/deeper/10_subentry_10-page21-1_1_text_value.mp3",
            "10-page27-1": "audio/voiceover/en/deeper/10_subentry_10-page27-1_1_text_value.mp3",
            "10-page29-1": "audio/voiceover/en/deeper/10_subentry_10-page29-1_1_text_value.mp3",
            "11-page21-1": "audio/voiceover/en/deeper/11_subentry_11-page21-1_1_text_value.mp3",
            "11-page27-1": "audio/voiceover/en/deeper/11_subentry_11-page27-1_1_text_value.mp3",
            "11-page29-1": "audio/voiceover/en/deeper/11_subentry_11-page29-1_1_text_value.mp3",
            "12-page21-1": "audio/voiceover/en/deeper/12_subentry_12-page21-1_1_text_value.mp3",
            "12-page27-1": "audio/voiceover/en/deeper/12_subentry_12-page27-1_1_text_value.mp3",
            "12-page29-1": "audio/voiceover/en/deeper/12_subentry_12-page29-1_1_text_value.mp3",
            "13-page29-1-1": "audio/voiceover/en/deeper/13_subentry_13-page29-1_subentry_13-page29-1-1_1_text_value.mp3",
            "13-page29-1-2": "audio/voiceover/en/deeper/13_subentry_13-page29-1_subentry_13-page29-1-2_1_text_value.mp3",
            "14-page21-1": "audio/voiceover/en/deeper/14_subentry_14-page21-1_1_text_value.mp3",
            "14-page27-1": "audio/voiceover/en/deeper/14_subentry_14-page27-1_1_text_value.mp3",
            "14-page29-1": "audio/voiceover/en/deeper/14_subentry_14-page29-1_1_text_value.mp3",
            "15-page21-1": "audio/voiceover/en/deeper/15_subentry_15-page21-1_1_text_value.mp3",
            "15-page27-1": "audio/voiceover/en/deeper/15_subentry_15-page27-1_1_text_value.mp3",
            "15-page29-1": "audio/voiceover/en/deeper/15_subentry_15-page29-1_1_text_value.mp3",
            "16-page21-1": "audio/voiceover/en/deeper/16_subentry_16-page21-1_1_text_value.mp3",
            "16-page27-1": "audio/voiceover/en/deeper/16_subentry_16-page27-1_1_text_value.mp3",
            "16-page29-1": "audio/voiceover/en/deeper/16_subentry_16-page29-1_1_text_value.mp3",
            "17-page21-1": "audio/voiceover/en/deeper/17_subentry_17-page21-1_1_text_value.mp3",
            "17-page27-1": "audio/voiceover/en/deeper/17_subentry_17-page27-1_1_text_value.mp3",
            "17-page29-1": "audio/voiceover/en/deeper/17_subentry_17-page29-1_1_text_value.mp3",
            "18-page21-1": "audio/voiceover/en/deeper/18_subentry_18-page21-1_1_text_value.mp3",
            "18-page27-1": "audio/voiceover/en/deeper/18_subentry_18-page27-1_1_text_value.mp3",
            "18-page29-1": "audio/voiceover/en/deeper/18_subentry_18-page29-1_1_text_value.mp3",
            "19-page21-1": "audio/voiceover/en/deeper/19_subentry_19-page21-1_1_text_value.mp3",
            "19-page27-1": "audio/voiceover/en/deeper/19_subentry_19-page27-1_1_text_value.mp3",
            "19-page29-1": "audio/voiceover/en/deeper/19_subentry_19-page29-1_1_text_value.mp3",
            "20-page21-1": "audio/voiceover/en/deeper/20_subentry_20-page21-1_1_text_value.mp3",
            "20-page27-1": "audio/voiceover/en/deeper/20_subentry_20-page27-1_1_text_value.mp3",
            "20-page29-1": "audio/voiceover/en/deeper/20_subentry_20-page29-1_1_text_value.mp3",
            "21-page21-1": "audio/voiceover/en/deeper/21_subentry_21-page21-1_1_text_value.mp3",
            "21-page27-1": "audio/voiceover/en/deeper/21_subentry_21-page27-1_1_text_value.mp3",
            "21-page29-1": "audio/voiceover/en/deeper/21_subentry_21-page29-1_1_text_value.mp3",
            "22-page21-1": "audio/voiceover/en/deeper/22_subentry_22-page21-1_1_text_value.mp3",
            "22-page27-1": "audio/voiceover/en/deeper/22_subentry_22-page27-1_1_text_value.mp3",
            "22-page29-1": "audio/voiceover/en/deeper/22_subentry_22-page29-1_1_text_value.mp3",
            "23-page21-1": "audio/voiceover/en/deeper/23_subentry_23-page21-1_1_text_value.mp3",
            "23-page27-1": "audio/voiceover/en/deeper/23_subentry_23-page27-1_1_text_value.mp3",
            "23-page29-1": "audio/voiceover/en/deeper/23_subentry_23-page29-1_1_text_value.mp3",
            "24-page21-1": "audio/voiceover/en/deeper/24_subentry_24-page21-1_1_text_value.mp3",
            "24-page27-1": "audio/voiceover/en/deeper/24_subentry_24-page27-1_1_text_value.mp3",
            "24-page29-1": "audio/voiceover/en/deeper/24_subentry_24-page29-1_1_text_value.mp3",
            "26-page45-1": "audio/voiceover/en/deeper/26_subentry_26-page45-1_1_text_value.mp3",
            "70-1": "audio/voiceover/en/deeper/70_subentry_70-1_1_text_value.mp3",
            "70-page29-1": "audio/voiceover/en/deeper/70_subentry_70-page29-1_1_text_value.mp3",
            "75-page29-1": "audio/voiceover/en/deeper/75_subentry_75-page29-1_1_text_value.mp3",
            "80-1-1-1": "audio/voiceover/en/deeper/80_subentry_80-1_subentry_80-1-1_subentry_80-1-1-1_1_text_value.mp3",
            "81-1": "audio/voiceover/en/deeper/81_subentry_81-1_1_text_value.mp3",
            "81-1-1": "audio/voiceover/en/deeper/81_subentry_81-1_subentry_81-1-1_1_text_value.mp3",
            "81-1-2": "audio/voiceover/en/deeper/81_subentry_81-1_subentry_81-1-2_1_text_value.mp3",
            "81-2-1": "audio/voiceover/en/deeper/81_subentry_81-2_subentry_81-2-1_1_text_value.mp3",
            "81-2-1-1": "audio/voiceover/en/deeper/81_subentry_81-2_subentry_81-2-1_subentry_81-2-1-1_1_text_value.mp3",
            "81-2-1-2": "audio/voiceover/en/deeper/81_subentry_81-2_subentry_81-2-1_subentry_81-2-1-2_1_text_value.mp3",
            "81-2-1-2-1": "audio/voiceover/en/deeper/81_subentry_81-2_subentry_81-2-1_subentry_81-2-1-2_subentry_81-2-1-2-1_1_text_value.mp3",
            "81-2-1-2-1-1": "audio/voiceover/en/deeper/236c881c_bentry_81-2-1_subentry_81-2-1-2_subentry_81-2-1-2-1_subentry_81-2-1-2-1-1_1_text_value.mp3",
            "81-2-1-2-1-2": "audio/voiceover/en/deeper/c9ea557e_bentry_81-2-1_subentry_81-2-1-2_subentry_81-2-1-2-1_subentry_81-2-1-2-1-2_1_text_value.mp3",
            "81-2-2": "audio/voiceover/en/deeper/81_subentry_81-2_subentry_81-2-2_1_text_value.mp3",
            "81-2-3": "audio/voiceover/en/deeper/81_subentry_81-2_subentry_81-2-3_1_text_value.mp3",
            "81-3": "audio/voiceover/en/deeper/81_subentry_81-3_1_text_value.mp3",
            "81-3-1": "audio/voiceover/en/deeper/81_subentry_81-3_subentry_81-3-1_1_text_value.mp3",
            "81-3-1-1": "audio/voiceover/en/deeper/81_subentry_81-3_subentry_81-3-1_subentry_81-3-1-1_1_text_value.mp3",
            "81-3-1-1-1": "audio/voiceover/en/deeper/81_subentry_81-3_subentry_81-3-1_subentry_81-3-1-1_subentry_81-3-1-1-1_1_text_value.mp3",
            "81-3-1-1-2": "audio/voiceover/en/deeper/81_subentry_81-3_subentry_81-3-1_subentry_81-3-1-1_subentry_81-3-1-1-2_1_text_value.mp3",
            "81-3-1-1-3": "audio/voiceover/en/deeper/81_subentry_81-3_subentry_81-3-1_subentry_81-3-1-1_subentry_81-3-1-1-3_1_text_value.mp3",
            "81-3-1-2": "audio/voiceover/en/deeper/81_subentry_81-3_subentry_81-3-1_subentry_81-3-1-2_1_text_value.mp3",
            "81-3-2": "audio/voiceover/en/deeper/81_subentry_81-3_subentry_81-3-2_1_text_value.mp3",
            "83-1": "audio/voiceover/en/deeper/83_subentry_83-1_1_text_value.mp3",
            "83-1-1": "audio/voiceover/en/deeper/83_subentry_83-1_subentry_83-1-1_1_text_value.mp3",
            "83-1-2": "audio/voiceover/en/deeper/83_subentry_83-1_subentry_83-1-2_1_text_value.mp3",
            "83-2": "audio/voiceover/en/deeper/83_subentry_83-2_1_text_value.mp3",
            "83-2-1": "audio/voiceover/en/deeper/83_subentry_83-2_subentry_83-2-1_1_text_value.mp3",
            "83-3": "audio/voiceover/en/deeper/83_subentry_83-3_1_text_value.mp3",
            "83-3-1": "audio/voiceover/en/deeper/83_subentry_83-3_subentry_83-3-1_1_text_value.mp3",
            "84-1": "audio/voiceover/en/deeper/84_subentry_84-1_1_text_value.mp3",
            "84-1-1": "audio/voiceover/en/deeper/84_subentry_84-1_subentry_84-1-1_1_text_value .mp3",
            "84-1-2": "audio/voiceover/en/deeper/84_subentry_84-1_subentry_84-1-2_1_text_value.mp3",
            "84-2": "audio/voiceover/en/deeper/84_subentry_84-2_1_text_value.mp3",
            "84-2-1": "audio/voiceover/en/deeper/84_subentry_84-2_subentry_84-2-1_1_text_value.mp3",
            "84-2-2": "audio/voiceover/en/deeper/84_subentry_84-2_subentry_84-2-2_1_text_value.mp3",
            "84-3": "audio/voiceover/en/deeper/84_subentry_84-3_1_text_value.mp3",
            "84-3-1": "audio/voiceover/en/deeper/84_subentry_84-3_subentry_84-3-1_1_text_value.mp3",
            "84-3-2": "audio/voiceover/en/deeper/84_subentry_84-3_subentry_84-3-2_1_text_value.mp3",
            "86-1-1": "audio/voiceover/en/deeper/86_subentry_86-1_subentry_86-1-1_1_text_value.mp3",
            "86-1-1-1": "audio/voiceover/en/deeper/86_subentry_86-1_subentry_86-1-1_subentry_86-1-1-1_1_text_value.mp3",
            "86-1-1-2": "audio/voiceover/en/deeper/86_subentry_86-1_subentry_86-1-1_subentry_86-1-1-2_1_text_value.mp3",
            "86-1-2": "audio/voiceover/en/deeper/86_subentry_86-1_subentry_86-1-2_1_text_value.mp3",
            "86-2": "audio/voiceover/en/deeper/86_subentry_86-2_1_text_value.mp3",
            "87-1-1-2-1-1-2-1-1-1": "audio/voiceover/en/deeper/7da49bd7_ntry_871121_sbntry_8711211_sbntry_87112112_sbnt_871121121__8711211211__87112112111_1__.mp3",
            "87-1-1-2-1-1-2-1-1-2": "audio/voiceover/en/deeper/4429a712_ntry_871121_sbntry_8711211_sbntry_87112112_sbnt_871121121__8711211211__87112112112_1__.mp3",
            "87-1-1-2-1-1-2-1-1-3": "audio/voiceover/en/deeper/5352b351_ntry_871121_sbntry_8711211_sbntry_87112112_sbnt_871121121__8711211211__87112112113_1__.mp3",
            "87-1-1-2-1-1-2-1-1": "audio/voiceover/en/deeper/9cf8c5a2_ntry_871121_sbntry_8711211_sbntry_87112112_sbntry_871121121_sbntry_8711211211_1_txt_vl.mp3",
            "87-1-1": "audio/voiceover/en/deeper/87_subentry_87-1_subentry_87-1-1_1_text_value.mp3",
            "87-1-1-1": "audio/voiceover/en/deeper/87_subentry_87-1_subentry_87-1-1_subentry_87-1-1-1_1_text_value.mp3",
            "87-1-1-2": "audio/voiceover/en/deeper/87_subentry_87-1_subentry_87-1-1_subentry_87-1-1-2_1_text_value.mp3",
            "87-1-1-2-1-1": "audio/voiceover/en/deeper/f90bd43a_bentry_87-1-1_subentry_87-1-1-2_subentry_87-1-1-2-1_subentry_87-1-1-2-1-1_1_text_value.mp3",
            "87-1-1-2-1-1-1": "audio/voiceover/en/deeper/79732049__87-1-1-2_subentry_87-1-1-2-1_subentry_87-1-1-2-1-1_subentry_87-1-1-2-1-1-1_1_text_val.mp3",
            "87-1-1-2-1-1-2": "audio/voiceover/en/deeper/50bb94bb__87-1-1-2_subentry_87-1-1-2-1_subentry_87-1-1-2-1-1_subentry_87-1-1-2-1-1-2_1_text_val.mp3",
            "90-1": "audio/voiceover/en/deeper/90_subentry_90-1_1_text_value.mp3",
            "90-1-1-1": "audio/voiceover/en/deeper/90_subentry_90-1_subentry_90-1-1_subentry_90-1-1-1_1_text_value.mp3",
            "90-1-1-2": "audio/voiceover/en/deeper/90_subentry_90-1_subentry_90-1-1_subentry_90-1-1-2_1_text_value.mp3",
            "90-1-1-3": "audio/voiceover/en/deeper/90_subentry_90-1_subentry_90-1-1_subentry_90-1-1-3_1_text_value.mp3",
            "90-1-2": "audio/voiceover/en/deeper/90_subentry_90-1_subentry_90-1-2_1_text_value.mp3",
            "90-2": "audio/voiceover/en/deeper/90_subentry_90-2_1_text_value.mp3",
            "90-2-1": "audio/voiceover/en/deeper/90_subentry_90-2_subentry_90-2-1_1_text_value.mp3",
            "90-2-1-1": "audio/voiceover/en/deeper/90_subentry_90-2_subentry_90-2-1_subentry_90-2-1-1_1_text_value.mp3",
            "90-2-2": "audio/voiceover/en/deeper/90_subentry_90-2_subentry_90-2-2_1_text_value.mp3",
            "1700-1-1": "audio/voiceover/en/deeper/1700_subentry_1700-1_subentry_1700-1-1_1_text_value.mp3",
            "1700-1-2": "audio/voiceover/en/deeper/1700_subentry_1700-1_subentry_1700-1-2_1_text_value.mp3",
            "1700-1-2-1": "audio/voiceover/en/deeper/1700_subentry_1700-1_subentry_1700-1-2_subentry_1700-1-2-1_1_text_value.mp3",
            "1700-1-2-1-1": "audio/voiceover/en/deeper/1700_subentry_1700-1_subentry_1700-1-2_subentry_1700-1-2-1_subentry_1700-1-2-1-1_1_text_value.mp3",
            "1701-1": "audio/voiceover/en/deeper/1701_subentry_1701-1_1_text_value.mp3",
            "1701-2-1": "audio/voiceover/en/deeper/1701_subentry_1701-2_subentry_1701-2-1_1_text_value.mp3",
            "1701-2-2": "audio/voiceover/en/deeper/1701_subentry_1701-2_subentry_1701-2-2_1_text_value.mp3",
            "1704-1-1-1": "audio/voiceover/en/deeper/1704_subentry_1704-1_subentry_1704-1-1_subentry_1704-1-1-1_1_text_value.mp3",
            "1704-1-1-2": "audio/voiceover/en/deeper/1704_subentry_1704-1_subentry_1704-1-1_subentry_1704-1-1-2_1_text_value.mp3",
            "1704-1-1-3": "audio/voiceover/en/deeper/1704_subentry_1704-1_subentry_1704-1-1_subentry_1704-1-1-3_1_text_value.mp3",
            "1704-1-2": "audio/voiceover/en/deeper/1704_subentry_1704-1_subentry_1704-1-2_1_text_value.mp3",
            "1705-1": "audio/voiceover/en/deeper/1705_subentry_1705-1_1_text_value.mp3",
            "1705-2": "audio/voiceover/en/deeper/1705_subentry_1705-2_1_text_value.mp3",
            "1707-1": "audio/voiceover/en/deeper/1707_subentry_1707-1_1_text_value.mp3",
            "1707-2": "audio/voiceover/en/deeper/1707_subentry_1707-2_1_text_value.mp3",
            "1708-1": "audio/voiceover/en/deeper/1708_subentry_1708-1_1_text_value.mp3",
            "1708-2-1": "audio/voiceover/en/deeper/1708_subentry_1708-2_subentry_1708-2-1_1_text_value.mp3",
            "1708-2-2": "audio/voiceover/en/deeper/1708_subentry_1708-2_subentry_1708-2-2_1_text_value.mp3",
            "1709-1": "audio/voiceover/en/deeper/1709_subentry_1709-1_1_text_value.mp3",
            "1710-1": "audio/voiceover/en/deeper/1710_subentry_1710-1_1_text_value.mp3",
            "1710-2": "audio/voiceover/en/deeper/1710_subentry_1710-2_1_text_value.mp3",
            "1711-1-1": "audio/voiceover/en/deeper/1711_subentry_1711-1_subentry_1711-1-1_1_text_value.mp3",
            "1711-1-2-1": "audio/voiceover/en/deeper/1711_subentry_1711-1_subentry_1711-1-2_subentry_1711-1-2-1_1_text_value.mp3",
            "1711-1-2-2": "audio/voiceover/en/deeper/1711_subentry_1711-1_subentry_1711-1-2_subentry_1711-1-2-2_1_text_value.mp3",
            "1711-2": "audio/voiceover/en/deeper/1711_subentryƒ_1711-2_1_text_value.mp3",
            "1900-2": "audio/voiceover/en/deeper/1900_subentry_1900-2_1_text_value.mp3",
            "1900-3": "audio/voiceover/en/deeper/1900_subentry_1900-3_1_text_value.mp3",
            "1900-4": "audio/voiceover/en/deeper/1900_subentry_1900-4_1_text_value.mp3",
            "1901-1": "audio/voiceover/en/deeper/1901_subentry_1901-1_1_text_value.mp3",
            "1901-2": "audio/voiceover/en/deeper/1901_subentry_1901-2_1_text_value.mp3",
            "1902-1": "audio/voiceover/en/deeper/1902_subentry_1902-1_1_text_value.mp3",
            "1902-2": "audio/voiceover/en/deeper/1902_subentry_1902-2_1_text_value.mp3",
            "1903-1": "audio/voiceover/en/deeper/1903_subentry_1903-1_1_text_value.mp3",
            "1903-2": "audio/voiceover/en/deeper/1903_subentry_1903-2_1_text_value.mp3",
            "1910-1-1-2": "audio/voiceover/en/deeper/1910_subentry_1910-1_subentry_1910-1-1_subentry_1910-1-1-2_1_text_value.mp3",
            "1910-2-1": "audio/voiceover/en/deeper/1910_subentry_1910-2_subentry_1910-2-1_1_text_value.mp3",
            "1910-2-2": "audio/voiceover/en/deeper/1910_subentry_1910-2_subentry_1910-2-2_1_text_value.mp3",
            "2102-1": "audio/voiceover/en/deeper/2102_subentry_2102-1_1_text_value.mp3",
            "2102-2": "audio/voiceover/en/deeper/2102_subentry_2102-2_1_text_value.mp3",
            "2103-1": "audio/voiceover/en/deeper/2103_subentry_2103-1_1_text_value.mp3",
            "2103-1-1": "audio/voiceover/en/deeper/2103_subentry_2103-1_subentry_2103-1-1_1_text_value.mp3",
            "2103-1-2": "audio/voiceover/en/deeper/2103_subentry_2103-1_subentry_2103-1-2_1_text_value.mp3",
            "2103-2": "audio/voiceover/en/deeper/2103_subentry_2103-2_1_text_value.mp3",
            "2106-1": "audio/voiceover/en/deeper/2106_subentry_2106-1_1_text_value.mp3",
            "2106-2-1": "audio/voiceover/en/deeper/2106_subentry_2106-2_subentry_2106-2-1_1_text_value.mp3",
            "2703-1": "audio/voiceover/en/deeper/2703_subentry_2703-1_1_text_value.mp3",
            "2703-2-1": "audio/voiceover/en/deeper/2703_subentry_2703-2_subentry_2703-2-1_1_text_value.mp3",
            "2712-1": "audio/voiceover/en/deeper/2712_subentry_2712-1_1_text_value.mp3",
            "2712-2": "audio/voiceover/en/deeper/2712_subentry_2712-2_1_text_value.mp3",
            "2712-2-1": "audio/voiceover/en/deeper/2712_subentry_2712-2_subentry_2712-2-1_1_text_value.mp3",
            "2712-3-1": "audio/voiceover/en/deeper/2712_subentry_2712-3_subentry_2712-3-1_1_text_value.mp3",
            "2712-4-1": "audio/voiceover/en/deeper/2712_subentry_2712-4_subentry_2712-4-1_1_text_value.mp3",
            "2900-1": "audio/voiceover/en/deeper/2900_subentry_2900-1_1_text_value.mp3",
            "2901-1": "audio/voiceover/en/deeper/2901_subentry_2901-1_1_text_value.mp3",
            "2901-1-1": "audio/voiceover/en/deeper/2901_subentry_2901-1_subentry_2901-1-1_1_text_value.mp3",
            "2901-1-2": "audio/voiceover/en/deeper/2901_subentry_2901-1_subentry_2901-1-2_1_text_value.mp3",
            "2902-1": "audio/voiceover/en/deeper/2902_subentry_2902-1_1_text_value.mp3",
            "2902-1-1": "audio/voiceover/en/deeper/2902_subentry_2902-1_subentry_2902-1-1_1_text_value.mp3",
            "2902-1-2": "audio/voiceover/en/deeper/2902_subentry_2902-1_subentry_2902-1-2_1_text_value.mp3",
            "2903-1": "audio/voiceover/en/deeper/2903_subentry_2903-1_1_text_value.mp3",
            "2903-1-1": "audio/voiceover/en/deeper/2903_subentry_2903-1_subentry_2903-1-1_1_text_value.mp3",
            "2903-1-2": "audio/voiceover/en/deeper/2903_subentry_2903-1_subentry_2903-1-2_1_text_value.mp3",
            "2904-1": "audio/voiceover/en/deeper/2904_subentry_2904-1_1_text_value.mp3",
            "2904-1-1": "audio/voiceover/en/deeper/2904_subentry_2904-1_subentry_2904-1-1_1_text_value.mp3",
            "2904-1-2": "audio/voiceover/en/deeper/2904_subentry_2904-1_subentry_2904-1-2_1_text_value.mp3",
            "2905-1": "audio/voiceover/en/deeper/2905_subentry_2905-1_1_text_value.mp3",
            "2905-1-1": "audio/voiceover/en/deeper/2905_subentry_2905-1_subentry_2905-1-1_1_text_value.mp3",
            "2905-1-2": "audio/voiceover/en/deeper/2905_subentry_2905-1_subentry_2905-1-2_1_text_value.mp3",
            "2906-1": "audio/voiceover/en/deeper/2906_subentry_2906-1_1_text_value.mp3",
            "2906-1-1": "audio/voiceover/en/deeper/2906_subentry_2906-1_subentry_2906-1-1_1_text_value.mp3",
            "2906-1-2": "audio/voiceover/en/deeper/2906_subentry_2906-1_subentry_2906-1-2_1_text_value.mp3",
            "2907-1": "audio/voiceover/en/deeper/2907_subentry_2907-1_1_text_value.mp3",
            "2907-1-1": "audio/voiceover/en/deeper/2907_subentry_2907-1_subentry_2907-1-1_1_text_value.mp3",
            "2907-1-2": "audio/voiceover/en/deeper/2907_subentry_2907-1_subentry_2907-1-2_1_text_value.mp3",
            "2908-1": "audio/voiceover/en/deeper/2908_subentry_2908-1_1_text_value-02.mp3",
            "2908-2-2-1": "audio/voiceover/en/deeper/2908_subentry_2908-2_subentry_2908-2-2_subentry_2908-2-2-1_1_text_value.mp3",
            "2908-2-2-2": "audio/voiceover/en/deeper/2908_subentry_2908-2_subentry_2908-2-2_subentry_2908-2-2-2_1_text_value.mp3",
            "4103-3": "audio/voiceover/en/deeper/4103_subentry_4103-3_1_text_value.mp3",
            "4103-3-1": "audio/voiceover/en/deeper/4103_subentry_4103-3_subentry_4103-3-1_1_text_value.mp3",
            "4103-3-2": "audio/voiceover/en/deeper/4103_subentry_4103-3_subentry_4103-3-2_1_text_value.mp3",
            "4104-1": "audio/voiceover/en/deeper/4104_subentry_4104-1_1_text_value.mp3",
            "4106-1": "audio/voiceover/en/deeper/4106_subentry_4106-1_1_text_value.mp3",
            "4106-2": "audio/voiceover/en/deeper/4106_subentry_4106-2_1_text_value.mp3",
            "4108-1": "audio/voiceover/en/deeper/4108_subentry_4108-1_1_text_value.mp3",
            "4108-1-1": "audio/voiceover/en/deeper/4108_subentry_4108-1_subentry_4108-1-1_1_text_value.mp3",
            "4108-1-2": "audio/voiceover/en/deeper/4108_subentry_4108-1_subentry_4108-1-2_1_text_value.mp3",
            "4108-2": "audio/voiceover/en/deeper/4108_subentry_4108-2_1_text_value.mp3",
            "4110-1": "audio/voiceover/en/deeper/4110_subentry_4110-1_1_text_value.mp3",
            "4110-1-1": "audio/voiceover/en/deeper/4110_subentry_4110-1_subentry_4110-1-1_1_text_value.mp3",
            "4110-1-2": "audio/voiceover/en/deeper/4110_subentry_4110-1_subentry_4110-1-2_1_text_value.mp3",
            "4110-2": "audio/voiceover/en/deeper/4110_subentry_4110-2_1_text_value.mp3",
            "4110-2-1": "audio/voiceover/en/deeper/4110_subentry_4110-2_subentry_4110-2-1_1_text_value.mp3",
            "4110-2-2": "audio/voiceover/en/deeper/4110_subentry_4110-2_subentry_4110-2-2_1_text_value.mp3",
            "4110-3": "audio/voiceover/en/deeper/4110_subentry_4110-3_1_text_value.mp3",
            "4110-3-1": "audio/voiceover/en/deeper/4110_subentry_4110-3_subentry_4110-3-1_1_text_value.mp3",
            "4110-3-2": "audio/voiceover/en/deeper/4110_subentry_4110-3_subentry_4110-3-2_1_text_value.mp3",
            "4111-1": "audio/voiceover/en/deeper/4111_subentry_4111-1_1_text_value.mp3",
            "4112-1": "audio/voiceover/en/deeper/4112_subentry_4112-1_1_text_value.mp3",
            "4112-2": "audio/voiceover/en/deeper/4112_subentry_4112-2_1_text_value.mp3",
            "4505-1": "audio/voiceover/en/deeper/4505_subentry_4505-1_1_text_value.mp3",
            "4505-2": "audio/voiceover/en/deeper/4505_subentry_4505-2_1_text_value.mp3",
            "4506-1": "audio/voiceover/en/deeper/4506_subentry_4506-1_1_text_value.mp3",
            "4507-1": "audio/voiceover/en/deeper/4507_subentry_4507-1_1_text_value.mp3",
            "4507-2": "audio/voiceover/en/deeper/4507_subentry_4507-2_1_text_value.mp3",
            "4507-2-1": "audio/voiceover/en/deeper/4507_subentry_4507-2_subentry_4507-2-1_1_text_value.mp3",
            "4507-2-2": "audio/voiceover/en/deeper/4507_subentry_4507-2_subentry_4507-2-2_1_text_value.mp3",
            "4508-1": "audio/voiceover/en/deeper/4508_subentry_4508-1_1_text_value.mp3",
            "4508-2": "audio/voiceover/en/deeper/4508_subentry_4508-2_1_text_value.mp3",
            "4508-2-2": "audio/voiceover/en/deeper/4508_subentry_4508-2_subentry_4508-2-2_1_text_value.mp3",
            "4508-2-3": "audio/voiceover/en/deeper/4508_subentry_4508-2_subentry_4508-2-3_1_text_value.mp3",
            "4508-2-4": "audio/voiceover/en/deeper/4508_subentry_4508-2_subentry_4508-2-4_1_text_value.mp3",
            "4509-1-2": "audio/voiceover/en/deeper/4509_subentry_4509-1_subentry_4509-1-2_1_text_value.mp3",
            "4509-2": "audio/voiceover/en/deeper/4509_subentry_4509-2_1_text_value.mp3",
            "4701-1": "audio/voiceover/en/deeper/4701_subentry_4701-1_1_text_value.mp3",
            "4701-2": "audio/voiceover/en/deeper/4701_subentry_4701-2_1_text_value.mp3",
            "4702-1": "audio/voiceover/en/deeper/4702_subentry_4702-1_1_text_value.mp3",
            "4703-1": "audio/voiceover/en/deeper/4703_subentry_4703-1_1_text_value.mp3",
            "4703-2": "audio/voiceover/en/deeper/4703_subentry_4703-2_1_text_value.mp3",
            "4703-4": "audio/voiceover/en/deeper/4703_subentry_4703-4_1_text_value.mp3",
            "4704-1-2": "audio/voiceover/en/deeper/4704_subentry_4704-1_subentry_4704-1-2_1_text_value.mp3",
            "4704-1-2-1": "audio/voiceover/en/deeper/4704_subentry_4704-1_subentry_4704-1-2_subentry_4704-1-2-1_1_text_value.mp3",
            "4704-1-2-2": "audio/voiceover/en/deeper/4704_subentry_4704-1_subentry_4704-1-2_subentry_4704-1-2-2_1_text_value.mp3",
            "4704-2": "audio/voiceover/en/deeper/4704_subentry_4704-2_1_text_value.mp3",
            "4705-1": "audio/voiceover/en/deeper/4705_subentry_4705-1_1_text_value.mp3",
            "4705-2": "audio/voiceover/en/deeper/4705_subentry_4705-2_1_text_value.mp3",
            "4707-1": "audio/voiceover/en/deeper/4707_subentry_4707-1_1_text_value.mp3",
            "4707-2": "audio/voiceover/en/deeper/4707_subentry_4707-2_1_text_value.mp3",
            "4708-1-1": "audio/voiceover/en/deeper/4708_subentry_4708-1_subentry_4708-1-1_1_text_value.mp3",
            "4708-1-2": "audio/voiceover/en/deeper/4708_subentry_4708-1_subentry_4708-1-2_1_text_value.mp3",
            "4708-2": "audio/voiceover/en/deeper/4708_subentry_4708-2_1_text_value.mp3",
            "Campsite-1": "audio/voiceover/en/deeper/camp_subentry_Campsite-1_1_text_value.mp3",
            "Campsite-2": "audio/voiceover/en/deeper/camp_subentry_Campsite-2_1_text_value.mp3",
            "Campsite-3": "audio/voiceover/en/deeper/camp_subentry_Campsite-3_1_text_value.mp3",
            "Campsite-3-1": "audio/voiceover/en/deeper/camp_subentry_Campsite-3_subentry_Campsite-3-1_1_text_value.mp3",
            "Campsite-3-2": "audio/voiceover/en/deeper/camp_subentry_Campsite-3_subentry_Campsite-3-2_1_text_value .mp3",
            "chaos": "audio/voiceover/en/deeper/chaos_1_text_value.mp3",
            "chaos-1": "audio/voiceover/en/deeper/chaos_subentry_chaos-1_1_text_value.mp3",
            "chaos-1-1": "audio/voiceover/en/deeper/chaos_subentry_chaos-1_subentry_chaos-1-1_1_text_value.mp3",
            "chaos-2": "audio/voiceover/en/deeper/chaos_subentry_chaos-2_1_text_value.mp3",
            "chaos-3": "audio/voiceover/en/deeper/chaos_subentry_chaos-3_1_text_value.mp3",
            "chaos-4": "audio/voiceover/en/deeper/chaos_subentry_chaos-4_1_text_value.mp3",
            "chaos-5": "audio/voiceover/en/deeper/chaos_subentry_chaos-5_1_text_value.mp3",
            "chaos-6": "audio/voiceover/en/deeper/chaos_subentry_chaos-6_1_text_value.mp3",
            "crossroad special-1": "audio/voiceover/en/deeper/crossroad_special_subentry_crossroad special-1_1_text_value.mp3",
            "crossroad special-2": "audio/voiceover/en/deeper/crossroad_special_subentry_crossroad special-2_1_text_value.mp3",
            "crossroad special-4": "audio/voiceover/en/deeper/crossroad_special_subentry_crossroad_special-4_1_text_value.mp3",
            "duel-1": "audio/voiceover/en/deeper/duel_subentry_duel-1_1_text_value.mp3",
            "duel-1-1": "audio/voiceover/en/deeper/duel_subentry_duel-1_subentry_duel-1-1_1_text_value.mp3",
            "duel-1-2": "audio/voiceover/en/deeper/duel_subentry_duel-1_subentry_duel-1-2_1_text_value.mp3",
            "duel-1-2-1": "audio/voiceover/en/deeper/duel_subentry_duel-1_subentry_duel-1-2_subentry_duel-1-2-1_1_text_value.mp3",
            "duel-1-2-2": "audio/voiceover/en/deeper/duel_subentry_duel-1_subentry_duel-1-2_subentry_duel-1-2-2_1_text_value.mp3",
            "duel-2": "audio/voiceover/en/deeper/duel_subentry_duel-2_1_text_value.mp3",
            "duel-2-1": "audio/voiceover/en/deeper/duel_subentry_duel-2_subentry_duel-2-1_1_text_value.mp3",
            "duel-2-2": "audio/voiceover/en/deeper/duel_subentry_duel-2_subentry_duel-2-2_1_text_value.mp3",
            "duel-3": "audio/voiceover/en/deeper/duel_subentry_duel-3_1_text_value.mp3",
            "duel-3-1": "audio/voiceover/en/deeper/duel_subentry_duel-3_subentry_duel-3-1_1_text_value.mp3",
            "duel-3-2": "audio/voiceover/en/deeper/duel_subentry_duel-3_subentry_duel-3-2_1_text_value.mp3",
            "Fortune": "audio/voiceover/en/deeper/Fortune_1_text_value.mp3",
            "Fortune-1": "audio/voiceover/en/deeper/Fortune_subentry_Fortune-1_1_text_value.mp3",
            "Fortune-1-1": "audio/voiceover/en/deeper/Fortune_subentry_Fortune-1_subentry_Fortune-1-1_1_text_value.mp3",
            "Fortune-1-1-1": "audio/voiceover/en/deeper/Fortune_subentry_Fortune-1_subentry_Fortune-1-1_subentry_Fortune-1-1-1_1_text_value.mp3",
            "Fortune-1-2": "audio/voiceover/en/deeper/Fortune_subentry_Fortune-1_subentry_Fortune-1-2_1_text_value.mp3",
            "Fortune-1-2-1": "audio/voiceover/en/deeper/Fortune_subentry_Fortune-1_subentry_Fortune-1-2_subentry_Fortune-1-2-1_1_text_value.mp3",
            "Fortune-1-2-1-1": "audio/voiceover/en/deeper/Fortune_subentry_Fortune-1_subentry_Fortune-1-2_subentry_Fortune-1-2-1_subentry_Fortune-1-2-1-1_1_text_value.mp3",
            "Fortune-1-3": "audio/voiceover/en/deeper/Fortune_subentry_Fortune-1_subentry_Fortune-1-3_1_text_value.mp3",
            "Fortune-2": "audio/voiceover/en/deeper/Fortune_subentry_Fortune-2_1_text_value.mp3",
            "law": "audio/voiceover/en/deeper/law_1_text_value.mp3",
            "law-1": "audio/voiceover/en/deeper/law_subentry_law-1_1_text_value.mp3",
            "omen-1": "audio/voiceover/en/deeper/omen_subentry_omen-1_1_text_value.mp3",
            "omen-1-1": "audio/voiceover/en/deeper/omen_subentry_omen-1_subentry_omen-1-1_1_text_value.mp3",
            "omen-1-2": "audio/voiceover/en/deeper/omen_subentry_omen-1_subentry_omen-1-2_1_text_value.mp3",
            "omen-2": "audio/voiceover/en/deeper/omen_subentry_omen-2_1_text_value.mp3",
            "omen-3": "audio/voiceover/en/deeper/omen_subentry_omen-3_1_text_value.mp3",
            "sacrifice-1": "audio/voiceover/en/deeper/sacrifice_subentry_sacrifice-1_1_text_value .mp3",
            "sacrifice-2": "audio/voiceover/en/deeper/sacrifice_subentry_sacrifice-2_1_text_value.mp3",
            "sacrifice-3": "audio/voiceover/en/deeper/sacrifice_subentry_sacrifice-3_1_text_value.mp3",
            "sidequest-1-1-1-1-1-1-1-1-2-1": "audio/voiceover/en/deeper/d09f44bf_-1-1-1-1-1-1-1_sbntry_sdqst-1-1-1-1-1-1-1-1-2_sbntry_sdqst-1-1-1-1-1-1-1-1-21_1_txt_vl.mp3",
            "sidequest-1-1-1-1-1-1-1-1-2-1-1-1-1-1": "audio/voiceover/en/deeper/d032c9f0__sdqst-1-1-1-1-1-1112111_sbntry_sdqst1111111121111_sbntry_sdqst11111111211111_1_txt_vl.mp3",
            "sidequest-2-1-1-1-1-1-2-2-1-2": "audio/voiceover/en/deeper/d706b8a0_-1-1-1-1-1-2-2_sbntry_sdqst-2-1-1-1-1-1-2-2-1_sbntry_sdqst-2-1-1-1-1-1-2-2-12_1_txt_vl.mp3",
            "sidequest-2-1-1-1-1-1-2-2-1-1-1-1": "audio/voiceover/en/deeper/1694542a_st-2-1-1-1-1-1-2-2-1-1_sbntry_sdqst-2-1-1-1-1-122111_sbntry_sdqst211111221111_1_txt_vl.mp3",
            "sidequest-3-1-1-1-1-1-1-2-1-1": "audio/voiceover/en/deeper/5817b5a8_-1-1-1-1-1-1-2_sbntry_sdqst-3-1-1-1-1-1-1-2-1_sbntry_sdqst-3-1-1-1-1-1-1-2-11_1_txt_vl.mp3",
            "sidequest-3-1-1-1-1-1-1-2-1-1-1": "audio/voiceover/en/deeper/3e3524b7_-1111112_sbntry_sdqst311111121_sbntry_sdqst3111111211_sbntry_sdqst31111112111_1_txt_vl.mp3",
            "sidequest-1-1-1-1-1-1": "audio/voiceover/en/deeper/4e70d08_try_sdqst-1-1-1_sbntry_sdqst-1-1-1-1_sbntry_sdqst-1-1-1-11_sbntry_sdqst111111_1_txt_vl.mp3",
            "sidequest-3-1-1-1-1-1": "audio/voiceover/en/deeper/bee5a93e_try_sdqst-3-1-1_sbntry_sdqst-3-1-1-1_sbntry_sdqst-3-1-1-11_sbntry_sdqst311111_1_txt_vl.mp3",
            "sidequest-3-1-1-1-1-2": "audio/voiceover/en/deeper/c2848ce5_try_sdqst-3-1-1_sbntry_sdqst-3-1-1-1_sbntry_sdqst-3-1-1-11_sbntry_sdqst311112_1_txt_vl.mp3",
            "sidequest-1-1-1-1-1-1-1": "audio/voiceover/en/deeper/1aa4a0af__sdqst111_sbntry_sdqst1111_sbntry_sdqst11111_sbntry_sdqst111111_sbntry_sdqs1111111_1__.mp3",
            "sidequest-2-1-1-1-1-1-1": "audio/voiceover/en/deeper/51e95d51__sdqst211_sbntry_sdqst2111_sbntry_sdqst21111_sbntry_sdqst211111_sbntry_sdqs2111111_1__.mp3",
            "sidequest-2-1-1-1-1-1-2": "audio/voiceover/en/deeper/68646194__sdqst211_sbntry_sdqst2111_sbntry_sdqst21111_sbntry_sdqst211111_sbntry_sdqs2111112_1__.mp3",
            "sidequest-3-1-1-1-1-1-1": "audio/voiceover/en/deeper/68d209fb__sdqst311_sbntry_sdqst3111_sbntry_sdqst31111_sbntry_sdqst311111_sbntry_sdqs3111111_1__.mp3",
            "sidequest-1-1": "audio/voiceover/en/deeper/sidequest_subentry_sidequest-1_subentry_sidequest-1-1_1_text_value.mp3",
            "sidequest-1-1-1-1-1": "audio/voiceover/en/deeper/acd0f174_idequest-1-1_subentry_sdqst-1-1-1_sbntry_sdqst-1-1-1-1_sbntry_sdqst-1-1-1-1-1_1_txt_vl.mp3",
            "sidequest-1-1-1-1-2": "audio/voiceover/en/deeper/d0b1d4af_idequest-1-1_subentry_sdqst-1-1-1_sbntry_sdqst-1-1-1-1_sbntry_sdqst-1-1-1-1-2_1_txt_vl.mp3",
            "sidequest-1-1-1": "audio/voiceover/en/deeper/sidequest_subentry_sidequest-1_subentry_sidequest-1-1_subentry_sidequest-1-1-1_1_text_value.mp3",
            "sidequest-1-1-2": "audio/voiceover/en/deeper/sidequest_subentry_sidequest-1_subentry_sidequest-1-1_subentry_sidequest-1-1-2_1_text_value.mp3",
            "sidequest-2-1": "audio/voiceover/en/deeper/sidequest_subentry_sidequest-2_subentry_sidequest-2-1_1_text_value.mp3",
            "sidequest-2-1-1-1-1": "audio/voiceover/en/deeper/ebcb08eb_idequest-2-1_subentry_sdqst-2-1-1_sbntry_sdqst-2-1-1-1_sbntry_sdqst-2-1-1-1-1_1_txt_vl.mp3",
            "sidequest-2-1-1-1": "audio/voiceover/en/deeper/6018cf93_ubentry_sidequest-2-1_subentry_sidequest-2-1-1_subentry_sidequest-2-1-1-1_1_text_value.mp3",
            "sidequest-2-1-1-2": "audio/voiceover/en/deeper/8a9e12f1_ubentry_sidequest-2-1_subentry_sidequest-2-1-1_subentry_sidequest-2-1-1-2_1_text_value.mp3",
            "sidequest-3-1": "audio/voiceover/en/deeper/sidequest_subentry_sidequest-3_subentry_sidequest-3-1_1_text_value.mp3",
            "sidequest-3-1-2-1-1": "audio/voiceover/en/deeper/30968eb2_idequest-3-1_subentry_sdqst-3-1-2_sbntry_sdqst-3-1-2-1_sbntry_sdqst-3-1-2-1-1_1_txt_vl.mp3",
            "sidequest-3-1-2-1-2": "audio/voiceover/en/deeper/4cf7ab69_idequest-3-1_subentry_sdqst-3-1-2_sbntry_sdqst-3-1-2-1_sbntry_sdqst-3-1-2-1-2_1_txt_vl.mp3",
            "sidequest-3-1-1": "audio/voiceover/en/deeper/sidequest_subentry_sidequest-3_subentry_sidequest-3-1_subentry_sidequest-3-1-1_1_text_value.mp3",
            "sidequest-3-1-1-1": "audio/voiceover/en/deeper/91a0cc2a_ubentry_sidequest-3-1_subentry_sidequest-3-1-1_subentry_sidequest-3-1-1-1_1_text_value.mp3",
            "sidequest-2-1-1-1-1-1-2-1": "audio/voiceover/en/deeper/sidequest-2-1-1-1-1-1-2_subentry_sidequest-2-1-1-1-1-1-2-1_1_text_value.mp3",
            "sidequest-2-1-1-1-1-1-2-2-1-1": "audio/voiceover/en/deeper/1192bf01_bentry_sidequest-2-1-1-1-1-1-2-2-1_subentry_sidequest-2-1-1-1-1-1-2-2-1-1_1_text_value.mp3",
            "87-1-1-2-1-1-2-1-1-2-1-2": "audio/voiceover/en/deeper/subentry_87-1-1-2-1-1-2-1-1-2-1_subentry_87-1-1-2-1-1-2-1-1-2-1-2_1_text_value.mp3",
            "87-1-1-2-1-1-2-1-1-2-1-2-1": "audio/voiceover/en/deeper/subentry_87-1-1-2-1-1-2-1-1-2-1-2_subentry_87-1-1-2-1-1-2-1-1-2-1-2-1_1_text_value.mp3",
            "87-1-1-2-1-1-2-1-1-2-1-2-1-1": "audio/voiceover/en/deeper/subentry_87-1-1-2-1-1-2-1-1-2-1-2-1_subentry_87-1-1-2-1-1-2-1-1-2-1-2-1-1_1_text_value.mp3",
            "87-1-1-2-1-1-2-1-1-2-1-2-1-1-1": "audio/voiceover/en/deeper/subentry_87-1-1-2-1-1-2-1-1-2-1-2-1-1_subentry_87-1-1-2-1-1-2-1-1-2-1-2-1-1-1_1_text_value.mp3",
            "sidequest-1-1-1-1-1-1-1-2": "audio/voiceover/en/deeper/7c39a08c_1-1-1_subentry_sidequest-1-1-1-1-1-1-1_subentry_sidequest-1-1-1-1-1-1-1-2_1_text_value.mp3",
            "sidequest-1-1-1-1-1-1-1-1-1": "audio/voiceover/en/deeper/c62ffd49_qst-1-1-1-1-1-1-1_sbntry_sdqst-1-1-1-1-1-1-1-1_sbntry_sdqst-1-1-1-1-1-1-1-1-1_1_txt_vl.mp3",
            "sidequest-1-1-1-1-1-1-1-1-2": "audio/voiceover/en/deeper/990fceb5_1_subentry_sidequest-1-1-1-1-1-1-1-1_subentry_sidequest-1-1-1-1-1-1-1-1-2_1_text_value.mp3",
            "sidequest-1-1-1-1-1-1-1-1-2-1-1": "audio/voiceover/en/deeper/subentry_sidequest-1-1-1-1-1-1-1-1-2-1_subentry_sidequest-1-1-1-1-1-1-1-1-2-1-1_1_text_value.mp3",
            "sidequest-1-1-1-1-1-1-1-1-2-1-1-2": "audio/voiceover/en/deeper/f6755f35_bentry_sidequest-1-1-1-1-1-1-1-1-2-1-1_subentry_sdqst-1-1-1-1-1-1-1-1-2-1-1-2_1_txt_vl.mp3",
            "sidequest-1-1-1-1-1-1-1-1-2-1-1-1": "audio/voiceover/en/deeper/subentry_sidequest-1-1-1-1-1-1-1-1-2-1-1_subentry_sidequest-1-1-1-1-1-1-1-1-2-1-1-1_1_text_value.mp3",
            "sidequest-2-1-1-1-1-1-2-1-3": "audio/voiceover/en/deeper/5d3df730_qst-2-1-1-1-1-1-2_sbntry_sdqst-2-1-1-1-1-1-2-1_sbntry_sdqst-2-1-1-1-1-1-2-1-3_1_txt_vl.mp3",
            "sidequest-2-1-1-1-1-1-2-1-2": "audio/voiceover/en/deeper/be401790_2_subentry_sidequest-2-1-1-1-1-1-2-1_subentry_sidequest-2-1-1-1-1-1-2-1-2_1_text_value.mp3",
            "sidequest-2-1-1-1-1-1-2-2": "audio/voiceover/en/deeper/subentry_sidequest-2-1-1-1-1-1-2_subentry_sidequest-2-1-1-1-1-1-2-2_1_text_value.mp3",
            "sidequest-2-1-1-1-1-1-2-1-1": "audio/voiceover/en/deeper/subentry_sidequest-2-1-1-1-1-1-2-1_subentry_sidequest-2-1-1-1-1-1-2-1-1_1_text_value.mp3",
            "sidequest-2-1-1-1-1-1-2-1-1-1": "audio/voiceover/en/deeper/subentry_sidequest-2-1-1-1-1-1-2-1-1_subentry_sidequest-2-1-1-1-1-1-2-1-1-1_1_text_value.mp3",
            "sidequest-2-1-1-1-1-1-2-1-3-1-1": "audio/voiceover/en/deeper/91c00e2e_ntry_sidequest-2-1-1-1-1-1-2-1-3-1_subentry_sidequest-2-1-1-1-1-1-2-1-3-1-1_1_text_val.mp3",
            "sidequest-2-1-1-1-1-1-2-2-1-1-1": "audio/voiceover/en/deeper/ec8bab7d_ntry_sidequest-2-1-1-1-1-1-2-2-1-1_subentry_sidequest-2-1-1-1-1-1-2-2-1-1-1_1_text_val.mp3",
            "sidequest-2-1-1-1-1-1-2-2-1-1-1-2-1": "audio/voiceover/en/deeper/23c72fd2_subentry_sdqst-2-1-1-1-1-1-2-2-1-1-1-2_sbntry_sdqst-2-1-1-1-1-1-2-2-1-1-1-2-1_1_txt_vl.mp3",
            "sidequest-2-1-1-1-1-1-2-2-1-1-1-2-2": "audio/voiceover/en/deeper/5fa60a09_subentry_sdqst-2-1-1-1-1-1-2-2-1-1-1-2_sbntry_sdqst-2-1-1-1-1-1-2-2-1-1-1-2-2_1_txt_vl.mp3",
            "sidequest-3-1-1-1-1-1-1-1-1": "audio/voiceover/en/deeper/fd5685d9_1_subentry_sidequest-3-1-1-1-1-1-1-1_subentry_sidequest-3-1-1-1-1-1-1-1-1_1_text_value.mp3",
            "sidequest-3-1-1-1-1-1-1-2": "audio/voiceover/en/deeper/subentry_sidequest-3-1-1-1-1-1-1_subentry_sidequest-3-1-1-1-1-1-1-2_1_text_value.mp3",
            "sidequest-3-1-1-1-1-1-1-2-1-2": "audio/voiceover/en/deeper/9d0d1a8d_bentry_sidequest-3-1-1-1-1-1-1-2-1_subentry_sidequest-3-1-1-1-1-1-1-2-1-2_1_text_value.mp3",
            "sidequest-3-1-1-1-1-1-1-2-1-1-2": "audio/voiceover/en/deeper/subentry_sidequest-3-1-1-1-1-1-1-2-1-1_subentry_sidequest-3-1-1-1-1-1-1-2-1-1-2_1_text_value.mp3",
            "tavern-1": "audio/voiceover/en/deeper/tavern_subentry_tavern-1_1_text_value.mp3",
            "tavern-2": "audio/voiceover/en/deeper/tavern_subentry_tavern-2_1_text_value.mp3",
            "tavern-2-1-1": "audio/voiceover/en/deeper/tavern_subentry_tavern-2_subentry_tavern-2-1_subentry_tavern-2-1-1_1_text_value.mp3",
            "tavern-3": "audio/voiceover/en/deeper/tavern_subentry_tavern-3_1_text_value.mp3",
            "trading-1": "audio/voiceover/en/deeper/trading_subentry_trading-1_1_text_value.mp3",
            "trading-2": "audio/voiceover/en/deeper/trading_subentry_trading-2_1_text_value.mp3",
            "trading-2-1": "audio/voiceover/en/deeper/trading_subentry_trading-2_subentry_trading-2-1_1_text_value.mp3",
            "trading-2-2": "audio/voiceover/en/deeper/trading_subentry_trading-2_subentry_trading-2-2_1_text_value.mp3",
            "trading-3": "audio/voiceover/en/deeper/trading_subentry_trading-3_1_text_value.mp3",
            'sidequest-1-1-1-1-1-1-1-1-2-1-1-1-1-2': 'audio/voiceover/en/deeper/sbntry_sdqst-1-1-1-1-1-1-1-1-2-1-1-1-1-2_1_txt_vl.mp3',
            '3-1': 'audio/voiceover/en/deeper/3_subentry_3-1_1_text_value.mp3',
            '3-page45-1': 'audio/voiceover/en/deeper/3_subentry_3-page45-1_1_text_value.mp3',
            '75-1': 'audio/voiceover/en/deeper/75_subentry_75-1_1_text_value.mp3',
            '1900-1': 'audio/voiceover/en/deeper/1900_subentry_1900-1_1_text_value.mp3',
            '1910-1': 'audio/voiceover/en/deeper/1910_subentry_1910-1_1_text_value.mp3',
            '4702-2': 'audio/voiceover/en/deeper/4702_subentry_4702-2_1_text_value.mp3',
            'law-1-1': 'audio/voiceover/en/deeper/law_subentry_law-1_subentry_law-1-1_1_text_value.mp3',
            'sidequest-1-1-1-1-1-1-1-1-2-1-1-1-2': 'audio/voiceover/en/deeper/sbntry_sdqst-1-1-1-1-1-1-1-1-2-1-1112_1_txt_vl.mp3',
        }
    }
};
