import { EntryDisplay } from "../screens/EntryDisplay/EntryDisplay";
import "./TutortialDisplay.scss";
import React from "react";
import { ADD_TO_QUEUE_FRONT, AFTER_LEAVING_QUEST_ENTRY, DONT_SHOW_UNLESS_ALL_SEEN, ON_QUEST_ENTRY_LOAD, PRIORITY_RETURNING, SHOW_EVEN_REPEAT, SKIP_AT_END_OF_TUTORIAL, SKIP_THIS_IF_WE_SAW_THAT } from "./tutorial";
import { str } from "../../Scenarios";
import { IMAGES } from "../../../image-data";
import { setSeenTutorial } from "../../../utils";

export class TutorialDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
      /*      tutorialEntry: this.props.activeScenario.getEntry(
                "tutorial-entry", 
                this.props.manager.historyManager, 
                "0", "", 
                "", 
                null
            ), */
            minimized: false,
            considering_close: false,
            reloaded: false,
            ending: false,
        }
        this.queue = [];
    }

    componentDidMount() {
        this.considerStart();
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.considerStart()) return;
        if(!this.state.reloaded && this.hasSeenIndex("tutorial-start") && (!this.props.entry || !this.props.entry.index.includes("setup"))) {
            this.setState({reloaded: true});
            for(let one_return of PRIORITY_RETURNING) {
                if(this.hasSeenIndex(one_return[0])) {
                    this.addToQueue(one_return);
                    this.goQueue(true);
                    break;
                }
            }
        }
        //Left an entry
        if(!this.props.backwards && prevProps.entry && (!this.props.entry || this.props.entry.index !== prevProps.entry.index)) {
            if(prevProps.entry.index in AFTER_LEAVING_QUEST_ENTRY) {
                if(AFTER_LEAVING_QUEST_ENTRY[prevProps.entry.index] === null) {
                    this.props.setTutorialEntry(null);
                    return;            
                } else {
                    this.addToQueue(AFTER_LEAVING_QUEST_ENTRY[prevProps.entry.index]);
                    this.goQueue();
                    return;
                }
            }
        }
        //Left a follower entry
        if(!this.props.backwards && prevProps.followerEntry && !this.props.followerEntry) {
            if(prevProps.followerEntry.index in AFTER_LEAVING_QUEST_ENTRY) {
                if(AFTER_LEAVING_QUEST_ENTRY[prevProps.followerEntry.index] === null) {
                    this.props.setTutorialEntry(null);
                    return;            
                } else {
                    this.addToQueue(AFTER_LEAVING_QUEST_ENTRY[prevProps.followerEntry.index]);
                    this.goQueue();
                    return;
                }
            }
        }
        //Entered an entry
        if(!this.props.backwards && this.props.entry && (!prevProps.entry || this.props.entry.index !== prevProps.entry.index)) {
            if(this.props.entry.index in ON_QUEST_ENTRY_LOAD) {
                if(ON_QUEST_ENTRY_LOAD[this.props.entry.index] === null) {
                    this.props.setTutorialEntry(null);
                    return;
                }
                this.addToQueue(ON_QUEST_ENTRY_LOAD[this.props.entry.index]);
                this.goQueue();
                return;
            } else {
                this.goQueue();
                return;
            }
        }
        //showing new tutorial entry
        if(!this.props.backwards && this.props.tutorialEntry && (!prevProps.tutorialEntry || prevProps.tutorialEntry.index !== this.props.tutorialEntry.index)) {
            let entr = document.querySelector(".tutorial-entry");
            entr.scrollTop = 0;
            if(this.props.tutorialEntry.index==="tutorial-ending") {
                this.setState({ending: true});
            }
            if(this.props.tutorialEntry.index in ADD_TO_QUEUE_FRONT) {
                this.addToQueue(ADD_TO_QUEUE_FRONT[this.props.tutorialEntry.index]);
                return;
            }
        }
        //come back from a follower entry
        if(!this.props.backwards && !this.props.followerEntry && prevProps.followerEntry) {
            this.goQueue();
            return;
        }
    }

    goQueue(even_repeat=false) {
        if(this.queue.length) {
            let proposed = this.queue.shift();
            if(
                (SHOW_EVEN_REPEAT.includes(proposed) && !this.state.ending)
                || !this.hasSeenIndex(proposed, (this.state.ending ? 1 : 2))
                || even_repeat
            ) {
                let prereqs_met = true;
                if(proposed in DONT_SHOW_UNLESS_ALL_SEEN && !this.state.ending) {
                    for(let one_prereq of DONT_SHOW_UNLESS_ALL_SEEN[proposed]) {
                        if(!this.hasSeenIndex(one_prereq)) {
                            prereqs_met = false;
                            break;
                        }
                    }
                }
                if(prereqs_met) {
                    this.goToEntry(proposed);
                    return;
                }
            } else {
                if(proposed in ADD_TO_QUEUE_FRONT) {
                    this.addToQueue(ADD_TO_QUEUE_FRONT[proposed]);
                }
            }
            if(this.queue.length) {
                this.goQueue();
            } else {
                this.props.setTutorialEntry(null);
            }
        } else {
            this.props.setTutorialEntry(null);
        }
    }

    addToQueue(items) {
        let f_items = items.filter(i=>!this.queue.includes(i) || i==="tutorial-spend-time");
        if(this.state.ending) {
            f_items = f_items.filter(i=>!SKIP_AT_END_OF_TUTORIAL.includes(i));
        }
        this.queue = [...f_items, ...this.queue];
    }

    considerStart() {
        if(
            !this.props.tutorialEntry
            && this.props.activeScenario.scenarioInfo.code==="wizards"
            && this.props.entry
            && this.props.entry.index==="setup"
            && !this.hasSeenIndex("tutorial-start")
        ) {
            this.goToEntry("tutorial-start");
            setSeenTutorial(true);
            this.setState({reloaded: true});
            return true;
        }
        return false;
    }

    considerClose() {
        this.setState({
            minimized: false,
            considering_close: true,
        })
    }

    goBack() {
        this.setState({considering_close: false});
    }

    hasSeenIndex(index, minimum=1) {
        if(index in SKIP_THIS_IF_WE_SAW_THAT) {
            return this.props.manager.historyManager.seenIndex(SKIP_THIS_IF_WE_SAW_THAT[index], minimum);
        }
        return this.props.manager.historyManager.seenIndex(index, minimum);
    }

    goToEntry(val) {
        this.setState({minimized: false});
        if(val==="tutorial-start-1") { //affirmation that you're closing it
            this.setState({considering_close: false});
        }
        if(val==="queue") {
            this.goQueue();
        } else {
            let newEntry = this.props.activeScenario.getEntry(
                val, 
                this.props.manager.historyManager, 
                "0", "", 
                "", 
                this.props.tutorialEntry
            );
            this.props.setTutorialEntry(newEntry);
            this.props.manager.historyManager.addEntry(newEntry);
        }
    }

    endEntry() {
        if(!this.props.tutorialEntry) {
            this.goBack();
            return;
        }
        if(this.state.considering_close) {
            this.setState({considering_close: false});
            return;
        }
        switch(this.props.tutorialEntry.index) {
            case "tutorial-start-1": //said no to turorial
                this.props.endTutorial();
                break;
            case "tutorial-start-2": //clicked whoops go back on ending tutorial
                this.setState({considering_close: false});
                break;
            case "tutorial-close": //cancelled closing
                this.goBack();
                break;
            case "tutorial-the-end": //its over
                this.props.endTutorial();
                break;
            default:
                break;
        }
    }

    render() {
        if(!this.props.tutorialEntry && !this.state.considering_close) {
            return <div className="tutorial">
                <div className="tutorial-header">
                    <div 
                        className="tutorial-header-min" 
                    >
                    </div>
                    <div className="tutorial-header-title cheddar">{str["Tutorial"]}</div>
                    <div className="tutorial-header-close" onClick={()=>this.considerClose()}>
                        <img src={IMAGES.interface.tutorial_x} alt="close" />
                    </div>
                </div>
            </div>;
        }

        let da_entry = this.state.considering_close ? this.props.activeScenario.getEntry(
            "tutorial-close", 
            this.props.manager.historyManager, 
            "0", "", 
            "", 
            null
        ) : this.props.tutorialEntry;

        let blocker = 
            da_entry && da_entry.content.filter(c=>c.type==="option").length>0 
            && (
                (!this.props.entry && da_entry.index!=="tutorial-attack-timer")
                || (this.props.entry && this.props.entry.index.includes("setup"))
                || da_entry.content.filter(c=>c.type==="image").length>0
            );

        if(this.props.followerEntry) {
            return null;
        }

        return <>
            <div className="tutorial">
            <div className="tutorial-header">
                <div 
                    className="tutorial-header-min" 
                    onClick={()=>this.setState({minimized: !this.state.minimized})}
                >
                    <img src={this.state.minimized ? IMAGES.interface.tutorial_caret : IMAGES.interface.tutorial_v} alt="minimize" />
                </div>
                <div className="tutorial-header-title cheddar">{str["Tutorial"]}</div>
                <div className="tutorial-header-close" onClick={()=>this.considerClose()}>
                    <img src={IMAGES.interface.tutorial_x} alt="close" />
                </div>
            </div>
            {this.state.minimized ? null :
                <div className={"tutorial-entry"+(blocker ? " can-block" : "")}>
                    <EntryDisplay
                        istutorial={true}
                        key={da_entry.index}
                        tutorial={true}
                        goBack={() => this.goBack()}
                        entry={da_entry}
                        entryRedirect={(newValue) => this.goToEntry(newValue)}
                        endEntry={() => this.endEntry()}
                        scenario={this.props.activeScenario}
                        entryLoader={(value) => this.goToEntry(value)}
                        formError={null}
                        controlAudio={(track)=>{}}
                        setDanger={(d)=>{}}
                        danger={null}
                        players={this.props.players}
                        setPage={(p)=>{}}
                        showTerm={(t)=>this.props.showTerm(t)}
                        textSize={this.props.textSize}
                        setArrows={this.props.setArrows}
                    />
                </div>
            }
        </div>
        </>
    }
}