import React from "react";

export class SplashScreen extends React.Component {
    constructor(props) {
        super(props);
        this.loaded = false;
        this.timesup = false;
        this.loaded = true;
        if(this.timesup) {
            this.props.onLoaded();
        }
        setTimeout(() => {
            this.timesup = true;
            if(this.loaded) {
                this.props.onLoaded();
            }
        }, 500);
    }

    render() {
        return(<div>splash!</div>);
    }
}