import {str} from "../../Scenarios";
import React from "react";
import {version} from "../../../version";
import {IMAGES} from "../../../image-data";
import "./MainMenu.scss";
import {WideButton, BUTTON_STYLES} from "../WideButton";
import { BACKEND_PAGES, backendPromise, getBackendUrl } from "../../../api";

export class MainMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: null,
        };
    }

    componentDidMount() {
        backendPromise(getBackendUrl(BACKEND_PAGES.APP_MESSAGES)).then(d=>{
            if(d.ok) {
                if(d.messages.length>0) {
                    this.setState({message: d.messages[0].message});
                }
            } else {
                this.setState({message: null});
            }
        });
    }

    render() {
        return(
            <>
                <img src={IMAGES.interface.logo} alt="Freelancers: A Crossoards Game" className="freelancers-logo" />
                <WideButton style={BUTTON_STYLES.TITLE} onClick={() => this.props.clickedPlay()}>
                    <span className="start-campaign-text">{str["Start Campaign"]}</span>
                </WideButton>
                <WideButton style={BUTTON_STYLES.TITLE} onClick={() => this.props.clickedContinue()}>
                    {str["Continue Campaign"]}
                </WideButton>
                { this.state.message &&
                    <div className="update-message" dangerouslySetInnerHTML={{__html: this.state.message}}>
                    </div>
                }
                <WideButton style={BUTTON_STYLES.SUBTITLE} onClick={() => this.props.clickedOptions()}>
                    {str.options}
                </WideButton>
                <WideButton style={BUTTON_STYLES.SUBTITLE} href="https://www.plaidhatgames.com/freelancers-crossroads-game-downloads/">
                    {str["Printables"]}
                </WideButton>
                <WideButton style={BUTTON_STYLES.SUBTITLE} onClick={() => this.props.clickedVariants()}>
                    {str["Variant Rules"]}
                </WideButton>
                <WideButton style={BUTTON_STYLES.SUBTITLE} href="https://www.plaidhatgames.com/freelancers-crossroads-game-downloads/">
                    {str["Desktop Download"]}
                </WideButton>
                <WideButton style={BUTTON_STYLES.SUBTITLE} onClick={() => this.props.clickedCredits()}>
                    {str["Credits"]}
                </WideButton>
                <footer>
                    <a href="https://www.plaidhatgames.com" className="studio-logo-container">
                        <img className="studio-logo" src={IMAGES["images/logos/phg.png"]} alt="Plaid Hat Games"/>
                    </a>
                    <p className="footnote">&#9400; 2023 Plaid Hat Games<br/>{str.version} {version}</p>
                    <div className="footer-gradient"></div>
                </footer>
            </>
        );
    }
}
