import React from "react";
import {str} from "../../Scenarios";
import {EntryForm} from "../EntryForm";
import {IMAGES} from "../../../image-data";
import "./PlayMenu.scss";
import { TextHr } from "../TextHr";
import { motion, AnimatePresence } from "framer-motion";
import { BUTTON_STYLES, WideButton } from "../WideButton";
import { FOLLOWERS } from "../../../utils";

const MAP_ICON_NAMES = {
    camp: "Camp",
    chaos: "Chaos Temple",
    crossroad: "Crossroads",
    duel: "Duel",
    special_duel: "Duel",
    "crossroad_special": "Crossroads",
    fortune: "Fortune Teller",
    law: "Lawful Shrine",
    omen: "Threat",
    sidequest: "Dungeon",
    tavern: "Tavern",
    trading: "Trading Post",
    treasure: "Treasure Golem",
    event_81: "Event",
    event_82: "Event",
    event_83: "Event",
    event_84: "Event",
    event_85: "Event",
    event_86: "Event",
    event_87: "Event",
    event_88: "Event",
    event_90: "Event",
    location_81: "Location",
    location_82: "Location",
    location_83: "Location",
    location_84: "Location",
    location_85: "Location",
    location_86: "Location",
    location_87: "Location",
    location_88: "Location",
    location_89: "Location",
}

const PAGE_NAMES = {
    "0": "Trave Map",
    "3": "Hub Slums",
    "5": "Driftlands",
    "7": "Hub Gilded District",
    "9": "River Village",
    "11": "Hub Entertainment District",
    "13": "Cemetery",
    "15": "Library",
    "17": "Lonely Cottage",
    "19": "Treasure Golem",
    "21": "Ambushed!",
    "23": "Corrupted Ferry",
    "25": "Opera House",
    "27": "Attack!",
    "29": "????",
    "31": "The Lich",
    "33": "Faerie Ridge",
    "35": "Hub Marketplace",
    "37": "The Wastes",
    "39": "The Highways",
    "41": "Dark Corridors",
    "43": "Paz, The Displayer",
    "45": "Mushroom Village",
    "47": "Trap Room",
    "49": "The Last Dragon",
}

const PAGE_ENTRIES = {
    "3": [301, 302, 303, 304, 305, 306, 307],
    "5": [501, 502, 503, 504, 505, 506, 507, 508, 509, 510],
    "7": [701, 702, 703, 704, 705, 706, 707, 708, 709],
    "9": [901, 902, 903, 904, 905, 906, 907, 908, 909, 910, 911, 912],
    "11": [1101, 1102, 1103, 1104],
    "13": [1301, 1302, 1303, 1304, 1305, 1306, 1307, 1308, 1309, 1310],
    "15": [1501, 1502, 1503, 1504, 1505, 1506, 1507, 1508, 1509, 1510, 1511],
    "17": [1701, 1702, 1703, 1704, 1705, 1706, 1707, 1708, 1709, 1710, 1711],
    "19": [1901, 1902, 1903, 1904, 1905, 1906, 1907, 1908, 1909, 1910],
    "21": [2101, 2102, 2103, 2104, 2105, 2106],
    "23": [2301, 2302, 2303, 2304, 2305, 2306, 2307, 2308],
    "25": [2501, 2502, 2503, 2504, 2505, 2506, 2507, 2508, 2509, 2510, 2511],
    "27": [2701, 2702, 2703, 2704, 2705, 2706, 2707, 2708, 2709, 2710, 2711, 2712],
    "29": [2901, 2902, 2903, 2904, 2905, 2906, 2907, 2908],
    "31": [3101, 3102, 3103, 3104, 3105],
    "33": [3301, 3302, 3303, 3304, 3305, 3306, 3307, 3308, 3309, 3310, 3311, 3312, 3313, 3314, 3315, 3316],
    "35": [3501, 3502, 3503, 3504, 3505, 3506, 3507, 3508, 3509],
    "37": [3701, 3702, 3703, 3704, 3705, 3706],
    "39": [3901, 3902, 3903, 3904, 3905, 3906, 3907, 3908, 3909, 3910, 3911, 3912, 3913],
    "41": [4101, 4102, 4103, 4104, 4105, 4106, 4107, 4108, 4109, 4110, 4111, 4112],
    "43": [4301, 4302, 4303, 4304, 4305, 4306, 4307, 4308, 4309, 4310, 4311],
    "45": [4501, 4502, 4503, 4504, 4505, 4506, 4507, 4508, 4509],
    "47": [4701, 4702, 4703, 4704, 4705, 4706, 4707, 4708],
    "49": [4901, 4902, 4903, 4904, 4905, 4906],
}


export class PlayMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page_selecting: false,
            entry_input: "",
            show_followers: false,
        }
    }

    entryLoader(value) {
        this.props.entryLoader(value);
        this.setState({entry_input: ""});
    }

    goEndTurn() {
        let current_index = null;
        for(let [index, player] of this.props.players.entries()) {
            if(player.current) {
                current_index = index;
                break;
            }
        }
        if(current_index===null) current_index = 0;
        let u_players = JSON.parse(JSON.stringify(this.props.players));
        if(u_players.length>1) {
            current_index++;
            if(current_index===u_players.length) current_index = 0;
        }
        for(let [index, player] of u_players.entries()) {
            if(index===current_index) {
                player.current = true;
            } else {
                player.current = false;
            }
        }
        this.props.setPlayers(u_players);
        this.entryLoader("danger");
    }

    goSkipTurn() {
        let current_index = null;
        for(let [index, player] of this.props.players.entries()) {
            if(player.current) {
                current_index = index;
                break;
            }
        }
        if(current_index===null) current_index = 0;
        let u_players = JSON.parse(JSON.stringify(this.props.players));
        if(u_players.length>1) {
            current_index++;
            if(current_index===u_players.length) current_index = 0;
        }
        for(let [index, player] of u_players.entries()) {
            if(index===current_index) {
                player.current = true;
            } else {
                player.current = false;
            }
        }
        this.props.setPlayers(u_players);
    }

    render() {
        return(<>
            <h2 className="upper cheddar page-selector" onClick={()=>this.setState({page_selecting: !this.state.page_selecting})}>
                <img src={this.state.page_selecting ? IMAGES.interface.up_arrow : IMAGES.interface.down_arrow} alt="choose page" className="dropdown" />
                <span>
                    {(this.props.page==="" ? str["Book Closed"] : str.formatString(str["Page {0}"], this.props.page)+" - "+PAGE_NAMES[this.props.page])}    
                </span>
            </h2>
            <div className="play-body with-top">
                {this.state.page_selecting ? 
                    <div className="page-select cheddar">
                        {this.props.scenario.scenarioInfo.pages.map((p, key)=>(
                        <div onClick={()=>{this.props.setPage(p);this.setState({page_selecting: false})}} className="one-page" key={key} value={p}>{p==="" ? str["Book Closed"] : str.formatString(str["Page {0}"], p)}</div>
                    ))}
                    </div>
                : <div className="book-header">
                    <img src={this.props.page==="" ? IMAGES.map_headers[this.props.scenario.scenarioInfo.map] : IMAGES.book_pages[this.props.page]} alt="book" className={this.props.page==="" ? "map-image" : "book-image"} />
                    {this.props.page==="" ? null : 
                        <button className="scenario-option toppy start-timer" onClick={() => this.props.timerLoader()}>
                            <img alt="timer" src={IMAGES.interface.timer} />
                            <span className="cheddar">{str["Start Timer"]}</span>
                        </button>
                    }
                </div> }
            </div>
            {this.props.page==="" ? <>
                <div className="stop-here">{str["Need to stop here?"]} - <span onClick={()=>this.entryLoader("save_quit")} className="stop-here-save">{str["Save & Quit"]}</span></div>
                <hr />
                <div className="map-icons">
                    {this.props.scenario.scenarioInfo.map_icons.map((k, i)=>
                        <button className={"map-icon-button cheddar map-icon-button-"+MAP_ICON_NAMES[k].toLowerCase()} key={i}>
                            <img src={IMAGES.map_icons[k]} 
                                alt={k} 
                                onClick={()=>{
                                    if(k.includes("location") || k.includes("event")) {
                                        this.setState({entry_input: parseInt(k.split("_")[1])});
                                    } else {
                                        if(k==="special_duel") {
                                            this.entryLoader("special duel");
                                        } else {
                                            this.entryLoader(k)};
                                        }
                                    }
                                } 
                            />
                            <span>{str[MAP_ICON_NAMES[k]]}</span>
                        </button>
                    )}
                </div>
            </> : null }
            <hr />
            <EntryForm
                entrySubmitted={(entryNumber) => this.entryLoader(entryNumber)}
                formError={this.props.formError}
                label={str.formInstructions}
                entry_input={this.state.entry_input}
                setInput={(v)=>this.setState({entry_input: v})}
            />
            <TextHr>{str["Quickfill Entries"]}</TextHr>
            <div className="quickfill">
                <img onClick={()=>this.setState({show_followers: true,})} className="quickfill-followers" alt="Follower tokens" src={IMAGES.interface.follower_button} />
                {this.props.page==="" ? null : <div className="quickfill-entries">
                    {PAGE_ENTRIES[this.props.page].map((e, i)=>
                        <button 
                            className="quickfill-entry cheddar" 
                            key={i} 
                            onClick={()=>this.setState({ entry_input: e, })}
                        >{e}</button>
                    )}
                </div> }
            </div>
            <hr />
            { this.props.page==="" ?
                <WideButton style={BUTTON_STYLES.SMALL_EXTENDED} onClick={()=>this.entryLoader("save_quit")}>{str["Save & Quit"]}</WideButton>
            : null }
            <div className="scenario-option-container">
                <button className="scenario-option" onClick={() => this.props.historyLoader()}>
                    <img alt="history" src={IMAGES.interface.history} />
                    <span className="cheddar">{str.viewHistory}</span>
                </button>
                <button className="scenario-option option-species" onClick={() => this.props.goSetPlayers()}>
                    <img alt="species" src={IMAGES.interface.species} />
                    <span className="cheddar">{str["Set Species/Jobs"]}</span>
                </button>
                <button className="scenario-option option-glossary" onClick={() => this.props.goToGlossary(null)}>
                    <img alt="rules glossary" src={IMAGES.interface.glossary} />
                    <span className="cheddar">{str["Rules Glossary"]}</span>
                </button>
            </div>
            <AnimatePresence>
                {this.state.show_followers ? <>
                    <motion.div initial={{opacity: 0}} animate={{opacity: 1,}} exit={{opacity: 0}} key={"overlay"} className="follower-overlay" onClick={()=>this.setState({show_followers: false})}></motion.div>
                    <motion.div initial={{opacity: 0}} animate={{opacity: 1,}} exit={{opacity: 0}} key={"follower-display"} className="follower-display">
                        {Object.keys(FOLLOWERS).map((k, i)=>
                            <button className="follower-button" key={i} onClick={
                                ()=>this.setState({entry_input: parseInt(FOLLOWERS[k]), show_followers: false})
                            }>
                                <img className="follower-token" alt={k} src={IMAGES.followers[k]} />
                                <div className="follower-number cheddar">{FOLLOWERS[k]}</div>
                            </button>
                        )}
                        <img src={IMAGES.interface.follower_x} alt="close" className="follower-close" onClick={()=>this.setState({show_followers: false})} />
                    </motion.div>
                </> : null}
            </AnimatePresence>
        </>);
    }
}
