import React from "react";
import {str} from "../../Scenarios";
import {
    getTimerLength,
    setTimerLength,
    getSoundEffectVolume,
    setSoundEffectVolume,
    getBackgroundVolume,
    setBackgroundVolume,
    getAudioSpeedSetting,
    setAudioSpeedSetting,
} from "../../../utils";
import "./OptionsScreen.scss"
import { BUTTON_STYLES, WideButton } from "../WideButton";

export class OptionsScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "timerLength": getTimerLength(),
            "soundEffectVolume": getSoundEffectVolume(),
            "backgroundVolume": getBackgroundVolume(),
            audioSpeed: getAudioSpeedSetting(),
        };
    }


    onTimerLengthChange(value) {
        let new_val = setTimerLength(value);
        this.setState({timerLength: new_val});
    }

    onTextSizeChange(value) {
        this.props.setTextSize(value);
    }

    onSoundEffectVolumeChange(value) {
        let new_val = setSoundEffectVolume(value);
        this.setState({soundEffectVolume: new_val});
    }

    onBackgroundVolumeChange(value) {
        let new_val = setBackgroundVolume(value);
        this.setState({backgroundVolume: new_val});
        this.props.adjustedVolume(new_val);
    }

    render() {
        return(<>
            <h2 className="upper cheddar page-selector">{str.options}</h2>
            <div className={"options-body with-top text-size-"+this.props.textSize || 4}>
                <h3>{str.language}</h3>
                <WideButton style={BUTTON_STYLES.ENTRY} onClick={() => this.props.clickedLanguage()} >
                    {str.changeLanguage}
                </WideButton>
                <h3>{str.textSize}</h3>
                <select className="poppins" value={this.props.textSize} onChange={event => this.onTextSizeChange(event.target.value)}>
                    <option value={4}>{str["Normal"]}</option>
                    <option value={5}>{str["Large"]}</option>
                    <option value={6}>{str["Extra Large"]}</option>
                </select>
                
                <h3>{str["Start tutorial with new W&W quest?"]}</h3>
                <select
                    value={this.props.tutorial}
                    onChange={event =>{
                        this.props.setTutorial(parseInt(event.target.value));
                    }}
                >
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                </select>
                <h3>{str["Audio Speed"]}</h3>
                <select 
                    value={this.state.audioSpeed}
                    onChange={(e)=>this.setState({audioSpeed: setAudioSpeedSetting(e.target.value)})}
                >
                    <option value={.5}>0.5</option>
                    <option value={.75}>0.75</option>
                    <option value={1}>1</option>
                    <option value={1.25}>1.25</option>
                    <option value={1.5}>1.5</option>
                    <option value={1.75}>1.75</option>
                    <option value={2}>2</option>
                </select>
                <h3>{str.timerLength}</h3>
                <select 
                    value={this.state.timerLength}
                    onChange={event=>this.onTimerLengthChange(event.target.value)}
                    id="timer-length-input"
                >
                    <option value={30}>30</option>
                    <option value={45}>45</option>
                    <option value={60}>60</option>
                </select>
                <h3>{str.soundEffectVolume}</h3>
                <div>{this.state.soundEffectVolume*10}</div>
                <input
                    type="range"
                    min="0"
                    max="10"
                    value={this.state.soundEffectVolume*10}
                    id="sound-effect-volume"
                    onChange={event => this.onSoundEffectVolumeChange(event.target.value/10)}
                />
            </div>
        </>);
    }
}
