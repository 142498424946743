import terms from './glossary_terms.json';
import {filterText} from "../../../../utils";
import {str} from "../../../Scenarios";
import React from "react";

export function getGlossaryResults(search) {
    let included = []
    let results = terms.filter(t=>{
        if(search==="") return true;
        if(included.includes(str[t.term])) return false;
        included.push(str[t.term]);
        if(str[t.term] && str[t.term].toLowerCase().includes(search.toLowerCase())) return true;
        if(str.getLanguage()==="en") {
            for (let one_alt of t.alt) {
                if (one_alt.toLowerCase().includes(search.toLowerCase())) {
                    return true;
                }
            }
        }
        return false;
    });

    return results.map((t,i)=>t.term).sort();
}

export function getEnglishGlossaryTerm(term) {
    if(str.getLanguage()==="en") return term;
    for(let [i, v] of Object.entries(str)) {
        if(term.toLowerCase && v.toLowerCase && term.toLowerCase()===v.toLowerCase()) {
            return i;
        }
    }
    return "";
}

export function getGlossaryTerm(term) {
    for(let one_term of terms) {
        if (
            one_term.term.toLowerCase() === term.toLowerCase()
            ||
            one_term.alt.some(a => a.toLowerCase() === term.toLowerCase())
            ||
            (
                str.getLanguage()==="en"
                && term.charAt(term.length - 1) === "s"
                &&
                (
                    one_term.term.toLowerCase() === term.substr(0, term.length - 1).toLowerCase()
                    ||
                    one_term.alt.some(a => a.toLowerCase() === term.substr(0, term.length - 1).toLowerCase())
                )
            )
        ) {
            return one_term;
        }
    }
    return null;
}

export function areTermsSame(t1, t2) {
    t1 = t1.toLowerCase();
    t2 = t2.toLowerCase();
    if(t1===t2) return true;
    let t1d = getGlossaryTerm(t1);
    if(!t1d) return false;
    let t2d = getGlossaryTerm(t2);
    if(!t2d) return false;
    return t1d.term===t2d.term;
}

export function sortTerms(t1, t2) {
    let tt1 = str[t1] || t1;
    let tt2 = str[t2] || t2;
    return tt1.localeCompare(tt2);
}

export function capitalizeWords(t) {
    return t.split(" ").map((word) => {
        return word[0].toUpperCase() + word.substring(1);
    }).join(" ");
}

export function getGlossaryTermContent(term, showGlossaryTerm) {
    for(let one_term of terms) {
        if(
            one_term.term.toLowerCase()===term.toLowerCase()
            ||
            (one_term.alt.some(a=>a.toLowerCase()===term.toLowerCase()))
            ||
            (
                str.getLanguage()==="en"
                && term.charAt(term.length-1)==="s"
                &&
                (
                    one_term.term.toLowerCase()===term.substr(0, term.length-1).toLowerCase()
                    ||
                    one_term.alt.some(a=>a.toLowerCase()===term.substr(0,term.length-1).toLowerCase())
                )
            )
        ) {
            let related = one_term.related.filter(r=>terms.some(
                s=>(s.term.toLowerCase()===r.toLowerCase() || s.alt.some(a=>a.toLowerCase()===r.toLowerCase()))
            ));
            return (<div className="explanation">
                <div className="definition">
                    {filterText(str[one_term.term+"|definition"], null, false, "", showGlossaryTerm, one_term.term)}
                </div>
                {related.length>0
                    ? <div className="see-also">
                        <span>{str["See also:"]} </span>
                        {related.map((r,i)=>
                            (<span key={'rel_'+i}>
                                <span className="link" onClick={()=>showGlossaryTerm(r)}>
                                    {str[getGlossaryTerm(r).term]}
                                </span>
                                {i<related.length-1 ? <span>, </span> : null}
                            </span>)
                        )}
                    </div>
                    : null
                }
            </div>);
        }
    }
    return null;
}