import React from 'react';
import './getGlossaryContent.scss';
import {getGlossaryResults, sortTerms} from "./logic";
import {str} from "../../../Scenarios";

export default class GlossaryContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            results: getGlossaryResults(""),
        };
    }

    updateSearch(val) {
        this.setState({
            search: val,
            results: getGlossaryResults(val),
        });
    }

    render() {
        let props = this.props;
        return <>
            <h2 className="upper cheddar">{str["Rules Glossary"]}</h2>
            <div className="glossary-body with-top">
                <input
                    type="text"
                    className="glossary-search"
                    value={this.state.search}
                    onChange={(e)=>this.updateSearch(e.target.value)}
                    maxLength={50}
                    placeholder={str["Enter search term"]}
                />
                <div className="glossary-results">
                    {this.state.results.sort(sortTerms).map((r,i)=>(
                        <div className="result" key={i} onClick={()=>props.showGlossaryTerm(r)}>
                            {str[r] || r}
                        </div>
                    ))}
                </div>
            </div>
        </>;
    }
}