import React from "react";
import {Howl} from 'howler';
import {getAudioSpeedSetting, getSoundEffectVolume} from "../../utils";
import "./AudioPlayer.scss";

export class AudioPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            playing: false,
            at_start: true,
            at_end: false,
            play_id: null,
            error: null,
            able_to_play: true,
        };
        this.player = new Howl({
            src: this.props.track,
            volume: getSoundEffectVolume(),
            loop: false,
            autoplay: false,
            html5: true,
            onloaderror: (id, message) => this.loadError(id, message),
            rate: getAudioSpeedSetting(),
        });
        this.player.on("end", ()=>this.onPlayOver());
    }

    loadError(id, message) {
        this.setState({
            error: "Unable to download audio.",
            able_to_play: false,
        });
    }

    componentWillUnmount() {
        if(this.player) {
            this.player.stop();
        }
    }

    clickedPlayPause() {
        if(this.state.playing) {
            if(this.state.play_id) {
                this.player.pause(this.state.play_id);
            } else {
                this.player.pause();
            }
            this.setState({playing: false,});
        } else {
            if(this.state.able_to_play) {
                if(this.state.play_id) {
                    this.player.play(this.state.play_id);
                    this.setState({
                        playing: true,
                        at_start: false,
                        at_end: false,
                    });
                } else {
                    this.setState({
                        play_id: this.player.play(),
                        playing: true,
                        at_start: false,
                        at_end: false,
                    });
                }
            }
        }
    }

    clickedBackwards() {
        if(!this.state.at_start && this.state.play_id) {
            let backwardTo = this.player.pause(this.state.play_id)._sounds[0]._seek - 5;
            if(backwardTo < 0) { backwardTo = 0; }
            this.player.seek(backwardTo, this.state.play_id);
            if(this.state.playing) {
                this.player.play(this.state.play_id);
            } else {
                this.setState({at_start: true});
            }
        }
    }

    clickedForward() {
        if(!this.state.at_end) {
            let forwardTo = this.player.pause(this.state.play_id)._sounds[0]._seek + 5;
            let duration = this.player.duration(this.state.play_id);
            if(forwardTo > duration) { forwardTo = duration; }
            this.player.seek(forwardTo, this.state.play_id);
            if(this.state.playing) {
                this.player.play(this.state.play_id);
            }
        }
    }

    onPlayOver() {
        this.setState({
            playing: false,
            at_end: false,
            at_start: true,
        });
    }

    render() {
        let play_pause_ui = "active play";
        if(this.state.playing) {
            play_pause_ui = "active pause";
        } else {
            if(!this.state.able_to_play) {
                play_pause_ui = "play";
            }
        }
        let rw_active = "";
        if(!this.state.at_start) {
            rw_active = " active";
        }
        let ff_active = "";
        if(!this.state.at_end) {
            ff_active = " active";
        }
        if(this.props.followerEntry) return (
            <div className="follower-first">
                <div className="audio-player-container">
                    <div className="audio-player">
                        <div className={"ui rw "+rw_active} onClick={()=>this.clickedBackwards()}>
                        </div>
                        <div className={"ui pp "+play_pause_ui} onClick={()=>this.clickedPlayPause()}>
                        </div>
                        <div className={"ui ff"+ff_active} onClick={()=>this.clickedForward()}>
                        </div>
                    </div>
                    <div className="audio-player-error">{this.state.error}</div>
                </div>
            </div>
        );
        return(
            <div className="audio-player-container">
                <div className="audio-player">
                    <div className={"ui rw "+rw_active} onClick={()=>this.clickedBackwards()}>
                    </div>
                    <div className={"ui pp "+play_pause_ui} onClick={()=>this.clickedPlayPause()}>
                    </div>
                    <div className={"ui ff"+ff_active} onClick={()=>this.clickedForward()}>
                    </div>
                </div>
                <div className="audio-player-error">{this.state.error}</div>
            </div>
        );
    }
}
