import React from "react";
import {BackButton} from "../BackButton";
import {SettingsButton} from "../SettingsButton";


export class UploadScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            primary: null,
            secondary: null,
        }
    }

    handlePrimaryUpload(oFREvent) {
        let fr = new FileReader();
        fr.readAsText(document.getElementById("fileUpload").files[0]);
        fr.onload = (e) => this.finishPrimaryUpload(e);
    }

    finishPrimaryUpload(oFREvent) {
        this.setState({primary: JSON.parse(oFREvent.target.result)});
    }

    handleSecondaryUpload(oFREvent) {
        let fr = new FileReader();
        fr.readAsText(document.getElementById("secondaryUpload").files[0]);
        fr.onload = (e) => this.finishSecondaryUpload(e);
    }

    finishSecondaryUpload(oFREvent) {
        this.setState({secondary: JSON.parse(oFREvent.target.result)});
    }

    proceed() {
        this.props.loadEntries(this.state.primary, this.state.secondary);
    }


    render() {
        return(<div className="screen check-tokens">
            <div className="top-runner">
                <div className="runner-title">Upload Data</div>
                <div className="runner-buttons">
                    <BackButton goBack={() => this.props.backDestination()}/>
                    <SettingsButton goSettings={() => this.props.goSettings()}/>
                </div>
            </div>
            <h2>Upload Entries</h2>
            <label className="button content-option"  htmlFor="fileUpload">Upload Primary Entries</label>
            <input type="file" id="fileUpload" className="show-for-sr" onChange={()=>this.handlePrimaryUpload()} />
            <label className="button content-option"  htmlFor="secondaryUpload">Upload Secondary Entries</label>
            <input type="file" id="secondaryUpload" className="show-for-sr" onChange={()=>this.handleSecondaryUpload()} />
            <button
                className="button content-option"
                onClick={()=>this.proceed()}
            >Ready</button>
        </div>);
    }
}
