import {str} from "../../Scenarios";
import React from "react";
import "./ContentScreens.scss";

export class CreditsScreen extends React.Component {
    render() {
        return <>
            <h2 className="cheddar">Freelancers: A Crossroads Game</h2>
            <p>This app requires the board game Freelancers. <a href="https://preorder.freelancersgame.com" target="_blank" rel="noopener noreferrer">Click here for more information about Freelancers and to pre-order.</a></p>
            <p><em>Freelancers: A Crossroads Game</em> is produced by Colby Dauch, Donald Shults, J. Arthur Ellis and Christopher R. Sabat.</p>
            <p>App by J. Arthur Ellis and Kendall Elmen</p>
            <p>Music by Otto Wlazlo, Brina Palencia, and Andrew Manson.</p>
            <p>Additional editing by Ray Clark</p>
            <p>Additional game credits can be found in the Freelancers rulebook.</p>
            <p><strong>Voice Cast</strong></p>
            <p>Narrator: Christopher R. Sabat</p>
            <p>Grunko, Son of Grung: SungWon Cho</p>
            <p>Schala: Bryn Apprill</p>
            <p>Cookie: Nazeeh Tarsha</p>
            <p>Pan Stringplucker: Brina Palencia</p>
            <p>CrypTech the Lich: Christopher Wehkamp</p>
            <p>Count Rizotto: Mike Smith</p>
            <p>Paz the Displayer: Anairis Quinones</p>
            <p>Veldimax the Great: Gary Furlong</p>
            <p>Additional Voices: Ricco Fajardo, Dawn Bennett, Michelle Rojas, Donald Shults, Niki Shults</p>
            <p>Audio Director and Producer: Donald Shults</p>
        </>
    }
}

export class VariantsScreen extends React.Component {
    render() {
        return <>
            <h2 className="cheddar">Game Variants</h2>
            <div>
                <a href="#solo2">Solo / 2-Player Rules</a>
                <br /><a href="#prologue">Prologue Quest</a>
                <br /><a href="#easy">Easy Mode</a>
                <br /><a href="#hard">Hard Mode</a>
            </div>
            <hr />
            <h2 className="cheddar" id="solo2">{str["Solo / 2-Player Rules"]}</h2>
            <p>Freelancers can be played as a solo or 2-player adventure! In the solo game, besides their own character, the player will have the help of 2 sidekick characters. In the 2-player game, each player will have 1 sidekick.</p>

            <h3>Sidekick Character Setup</h3>
            <p>When setting up, in addition to each player's character, create 2 sidekick characters. (For 2 players, each player sets up 1.)</p>
            <ol><li>For each sidekick, gather a species sheet, the associated standee, and their starting weapon. Do NOT gather a job sheet or influence token - these are not used for a sidekick character.</li>
            <li>Give each sidekick character a name and answer the question as usual, but cross out the story blanks section - it will not be used.</li>
            <li>Place only your full character's influence token on the influence track, on space 4 for a solo game, and spaces 3 and 4 for a 2-player game.</li></ol>

            <h3>About Sidekick Characters</h3>
            <p><strong>Shared Skills/XP/Titles: </strong>Sidekicks do not have their own skills, XP, titles, or influence - instead, whenever they gain skills, XP, or titles, your character gains them. Whenever they use skills, they use your skills. Whenever they gain or lose influence, move your marker on the track. You will often go over 8 titles, especially in a solo game.</p>
            <p><strong>Considered Players: </strong>Sidekick characters count toward the player count, and count as a player for most situations, except that you fully control them, making all decisions on their behalf. Whenever a player must be chosen, sidekick characters are eligible. When "all players" do something, the sidekick characters also do it. Sidekicks count as "another player" for the purpose of taking stress to re-roll skill checks and giving away followers.</p>
            <p><strong>Treasure and Tokens: </strong>Sidekick characters have their own gold, luck, and corruption. They have 4 camp actions, and can have up to 1 follower and 1 armor. Sidekicks can have up to 1 weapon and 1 trinket - you may place them over the crossed out story blanks for convenience.</p>
            <p><strong>Placing Standees: </strong>Since sidekicks don't have influence markers, when a player places their main character's standee during a round on a location book page, they also place their sidekick(s) at that time. If a sidekick is ever tied for choosing a player for a game effect, the players decide who to chose.</p>

            <h3>Influence Track</h3>
            <p><strong>Moving Up and Down the Track: </strong>When gaining or losing influence, unlike in a 3+ player game, move influence markers up and down the empty spaces of the influence track, using the entire track. In a 2-player game, if you pass the other player, switch the location of your influence tokens as normal.</p>
            <p><strong>Helping Yourself: </strong>If your influence marker is on spaces 1 through 4 of the influence track, during a character or sidekick's skill check, you may move your influence marker down 3 spaces to allow that character or sidekick to take a stress to re-roll their own skill check.</p>
            <p><strong>Giving a Follower to Yourself: </strong>If your influence marker is on spaces 1 through 4 of the influence track, a player or sidekick who has a follower with a "give to another player" effect may use the effect on their own character, if you move your influence marker down 3 spaces.</p>
            <p>(Different characters and sidekicks can use the normal rules to support each other without moving up and down the influence track, even if they are controlled by the same human player.)</p>

            <h3>Longer Skill Track</h3>
            <p>When gaining a skill, fill in half of the box only. When determining your bonus die for a skill check, a half-filled box counts as filled. The next time you gain that skill, fill in the other half of the box. When gaining a coin or XP reward after gaining a skill, only gain the reward when you have fully filled in the box.</p>

            <h3>Longer XP Track</h3>
            <p>The first time you gain an XP, fill in the top star as normal. The second time you gain XP, instead of filling the next star, cross out or trace over the line between the first and second star. The next time you gain an XP, fill out the second star, and so on. So reaching level 4 requires gaining 11 total XP instead of 6.</p>

            <hr />
            <h2 className="cheddar" id="prologue">Prologue Quest</h2>
            <p>We have temporarily taken down the prologue (convention demo) quest to ready it for the next convention and to smooth out some bumps. Check here again soon!</p>

            <hr />
            <h2 className="cheddar" id="easy">Easy Mode</h2>
            <p>For a more casual experience, at the beginning of the game, remove 1 of each basic risk token from the time bag (without the "+" symbol), and return them to the game box. They will not be used this game.</p>

            <hr />
            <h2 className="cheddar" id="hard">Hard Mode</h2>
            <p>For a more challenging experience, at the beginning of the game, take 1 wound token from the wound track and place it in the time bag.</p>
        </>
    }
}
