import React from "react";
import {str} from "../../Scenarios";
import { IMAGES } from "../../../image-data"
import "./ScenarioSelector.scss"


export class ScenarioSelector extends React.Component {
    clickedScenario(scenario) {
        this.props.controlAudio(null);
        this.props.onSelected(scenario);
    }

    render() {
        return <>
            <p className="narrow">{str["If this is your first game, start with Wizards & Wurms - Map A"]}</p>
            {this.props.manager.scenarios.map(function(scenario, index){
                if(scenario.code==="prologue") {
                    return null;
                }
                return (<div className="scenario-select-container" key={index}>
                    <hr className="thin" />
                    <div className={"one-scenario scen-"+scenario.code+(scenario.release ? " unreleased" : "")} key={index} onClick={(scenario.release ? ()=>{} : () => this.clickedScenario(scenario))}>
                        <img src={IMAGES.scenario_buttons[scenario.code]} alt={scenario.title} />
                        <h3 className="cheddar"><span>{scenario.title} - {str[scenario.map]}</span></h3>
                        <div className="cheddar scenario-difficulty">
                            <span>{str["Difficulty:"]}</span>
                            {Array(scenario.difficulty).fill(null).map((v, i)=> <img key={i} src={IMAGES.interface.axe} className="difficulty-axe" alt="difficulty" />)}
                        </div>
                    </div>
                    {scenario.release ? <div className="release-date">Release Date: {scenario.release}</div> : null}
                </div>);
            },this)}
        </>;
    }
}
