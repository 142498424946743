export const IMAGES = {
    'images/logos/phg.png': require('./images/logos/phg.png'),
    'images/logos/crossroads.png': require('./images/logos/crossroads.png'),
    'images/logos/logo-en.png': require('./images/logos/logo-en.png'),

    backgrounds: {
        generic_left: require('./images/backgrounds/Background-Left.jpg'),
        generic_right: require('./images/backgrounds/Background-Right.jpg'),
    },
    entry_buttons: {
        treasure_trinket: require('./images/entry-buttons/Trinket.png'),
        treasure_armor: require('./images/entry-buttons/Armor.png'),
        treasure_weapon: require('./images/entry-buttons/Weapon.png'),
        treasure_augment: require('./images/entry-buttons/Augment.png'),
        token_starting_follower: require('./images/entry-buttons/Follower_Green.png'),
        token_follwer: require('./images/entry-buttons/Follower_Purple.png'),
        token_risk_excl: require('./images/entry-buttons/Risk-Exclamation.png'),
        token_risk_excl_plus: require('./images/entry-buttons/Risk-Exclamation-Plus.png'),
        token_risk_radiation: require('./images/entry-buttons/Risk-Radiation.png'),
        token_risk_radiation_plus: require('./images/entry-buttons/Risk-Radiation-Plus.png'),
        token_suppies: require('./images/entry-buttons/Supplies.png'),
        token_wound: require('./images/entry-buttons/Wound-Risk.png'),
    },
    glossary: {
        action_limited: require('./images/glossary/action_limited.png'),
        action_locked: require('./images/glossary/action_locked.png'),
        action_required: require('./images/glossary/action_required.png'),
        action_unlimited: require('./images/glossary/action_unlimited.png'),
        armor: require('./images/glossary/armor.png'),
        campsite: require('./images/glossary/campsite.png'),
        chaotic_temple: require('./images/glossary/chaotic_temple.png'),
        check_agility: require('./images/glossary/check_agility.png'),
        check_might: require('./images/glossary/check_might.png'),
        check_sense: require('./images/glossary/check_sense.png'),
        check_smarts: require('./images/glossary/check_smarts.png'),
        check_will: require('./images/glossary/check_will.png'),
        corruption_token: require('./images/glossary/corruption_token.png'),
        crossroads: require('./images/glossary/crossroads.png'),
        d4: require('./images/glossary/d4.png'),
        d6: require('./images/glossary/d6.png'),
        d8: require('./images/glossary/d8.png'),
        d10: require('./images/glossary/d10.png'),
        d12: require('./images/glossary/d12.png'),
        d20: require('./images/glossary/d20.png'),
        duel: require('./images/glossary/duel.png'),
        dungeon: require('./images/glossary/dungeon.png'),
        event: require('./images/glossary/event.png'),
        follower_token: require('./images/glossary/follower_token.png'),
        follower: require('./images/glossary/follower.png'),
        fortune_teller: require('./images/glossary/fortune_teller.png'),
        gain_agility: require('./images/glossary/gain_agility.png'),
        gain_gold: require('./images/glossary/gain_gold.png'),
        gain_influence: require('./images/glossary/gain_influence.png'),
        gain_might: require('./images/glossary/gain_might.png'),
        gain_risk: require('./images/glossary/gain_risk.png'),
        gain_sense: require('./images/glossary/gain_sense.png'),
        gain_smarts: require('./images/glossary/gain_smarts.png'),
        gain_title: require('./images/glossary/gain_title.png'),
        gain_will: require('./images/glossary/gain_will.png'),
        gain_xp: require('./images/glossary/gain_xp.png'),
        lawful_shrine: require('./images/glossary/lawful_shrine.png'),
        location_page: require('./images/glossary/location_page.png'),
        luck_token: require('./images/glossary/luck_token.png'),
        map_threat: require('./images/glossary/map_threat.png'),
        risk_token_2: require('./images/glossary/risk_token_2.png'),
        risk_token: require('./images/glossary/risk_token.png'),
        skill_modifier: require('./images/glossary/skill_modifier.png'),
        skill_roll: require('./images/glossary/skill_roll.png'),
        skill_total: require('./images/glossary/skill_total.png'),
        stress_token: require('./images/glossary/stress_token.png'),
        supplies_token: require('./images/glossary/supplies_token.png'),
        tavern: require('./images/glossary/tavern.png'),
        threat_token: require('./images/glossary/threat_token.png'),
        threat_tokens_numbered: require('./images/glossary/threat_tokens_numbered.png'),
        trading_post: require('./images/glossary/trading_post.png'),
        treasure_golem: require('./images/glossary/treasure_golem.png'),
        trinket: require('./images/glossary/trinket.png'),
        weapon_augment: require('./images/glossary/weapon_augment.png'),
        weapon: require('./images/glossary/weapon.png'),
        wound_token: require('./images/glossary/wound_token.png'),
    },
    entries: {
        en: {
            'paz-take': require('./images/tutorial/paz-take.png'),
            'paz-place': require('./images/tutorial/paz-place.png'),
            'location_book': require('./images/setup/Step 1-Location_Book.png'),
            'story_cards': require('./images/setup/Step 2-Story_Cards.png'),
            'treasure_cards': require('./images/setup/Step 3-Treasure_Cards.png'),
            'follower_cards': require('./images/setup/Step 4-Follower_Cards.png'),
            'tokens': require('./images/setup/Step 5-Tokens.png'),
            'species': require('./images/setup/Step 6-Species.png'),
            'jobs': require('./images/setup/Step 7-Jobs.png'),
            'fill_in': require('./images/setup/Step 8-Fill_In.png'),
            'starting_weapon': require('./images/setup/Step 9-Starting_Weapon.png'),
            'roles': require('./images/setup/Step 10-Roles.png'),
            'game_master': require('./images/setup/Step 10a-Game_Master.png'),
            'bookkeeper': require('./images/setup/Step 10b-Bookkeeper.png'),
            'cartographer': require('./images/setup/Step 10c-Cartographer.png'),
            'scout': require('./images/setup/Step 10d-Scout.png'),
            'lookout': require('./images/setup/Step 10e-Lookout.png'),
            'influencer': require('./images/setup/Step 10f-Influencer.png'),
            'medic': require('./images/setup/Step 10d-Medic.png'),
            "tutorial-stress": require('./images/tutorial/tutorial-stress.png'),
            "tutorial-action-list-7-1-1": require('./images/tutorial/tutorial-action-list-7-1-1.png'),
            "tutorial-action-list-7-1": require('./images/tutorial/tutorial-action-list-7-1.png'),
            "tutorial-book-round-1-1-1-1": require('./images/tutorial/tutorial-book-round-1-1-1-1.png'),
            "tutorial-book-round-1-1-1": require('./images/tutorial/tutorial-book-round-1-1-1.png'),
            "tutorial-camp": require('./images/tutorial/tutorial-camp.png'),
            "tutorial-current-threat": require('./images/tutorial/tutorial-current-threat.png'),
            "tutorial-did-gain-xp-1-1": require('./images/tutorial/tutorial-did-gain-xp-1-1.png'),
            "tutorial-draw-risk-1": require('./images/tutorial/tutorial-draw-risk-1.png'),
            "tutorial-draw-risk": require('./images/tutorial/tutorial-draw-risk.png'),
            "tutorial-follower": require('./images/tutorial/tutorial-follower.png'),
            "tutorial-gain-skill": require('./images/tutorial/tutorial-gain-skill.png'),
            "tutorial-influence-1": require('./images/tutorial/tutorial-influence-1.gif'),
            "tutorial-influence": require('./images/tutorial/tutorial-influence.gif'),
            "tutorial-skill-check": require('./images/tutorial/tutorial-skill-check.png'),
            "tutorial-spend-time-plus": require('./images/tutorial/tutorial-spend-time-plus.png'),
            "tutorial-title": require('./images/tutorial/tutorial-title.png'),
            "tutorial-wounds-1": require('./images/tutorial/tutorial-wounds-1.png'),
            "tutorial-wounds": require('./images/tutorial/tutorial-wounds.png'),
            "tutorial-treasure-augment": require('./images/tutorial/tutorial-treasure-augment.gif'),
            "tutorial-treasure-trinket-1": require('./images/tutorial/tutorial-treasure-trinket-1.png'),
            "tutorial-treasure-trinket": require('./images/tutorial/tutorial-treasure-trinket.png'),
            "easy_difficulty": require('./images/tutorial/easy_difficulty.png'),
            "hard_difficulty": require('./images/tutorial/hard_difficulty.png'),       
            'tutorial-book': require('./images/tutorial/tutorial-book.png'),
            'tutorial-map': require('./images/tutorial/tutorial-map.png'),
            'tutorial-book-attack': require('./images/tutorial/tutorial-book-attack.png'),  
        },
    },
    interface: {
        setup_back: require('./images/setup/Back Arrow.png'),
        setup_next: require('./images/setup/Forward Arrow.png'),
        left_brick: require('./images/flinterface/Left_Brick.png'),
        right_brick: require('./images/flinterface/Right_Brick.png'),
        logo: require('./images/flinterface/Freelancers_Logo.png'),
        back: require('./images/flinterface/Back_Arrow.png'),
        settings: require('./images/flinterface/Gear_Icon.png'),
        divider: require('./images/flinterface/Divider.png'),
        divider_mall: require('./images/flinterface/Small_Divider.png'),
        small_divider: require('./images/flinterface/Small_Divider.png'),
        down_arrow: require('./images/flinterface/Dropdown_Arrow.png'),
        up_arrow: require('./images/flinterface/Dropdown_Arrow_Up.png'),
        follower_button: require('./images/flinterface/Follower_Button.png'),
        follower_x: require('./images/flinterface/X.png'),
        timer: require('./images/flinterface/Timer_Icon.png'),
        glossary: require('./images/flinterface/Rules_Glossary.png'),
        history: require('./images/flinterface/Entry_History_Icon.png'),
        species: require('./images/flinterface/Edit_Character_Sheet_Icon.png'),
        trash: require('./images/flinterface/trash.png'),
        axe: require('./images/scenario-buttons/Axe_Icon-Outlined.png'),
        tutorial_v: require('./images/flinterface/Minimize.png'),
        tutorial_x: require('./images/flinterface/X 2.png'),
        tutorial_caret: require('./images/flinterface/Maximize.png'),
    },
    species_select: {
        mouser_selected: require('./images/species-select/Mouser-Selected.png'),
        mouser_unselected: require('./images/species-select/Mouser-Unselected.png'),
        goblin_selected: require('./images/species-select/Goblin-Selected.png'),
        goblin_unselected: require('./images/species-select/Goblin-Unselected.png'),
        elf_selected: require('./images/species-select/Elf-Selected.png'),
        elf_unselected: require('./images/species-select/Elf-Unselected.png'),
        hound_selected: require('./images/species-select/Hound-Selected.png'),
        hound_unselected: require('./images/species-select/Hound-Unselected.png'),
        dwarf_selected: require('./images/species-select/Dwarf-Selected.png'),
        dwarf_unselected: require('./images/species-select/Dwarf-Unselected.png'),
        stiltkin_selected: require('./images/species-select/Stilt-Kin-Selected.png'),
        stiltkin_unselected: require('./images/species-select/Stilt-Kin-Unselected.png'),
        troll_selected: require('./images/species-select/Troll-Selected.png'),
        troll_unselected: require('./images/species-select/Troll-Unselected.png'),
        merfolk_selected: require('./images/species-select/Merfolk-Selected.png'),
        merfolk_unselected: require('./images/species-select/Merfolk-Unselected.png'),
        imp_selected: require('./images/species-select/Imp-Selected.png'),
        imp_unselected: require('./images/species-select/Imp-Unselected.png'),
    },
    followers: {
        "Schala": require('./images/followers/Follower_Button-1.png'),
        "Cookie": require('./images/followers/Follower_Button-2.png'),
        "Grunko": require('./images/followers/Follower_Button-3.png'),
        "Gopher": require('./images/followers/Follower_Button-4.png'),
        "Pascal": require('./images/followers/Follower_Button-5.png'),
        "Strider": require('./images/followers/Follower_Button-6.png'),
        "Porter": require('./images/followers/Follower_Button-7.png'),
        "Tink": require('./images/followers/Follower_Button-8.png'),
        "Scav": require('./images/followers/Follower_Button-9.png'),
        "Harold": require('./images/followers/Follower_Button-10.png'),
        "Clara": require('./images/followers/Follower_Button-11.png'),
        "Cruncher": require('./images/followers/Follower_Button-12.png'),
        "Lazy Bones": require('./images/followers/Follower_Button-13.png'),
        "Maverick": require('./images/followers/Follower_Button-14.png'),
        "Trent": require('./images/followers/Follower_Button-15.png'),
        "Gabby": require('./images/followers/Follower_Button-16.png'),
        "Tik-Ton": require('./images/followers/Follower_Button-17.png'),
        "Flora": require('./images/followers/Follower_Button-18.png'),
        "Wilma": require('./images/followers/Follower_Button-19.png'),
        "Serpa": require('./images/followers/Follower_Button-20.png'),
        "Doji": require('./images/followers/Follower_Button-21.png'),
        "Rookie": require('./images/followers/Follower_Button-22.png'),
        "Sir Croakly": require('./images/followers/Follower_Button-23.png'),
        "Otto": require('./images/followers/Follower_Button-24.png'),
        "Dawn Chorus": require('./images/followers/Follower_Button-25.png'),
        "Mysterious Stranger": require('./images/followers/Follower_Button-26.png'),
    },
    scenario_buttons: {
        wizards: require('./images/scenario-buttons/Quest-Wizards_and_Wurms.png'),
        heroes: require('./images/scenario-buttons/Quest-Heroes_and_Horrors.png'),
        sisters: require('./images/scenario-buttons/Quest-Sisters_and_Songs.png'),
        relics: require('./images/scenario-buttons/Quest-Relics_and_Revenge.png'),
        deeper: require('./images/scenario-buttons/Quest-Deep_and_Deeper.png'),
    },
    map_headers: {
        "Map A": require('./images/map-headers/Map A.png'),
        "Prologue Map": require('./images/map-headers/Prologue Map.png'),
        "Map B": require('./images/map-headers/Map B.png'),
        "Map C": require('./images/map-headers/Map C.png'),
        "Map D": require('./images/map-headers/Map D.png'),
        "Map E": require('./images/map-headers/Map E.png'),
    },
    book_pages: {
        "0": require('./images/book-pages/Location_Book-Page3.png'),
        "3": require('./images/book-pages/Location_Book-Page3.png'),
        "5": require('./images/book-pages/Location_Book-Page5.png'),
        "7": require('./images/book-pages/Location_Book-Gilded_District.png'),
        "9": require('./images/book-pages/Location_Book-Page9.png'),
        "11": require('./images/book-pages/Location_Book-Page11.png'), 
        "13": require('./images/book-pages/Location_Book-Page13.png'),
        "15": require('./images/book-pages/Location_Book-Page15.png'),
        "17": require('./images/book-pages/Location_Book-Page17.png'),
        "19": require('./images/book-pages/Location_Book-Page19.png'),
        "21": require('./images/book-pages/Location_Book-Page21.png'),
        "23": require('./images/book-pages/Location_Book-Page23.png'),
        "25": require('./images/book-pages/Location_Book-Page25.png'),
        "27": require('./images/book-pages/Location_Book-Page27.png'),
        "29": require('./images/book-pages/Location_Book-Page29.png'),
        "31": require('./images/book-pages/Location_Book-Page31.png'),
        "33": require('./images/book-pages/Location_Book-Page33.png'),
        "35": require('./images/book-pages/Location_Book-Page35.png'),
        "37": require('./images/book-pages/Location_Book-Page37.png'),
        "39": require('./images/book-pages/Location_Book-Page39.png'),
        "41": require('./images/book-pages/Location_Book-Page41.png'),
        "43": require('./images/book-pages/Location_Book-Page43.png'),
        "45": require('./images/book-pages/Location_Book-Page45.png'),
        "47": require('./images/book-pages/Location_Book-Page47.png'),
        "49": require('./images/book-pages/Location_Book-Page49.png'),
    },
    icons: {
        chaos: require('./images/flicons/inline/Inline_Icons-Chaotic_Title.png'),
        corruption: require('./images/flicons/inline/Inline_Icons-Corruption.png'),
        d4: require('./images/flicons/inline/Inline_Icons-d4.png'),
        d6: require('./images/flicons/inline/Inline_Icons-d6.png'),
        d8: require('./images/flicons/inline/Inline_Icons-d8.png'),
        d10: require('./images/flicons/inline/Inline_Icons-d10.png'),
        d12: require('./images/flicons/inline/Inline_Icons-d12.png'),
        d20: require('./images/flicons/inline/Inline_Icons-d20.png'),
        fire: require('./images/flicons/inline/Inline_Icons-Fire.png'),
        follower: require('./images/flicons/inline/Inline_Icons-Follower.png'),
        frost: require('./images/flicons/inline/Inline_Icons-Frost.png'),
        gold: require('./images/flicons/inline/Inline_Icons-Gold.png'),
        health: require('./images/flicons/inline/Inline_Icons-Health.png'),
        hp: require('./images/flicons/inline/Inline_Icons-Health.png'),
        influence: require('./images/flicons/inline/Inline_Icons-Influence.png'),
        law: require('./images/flicons/inline/Inline_Icons-Lawful_Title.png'),
        lightning: require('./images/flicons/inline/Inline_Icons-Lightning.png'),
        lock: require('./images/flicons/inline/Inline_Icons-Lock.png'),
        luck: require('./images/flicons/inline/Inline_Icons-Luck.png'),
        range: require('./images/flicons/inline/Inline_Icons-Range.png'),
        risk: require('./images/flicons/inline/Inline_Icons-Risk-Generic.png'),
        stress: require('./images/flicons/inline/Inline_Icons-Stress.png'),
        threat: require('./images/flicons/inline/Inline_Icons-Threat.png'),
        supply: require('./images/flicons/inline/Inline_Icons-Supplies.png'),
        treasure: require('./images/flicons/inline/Inline_Icons-Treasure.png'),
        wound: require('./images/flicons/inline/Inline_Icons-Wound.png'),
        xp: require('./images/flicons/inline/Inline_Icons-XP.png'),
        will: require('./images/flicons/inline/Inline_Icons-Will.png'),
        smarts: require('./images/flicons/inline/Inline_Icons-Smarts.png'),
        might: require('./images/flicons/inline/Inline_Icons-Might.png'),
        sense: require('./images/flicons/inline/Inline_Icons-Sense.png'),
        agility: require('./images/flicons/inline/Inline_Icons-Agility.png'),
    },
    'map_icons': {
        camp: require('./images/flicons/map/Camp_Action.png'),
        chaos: require('./images/flicons/map/Chaotic_Temple.png'),
        crossroad: require('./images/flicons/map/Crossroads.png'),
        crossroad_special: require('./images/flicons/map/Crossroads.png'),
        duel: require('./images/flicons/map/Duel.png'),
        special_duel: require('./images/flicons/map/Duel.png'),
        fortune: require('./images/flicons/map/Fortune_Teller.png'),
        law: require('./images/flicons/map/Lawful_Shrine.png'), 
        omen: require('./images/flicons/map/Threat.png'),
        sidequest: require('./images/flicons/map/Dungeon.png'),
        tavern: require('./images/flicons/map/Tavern.png'),
        trading: require('./images/flicons/map/Trading_Post.png'),
        treasure: require('./images/flicons/map/Treasure_Golem.png'),
        event_81: require('./images/flicons/map/Event-81.png'),
        event_82: require('./images/flicons/map/Event-82.png'),
        event_83: require('./images/flicons/map/Event-83.png'),
        event_84: require('./images/flicons/map/Event-84.png'),
        event_85: require('./images/flicons/map/Event-85.png'),
        event_86: require('./images/flicons/map/Event-86.png'),
        event_87: require('./images/flicons/map/Event-87.png'),
        event_88: require('./images/flicons/map/Event-88.png'),
        event_90: require('./images/flicons/map/Event-90.png'),
        location_81: require('./images/flicons/map/Location_Page-81.png'),
        location_82: require('./images/flicons/map/Location_Page-82.png'),
        location_83: require('./images/flicons/map/Location_Page-83.png'),
        location_84: require('./images/flicons/map/Location_Page-84.png'),
        location_85: require('./images/flicons/map/Location_Page-85.png'),
        location_86: require('./images/flicons/map/Location_Page-86.png'),
        location_87: require('./images/flicons/map/Location_Page-87.png'),
        location_88: require('./images/flicons/map/Location_Page-88.png'),
        location_89: require('./images/flicons/map/Location_Page-89.png'),
    },
    'images/round-timer-frames/round-timer-frames-0.png': require('./images/round-timer-frames/Timer 1.png'),
    'images/round-timer-frames/round-timer-frames-1.png': require('./images/round-timer-frames/Timer 2.png'),
    'images/round-timer-frames/round-timer-frames-2.png': require('./images/round-timer-frames/Timer 3.png'),
    'images/round-timer-frames/round-timer-frames-3.png': require('./images/round-timer-frames/Timer 4.png'),
    'images/round-timer-frames/round-timer-frames-4.png': require('./images/round-timer-frames/Timer 5.png'),
    'images/round-timer-frames/round-timer-frames-5.png': require('./images/round-timer-frames/Timer 6.png'),
    'images/round-timer-frames/round-timer-frames-6.png': require('./images/round-timer-frames/Timer 7.png'),
    'images/round-timer-frames/round-timer-frames-7.png': require('./images/round-timer-frames/Timer 8.png'),
    'images/round-timer-frames/round-timer-frames-8.png': require('./images/round-timer-frames/Timer 9.png'),
};
