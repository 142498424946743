import React from "react";
import {str} from "../../Scenarios";
import {EntryText} from "./EntryDisplay/EntryText";
import {EntryImage} from "./EntryDisplay/EntryImage";
import { BUTTON_STYLES, WideButton } from "../WideButton";


export class HistoryScreen extends React.Component {
    constructor(props) {
        super(props);
        this.entriesPerPage = parseInt(props.entriesPerPage);
        this.entries = [];
        let hasMore = this._loadMore(0);
        this.state = {
            "entries": this.entries,
            "hasMore": hasMore
        };
    }

    _loadMore(currentEntryCount) {
        let newEntryHistory = this.props.historyManager.getHistory(
            currentEntryCount,
            this.entriesPerPage,
            this.props.scenario.scenarioInfo.code,
            null,
            "source_2", //tutorial
        );
        let newEntries = [];
        newEntryHistory.forEach((entryToGet) => {
            let newEntry = this.props.scenario.getEntry(
                entryToGet.entry,
                this.props.historyManager,
            );
            if(newEntry) {
                newEntries.unshift(newEntry);
            }
        }, this);
        newEntries.unshift(...this.entries);
        this.entries = newEntries;
        if(newEntryHistory.length < this.entriesPerPage) {
            return false;
        }
        return true;
    }

    loadMore() {
        let hasMore = this._loadMore(this.entries.length);
        this.setState({
            "entries": this.entries,
            "hasMore": hasMore
        });
    }

    render() {
        let htmlEntries = [];
        for (let [index, entry] of this.state.entries.entries()) {
            htmlEntries.push(
                <EntryHistoryDisplay textSize={this.props.textSize} info={this.props.info} save_code={this.props.save_code} entry={entry} key={index} />
            );
        }
        let moreButton = null;
        if(this.state.hasMore) {
            moreButton = (
                <WideButton style={BUTTON_STYLES.ENTRY} onClick={() => this.loadMore()}>
                    {str.loadMore}
                </WideButton>
            );
        }
        return(
            <>
                <h2 className="upper cheddar">{str.historyFor}</h2>
                <div className="options-body with-top">
                    {htmlEntries}
                    {moreButton}
                </div>
            </>
        );
    }
}

class EntryHistoryDisplay extends React.Component {
    render() {
        let arrayToRender = [];
        for(let [index, contentPart] of this.props.entry.content.entries()){
            switch(contentPart.type) {
                case "text":
                    arrayToRender.push(
                        <EntryText
                            title={contentPart.title}
                            value={contentPart.value}
                            key={index}
                            save_code={this.props.save_code}
                            info={this.props.info}
                            textSize={this.props.textSize}
                        />);
                    break;
                case "image":
                    arrayToRender.push(
                        <EntryImage
                            title={contentPart.title}
                            value={contentPart.value}
                            key={index}
                        />);
                    break;
                case "option":
                    arrayToRender.push(
                        <p key={index}>({contentPart.title})</p>);
                    break;
                default:
                    break;
            }
        }
        return (<div className="entry-history-display">
            {arrayToRender}
        <hr /></div>);
    }
}
