import LocalizedStrings from 'react-localization';
import {translationData, translationInfo} from './translations/translations';
import {ENTRIES} from './scenario_data';

let str = new LocalizedStrings(translationData);
export { str }

const DONT_GO = [];

export class ScenarioManager {
    constructor(historyManager) {
        this.setScenarioInfo();
        this.historyManager = historyManager;
    }

    setScenarioInfo() {
        let matchingInfo = translationInfo.filter(info => info.code===str.getLanguage());
        if (matchingInfo.length) {
            this.scenarios = matchingInfo[0].scenarioInfo;
        } else {
            this.scenarios = [];
        }
    }

    getScenarioImageByCode(scenarioInfo, imageCode) {
        let matchingImages = scenarioInfo.images.filter(image => image.code===imageCode);
        if (matchingImages.length) { return matchingImages[0].file }
        return null;
    }

    loadScenario(code=null, primary=null, secondary=null){
        let matchingScenarioInfo = null;
        if(code) {
            let new_files = [];
            matchingScenarioInfo = this.scenarios.filter(scenario => scenario.code===code)[0];
            for(let one_file of matchingScenarioInfo.entry_files) {
                if(!Array.isArray(one_file)) {
                    new_files.push(ENTRIES[one_file]);
                } else {
                    new_files.push(one_file);
                }
            }
            matchingScenarioInfo.entry_files = new_files;
        } else {
            matchingScenarioInfo = {
                "code": "dev",
                "title": "Development",
                "entry_files": [
                    primary,
                    secondary,
                ]
            };
        }
        let daScenario =  new Scenario(matchingScenarioInfo);
        daScenario.loadEntries();
        return daScenario;
    }
}

export class HistoryManager {
    constructor(historySaver) {
        this.historySaver = historySaver;
        this.history = null;
    }

    loadHistory(code, sync=true) {
        this.history = this.historySaver.loadHistory(code, sync);
    }

    getLoadHistory(code) {
        return this.historySaver.loadHistory(code, false);
    }

    seenIndex(index, minimum=1) {
        if(!this.history) {
            return false;
        }
        return this.history.history.filter(entry => entry.entry === index).length >= minimum;
    }

    addEntry(entry) {
        this.history.history.unshift({
            "entry": entry.index,
            "scenario": entry.scenario,
            "source": entry.source
        });
        this.historySaver.saveHistory(this.history, false);
        if(entry.source==="source_1") {
            let genhis = this.historySaver.loadGenericHistory();
            genhis.unshift({
                "entry": entry.index,
                "scenario": entry.scenario,
                "source": entry.source,
            });
            this.historySaver.saveGenericHistory(genhis);
        }
    }

    removeEntry(index) {
        this.history.history = this.history.history.filter(entry => entry.entry !== index);
        this.historySaver.saveHistory(this.history, false);
    }

    clearEntries()  {
        this.history = [];
        this.historySaver.saveHistory(this.history, false);
    }

    getGenericHistory() {
        return this.historySaver.loadGenericHistory();
    }

    getHistory(startingItem, numberOfItems, scenario_code = null, source = null, exclude_source = null) {
        let filteredHistory = this.history.history;
        if(exclude_source) {
            filteredHistory = filteredHistory.filter(entry => entry.source !== exclude_source);
        }
        if(scenario_code) {
            filteredHistory = filteredHistory.filter(entry => entry.scenario === scenario_code);
        }
        if(source) {
            filteredHistory = filteredHistory.filter(entry => entry.source === source);
        }
        let historyToReturn = [];
        let returnCount = 0;
        for(let i=startingItem; i<filteredHistory.length && returnCount<numberOfItems; i++) {
            returnCount++;
            historyToReturn.unshift(filteredHistory[i]);
        }
        return historyToReturn;
    }

    saveHistory(h, sync=false) {
        this.history = this.historySaver.saveHistory(h, sync);
    }
}

export class Scenario {
    constructor(scenarioInfo) {
        this.scenarioInfo = scenarioInfo;
        this.entries = [];
    }

    loadEntries() {
        this._loadEntriesHelper(0);
    }

    _getEntriesToChooseFrom(index, page=null, jobs=[], species=[], require_source=null) {
        let entries_to_return = [];
        let filteredEntries = this.entries.filter(entry => entry.index.toLowerCase()===index.toLowerCase());
        if(require_source) {
            filteredEntries = filteredEntries.filter(entry => entry.source===require_source);
        }
        filteredEntries = filteredEntries.filter(e=>e.scenario!=="wizards" || e.index!=="trading-12");
        filteredEntries = filteredEntries.filter(e=>!DONT_GO.includes(e.index));
        if(filteredEntries.length) {
            let page_entries = [];
            let job_entries = [];
            let species_entries = [];
            let other_entries = [];
            for(let wantedEntry of filteredEntries) {
                if(wantedEntry.content.length) {
                    if(wantedEntry.content.length===1 && wantedEntry.content[0].type==="redirect") {
                        entries_to_return = entries_to_return.concat(
                            this._getEntriesToChooseFrom(wantedEntry.content[0].value, page, jobs, species)
                        );
                    } else {
                        entries_to_return.push(wantedEntry);
                    }
                } else if(wantedEntry.subentries.length) {
                    for(let one_sub of wantedEntry.subentries) {
                        let page_string = one_sub.index.match(/-page([0-9]+)-/);
                        let job_string = one_sub.index.match(/-job-([a-z]+)-/);
                        let species_string = one_sub.index.match(/-species-([a-z]+)-/);

                        if(page && page_string && page_string[1]===page) {
                            page_entries = [...page_entries, ...this._getEntriesToChooseFrom(one_sub.index, null, [], [], one_sub.source)];
                        }
                        if(job_string && jobs.includes(job_string[1])) {
                            job_entries = [...job_entries, ...this._getEntriesToChooseFrom(one_sub.index, null, [], [], one_sub.source)];
                        }
                        if(species_string && species.includes(species_string[1])) {
                            species_entries = [...species_entries, ...this._getEntriesToChooseFrom(one_sub.index, null, [], [], one_sub.source)];
                        }
                        if(!page_string && !job_string && !species_string) {
                            other_entries = [...other_entries, ...this._getEntriesToChooseFrom(one_sub.index, null, [], [], one_sub.source)];
                        }
                    }
                }
            }
            if(page_entries.length>0) {
                entries_to_return = entries_to_return.concat(page_entries);
            } else {
                entries_to_return = [...entries_to_return, ...job_entries, ...species_entries, ...other_entries];
            }
        } else {
            if(index.startsWith("0")) {
                entries_to_return = entries_to_return.concat(
                    this._getEntriesToChooseFrom(index.substr(1))
                );
            }
        }
        return entries_to_return;
    }

    readyReturnEntry(wantedEntry, historymanager, page=null) {
        return wantedEntry;
    }

    getEntry(index, historyManager, page=null, jobs=[], species=[], current_entry=null, source=null) {
        let entries_to_choose_from = this._getEntriesToChooseFrom(index, page, jobs, species, source);
        switch(entries_to_choose_from.length) {
            case 0:
                return null;
            case 1:
                return this.readyReturnEntry(entries_to_choose_from[0], historyManager, page);
            default:
                let all_gen = false;
                if(entries_to_choose_from.length===entries_to_choose_from.filter(e=>e.source==="source_1").length) {
                    all_gen = true;
                }
                if(current_entry) {
                    let from_current_entry_source = entries_to_choose_from.filter(e=>e.source===current_entry.source);
                    if(from_current_entry_source.length>0) {
                        entries_to_choose_from = from_current_entry_source;
                    }
                }

                let lowest_candidates = [];
                let lowest_total = 999999999;
                for(let one_entry of entries_to_choose_from) {
                    let num_times = 0;
                    if(all_gen) {
                        let gen_history = historyManager.getGenericHistory();
                        num_times = gen_history.filter(entry=>entry.entry===one_entry.index).length;
                    } else {
                        let source_history = historyManager.getHistory(
                            0,
                            1000000,
                            null,
                            one_entry.source
                        );
                        num_times = source_history.filter(entry=>entry.entry===one_entry.index).length;
                    }
                    if(num_times<lowest_total) {
                        lowest_total = num_times;
                        lowest_candidates = [one_entry];
                    } else if (lowest_total === num_times) {
                        lowest_candidates.push(one_entry);
                    }
                }
                if(lowest_candidates.length === 1) return this.readyReturnEntry(lowest_candidates[0], historyManager, page);
                let scen_cand = [];
                let gen_cand = [];
                let spec_cand = [];
                let job_cand = [];
                for(let one_entry of lowest_candidates) {
                    if(one_entry.index.includes("-job-")) {
                        job_cand.push(one_entry);
                    } else if(one_entry.index.includes("-species-")) {
                        spec_cand.push(one_entry);
                    }else if(
                        one_entry.source.substr(one_entry.source.length-7) === "generic"
                        || one_entry.source==="source_1"
                    ) {
                        gen_cand.push(one_entry);
                    } else {
                        scen_cand.push(one_entry);
                    }
                }
                if(job_cand.length>0) {
                    return this.readyReturnEntry(job_cand[Math.floor(Math.random()*job_cand.length)], historyManager, page);
                }
                if(spec_cand.length>0) {
                    return this.readyReturnEntry(spec_cand[Math.floor(Math.random()*spec_cand.length)], historyManager, page);
                }
                if(scen_cand.length>0) {
                    return this.readyReturnEntry(scen_cand[Math.floor(Math.random()*scen_cand.length)], historyManager, page);
                }
                return this.readyReturnEntry(gen_cand[Math.floor(Math.random()*gen_cand.length)], historyManager, page);
        }
    }

    _loadEntriesHelper(entryFileIndex) {
        if(this.scenarioInfo.entry_files[entryFileIndex] !== null) {
            this._addEntriesFromJSON(this.scenarioInfo.entry_files[entryFileIndex], entryFileIndex);
        }
        if(entryFileIndex+1 < this.scenarioInfo.entry_files.length) {
            this._loadEntriesHelper(entryFileIndex+1);
        }
    }

    _addEntriesFromJSON(data, filePath) {
        for(let newEntry of data) {
            newEntry.source = "source_"+filePath;
            newEntry.scenario = this.scenarioInfo.code;
            this.entries.push(newEntry);
            this._addEntriesFromJSON(newEntry.subentries, filePath);
        }
    }
}
