import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import {
    ScenarioManager,
    HistoryManager,
} from './scenarios/Scenarios.js';
import {
    SCREENS,
    ScenarioInterface,
} from "./scenarios/components/ScenarioInterface";
import ls from 'local-storage'
import * as serviceWorker from './serviceWorker';
import { getHash, randomID } from './utils';
import { config } from './config';
import { flSavePromise } from './api';

class WebHistorySaver {
    constructor(key, generic_key="generic-history") {
        this.key = key;
        this.save_timer = null;
        this.generic_key = generic_key;
    }

    getSaveCodes() {
        return ls.get(this.key+"_save_codes") || [];
    }

    clearTimer() {
        if(this.save_timer) {
            clearTimeout(this.save_timer);
            this.save_timer = null;
        }
    }

    addSaveCode(code) {
        let codes = this.getSaveCodes();
        if(codes.includes(code)) return;
        codes.push(code);
        ls.set(this.key+"_save_codes", codes);
    }

    deleteQuest(save_code) {
        ls.remove(this.key+"_"+save_code);
        let codes = this.getSaveCodes();
        let index = codes.indexOf(save_code);
        if (index > -1) {
            codes.splice(index, 1);
        }
        ls.set(this.key+"_save_codes", codes);
    }

    newQuest(code) {
        let newy = {
            scenario_code: code,
            history: [],
            page: "",
            result: "active",
            save_code: randomID(8),
            active_jobs: [],
            active_species: [],
            date_started: new Date(),
            date_updated: new Date(),
            online: true,
        }
        this.saveHistory(newy, false);
        this.addSaveCode(newy.save_code);
        flSavePromise(newy).then(d=>{});
        return newy;
    }

    loadGenericHistory() {
        return ls.get(this.generic_key) || [];
    }

    saveGenericHistory(history) {
        ls.set(this.generic_key, history);
    }

    loadHistory(save_code, sync=true) {
        let h = ls.get(this.key+"_"+save_code) || null;
        if(sync) flSavePromise(h).then(null);
        return h
    }

    saveHistory(quest, sync=false) {
        let oldy = this.loadHistory(quest.save_code, false) || {};
        this.addSaveCode(quest.save_code);
        let h = Object.assign({}, oldy, quest, {date_updated: new Date()});
        ls.set(this.key+"_"+quest.save_code, h);
        this.clearTimer();
        if(sync) {
            flSavePromise(h).then(null);
        }
        return h;
    }
}

let historyManager = new HistoryManager(new WebHistorySaver(config.save_game_key));
let scenarioManager = new ScenarioManager(historyManager);

ReactDOM.render(
    <ScenarioInterface debug={config.debug || getHash()==="#debug"} manager={scenarioManager} screen={SCREENS.SPLASH} />,
    document.getElementById('freelancers-app')
);

serviceWorker.unregister();
