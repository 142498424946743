import enGenericEntries from './translations/en/generic.json';
import enSistersEntries from './translations/en/sisters.json';
import enWizardsEntries from './translations/en/wizards.json';
import enPrologueEntries from './translations/en/prologue.json';
import enHeroesEntries from './translations/en/heroes.json';
import enTutorialEntries from './translations/en/tutorial.json';
import enRelicsEntries from './translations/en/relics.json';
import enDeeperEntries from './translations/en/deeper.json';

export const ENTRIES = {
    "en/generic": enGenericEntries,
    "en/sisters": enSistersEntries,
    'en/wizards': enWizardsEntries,
    'en/prologue': enPrologueEntries,
    'en/heroes': enHeroesEntries,
    'en/tutorial': enTutorialEntries,
    'en/relics': enRelicsEntries,
    'en/deeper': enDeeperEntries,
};
