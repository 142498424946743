import {config} from "./config";
import {str} from './scenarios/Scenarios';

export const BACKEND_PAGES = {
    APP_MESSAGES: 'app messages',
    SAVE_GAMES: 'save games',
    APP_VERSIONS: 'app versions,'
};

export function getBackendUrl(whereto) {
    switch(whereto) {
        case BACKEND_PAGES.SAVE_GAMES:
            return config.backend + '/freelancers-save/';
        case BACKEND_PAGES.APP_VERSIONS:
            return config.backend + '/app-versions/fl/';
        case BACKEND_PAGES.APP_MESSAGES:
        default:
            return config.backend + '/app-messages/fl/'+str.getLanguage()+'/';
    }
}

export function flLoadPromise(code) {
    return backendPromise(
        getBackendUrl(BACKEND_PAGES.SAVE_GAMES)+code+"/",
    );
}

export function flSavePromise(data) {
    return backendPromise(
        getBackendUrl(BACKEND_PAGES.SAVE_GAMES)+"none/",
        'POST',
        null,
        data
    );
}

export function backendPromise(whereto, method='GET', csrftoken=null, body=null) {
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    if(csrftoken) {
        headers['X-CSRFToken'] = csrftoken;
    }
    let req_opt = {
        method: method,
        headers: headers,
    };
    if(body) {
        req_opt['body'] = JSON.stringify(body);
    }
    return new Promise(resolve=>{
        try {
            fetch(
                whereto,
                req_opt,
            ).then(r => {
                if (r.status === 200 && r.ok === true) {
                    r.json().then((d) => {
                        resolve(d);
                    });
                } else {
                    resolve({
                        ok: false,
                        message: str.formatString(str["errorServerReturnedCode"], r.status, r.statusText),
                    });
                }

            }).catch(error => {
                resolve({
                    ok: false,
                    message: str.formatString(str["errorServerReturnedCode"], '0', 'Network error.'),
                });
            });
        } catch(error) {
            resolve({
                ok: false,
                message: str["Failed to fetch"],
            });
        }
    });
}