import React from "react";
import {str} from "../Scenarios";
import {MainMenu} from "./screens/MainMenu";
import {CreditsScreen, VariantsScreen} from "./screens/ContentScreens";
import {ScenarioSelector} from "./screens/ScenarioSelector";
import {EntryDisplay} from "./screens/EntryDisplay/EntryDisplay";
import {HistoryScreen} from "./screens/HistoryScreen";
import {OptionsScreen} from "./screens/OptionsScreen";
import {SplashScreen} from "./screens/SplashScreen";
import {LanguageScreen} from "./screens/LanguageScreen";
import {PlayMenu} from "./screens/PlayMenu";
import {SandTimer} from "./screens/SandTimer";
import {UploadScreen} from "./screens/UploadScreen";
import {getTimerLength, getBackgroundVolume, getTopTitle, setTutorialOption, getTextSize, setTextSize,} from "../../utils";
import {Howl} from 'howler';
import {AUDIO_URLS} from "../../audio-data";
import { SetPlayersScreen } from './screens/SetPlayersScreen';
import { ContinueScreen } from "./screens/Continue";
import { WoodenHeader } from "./WoodenHeader";
import { AnimatePresence, motion } from "framer-motion";
import { IMAGES } from "../../image-data";
import { config } from "../../config";
import GlossaryContent from "./screens/Glossary/getGlossaryContent";
import { getGlossaryTerm } from "./screens/Glossary/logic";
import { filterText } from "../../utils";
import { getTutorialOption } from "../../utils";
import { TutorialDisplay } from "./TutorialDisplay/TutorialDisplay";
import { LearnToPlayArrow } from "./LearnToPlayArrow";
import ScenarioDev from "./ScenarioDev/ScenarioDev";


export const SCREENS = {
    'SPLASH': 0,
    'MAIN_MENU': 1,
    'ABOUT': 2,
    'OPTIONS': 3,
    'LANGUAGE': 4,
    'SCENARIO_SELECTION': 5,
    'PLAY_MENU': 6,
    'ENTRY_DISPLAY': 7,
    'TIMER': 8,
    'HISTORY': 9,
    'INSTALL': 10,
    'CHECK_TOKENS': 11,
    'VARIANTS': 12,
    'NARRATIVE_TEXT_CHOICE': 13,
    'UPLOAD': 14,
    'SET_PLAYERS': 15,
    'CONTINUE': 16,
    'GLOSSARY': 17,
    'CREDITS': 18,
};

const NON_SCENARIO_SCREENS = [
    SCREENS.SPLASH,
    SCREENS.MAIN_MENU,
    SCREENS.ABOUT,
    SCREENS.SCENARIO_SELECTION,
    SCREENS.VARIANTS,
    SCREENS.CONTINUE,
    SCREENS.CREDITS,
];

const SCENARIO_BACKGROUND_CLASSES = {
    "wizards": "map-a",
    "heroes": "map-e",
    "sisters": "map-b",
    "relics": "map-c",
    "deeper": "map-d"
}

const PAGE_BACKGROUND_CLASSES = {
    "3": "slums-hub",
    "5": "driftlands",
    "7": "gilded-district",
    "9": "river-village",
    "11": "entertainment-district",
    "13": "cemetery",
    "15": "the-great-library",
    "17": "lonely-cottage",
    "19": "treasure-golem",
    "21": "ambush",
    "23": "corrupt-ferry",
    "25": "opera-house",
    "27": "attack",
    "29": "skeleton",
    "31": "the-lich",
    "35": "marketplace",
    "37": "wastes",
    "39": "highway",
    "41": "dark-corridors",
    "45": "mushroom-village",
    "47": "trap",
    "49": "dragon",
}

const BLANK_BGS = [SCREENS.MAIN_MENU];

export class ScenarioInterfaceState {
    constructor(activeScreen, activeScenario, activeEntry, previousState=null) {
        this.activeScreen = activeScreen;
        this.activeScenario = activeScenario;
        this.activeEntry = activeEntry;
        this.formError = null;
        this.previousState = previousState;
        this.backwards = false;
    }
}

export class ScenarioInterface extends React.Component {
    constructor(props) {
        super(props);
        this.state = new ScenarioInterfaceState(this.props.initialScreen);
        this.state.activeBackgroundTrack = null;
        this.state.activeBackgroundAudioId = null;
        this.state.page = this.getPage();
        this.state.activeScreen = SCREENS.MAIN_MENU;
        this.manager = this.props.manager;
        this.backgroundPlayer = null;
        this.state.popup = null;
        this.state.activeFollowerEntry = null;
        this.state.tutorial = getTutorialOption();
        this.state.textSize = getTextSize();
        this.state.arrows = [];
        this.state.tutorialEntry = null;
    }

    componentDidMount() {
        if(config.preload) {
            let scen = this.manager.historyManager.historySaver.loadHistory(config.preload);
            this.goToScenario(scen, false, false, config.preload);
        }
        window.onbeforeunload = ()=>{
            if(!NON_SCENARIO_SCREENS.includes(this.state.activeScreen)) return true;
        };
    }

    setTextSize(size) {
        setTextSize(size);
        this.setState({textSize: size, backwards: false});
    }

    setArrows(arrows) {
        this.setState({arrows: arrows, backwards: false});
    }

    sizeAdjust() {
    }

    setPopup(title, content) {
        this.setState({popup: {title: title, content: content}, backwards: false});
    }

    closePopup() {
        this.setState({popup: null, backwards: false})
    }

    getSpecies() {
        if(!this.props.manager.historyManager.history) {
            return [];
        }
        return this.props.manager.historyManager.history.active_species;
    }

    getJobs() {
        if(!this.props.manager.historyManager.history) {
            return [];
        }
        return this.props.manager.historyManager.history.active_jobs;
    }

    getPage() {
        if(!this.props.manager.historyManager.history) {
            return "3";
        }
        return this.props.manager.historyManager.history.page;
    }

    setSpecies(species) {
        if(!this.props.manager.historyManager.history) {
            return;
        }
        this.props.manager.historyManager.saveHistory({save_code: this.props.manager.historyManager.history.save_code, active_species: species});
    }

    setJobs(jobs) {
        if(!this.props.manager.historyManager.history) {
            return;
        }
        this.props.manager.historyManager.saveHistory({save_code: this.props.manager.historyManager.history.save_code, active_jobs: jobs});
    }

    goToPreviousState() {
        if(this.state.previousState !== null) {
            this.setState(Object.assign(
                {},
                this.state.previousState,
                {
                    activeBackGroundTrack: this.state.activeBackgroundTrack,
                    activeBackgroundAudioId: this.state.activeBackgroundAudioId,
                    tutorial: this.state.tutorial,
                    textSize: this.state.textSize,
                    backwards: true,
                }
            ));
        } else {
            this.goToBasicScreen(SCREENS.MAIN_MENU);
        }
    }

    setPlayers(species, jobs) {
        this.setSpecies(species);
        this.setJobs(jobs);
        if(
            !this.props.manager.historyManager.seenIndex("code")
            || !this.props.manager.historyManager.seenIndex("difficulty")
            || !this.props.manager.historyManager.seenIndex("intro")
        ) {
            this.goToEntry("code");
        } else {
            this.returnToScenario();
        }
    }
    setPage(p, sync=true) {
        this.setState({page: p, backwards: false});
        if(sync) {
            this.props.manager.historyManager.saveHistory({
                save_code: this.props.manager.historyManager.history.save_code,
                page: p,
            }, true);
        }
    }

    startBackgroundPlayer(which_track) {
        if(which_track in AUDIO_URLS) {
            if(which_track !== this.state.activeBackgroundTrack) {
                if(this.state.activeBackgroundAudioId && this.backgroundPlayer) {
                    this.backgroundPlayer.once(
                        'fade',
                        ()=>this.audioGo(which_track),
                        this.state.activeBackgroundAudioId
                    );
                    this.backgroundPlayer.fade(getBackgroundVolume(), 0, 1000, this.state.activeBackgroundAudioId);
                } else {
                    this.audioGo(which_track);
                }
            }
        }
    }

    audioGo(which_track) {
        if(this.backgroundPlayer && this.state.activeBackgroundAudioId) {
            this.backgroundPlayer.stop(this.state.activeBackgroundAudioId);
        }
        this.backgroundPlayer = new Howl({
            src: AUDIO_URLS[which_track],
            volume: 0,
            loop: true,
        });
        this.setState({
            activeBackgroundTrack: which_track,
            activeBackgroundAudioId: null,
            backwards: false
        });
        if(getBackgroundVolume() !== 0) {
            let new_id = this.backgroundPlayer.play();
            this.backgroundPlayer.fade(0, getBackgroundVolume(), 1000, new_id);
            this.setState({
                activeBackgroundAudioId: new_id, backwards: false
            });
        }
    }

    stopBackgroundPlayer() {
        if(this.backgroundPlayer && this.state.activeBackgroundAudioId) {
            let old_id = this.state.activeBackgroundAudioId;
            this.backgroundPlayer.once(
                'fade',
                ()=>this.backgroundPlayer.stop(old_id),
                old_id
            );
            this.backgroundPlayer.fade(getBackgroundVolume(), 0, 1000, this.state.activeBackgroundAudioId);
            this.setState({
                activeBackgroundTrack: null,
                activeBackgroundAudioId: null, backwards: false
            });
        }
    }

    controlAudio(track) {
        if(track) {
            this.startBackgroundPlayer(track);
        } else {
            this.stopBackgroundPlayer();
        }
    }

    adjustBackgroundVolume(new_volume) {
        if(this.backgroundPlayer && this.state.activeBackgroundAudioId) {
            if(new_volume === 0) {
                this.backgroundPlayer.pause(this.state.activeBackgroundAudioId)
            } else {
                if(!this.backgroundPlayer.playing(this.state.activeBackgroundAudioId)) {
                    this.backgroundPlayer.play(this.state.activeBackgroundAudioId);
                }
                this.backgroundPlayer.volume(new_volume, this.state.activeBackgroundAudioId);
            }
        }
    }

    languageChosen() {
        if(
            this.state.previousState.activeScreen === SCREENS.PLAY_MENU
            || this.state.previousState.activeScreen === SCREENS.ENTRY_DISPLAY
            || this.state.previousState.activeScreen === SCREENS.HISTORY
        ) {
            this.goToBasicScreen(SCREENS.MAIN_MENU);
        } else {
            this.goToPreviousState();
        }
    }

    goToBasicScreen(whichScreen) {
        if(whichScreen === SCREENS.MAIN_MENU) {
            this.controlAudio();
        }
        this.setState(new ScenarioInterfaceState(
            whichScreen,
            null, null
        ));
    }
    goToNarrativeTextChoiceScreen() {
        this.setState(new ScenarioInterfaceState(
            SCREENS.NARRATIVE_TEXT_CHOICE,
            this.state.activeScenario,
            null,
        ));
    }
    endTutorial() {
        this.setTutorial(0);
    }

    setTutorial(val) {
        setTutorialOption(val);
        this.setState({
            tutorial: val, backwards: false,
            tutorialEntry: null,
        })
    }

    goToUploadScreen() {
        this.setState(new ScenarioInterfaceState(
            SCREENS.UPLOAD,
            null,
            null,
        ));
    }
    goToOptionsScreen() {
        this.setState(new ScenarioInterfaceState(
            SCREENS.OPTIONS,
            this.state.activeScenario,
            this.state.activeEntry,
            Object.assign({}, this.state)
        ));
    }
    goToLanguageScreen() {
        let previous_state = null;
        if(this.state.previousState !== null){
            previous_state = Object.assign({}, this.state.previousState);
        }
        this.setState(new ScenarioInterfaceState(
            SCREENS.LANGUAGE,
            this.state.activeScenario,
            this.state.activeEntry,
            previous_state,
        ));
    }
    goToScenario(scenario, setup=false, is_new=false, save_code=null, loadedScenario=null) {
        let code;
        if(typeof scenario === "string") {
            code = scenario;
        } else {
            code = scenario.scenario_code;
            if(!code) code = scenario.code;
        }
        if(!loadedScenario) loadedScenario = this.props.manager.loadScenario(code);
        let entry = null;
        if(is_new) {
            let save = this.props.manager.historyManager.historySaver.newQuest(scenario.code);
            loadedScenario.save_code = save.save_code;
            this.props.manager.historyManager.loadHistory(save.save_code, false);
        } else {
            loadedScenario.save_code = save_code;
            this.props.manager.historyManager.loadHistory(save_code, true);
        }
        if(setup) {
            if(is_new) {
                entry = loadedScenario.getEntry("setup", this.props.manager.historyManager, this.state.page);
            } else {
                entry = loadedScenario.getEntry("setup-load", this.props.manager.historyManager, this.state.page);
            }
        }
        this.setState(new ScenarioInterfaceState(
            (entry ? SCREENS.ENTRY_DISPLAY : SCREENS.PLAY_MENU),
            loadedScenario,
            entry,
            Object.assign({}, this.state)
        ));
        this.setPage(this.props.manager.historyManager.history.page, false);
    }
    loadDevScenario(primary, secondary) {
        let loadedScenario = this.props.manager.loadScenario(null, primary, secondary);
        this.goToScenario({code: "dev"}, true, true, null, loadedScenario);
    }
    returnToScenario() {
        if(this.state.activeEntry && this.state.activeEntry.index==="save_quit-1-1-1") {
            this.goToBasicScreen(SCREENS.MAIN_MENU);
            return;
        }
        this.setState(new ScenarioInterfaceState(
            SCREENS.PLAY_MENU,
            this.state.activeScenario,
            null
        ))
    }
    goToHistory() {
        this.setState(new ScenarioInterfaceState(
            SCREENS.HISTORY,
            this.state.activeScenario,
            null
        ))
    }
    goToPlayers() {
        this.setState(new ScenarioInterfaceState(
            SCREENS.SET_PLAYERS,
            this.state.activeScenario,
            null,
            Object.assign({}, this.state)
        ))
    }
    returnToScenarioWithError(error_msg) {
        if(this.state.activeScreen === SCREENS.PLAY_MENU) {
            let newState = new ScenarioInterfaceState(
                SCREENS.PLAY_MENU,
                this.state.activeScenario,
                null
            );
            newState.formError = error_msg;
            this.setState(newState);
        } else if(this.state.activeScreen === SCREENS.ENTRY_DISPLAY) {
            let newState = new ScenarioInterfaceState(
                SCREENS.ENTRY_DISPLAY,
                this.state.activeScenario,
                this.state.activeEntry
            );
            newState.formError = error_msg;
            this.setState(newState);
        }
    }
    goToEntry(newValue, checkTokens = false, from_follower_entry=false, source=null) {
        if(newValue==="characters") {
            if(this.getJobs().length===0 && this.getSpecies().length===0) {
                this.goToPlayers();
            } else {
                this.returnToScenario();
            }
            return;
        }
        if(!newValue) {
            this.returnToScenarioWithError(str.enterANumberAbove);
        } else {
            if(newValue==="save_quit") {
                this.props.manager.historyManager.saveHistory({save_code: this.props.manager.historyManager.history.save_code},true);
            }
            let is_follower_entry = from_follower_entry;
            let ent_val = parseInt(newValue);
            if(ent_val && ent_val>=1 && ent_val <=26) {
                is_follower_entry = true;
            }
            let newEntry = this.state.activeScenario.getEntry(
                newValue, 
                this.props.manager.historyManager, 
                this.state.page, this.getJobs(), 
                this.getSpecies(), 
                (is_follower_entry || this.state.activeFollowerEntry ? this.state.activeFollowerEntry : this.state.activeEntry),
                source
            );
            if(newEntry && !is_follower_entry) {
                let previousState = null;
                if(
                    (
                        this.state.activeScreen === SCREENS.ENTRY_DISPLAY
                        && this.state.activeEntry !== null
                    )
                    || this.state.activeScreen === SCREENS.SET_PLAYERS
                ) {
                    previousState = Object.assign({}, this.state);
                }
                this.setState(new ScenarioInterfaceState(
                    SCREENS.ENTRY_DISPLAY,
                    this.state.activeScenario,
                    newEntry,
                    previousState,
                ));
                this.setState({formError: null, backwards: false});
                this.manager.historyManager.addEntry(newEntry);
            } else if(newEntry && is_follower_entry) {
                this.setState({activeFollowerEntry: newEntry, formError: null, backwards: false, previousState:Object.assign({}, this.state)});
                this.manager.historyManager.addEntry(newEntry);
            } else {
                this.returnToScenarioWithError(
                    str.formatString(str.entryNotFound, newValue)
                );
            }
        }

    }
    goToTimer() {
        this.setState(new ScenarioInterfaceState(
            SCREENS.TIMER,
            this.state.activeScenario,
            null
        ));
    }

    goToGlossary(term) {
        this.setState(new ScenarioInterfaceState(
            SCREENS.GLOSSARY,
            this.state.activeScenario,
            null
        ));
    }

    showTerm(term) {
        let t = getGlossaryTerm(term);
        if(t) {
            this.setPopup(
                t.term,
                <>
                    <div className="glossary-def">{filterText(t.definition+"\n", {}, "none", (t)=>this.showTerm(t), t.term)}</div>
                    <span>
                        {t.related.length ? <>
                            <span>{str["Related:"]}&nbsp;</span>
                            {t.related.map((r, i) => <span key={"related_"+i}>
                                <span 
                                    className="related-term"
                                    onClick={()=>this.showTerm(r)}
                                >
                                    {r}
                                </span>
                                {i===t.related.length-1 ? null : ", "}
                            </span>)}
                        </> : null}
                    </span>
                </>
            )
        }
    }

    entryGoBack() {
        if(this.state.previousState === null || !this.state.activeEntry) {
            this.returnToScenario();
        } else {
            for(let contentPart of this.state.activeEntry.content){
                if(contentPart.type === "audio" && contentPart.value === "enter") {
                    this.controlAutio(null);
                    break;
                }
            };
            this.goToPreviousState();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.activeScreen !== this.state.activeScreen) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        let content;
        let content_class = "";
        let header;
        let ltp_arrow = null;
        switch(this.state.activeScreen) {
            case SCREENS.SPLASH:
                content =
                    <SplashScreen
                        manager={this.props.manager}
                        onLoaded={() => this.goToBasicScreen(SCREENS.MAIN_MENU)}
                    />;
                    break;
            case SCREENS.SCENARIO_SELECTION:
                content_class = "scenario-selection";
                header = <WoodenHeader
                    backDestination={() => this.goToBasicScreen(SCREENS.MAIN_MENU)}
                    goSettings={() => this.goToOptionsScreen()}
                >
                    <h1 className="cheddar">{str["Select Your Adventure!"]}</h1>
                </WoodenHeader>;
                ltp_arrow = <LearnToPlayArrow id="ltp-scen" key="ltp-scen" item=".scen-wizards h3 span" />
                content =
                    <ScenarioSelector
                        manager={this.props.manager}
                        onSelected={(scenario) => this.goToScenario(scenario, true, true)}
                        controlAudio={(track)=>this.controlAudio(track)}
                    />;
                    break;
            case SCREENS.ENTRY_DISPLAY:
                content_class = "entry-display";
                header = <WoodenHeader
                    backDestination={() => this.entryGoBack()}
                    goSettings={() => this.goToOptionsScreen()}
                ><h1 className="cheddar">{this.state.activeScenario.scenarioInfo.title}</h1></WoodenHeader>
                content =
                    <EntryDisplay
                        key={this.state.activeEntry.index}
                        goBack={() => this.entryGoBack()}
                        entry={this.state.activeEntry}
                        entryRedirect={(newValue) => this.goToEntry(newValue)}
                        endEntry={() => this.returnToScenario()}
                        scenario={this.state.activeScenario}
                        entryLoader={(value) => this.goToEntry(value, false)}
                        formError={this.state.formError}
                        controlAudio={(track)=>this.controlAudio(track)}
                        setDanger={(d)=>{}}
                        danger={this.state.danger}
                        players={this.state.players}
                        setPage={(p)=>this.setPage(p)}
                        showTerm={(t)=>this.showTerm(t)}
                        textSize={this.state.textSize}
                        nobuttons={this.state.tutorial && this.state.tutorialEntry && this.state.tutorialEntry.content.filter((c)=>c.type==="option").length>0}
                    />;
                    break;
            case SCREENS.CONTINUE: 
                content_class = "continue";
                header = <WoodenHeader
                    backDestination={() => this.goToBasicScreen(SCREENS.MAIN_MENU)}
                    goSettings={() => this.goToOptionsScreen()}
                ><h1 className="cheddar">{str["Continue Campaign"]}</h1></WoodenHeader>
                content =
                    <ContinueScreen
                        manager={this.props.manager}
                        onSelected={(scenario_code, save_code)=>this.goToScenario(scenario_code, true, false, save_code)}
                    />;
                    break;
            case SCREENS.GLOSSARY:
                content_class = "glossary";
                header = <WoodenHeader
                    backDestination={() => this.returnToScenario()}
                    goSettings={() => this.goToOptionsScreen()}
                ><h1 className="cheddar">{this.state.activeScenario.scenarioInfo.title}</h1></WoodenHeader>;
                content = <GlossaryContent
                    showGlossaryTerm={(term) => this.showTerm(term)}
                />;
                break;
            case SCREENS.PLAY_MENU:
                content_class = "play-menu";
                header = <WoodenHeader
                    backDestination={() => this.goToBasicScreen(SCREENS.MAIN_MENU)}
                    goSettings={() => this.goToOptionsScreen()}
                ><h1 className="cheddar">{this.state.activeScenario.scenarioInfo.title}</h1></WoodenHeader>
               content =
                    <PlayMenu
                        scenario={this.state.activeScenario}
                        entryLoader={(value) => this.goToEntry(value, true)}
                        formError={this.state.formError}
                        timerLoader={() => this.goToTimer()}
                        historyLoader={() => this.goToHistory()}
                        goSetPlayers={() => this.goToPlayers()}
                        setDanger={(d)=>{}}
                        danger={this.state.danger}
                        players={this.state.players}
                        page={this.state.page}
                        setPage={(p)=>this.setPage(p)}
                        goToGlossary={(term)=>this.goToGlossary(term)}
                    />;
                    break;
            case SCREENS.TIMER:
                content_class = "timer";
                header = <WoodenHeader
                    backDestination={() => this.returnToScenario()}
                    goSettings={() => this.goToOptionsScreen()}
                ><h1 className="cheddar">{this.state.activeScenario.scenarioInfo.title}</h1></WoodenHeader>
                content =
                    <SandTimer
                        seconds={getTimerLength()}
                        backDestination={() => this.returnToScenario()}
                        page={this.state.page ? this.state.page.toString() : null}
                    />;
                    break;
            case SCREENS.SET_PLAYERS:
                content_class = "species-selection";
                header = <WoodenHeader
                    backDestination={() => this.goToPreviousState()}
                    goSettings={() => this.goToOptionsScreen()}
                ><h1 className="cheddar">{this.state.activeScenario.scenarioInfo.title}</h1></WoodenHeader>
                content =
                    <SetPlayersScreen
                        getSpecies={()=>this.getSpecies()}
                        getJobs={()=>this.getJobs()}
                        scenario={this.state.activeScenario}
                        manager={this.props.manager}
                        setPlayers={(s,j)=>this.setPlayers(s,j)}
                    />;
                    break;
            case SCREENS.CREDITS:
                content_class = "credits-screen";
                header = <WoodenHeader
                    backDestination={() => this.goToBasicScreen(SCREENS.MAIN_MENU)}
                ><h1 className="cheddar">{str["Credits"]}</h1></WoodenHeader>
                content =
                    <CreditsScreen />;
                    break;
            case SCREENS.VARIANTS:
                content_class = "variants-screen";
                header = <WoodenHeader
                    backDestination={() => this.goToBasicScreen(SCREENS.MAIN_MENU)}
                ><h1 className="cheddar">{str["Solo / 2-Player Rules"]}</h1></WoodenHeader>
                content =
                    <VariantsScreen
                        goPrologue={()=>this.goToScenario("prologue", true, true)}
                    />;
                    break;
            case SCREENS.OPTIONS:
                content_class = "options-screen";
                header = <WoodenHeader
                    backDestination={() => this.goToPreviousState()}
                >
                    <h1 className="cheddar">{str.options}</h1>
                </WoodenHeader>
                content =
                    <OptionsScreen
                        historyManager={this.props.manager.historyManager}
                        clickedLanguage={() => this.goToLanguageScreen()}
                        adjustedVolume={(new_volume) => this.adjustBackgroundVolume(new_volume)}
                        setTutorial={new_value => this.setTutorial(new_value)}
                        tutorial={this.state.tutorial}
                        textSize={this.state.textSize}
                        setTextSize={new_size => this.setTextSize(new_size)}
                    />;
                    break;
            case SCREENS.LANGUAGE:
                content_class = "language";
                header = <WoodenHeader
                    backDestination={() => this.goToPreviousState()}
                >
                    <h1 className="cheddar">{str.language}</h1>
                </WoodenHeader>
                content =
                    <LanguageScreen
                        manager={this.props.manager}
                        languageChosen={() => this.languageChosen()}
                    />;
                    break;
            case SCREENS.HISTORY:
                content_class = "history";
                header = <WoodenHeader
                    backDestination={() => this.returnToScenario()}
                    goSettings={() => this.goToOptionsScreen()}
                >
                    <h1 className="cheddar">{this.state.activeScenario.scenarioInfo.title}</h1>
                </WoodenHeader>
                content =
                    <HistoryScreen
                        scenario={this.state.activeScenario}
                        info={this.state.activeScenario.scenarioInfo}
                        save_code={this.state.activeScenario.save_code}
                        historyManager={this.props.manager.historyManager}
                        entriesPerPage="10"
                        textSize={this.state.textSize}
                    />;
                    break;
            case SCREENS.UPLOAD:
                content =
                    <UploadScreen
                        backDestination={() => this.goToBasicScreen(SCREENS.MAIN_MENU)}
                        loadEntries={(entries_1, entries_2) => this.loadDevScenario(entries_1, entries_2)}
                    />;
                    break;
            default:
                content_class = "main-menu";
                ltp_arrow = <LearnToPlayArrow id="ltp-start" key="ltp-start" item=".start-campaign-text" />
                content =
                        <MainMenu
                            clickedPlay={() => this.goToBasicScreen(SCREENS.SCENARIO_SELECTION)}
                            clickedAbout={() => this.goToBasicScreen(SCREENS.ABOUT)}
                            clickedOptions={() => this.goToBasicScreen(SCREENS.OPTIONS)}
                            clickedUpload={() => this.goToBasicScreen(SCREENS.UPLOAD)}
                            clickedLanguage={() => this.goToBasicScreen(SCREENS.LANGUAGE)}
                            clickedInstall={() => this.goToBasicScreen(SCREENS.INSTALL)}
                            clickedVariants={() => this.goToBasicScreen(SCREENS.VARIANTS)}
                            clickedContinue={() => this.goToBasicScreen(SCREENS.CONTINUE)}
                            clickedCredits={()=>this.goToBasicScreen(SCREENS.CREDITS)}
                            
                            controlAudio={(track)=>this.controlAudio(track)}
                        />;
                        break;
        }
        let bgclass = this.getDynamicBackgroundClass();
        return <>
            <div className={"dynamic-background "+bgclass}>
                {bgclass==="generic" ? <>
                    <img src={IMAGES.backgrounds.generic_left} alt="bg" className="bg-left" />
                    <img src={IMAGES.backgrounds.generic_right} alt="bg" className="bg-right" />
                </> : null}
            </div>
            <AnimatePresence>
                <motion.div 
                    key={"screen_"+this.state.activeScreen} 
                    initial={{opacity:0}}
                    animate={{opacity:1}}
                    exit={{opacity:0}}
                    className={
                        "content"
                        +(this.hasBackground() ? " runner-bg" : "")+" "
                        +content_class
                        +(header ? " with-header" : "")
                    }
                >
                    {header}
                    {content}
                    {this.state.tutorial===1 && this.state.activeScenario && this.state.activeScenario.scenarioInfo.code==="wizards" ?
                        <div className="tutorial-spacer"></div>
                    : null}
                    {this.hasBackground() ? <>
                        <div className="left-brick"/>
                        <div className="right-brick"/>
                    </> : null}
                    {this.state.arrows}
                </motion.div>
            </AnimatePresence>
            {ltp_arrow}
            <AnimatePresence>
                {this.state.popup ?
                    <motion.div
                        key="popup-bg"
                        initial={{opacity:0}}
                        animate={{opacity:1}}
                        exit={{opacity:0}}
                        className="popup-bg"
                        onClick={()=>this.closePopup()}
                    />
                : null }
            </AnimatePresence>
            <AnimatePresence>
                {this.state.popup ?
                    <motion.div
                        key="popup"
                        initial={{opacity:0}}
                        animate={{opacity:1}}
                        exit={{opacity:0}}
                        className="popup"
                    >
                        {this.state.popup.title ? <>
                            <h3>{this.state.popup.title}</h3>
                            <hr className="popup-hr" />
                        </> : null }
                        <div className="popup-content">
                            {this.state.popup.content}
                        </div>
                        <img src={IMAGES.interface.follower_x} alt="close" className="popup-close" onClick={()=>this.closePopup()} />
                    </motion.div>
                : null}
            </AnimatePresence>
            <AnimatePresence>
                {this.state.activeFollowerEntry ?
                    <motion.div
                        key="popup-follower-bg"
                        initial={{opacity:0}}
                        animate={{opacity:1}}
                        exit={{opacity:0}}
                        className="popup-bg popup-bg-follower"
                        onClick={()=>this.setState({activeFollowerEntry: null, backwards: false})}
                    />
                : null }
            </AnimatePresence>
            <AnimatePresence>
                {this.state.activeFollowerEntry ?
                    <motion.div
                        key="popup-follower"
                        initial={{opacity:0}}
                        animate={{opacity:1}}
                        exit={{opacity:0}}
                        className={"popup-follower follower-num-"+this.getCoreFollowerID(this.state.activeFollowerEntry.index)}
                    >
                        <div className="popup-follower-inner">
                            <h3 className="cheddar">{getTopTitle(this.state.activeFollowerEntry)}</h3>
                            <div className="popup-follower-content">
                                <EntryDisplay
                                    key={this.state.activeFollowerEntry.index}
                                    goBack={() => this.setState({activeFollowerEntry: null, backwards: false})}
                                    entry={this.state.activeFollowerEntry}
                                    entryRedirect={(newValue) => this.goToEntry(newValue, false, false)}
                                    endEntry={() => this.setState({activeFollowerEntry: null, backwards: false})}
                                    scenario={this.state.activeScenario}
                                    entryLoader={(value) => this.goToEntry(value, false, false)}
                                    formError={this.state.formError}
                                    controlAudio={(track)=>this.controlAudio(track)}
                                    setDanger={(d)=>{}}
                                    danger={this.state.danger}
                                    players={this.state.players}
                                    setPage={(p)=>this.setPage(p)}
                                    showTerm={(t)=>this.showTerm(t)}
                                    followerEntry={true}
                                    textSize={this.state.textSize}
                                />
                                <div onClick={()=>this.goToPreviousState()} className="follower-back-container">
                                <img src={IMAGES.interface.back} class="follower-back-img" alt="back" /><span>{str["BACK"]}</span>
                                </div>
                            </div>
                            <img src={IMAGES.interface.follower_x} alt="close" className="popup-follower-close" onClick={()=>this.setState({activeFollowerEntry: null})} />
                        </div>
                    </motion.div>
                : null}
            </AnimatePresence>
            {this.state.tutorial===1 && this.state.activeScenario && this.state.activeScenario.scenarioInfo.code==="wizards" ?
                <TutorialDisplay
                    entry={this.state.activeEntry}
                    followerEntry={this.state.activeFollowerEntry}
                    activeScenario={this.state.activeScenario}
                    player={this.state.players}
                    showTerm={(t)=>this.showTerm(t)}
                    endTutorial={()=>this.endTutorial()}
                    manager={this.props.manager}
                    textSize={this.state.textSize}
                    setArrows={(a)=>this.setArrows(a)}
                    tutorialEntry={this.state.tutorialEntry}
                    setTutorialEntry={(e)=>this.setState({tutorialEntry:e, backwards: false})}
                    backwards={this.state.backwards}
                />
            : null}
            {this.props.debug && this.state.activeScenario ? 
                <ScenarioDev goToEntry={(v,s)=>this.goToEntry(v, false, false, s)} scenario={this.state.activeScenario} entry={this.state.activeEntry} followerEntry={this.state.followerEntry} tutorialEntry={this.state.tutorialEntry} /> 
            : null}
        </>
    }

    getCoreFollowerID(index) {
        let spl_index = index.split("-");
        if(spl_index.length===0) {
            return null;
        }
        if(spl_index[0]==="gensub" && spl_index.length>1) {
            return spl_index[1];
        }
        return spl_index[0];
    }

    getDynamicBackgroundClass() {
        if(this.state.activeScreen===SCREENS.MAIN_MENU) {
            return "main-menu";
        }
        if(this.state.activeScenario) {
            if(this.state.page && this.state.page !== "" && this.state.page in PAGE_BACKGROUND_CLASSES) {
                return PAGE_BACKGROUND_CLASSES[this.state.page];
            }
            let code = this.state.activeScenario.scenarioInfo.code;
            if(code in SCENARIO_BACKGROUND_CLASSES) {
                return SCENARIO_BACKGROUND_CLASSES[code];
            }
        }
        return "generic";
    }

    hasBackground() {
        return !BLANK_BGS.includes(this.state.activeScreen);
    }

}
