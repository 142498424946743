import { IMAGES } from "../../image-data";
import React from "react";

export class SettingsButton extends React.Component {
    goSettings() {
        this.props.goSettings();
    }

    render() {
        return(
            <button className="settings" onClick={() => this.goSettings()}>
                <img src={IMAGES.interface.settings} alt="Settings" />
            </button>
        );
    }
}
