import { IMAGES } from "../../image-data";
import React from "react";

export class BackButton extends React.Component {
    goBack() {
        this.props.goBack();
    }

    render() {
        return(
            <button className="back" onClick={() => this.goBack()}>
                <img src={IMAGES.interface.back} alt="Go Back" />
            </button>
        );
    }
}
