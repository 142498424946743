import React from "react";
import { BackButton } from "./BackButton";
import { SettingsButton } from "./SettingsButton";

export function WoodenHeader(props) {
    return <div className="wooden-header">
        <div className="wooden-header-left">
            {props.children}
        </div>
        <div className="wooden-header-right">
            <BackButton goBack={() => props.backDestination()}/>
            {props.goSettings ? <SettingsButton goSettings={() => props.goSettings()}/> : null}
        </div>
    </div>
}