import React from "react";
import { str } from "../Scenarios";
import { hasSeenTutorial } from "../../utils";

export class LearnToPlayArrow extends React.Component {
    constructor(props) {
        super(props);
        this.timer = null;
        this.state = {mounted: false};
    }
    componentWillUnmount() {
        clearTimeout(this.timer);
    }
    componentDidMount() {
        this.timer = setTimeout(()=>{
            this.setState({mounted: true});
        }, 1500);
    }
    render() {
        if(hasSeenTutorial()) return null;
        if(!this.state.mounted) {
            return <div
                className="pulse learn-to-play-arrow cheddar noshow"
                id={this.props.id}
                key={this.props.id}
            >
                {str["Learn To Play!"]}
            </div>
        }
        let el = document.querySelector(this.props.item);
        if(!el) {
            setTimeout(()=>this.setState({}), 300);
            return null;
        }
        let el_rect = el.getBoundingClientRect();
        return <div
            className="pulse learn-to-play-arrow cheddar"
            id={this.props.id}
            key={this.props.id}
            style={{
                top: el_rect.y+(el_rect.height/2)-(125/2)+"px",
                left: (el_rect.x+el_rect.width)+"px",
            }}
        >
            {str["Learn To Play!"]}
        </div>
    }
}


