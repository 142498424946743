import React from "react";
import {str} from "../../Scenarios";
import {IMAGES} from "../../../image-data";
import {AUDIO} from "../../../audio-data";
import {Howl} from 'howler';
import {filterText} from "../../../utils";
import {getSoundEffectVolume} from "../../../utils";
import { BUTTON_STYLES, WideButton } from "../WideButton";
import "./SandTimer.scss";

export class SandTimer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            secondsLeft: this.props.seconds,
            counter: 0
        };
        this.interval = (this.props.seconds * 1000) / 8;
        this.timer = setInterval(() => this.oneSecond(), 1000, this);
        this.intervalTimer = setInterval(() => this.intervalSeconds(), this.interval, this);
        let song_length = this.props.seconds.toString();
        if(!(song_length in AUDIO.timers)) song_length="45";
        this.music = new Howl({
            src: [props.page && props.page in AUDIO.timers[song_length] ? AUDIO.timers[song_length][props.page] : AUDIO.timers[song_length].default],
            volume: getSoundEffectVolume(),
            autoplay: true,
            onend: () => {
                if(getSoundEffectVolume() !== 0) {
                    this.bell.play();
                }
            }
        });
        this.bell = new Howl({
            src: [AUDIO.bell],
            volume: getSoundEffectVolume(),
            autoplay: false,
        });
    }

    componentWillUnmount() {
        this.music.stop();
        clearInterval(this.timer);
        this.timer = null;
        clearInterval(this.intervalTimer);
        this.intervalTimer = null;
    }

    prepGoBack() {
        this.props.backDestination();
    }

    oneSecond() {
        let newSeconds = this.state.secondsLeft - 1;
        if (newSeconds === 0) {
            this.setState({
                message: filterText(str.timeIsUpDiscontentPlus1),
                secondsLeft: 0
            });
            clearInterval(this.timer);
            
        } else {
            this.setState({
                message: "",
                secondsLeft: newSeconds
            });
        }
    }

    intervalSeconds() {
        if (this.state.counter === 8) {
            clearInterval(this.intervalTimer);
        } else {
            this.setState({
                counter: this.state.counter + 1
            });
        }
    };

    render() {
        return(<>
            <div className="time-left">
                <div className="seconds cheddar">
                  <span>{this.state.secondsLeft}</span>
                </div>
                <img className="hourglass" src={IMAGES['images/round-timer-frames/round-timer-frames-' + this.state.counter + '.png']} alt="Hourglass" />
            </div>
            <div className="timer-message">
                {this.state.message}
            </div>
            <WideButton style={BUTTON_STYLES.SMALL_EXTENDED} onClick={() => this.prepGoBack()}>
                {str.done}
            </WideButton>
        </>);
    }
}
