import lang_en from './en/static.json';

const translationData = {
    en: lang_en,
};

const translationInfo = [
    {
        "code": "en",
        "name": "English",
        "scenarioInfo": [
            {
                "code": "wizards",
                "title": "Wizards & Wurms",
                "difficulty": 2,
                "map": "Map A",
                "entry_files": [
                    "en/wizards",
                    "en/generic",
                    "en/tutorial",
                ],
                "map_icons": [
                    "camp",
                    "sidequest",
                    "fortune",
                    "omen",
                    "tavern",
                    "duel",
                    "trading",
                    "chaos",
                    "crossroad",
                    "treasure",
                    "law",
                    "event_81",
                    "location_82",
                    "event_83",
                    "event_84",
                    "location_85",
                    "event_86",
                    "location_87",
                ],
                "pages": [
                    "", "9", "19", "27", "35", "41", "49"
                ]
            },
            {
                "code": "sisters",
                "title": "Sisters & Songs",
                "difficulty": 3,
                "map": "Map B",
                "entry_files": [
                    "en/sisters",
                    "en/generic"
                ],
                "map_icons": [
                    "camp",
                    "sidequest",
                    "fortune",
                    "omen",
                    "tavern",
                    "duel",
                    "trading",
                    "chaos",
                    "crossroad",
                    "treasure",
                    "law",
                    "location_81",
                    "location_82",
                    "event_83",
                    "location_84",
                    "location_85",
                    "location_86",
                ],
                "pages": [
                    "", "11", "13", "15", "17", "19", "21", "23", "25", "27"  
                ]
            },
            {
                "code": "relics",
                "title": "Relics & Revenge",
                "difficulty": 3,
                "map": "Map C",
                "entry_files": [
                    "en/relics",
                    "en/generic"
                ],
                "map_icons": [
                    "camp",
                    "sidequest",
                    "fortune",
                    "omen",
                    "tavern",
                    "duel",
                    "trading",
                    "chaos",
                    "crossroad",
                    "treasure",
                    "law",
                    "location_81",
                    "location_82",
                    "event_83",
                    "location_84",
                    "location_85",
                    "event_86",
                    "location_87",
                    "event_88"
                ],
                "pages": [
                    "", "3", "5", "7", "9", "11", "19", "33", "43"  
                ]
            },
            {
                "code": "deeper",
                "title": "Deeper & Deeper",
                "difficulty": 4,
                "map": "Map D",
                "entry_files": [
                    "en/deeper",
                    "en/generic"
                ],
                "map_icons": [
                    "camp",
                    "sidequest",
                    "fortune",
                    "omen",
                    "tavern",
                    "duel",
                    "trading",
                    "chaos",
                    "crossroad_special",
                    "treasure",
                    "law",
                    "event_81",
                    "location_82",
                    "location_83",
                    "event_84",
                    "location_85",
                    "event_86",
                    "event_87",
                    "location_88",
                    "location_89",
                    "event_90"
                ],
                "pages": [
                    "", "17", "19", "21", "27", "29", "41", "45", "47"
                ]
            },
            {
                "code": "heroes",
                "title": "Heroes & Horrors",
                "difficulty": 4,
                "map": "Map E",
                "entry_files": [
                    "en/heroes",
                    "en/generic"
                ],
                "map_icons": [
                    "camp",
                    "sidequest",
                    "fortune",
                    "omen",
                    "tavern",
                    "duel",
                    "trading",
                    "chaos",
                    "crossroad",
                    "treasure",
                    "law",
                    "event_81",
                    "event_82",
                    "location_83",
                    "location_84",
                    "event_85",
                    "event_86",
                    "location_87",
                    "location_88",
                    "location_89",
                ],
                "pages": [
                    "", "13", "19", "31", "35", "37", "39", "41", "47"
                ]
            }
        ]
    }
];

export {translationData, translationInfo};
