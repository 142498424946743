import "./ScenarioDev.scss";
import React from "react";

export default function ScenarioDev(props) {
    return (
        <div className="scenario-dev">
            {props.scenario.entries.map((entry, index) => 
                <div
                    key={"debug-"+index}
                    className={"debug-entry"
                        +(
                            (props.entry && props.entry.index===entry.index)
                            || (props.tutorialEntry && props.tutorialEntry.index===entry.index)
                            || (props.followerEntry && props.followerEntry.index===entry.index)
                            ? " active" : "") 
                    }
                    onClick={()=>props.goToEntry(entry.index, entry.source)}
                >
                    {entry.source.slice(entry.source.length-1)!=="0" ? "gen " : ""}{entry.index}
            </div>)}
        </div>
    );
}