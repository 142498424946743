import {filterText, } from "../../../../utils";
import React from "react";

export function EntryText(props) {
    let titleHtml = null;
    if(props.title !== "") titleHtml = (
        <h2>{props.title}</h2>
    );
    if (!props.firstFollowerEntry) return(<div className={"entry-content content-text text-size-"+(props.textSize || 4)}>
        {titleHtml}
        {filterText(props.value, props.info, props.save_code, props.showTerm)}
    </div>)

    return(<div className="follower-first"><div className={"entry-content content-text text-size-"+(props.textSize || 4)}>
        {titleHtml}
        {filterText(props.value, props.info, props.save_code, props.showTerm)}
    </div></div>)
}
