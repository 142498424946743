import React from "react";
import {str} from "../../../Scenarios";
import {EntryImage} from "./EntryImage";
import {EntryOption} from "./EntryOption";
import {EntryText} from "./EntryText";
import {FOLLOWERS, getAudioSet, hasItalicText} from "../../../../utils";
import {AudioPlayer} from "../../AudioPlayer";
import "./EntryDisplay.scss";
import { IMAGES } from "../../../../image-data";
import { AnimatePresence, motion } from "framer-motion";
import { EntryArrow } from "./EntryArrow";

const SETUP_ORDER = [
    "setup-2",
    "setup-2-1",
    "setup-2-1-1",
    "setup-2-1-1-1",
    "setup-2-1-1-1-1",
    "setup-2-1-1-1-1-1",
    "setup-2-1-1-1-1-1-1",
    "setup-2-1-1-1-1-1-1-1",
    "setup-2-1-1-1-1-1-1-1-1",
    "setup-2-1-1-1-1-1-1-1-1-1",
    "setup-2-1-1-1-1-1-1-1-1-1-1",
    "setup-2-1-1-1-1-1-1-1-1-1-1-1",
    "setup-2-1-1-1-1-1-1-1-1-1-1-1-1",
    "setup-2-1-1-1-1-1-1-1-1-1-1-1-1-1",
    "setup-2-1-1-1-1-1-1-1-1-1-1-1-1-1-1",
    "setup-2-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1",
    "setup-2-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1",
    "setup-2-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1",
    "setup-load-1",
    "setup-load-1-1",
    "setup-load-1-1-1",
    "setup-load-1-1-1-1",
    "setup-load-1-1-1-1-1",
    "setup-load-1-1-1-1-1-1",
    "setup-load-1-1-1-1-1-1-1",
    "setup-load-1-1-1-1-1-1-1-1",
    "setup-load-1-1-1-1-1-1-1-1-1",
    "setup-load-1-1-1-1-1-1-1-1-1-1",
    "setup-load-1-1-1-1-1-1-1-1-1-1-1",
    "setup-load-1-1-1-1-1-1-1-1-1-1-1-1",
    "setup-load-1-1-1-1-1-1-1-1-1-1-1-1-1",
    "setup-load-1-1-1-1-1-1-1-1-1-1-1-1-1-1",
    "setup-load-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1",
]

export class EntryDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.exit_track = null;
        this.audio_track = null;
        this.hideNarrativeText = false;
        this.hideVoiceover = false;
        this.state = {
            show_followers: false,
        }
    }

    componentDidMount() {
        this.audioUpdate();
        if(!this.props.istutorial) {
            window.scrollTo(0, 0);
        }

        let page_turn_content = this.props.entry.content.filter(c=>c.type==="page_turn");
        if(page_turn_content.length>0) {
            let new_page = page_turn_content[0].title;
            this.props.setPage(new_page);
        }

        if(this.props.setArrows) {
            let arrow_content = this.props.entry.content.filter(c=>c.type==="arrow");
            this.props.setArrows(arrow_content.map((a, i)=> <EntryArrow
            key={this.props.entry.index+"_content_arrow"+i}
            id={this.props.entry.index+"_content_arrow"+i}
            item={a.value}
            flip={a.title==="flip"}
        />));
        }

    }

    componentWillUnmount() {
        if(this.props.setArrows) this.props.setArrows([]);
    }


    audioUpdate() {
        for(let contentPart of this.props.entry.content) {
            if(contentPart.type === "audio") {
                if(contentPart.value === "exit") {
                    this.exit_track = contentPart.title;
                } else {
                    this.goTrack(contentPart.title);
                }
                break;
            }
        }
    }

    goTrack(track_title) {
        if(track_title === "stop") {
            this.props.controlAudio(null);
        } else {
            this.props.controlAudio(track_title);
        }
    }

    clickedOption(value) {
        if(this.exit_track) {
            this.goTrack(this.exit_track);
        }
        if(value==="return") {
            this.props.endEntry();
        } else {
            this.props.entryRedirect(value);
        }
    }

    entryLoader(value, checkTokens) {
        if(this.exit_track) {
            this.goTrack(this.exit_track);
        }
        this.props.entryLoader(value, checkTokens);
    }

    choseFollower(v) {
        this.setState({show_followers: false});
        this.props.entryRedirect(v);
    }


    render() {
        this.audio_track = null;
        let arrayToRender = [];
        let toptitle = null;
        let hasOption = false;
        let x = 0;
        let lastButton = null;
        let hasAudio = false;
        let supplemental = [];
        for(let contentPart of this.props.entry.content) {
            switch(contentPart.type) {
                case "text":
                    if(!hasAudio && hasItalicText(contentPart.value) && !this.hideVoiceover) {
                        let audio_set = getAudioSet(this.props.entry.scenario, this.props.entry.source);
                        if(this.props.entry.index in audio_set) {
                            this.audio_track = <AudioPlayer
                                track={audio_set[this.props.entry.index]}
                                key={this.props.entry.index+"_audio"}
                                followerEntry={this.props.followerEntry}
                            />;
                            hasAudio = true;
                        }
                    }
                    let this_title = contentPart.title;
                    if(contentPart.title !== "" && !toptitle) {
                        toptitle = contentPart.title;
                        this_title = "";
                    }
                    arrayToRender.push(
                        <EntryText
                            key={this.props.entry.index+"_content_"+x}
                            title={this_title}
                            value={contentPart.value}
                            info={this.props.scenario.scenarioInfo}
                            save_code={this.props.scenario.save_code}
                            showTerm={this.props.showTerm}
                            firstFollowerEntry={this.props.followerEntry && x===0 && !this.audio_track}
                            textSize={this.props.textSize}
                        />
                    );
                    x++;
                    break;
                case "image":
                    arrayToRender.push(
                        <EntryImage
                            key={this.props.entry.index+"_content_"+x}
                            title={contentPart.title}
                            value={contentPart.value}
                        />);
                    x++;
                    break;
                case "option":
                    hasOption = true;
                    lastButton = ()=>this.clickedOption(contentPart.value);
                    if(this.props.entry.index==="tutorial-treasure") {
                        if(x>=1 && x<=4) {
                            let tt_src;
                            switch(x) {
                                case 2:
                                    tt_src = IMAGES.entry_buttons.treasure_armor;
                                    break;
                                case 3:
                                    tt_src = IMAGES.entry_buttons.treasure_weapon;
                                    break;
                                case 4: 
                                    tt_src = IMAGES.entry_buttons.treasure_augment;
                                    break;
                                default:
                                    tt_src = IMAGES.entry_buttons.treasure_trinket;
                                    break;
                            }
                            supplemental.push(<div 
                                className={"tut-treasure-item item-"+x}
                                key={this.props.entry.index+"_content_"+x}
                                onClick={()=>this.clickedOption(contentPart.value)}
                            >
                                <img src={tt_src} alt={contentPart.title} />
                                <span>{contentPart.title}</span>
                            </div>)
                            if(x===4) {
                                arrayToRender.push(
                                    <div className="tut-treasure-container" key="tut-treasure-container">
                                        {supplemental}
                                    </div>
                                )
                            }
                            x++;
                            break;
                        }
                    } else if(this.props.entry.index==="tutorial-spend-time-tokens") {
                        if(x>=1 && x<=8) {
                            let tk_src;
                            switch(x) {
                                case 1:
                                    tk_src = IMAGES.entry_buttons.token_starting_follower;
                                    break;
                                case 2:
                                    tk_src = IMAGES.entry_buttons.token_follwer;
                                    break;
                                case 3:
                                    tk_src = IMAGES.entry_buttons.token_suppies;
                                    break;
                                case 4:
                                    tk_src = IMAGES.entry_buttons.token_wound;
                                    break;
                                case 5:
                                    tk_src = IMAGES.entry_buttons.token_risk_excl;
                                    break;
                                case 6:
                                    tk_src = IMAGES.entry_buttons.token_risk_excl_plus;
                                    break;
                                case 7:
                                    tk_src = IMAGES.entry_buttons.token_risk_radiation;
                                    break;
                                case 8:
                                    tk_src = IMAGES.entry_buttons.token_risk_radiation_plus;
                                    break;
                                default:
                                    break;
                            }
                            supplemental.push(<div 
                                className={"tut-risk-token token-"+x}
                                key={this.props.entry.index+"_content_"+x}
                                onClick={()=>this.clickedOption(contentPart.value)}
                            >
                                <img src={tk_src} alt={contentPart.title} />
                            </div>);
                            if(x===8) {
                                arrayToRender.push(
                                    <div className="tut-risk-container" key="tut-risk-container">
                                        {supplemental}
                                    </div>
                                )
                            }
                            x++;
                            break;
                        }
                    }
                    arrayToRender.push(
                        <EntryOption
                            title={contentPart.title}
                            value={contentPart.value}
                            key={this.props.entry.index+"_content_"+x}
                            classit={this.props.entry.index.replace(/ /g, "_").replace(/:/g, "_")+"_content_"+x}
                            clickedOption={(value) => this.clickedOption(value)}
                            tutorial={this.props.tutorial}
                            nobuttons={this.props.nobuttons}
                        />);
                    x++;
                    break;
                case "input":
                    arrayToRender.push(
                        <img 
                            onClick={()=>this.setState({show_followers: true,})} 
                            key={this.props.entry.index+"_content_"+x}
                            className="quickfill-followers" 
                            alt="Follower tokens" 
                            src={IMAGES.interface.follower_button} 
                        />
                    );
                    x++;
                    break;
                default:
                    break;
            }
        }
        if(!hasOption && this.props.tutorial!==true) {
            arrayToRender.push(
                <EntryOption
                    title={str.continue}
                    value="return"
                    clickedOption={(value) => this.clickedOption(value)}
                    key={this.props.entry.index+"_return_button"}
                    nobuttons={this.props.nobuttons}
                />
            )
        }

        if(!SETUP_ORDER.includes(this.props.entry.index)) {
            return (<>
                { toptitle && !this.props.followerEntry ? <h2 className="upper cheddar">
                    <span>{toptitle}</span>
                </h2> : null }
                <div className={
                    "entry-container"
                    +(toptitle ? " with-top" : "")
                    +(this.props.followerEntry ? " follower-entry" : "")
                }>
                    {this.audio_track}
                    {arrayToRender}
                </div>
                <AnimatePresence>
                    {this.state.show_followers ? <>
                        <motion.div initial={{opacity: 0}} animate={{opacity: 1,}} exit={{opacity: 0}} key={"overlay"} className="follower-overlay" onClick={()=>this.setState({show_followers: false})}></motion.div>
                        <motion.div initial={{opacity: 0}} animate={{opacity: 1,}} exit={{opacity: 0}} key={"follower-display"} className="follower-display">
                            {Object.keys(FOLLOWERS).map((k, i)=>
                                <button className="follower-button" key={i} onClick={()=>this.choseFollower(FOLLOWERS[k])}>
                                    <img className="follower-token" alt={k} src={IMAGES.followers[k]} />
                                    <div className="follower-number cheddar">{FOLLOWERS[k]}</div>
                                </button>
                            )}
                            <img src={IMAGES.interface.follower_x} alt="close" className="follower-close" onClick={()=>this.setState({show_followers: false})} />
                        </motion.div>
                    </> : null}
                </AnimatePresence>
            </>);
        }

        return (<>
            <h2 className="cheddar guide">{str["Setup Guide"]}</h2>
            { toptitle ? <h2 className="upper cheddar setup">
                <button disabled={SETUP_ORDER.indexOf(this.props.entry.index)===0} className="setup-arrow" onClick={()=>this.props.goBack()}>
                    <img src={IMAGES.interface.setup_back} alt="back" />
                </button>
                <span>{toptitle}</span>
                <button disabled={!lastButton || SETUP_ORDER.indexOf(this.props.entry.index)===SETUP_ORDER.length-1} className="setup-arrow" onClick={lastButton}>
                    <img src={IMAGES.interface.setup_next} alt="next" />
                </button>
            </h2> : null }
            <div className={"entry-container"+(toptitle ? " with-top-top" : "")}>
                {this.audio_track}
                {arrayToRender}
            </div>
        </>);
    }

    componentDidUpdate(prevProps, prevState) {
        this.audioUpdate();
        if(prevProps.entry.index !== this.props.entry.index) {
            window.scrollTo(0, 0);
        }
    }
}
