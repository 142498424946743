import React from "react";
import { JOBS, SPECIES } from "../../../utils";
import {str} from "../../Scenarios";
import { BUTTON_STYLES, WideButton } from "../WideButton";
import "./SetPlayerScreen.scss";
import { IMAGES } from "../../../image-data";

export class SetPlayersScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active_species: this.props.getSpecies(),
            active_jobs: this.props.getJobs(),
        };
    }
    render() {
        return(<>
            <h2 className="upper cheddar">Set Species/Jobs</h2>
            <div className="species-content with-top">
                <p>{str["Select the species that are in the game."]}</p>
                <div className="select-species">
                    {Object.values(SPECIES).sort().map((species, index) => 
                        <div 
                            className="one-species"
                            key={index}
                            onClick={() => (this.state.active_species.includes(species) ? this.setState({active_species: this.state.active_species.filter(s => s!==species)}) : this.setState({active_species: [...this.state.active_species, species]}))}
                        >
                            <img alt={str[species]} src={IMAGES.species_select[species+(this.state.active_species.includes(species) ? "_selected" : "_unselected" )]} />
                            <span className="cheddar">{str[species]}</span>
                        </div>
                    )}
                </div>
                <p>{str["Select the jobs that are in the game."]}</p>
                <div className="select-jobs">
                    {Object.values(JOBS).sort().map((species, index) => 
                        <WideButton 
                            style={this.state.active_jobs.includes(species) ? BUTTON_STYLES.JOB_SELECTED : BUTTON_STYLES.JOB}
                            className={"one-species"+(this.state.active_jobs.includes(species) ? " active" : "")} 
                            key={index}
                            onClick={() => (this.state.active_jobs.includes(species) ? this.setState({active_jobs: this.state.active_jobs.filter(s => s!==species)}) : this.setState({active_jobs: [...this.state.active_jobs, species]}))}
                        >
                            {str[species]}
                        </WideButton>
                    )}
                </div>
                 <WideButton disabled={this.state.active_species.length===0 || this.state.active_jobs.lengt===0} style={BUTTON_STYLES.ENTRY} onClick={() => this.props.setPlayers(this.state.active_species, this.state.active_jobs)}>{str["Save and Continue"]}</WideButton>
            </div>
        </>);
    }
}
