export const PRIORITY_RETURNING = [
    ["tutorial-ending",],
    [
        "tutorial-campsite",
        "tutorial-healing",
        "tutorial-campsite-time",
        "tutorial-spend-time",
        "tutorial-campsite-travel",
        "tutorial-spend-time",   
    ],
    ["tutorial-attack-list"],
    ["tutorial-map-button"],
    ["tutorial-end-round-go"],
    ["tutorial-action-list"],
    ["tutorial-book-round"],
    ["tutorial-hub-intro"],
];

const TRADING_POST_QUEUE = [
    "tutorial-trading",
    "tutorial-did-gain-gold-1-1",
    "tutorial-lose-treasure",
    "tutorial-did-gain-xp-1-1",
    "tutorial-treasure",
    "tutorial-ending",
]

export const ON_QUEST_ENTRY_LOAD = {
    "difficulty": ["tutorial-difficulty"],
    "3500": ["tutorial-hub-intro"],
    "3501": null,
    "3501-1": [ //lift with your back
        "tutorial-move-boxes-your-back",
        "tutorial-influence"
    ], 
    "3501-2": [
        "tutorial-move-boxes-your-legs",
        "tutorial-wounds",
    ],
    "3501-3": [
        "tutorial-move-boxes-neither",
        "tutorial-stress",
        "tutorial-influence",
    ],
    "3502": [
        "tutorial-move-boxes-good",
        "tutorial-title",
        "tutorial-did-gain-gold-1-1",
    ],
    "3503": [
        "tutorial-junk-shop-special",
        "tutorial-luck",
        "tutorial-story-card",
        "tutorial-junk-shop-special-trinket",
        "tutorial-treasure-trinket-no-loop",
        "tutorial-robot-note",
    ],
    "3504": [
        "tutorial-magic-shop-bad",
        "tutorial-influence",
        "tutorial-corruption",
        "tutorial-title",
    ],
    "3505": [
        "tutorial-magic-shop-good",
        "tutorial-magic-shop-treasure-special",
        "tutorial-treasure-augment",
        "tutorial-title",
    ],
    "3506-1": [
        "tutorial-influence",
    ],
    "3506-2": [
        "tutorial-lose-treasure",
    ],
    "3506-3": [
        "tutorial-wounds",
    ],
    "3507": [
        "tutorial-pick-pocket-good",
        "tutorial-treasure",
        "tutorial-did-gain-gold-1-1",
    ],
    "3508-1": [
        "tutorial-luck",
    ],
    "3508-2": [
        "tutorial-title",
    ],
    "3508-4": [
        "tutorial-title",
    ],
    "3509": null,
    "3509-1": ["tutorial-end-round-go"],
    "Camp-1": [
        "tutorial-campsite",
        "tutorial-healing",
        "tutorial-corruption",
        "tutorial-campsite-time",
        "tutorial-spend-time",
        "tutorial-camp",
        "tutorial-camp-list",
        "tutorial-campsite-travel",
        "tutorial-spend-time",        
    ],
    "Camp-2": [
        "tutorial-campsite",
        "tutorial-healing",
        "tutorial-corruption",
        "tutorial-campsite-time",
        "tutorial-spend-time",
        "tutorial-camp",
        "tutorial-campsite-travel",
        "tutorial-spend-time",        
    ],
    "Camp-3": [
        "tutorial-campsite",
        "tutorial-healing",
        "tutorial-corruption",
        "tutorial-campsite-time",
        "tutorial-spend-time",
        "tutorial-camp",
        "tutorial-campsite-travel",
        "tutorial-spend-time",        
    ],
    "tutorial-title": [
        "tutorial-did-gain-xp-1-1",
    ],
    "fortune-1": ["tutorial-title"],
    "fortune-2": ["tutorial-title"],
    "Tavern-1": ["tutorial-ties"],
    "Tavern-2": ["tutorial-ties"],
    "Tavern-1-1-2-1": [
        "tutorial-tavern",
        "tutorial-generic-travel",
        "tutorial-spend-time",
        "tutorial-map-button"
    ],
    "trading-1": TRADING_POST_QUEUE,
    "trading-2": TRADING_POST_QUEUE,
    "trading-3": TRADING_POST_QUEUE,
    "trading-4": TRADING_POST_QUEUE,
    "trading-5": TRADING_POST_QUEUE,
    "trading-6": TRADING_POST_QUEUE,
    "trading-7": TRADING_POST_QUEUE,
    "trading-8": TRADING_POST_QUEUE,
    "trading-9": TRADING_POST_QUEUE,
    "trading-10": TRADING_POST_QUEUE,
    "trading-11": TRADING_POST_QUEUE,
    "trading-13": TRADING_POST_QUEUE,
    "trading-14": TRADING_POST_QUEUE,
    "trading-15": TRADING_POST_QUEUE,
    "trading-16": TRADING_POST_QUEUE,
    "trading-17": TRADING_POST_QUEUE,
    "trading-18": TRADING_POST_QUEUE,
    "trading-19": TRADING_POST_QUEUE,
    "trading-20": TRADING_POST_QUEUE,
    "81-1-1-2-1": ["tutorial-supplies"],
    "81-1-1-2-1-1-1": ["tutorial-generic-travel", "tutorial-spend-time", "tutorial-map-button"],
    "81-3-1": ["tutorial-stress", "tutorial-title", "tutorial-influence", "tutorial-ties"],
    "81-2-1": ["tutorial-ties"],
    "81-2-2-1": ["tutorial-lose-followers"],
    "81-2-1-1": ["tutorial-gain-croakley"],
    "2700": ["tutorial-dials", "tutorial-attack-round"],
    "2701-1": ["tutorial-corruption"],
    "2701-2": ["tutorial-wounds"],
    "2702-1": ["tutorial-luck"],
    "2704": ["tutorial-corruption", "tutorial-wounds"],
    "2705-2": ["tutorial-luck", "tutorial-influence"],
    "2706": ["tutorial-corruption", "tutorial-wounds"],    
    "2707": ["tutorial-corruption"],
    "2708": ["tutorial-corruption"],
    "2709": ["tutorial-wounds", "tutorial-influence"],
    "2710": ["tutorial-influence"],
    "2711": ["tutorial-follower"],
    "2712-1": ["tutorial-did-gain-gold-1-1"],
    "2712-2": ["tutorial-check-weapon"],
    "2712-2-3-3-1": ["tutorial-spend-time", "tutorial-did-gain-gold-1-1"],
    "2712-2-3-3": ["tutorial-wounds"],
};

export const AFTER_LEAVING_QUEST_ENTRY = {
    "setup": null,
    "difficulty": null,
    "3500": ["tutorial-book-round"],
    "3501-1": ["tutorial-action-list"],
    "3501-2": ["tutorial-action-list"],
    "3501-3": ["tutorial-action-list"],
    "3502": ["tutorial-action-list"],
    "3503": ["tutorial-action-list"],
    "3504": ["tutorial-action-list"],
    "3505": ["tutorial-action-list"],
    "3506-1": ["tutorial-action-list"],
    "3506-2": ["tutorial-action-list"],
    "3506-3": ["tutorial-action-list"],
    "3507": ["tutorial-action-list"],
    "3508-1": ["tutorial-action-list"],
    "3508-2": ["tutorial-action-list"],
    "3508-4": ["tutorial-action-list"],
    "Camp-1": ["tutorial-map-button"],
    "Camp-2": ["tutorial-map-button"],
    "Camp-3": ["tutorial-map-button"],
    "fortune-1-1-1-1": [
        "tutorial-generic-travel",
        "tutorial-spend-time",   
        "tutorial-map-button",
    ],
    "fortune-1-1-1-2": [
        "tutorial-generic-travel",
        "tutorial-spend-time",   
        "tutorial-map-button",
    ],
    "fortune-1-1-3-1": [
        "tutorial-generic-travel",
        "tutorial-spend-time",   
        "tutorial-map-button",
    ],
    "fortune-1-1-3-2": [
        "tutorial-generic-travel",
        "tutorial-spend-time",   
        "tutorial-map-button",
    ],
    "fortune-1-1-2": [
        "tutorial-generic-travel",
        "tutorial-spend-time",   
        "tutorial-map-button",
    ],
    "1-page35-1": [
        "tutorial-luck",
    ],
    "2-page35-1": [
        "tutorial-influence",
    ],
    "3-page35-1": [
        "tutorial-influence",
    ],
    "1-1": [
        "tutorial-healing",
    ],
    "2-1": [
        "tutorial-supplies",
    ],
    "3-1": [
        "tutorial-reduce-risk",
    ],
    "2700": ["tutorial-attack-timer"],
    "2701-1": ["tutorial-attack-list"],
    "2701-2": ["tutorial-attack-list"],
    "2702-1": ["tutorial-attack-list"],
    "2702-2": ["tutorial-attack-list"],
    "2703-2-1-1": ["tutorial-attack-list"],
    "2703-2-1-1-1": ["tutorial-attack-list"],
    "2704": ["tutorial-attack-list"],
    "2705-1": ["tutorial-attack-list"],
    "2705-2": ["tutorial-attack-list"],
    "2706": ["tutorial-attack-list"],
    "2707": ["tutorial-attack-list"],
    "2708": ["tutorial-attack-list"],
    "2709": ["tutorial-attack-list"],
    "2710": ["tutorial-attack-list"],
    "2711": ["tutorial-attack-list"],
}
//and after coming back from a follower entry, go to the queue

export const ADD_TO_QUEUE_FRONT = {
    "tutorial-tavern-1": [
        "tutorial-spend-time",
        "tutorial-healing",
        "tutorial-gain-skill",
    ],
    "tutorial-ending": [
        "tutorial-gain-skill",
        "tutorial-did-gain-gold-1-1",
        "tutorial-did-gain-xp-1-1",
        "tutorial-corruption",
        "tutorial-skill-check",
        "tutorial-check-corruption",
        "tutorial-check-roll",
        "tutorial-luck",
        "tutorial-check-luck",
        "tutorial-check-modifiers",
        "tutorial-check-help",
        "tutorial-stress",
        "tutorial-wounds",
        "tutorial-drawing-wounds",
        "tutorial-title",
        "tutorial-influence",
        "tutorial-treasure-trinket",
        "tutorial-treasure-armor",
        "tutorial-healing-armor",
        "tutorial-treasure-armor-finish",
        "tutorial-treasure-weapon",
        "tutorial-check-weapon",
        "tutorial-treasure-augment",
        "tutorial-lose-treasure",
        "tutorial-story-card",
        "tutorial-green-actions",
        "tutorial-healing",
        "tutorial-follower",
        "tutorial-spend-time",
        "tutorial-draw-risk",
        "tutorial-supplies",
        "tutorial-draw-supplies",
        "tutorial-draw-threat",
        "tutorial-spend-time-plus",
        "tutorial-reduce-risk",
        "tutorial-current-threat",
        "tutorial-lose-followers",
        "tutorial-ties",
        "tutorial-the-end",
    ],
    "tutorial-book-round-1-1-1-1-1-1-1-1-1-1": [ //move boxes
        "tutorial-resolve-an-action", 
        "tutorial-move-boxes-detail", 
        "tutorial-gain-skill", 
        "tutorial-move-boxes-check",
        "tutorial-skill-check",
        "tutorial-move-boxes-result",
        "tutorial-skill-check-finish",
        "tutorial-entry",
    ],
    "tutorial-action-list-2": [ // magic shop
        "tutorial-resolve-an-action",
        "tutorial-magic-shop-details",
        "tutorial-gain-skill", 
        "tutorial-magic-shop-check",
        "tutorial-skill-check",
        "tutorial-magic-shop-result",
        "tutorial-skill-check-finish",
        "tutorial-entry",
    ],
    "tutorial-action-list-3": [ //grab drinks
        "tutorial-resolve-an-action",
        "tutorial-green-actions",
        "tutorial-lose-treasure",
        "tutorial-grab-drinks-detail",
    ],
    "tutorial-action-list-4": [ //rest
        "tutorial-resolve-an-action",
        "tutorial-green-actions",
        "tutorial-rest-detail",
        "tutorial-spend-time",
        "tutorial-camp",
        "tutorial-action-list",
    ],
    "tutorial-action-list-5": [ // pick a pocket
        "tutorial-resolve-an-action",
        "tutorial-pick-pocket-details",
        "tutorial-gain-skill", 
        "tutorial-pick-pocket-check",
        "tutorial-skill-check",
        "tutorial-pick-pocket-result",
        "tutorial-skill-check-finish",
        "tutorial-entry",
    ],
    "tutorial-action-list-6": [ //look at flyers
        "tutorial-resolve-an-action",
        "tutorial-look-flyers-details",
        "tutorial-gain-skill",
        "tutorial-entry",
    ],
    "tutorial-camp": [
        "tutorial-camp-list",
    ],
    "tutorial-grab-drinks-detail-2": [ //buy a round
        "tutorial-influence",
        "tutorial-grab-drinks-detail",
    ],
    "tutorial-grab-drinks-detail-3": [ //rub belly
        "tutorial-luck",
        "tutorial-grab-drinks-detail",
    ],
    "tutorial-grab-drinks-detail-1": [ //make new friends
        "tutorial-follower",
        "tutorial-grab-drinks-detail",
    ],
    "tutorial-move-boxes-result-1": [ //rolled bad move boxes
        "tutorial-check-help",
    ],
    "tutorial-magic-shop-result-1": [ //rolled back magic shop
        "tutorial-check-help",
    ],
    "tutorial-pick-pocket-result-1": [ //rolled back pick pocket
        "tutorial-check-help",
    ],
    "tutorial-wounds": [
        "tutorial-drawing-wounds",
    ],
    "tutorial-spend-time": [
        "tutorial-spend-time-tokens",
        "tutorial-drawing-wounds",
    ],
    "tutorial-draw-risk": [
        "tutorial-spend-time-plus",
        "tutorial-spend-time-tokens",
    ],
    "tutorial-draw-threat": [
        "tutorial-spend-time-tokens",
    ],
    "tutorial-spend-time-tokens-1": [ //wounds
        "tutorial-spend-time-tokens",
    ],
    "tutorial-check-help": [ //help from a friend
        "tutorial-stress",
        "tutorial-help-finish",
    ],
    "tutorial-gain-skill": [
        "tutorial-did-gain-xp",
        "tutorial-did-gain-gold",
    ],
    "tutorial-did-gain-xp-1-1": [
        "tutorial-story-card",
    ],
    "tutorial-camp-list-1": [ //supplies
        "tutorial-camp-list",
    ],
    "tutorial-camp-list-2": [ //healing
        "tutorial-camp-list",
    ],
    "tutorial-camp-list-3": [ //follower
        "tutorial-camp-list",
    ],
    "tutorial-camp-list-4": [ //treasure
        "tutorial-camp-list",
    ],
    "tutorial-camp-list-5": [ //luck
        "tutorial-camp-list",
    ],
    "tutorial-camp-list-6": [ //corruption
        "tutorial-camp-list",
    ],
    "tutorial-camp-list-7": [ //risk
        "tutorial-camp-list",
    ],
    "tutorial-camp-list-8": [ //influence
        "tutorial-camp-list",
    ],
    "tutorial-camp-list-9": [ //gold
        "tutorial-camp-list",
    ],
    "tutorial-skill-check": [
        "tutorial-check-corruption",
        "tutorial-check-roll",
        "tutorial-check-luck",
        "tutorial-check-modifiers",
    ],
    "tutorial-luck": [
        "tutorial-check-luck",
    ],
    "tutorial-action-list-1": [ //visit junk shop
        "tutorial-resolve-an-action",
        "tutorial-junk-shop-detail",
    ],
    "tutorial-junk-shop-detail-1": [//junk shop trade
        "tutorial-lose-treasure",
        "tutorial-treasure",
        "tutorial-action-list"
    ],
    "tutorial-junk-shop-detail-2": [//junk shop treat yourself
        "tutorial-treasure",
        "tutorial-action-list",
    ],
    "tutorial-treasure-armor": [
        "tutorial-healing-armor",
        "tutorial-treasure-armor-finish",
        "tutorial-treasure-effect",
    ],
    "tutorial-treasure-trinket": [
        "tutorial-treasure-effect",
    ],
    "tutorial-treasure-weapon": [
        "tutorial-check-weapon",
        "tutorial-treasure-effect",
    ],
    "tutorial-treasure-weapon-no-loop": [
        "tutorial-check-weapon",
    ],
    "tutorial-treasure-augment": [
        "tutorial-treasure-weapon-no-loop",
        "tutorial-treasure-effect", //when going, remove any from future queue
    ],
    "tutorial-healing": [
        "tutorial-stress",
        "tutorial-wounds",
        "tutorial-healing-armor",
    ],
    "tutorial-treasure-corruption": [
        "tutorial-corruption",
    ],
    "tutorial-corruption": [
        "tutorial-check-corruption",
    ],
    "tutorial-treasure-effect": [
        "tutorial-treasure",
    ],
    "tutorial-treasure-effect-1-2": [ //had a camp action effect but not a schematic
        "tutorial-did-gain-gold-1-1",
    ],
    "tutorial-treasure-effect-1-1": [ //had a camp action and is schematic
        "tutorial-story-card",  
    ],
    "tutorial-end-round-go-1": [
        "tutorial-spend-time",
        "travel-step-3",
    ],
    "tutorial-attack-list-1": [ //attack, grapple
        "tutorial-gain-skill", 
        "tutorial-skill-check",
        "tutorial-check-help",
        "tutorial-skill-check-finish",
        "tutorial-entry-attack",
    ],
    "tutorial-attack-list-2": [ //attack, full assult
        "tutorial-treasure-weapon-no-loop",
        "tutorial-skill-check",
        "tutorial-check-weapon",
        "tutorial-check-help",
        "tutorial-skill-check-finish",
        "tutorial-wounds",
        "tutorial-luck",
        "tutorial-attack-list",
    ],
    "tutorial-attack-list-7": [ //attack, full assult
        "tutorial-gain-skill", 
        "tutorial-skill-check",
        "tutorial-check-help",
        "tutorial-skill-check-finish",
        "tutorial-wounds",
        "tutorial-luck",
        "tutorial-treasure",
        "tutorial-attack-list",
    ]
}

export const SHOW_EVEN_REPEAT = [
    "tutorial-the-end",
    "tutorial-ending",
    "tutorial-tavern",
    "tutorial-attack-round",
    "tutorial-attack-timer",
    "tutorial-generic-travel",
    "tutorial-treasure",
    "tutorial-treasure-effect",
    "tutorial-gain-skill",
    "tutorial-grab-drinks-detail",
    "tutorial-action-list",
    "tutorial-attack-list",
    "tutorial-spend-time-tokens",
    "tutorial-pick-pocket-detail",
    "tutorial-pick-pocket-check",
    "tutorial-pick-pocket-result",
    "tutorial-move-boxes-detail", 
    "tutorial-move-boxes-check", 
    "tutorial-move-boxes-result", 
    "tutorial-magic-shop-details",
    "tutorial-magic-shop-check",
    "tutorial-magic-shop-result",
    "tutorial-grab-drinks-detail",
    "tutorial-rest-detail",
    "tutorial-look-flyers-details",
    "tutorial-camp-list",
    "tutorial-book-round",
    "tutorial-map-button",
    "tutorial-end-round-go",
    "tutorial-book-round",
    "tutorial-hub-intro",
    "tutorial-skill-check",
    "tutorial-camp",
    "tutorial-entry",
    "tutorial-entry-attack",
    "tutorial-skill-check-finish",
]

export const BLOCK_IT = [
    "3500",
    "3509-1",
]

export const SKIP_AT_END_OF_TUTORIAL = [
    "tutorial-treasure",
    "tutorial-treasure-effect",
    "tutorial-action-list",
    "tutorial-attack-list",
    "tutorial-rest-detail",
    "tutorial-did-gain-gold",
    "tutorial-did-gain-xp",
    "tutorial-grab-drinks-detail",
    "tutorial-help-finish",
    "tutorial-junk-shop-detail",
    "tutorial-junk-shop-special",
    "tutorial-junk-shop-special-trinket",
    "tutorial-magic-shop-bad",
    "tutorial-magic-shop-check",
    "tutorial-magic-shop-details",
    "tutorial-magic-shop-good",
    "tutorial-magic-shop-result",
    "tutorial-magic-shop-treasure-special",
    "tutorial-move-boxes-check",
    "tutorial-move-boxes-detail",
    "tutorial-move-boxes-good",
    "tutorial-move-boxes-neither",
    "tutorial-move-boxes-result",
    "tutorial-move-boxes-your-back",
    "tutorial-move-boxes-your-legs",
    "tutorial-rest-detail",
    "tutorial-robot-note",
    "tutorial-skill-check-finish",
    "tutorial-treasure-trinket",
    "tutorial-spend-time-tokens",
    "tutorial-camp-list",
]


export const DONT_SHOW_UNLESS_ALL_SEEN = {
    "tutorial-check-corruption": [
        "tutorial-skill-check",
        "tutorial-corruption",
    ],
    "tutorial-check-luck": [
        "tutorial-check-roll",
        "tutorial-luck",
    ],
    "tutorial-drawing-wounds": [
        "tutorial-wounds",
        "tutorial-spend-time",
    ],
    "tutorial-healing-armor": [
        "tutorial-treasure-armor",
        "tutorial-healing",
    ],
    "tutorial-check-weapon": [
        "tutorial-treasure-weapon",
        "tutorial-skill-check",
    ],
}

export const SKIP_THIS_IF_WE_SAW_THAT = { //if and only if
    "tutorial-did-gain-xp": "tutorial-did-gain-xp-1",
    "tutorial-did-gain-gold": "tutorial-did-gain-gold-1",
    "tutorial-junk-shop-special-trinket": "tutorial-treasure-trinket",
    "tutorial-treasure-trinket-no-loop": "tutorial-treasure-trinket",
    "tutorial-treasure-trinket": "tutorial-treasure-trinket-no-loop",
    "tutorial-treasure-weapon-no-loop": "tutorial-treasure-weapon",
}

