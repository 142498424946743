import React from "react";
import { filterText } from "../../../../utils";
import { BUTTON_STYLES, WideButton } from "../../WideButton";

export class EntryOption extends React.Component {
    clickedOption() {
        this.props.clickedOption(this.props.value);
    }

    render() {
        let button_style = this.props.tutorial ? BUTTON_STYLES.ENTRY_PURPLE: BUTTON_STYLES.ENTRY;
        switch(this.props.classit) {
            case "tutorial-action-list_content_1":
            case "tutorial-action-list_content_2":
            case "tutorial-action-list_content_5":
            case "tutorial-action-list_content_6":
            case "tutorial-attack-list_content_1":
            case "tutorial-attack-list_content_3":
            case "tutorial-attack-list_content_4":
            case "tutorial-attack-list_content_5":
            case "tutorial-attack-list_content_6":
            case "tutorial-attack-list_content_7":
                button_style = BUTTON_STYLES.ENTRY_BLUE;
                break;
            case "tutorial-action-list_content_3":
            case "tutorial-action-list_content_4":
            case "tutorial-attack-list_content_2":
                button_style = BUTTON_STYLES.ENTRY_GREEN;
                break;
            default:
                break;
        }

        return(<WideButton
            classit={"key-"+this.props.classit}
            style={button_style}
            onClick={() => this.clickedOption()}
            tutorial={this.props.tutorial}
            disabled={this.props.nobuttons}
        >
            {filterText(this.props.title)}
        </WideButton>)
    }
}