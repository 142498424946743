import {IMAGES} from "./image-data";
import React from 'react';
import ls from 'local-storage';
import { AUDIO_URLS } from "./audio-data";
import { str } from "./scenarios/Scenarios";
import { areTermsSame, getEnglishGlossaryTerm } from "./scenarios/components/screens/Glossary/logic";

const option_timer_key = "option_timer";

export const SPECIES =  {
    MOUSER: 'mouser',
    GOBLIN: 'goblin',
    ELF: 'elf',
    HOUND: 'hound',
    DWARF: 'dwarf',
    STILTKIN: 'stiltkin',
    TROLL: 'troll',
    MERFOLK: 'merfolk',
    IMP: 'imp',
};

export const JOBS =  {
    WARRIOR: 'warrior',
    JUDGE: 'judge',
    RANGER: 'ranger',
    PALADIN: 'paladin',
    FRIAR: 'friar',
    BARBERIAN: 'barberian',
    SPELLSWORD: 'spellsword',
    PIRATE: 'pirate',
    DIVORCEE: 'divorcee',
    CLAMDIGGER: 'clamdigger',
    GUNSLINGER: 'gunslinger',
    PRIVATE_EYE: 'privateeye',
    BARD: 'bard',
    ARTIFICER: 'artificer',
    THEIF: 'theif',
    DISCRACED_NOBLE: 'disgracednoble',
    DUNG_FARMER: 'dungfarmer',
    WITCH: 'witch',
    KNIGHT: 'knight',
    SKINCHANGER: 'skinchanger',
    GAMBLER: 'gambler',
};


export function getTimerLength() {
    let num = parseInt(ls.get(option_timer_key) || 45);
    if([30,45,60].includes(num)) return num;
    return 45;
}

export function randomID(length) {
    let result = '';
    const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}

export function setTimerLength(new_val) {
    let num_val = 45;
    try {
        num_val = parseInt(new_val);
        if (num_val < 1) num_val = 45;
    } catch {
        num_val = 45;
    }
    ls.set(option_timer_key, num_val);
    return num_val;
}

const tutorial_key = "tutorial_key";

export function getTutorialOption() {
    let val = ls.get(tutorial_key);
    if(val===null) val = 1;
    return parseInt(val)===0 ? 0 : 1;
}

export function setTutorialOption(new_val) {
    let val = parseInt(new_val)===0 ? 0 : 1;
    ls.set(tutorial_key, val);
    return val;
}

const sound_effect_key = "sound_effect_key";

export function getSoundEffectVolume() {
    let val = ls.get(sound_effect_key);
    if(val === false || val === null) {
        val = .8;
    }
    return parseFloat(val);
}

export function setSoundEffectVolume(new_val) {
    let num_val = 1;
    try {
        num_val = parseFloat(new_val);
        if (num_val > 1) num_val = 1;
        if (num_val < 0) num_val = 0;
    } catch {
        num_val = 0;
    }
    ls.set(sound_effect_key, num_val);
    return num_val;
}

const danger_key = "danger_key";

export function getDanger() {
    let val = ls.get(danger_key);
    if(val === false || val === null) {
        val = 0;
    }
    return parseInt(val);
}

export function setDanger(new_val) {
    let num_val = 0;
    try {
        num_val = parseInt(new_val);
        if (num_val > 10) num_val = 10;
        if (num_val < 0) num_val = 0;
    } catch {
        num_val = 0;
    }
    ls.set(danger_key, num_val);
    return num_val;
}

const players_key = "players_key";

export function getAudioSet(scenario_code, source=null) {
    let lang = str.getLanguage() in AUDIO_URLS ? str.getLanguage() : "en";
    if(source==="source_1") {
        return AUDIO_URLS[lang]["generic"];    
    } else if(source==="source_0" && scenario_code in AUDIO_URLS[lang]) {
        return AUDIO_URLS[lang][scenario_code];
    } else {
        return Object.assign({}, AUDIO_URLS[lang]["generic"], AUDIO_URLS[lang][scenario_code]);
    }
}

export function getPlayers() {
    let val = ls.get(players_key);
    if(val === false || val === null) {
        return [{"name": "Player 1", current: true,}];
    }
    let players = JSON.parse(val);
    if(players.filter(player=>player.current===true).length===0) {
        players[0].current = true;
    }
    return players;
}

export function setPlayers(val) {
    ls.set(players_key, JSON.stringify(val));
    return val;
}

const page_key = "page_key";

export function getPage() {
    let val = ls.get(page_key);
    if(val === false || val === null) {
        return "3";
    }
    return val;
}

export function setPage(val) {
    ls.set(page_key, val);
    return val;
}

const background_key = "background_key";

export function getBackgroundVolume() {
    let val = ls.get(background_key);
    if(val === false || val === null) {
        val = .7;
    }
    return parseFloat(val);
}

export function setBackgroundVolume(new_val) {
    let num_val = 1;
    try {
        num_val = parseFloat(new_val);
        if (num_val > 1) num_val = 1;
        if (num_val < 0) num_val = 0;
    } catch {
        num_val = 0;
    }
    ls.set(background_key, num_val);
    return num_val;
}

const saw_tutorial_key = "saw_tutorial";
export function hasSeenTutorial() {
    return ls.get(saw_tutorial_key) === "true" || getTutorialOption() === 0;
}
export function setSeenTutorial(seen) {
    seen = seen ? "true" : "false";
    ls.set(saw_tutorial_key, seen);
}

const option_text_size_key = "option_text_size";

export function getTextSize() {
    let size = parseInt(ls.get(option_text_size_key) || 4);
    if(size<4 || size>6) size = 4;
    return size;
}

export function setTextSize(new_val) {
    let num_val = 4;
    try {
        num_val = parseInt(new_val);
        if (num_val < 4) num_val = 4;
        if (num_val > 6) num_val = 6;
    } catch {
        num_val = 6;
    }
    ls.set(option_text_size_key, num_val);
    return num_val;
}

const narrative_text_settings_key = "narrative_text_setting";

export const NARRATIVE_TEXT_SETTINGS = {
    TEXT_ONLY: "text_only",
    VOICEOVER_ONLY: "voiceover_only",
    BOTH: "both",
};

export function isNarrativetextSettingSet() {
    return isNarrativeTextSettingValid(ls.get(narrative_text_settings_key));
}

export function getNarrativeTextSetting() {
    let return_val = ls.get(narrative_text_settings_key || NARRATIVE_TEXT_SETTINGS.BOTH);
    if(isNarrativeTextSettingValid(return_val)) {
        return return_val;
    }
    return NARRATIVE_TEXT_SETTINGS.BOTH;
}

export function setNarrativeTextSetting(new_val) {
    let final_val = NARRATIVE_TEXT_SETTINGS.BOTH;
    if(isNarrativeTextSettingValid(new_val)) {
        final_val = new_val;
    }
    ls.set(narrative_text_settings_key, final_val);
    return final_val;
}

function isNarrativeTextSettingValid(val) {
    for (let value of Object.values(NARRATIVE_TEXT_SETTINGS)) {
        if(val === value) {
            return true;
        }
    }
    return false;
}

export function getAudioSpeedSetting() {
    let return_val = ls.get("audio_speed" || 1);
    return_val = parseFloat(return_val);
    if(return_val >= 0.5 && return_val <= 2) {
        return return_val;
    }
    return 1;
}

export function setAudioSpeedSetting(new_val) {
    let final_val = 1;
    if(new_val >= 0.5 && new_val <= 2) {
        final_val = new_val;
    }
    ls.set("audio_speed", final_val);
    return final_val;
}


export function isAppInstalled() {
    return window.matchMedia('(display-mode: standalone)').matches;
}



export function hasItalicText(raw_text) {
    let mods = /(\[(it)\])(.*?)\[\/\2\]/g;
    raw_text = raw_text.replace(/(?:\r\n|\r|\n|\u2028)/g, '[>]');
    let spl_text = raw_text.split(mods);
    for(let one_txt of spl_text) {
        if(one_txt === "[it]") {
            return true;
        }
    }
    return false;
}


function filterList(text) {
    let listmods = /(\[(#|li)\])(.*?)\[\/\2\]/g;
    let spl_text = text.split(listmods);
    let jsx_to_return = [];
    let spl_ranger = 0;
    while(spl_ranger<spl_text.length) {
        switch(spl_text[spl_ranger]) {
            case "[li]":
                jsx_to_return.push(
                 <li key={spl_ranger}>
                    <ul>{filterList(spl_text[spl_ranger+2])}</ul>
                 </li>
               );
               spl_ranger+=3;
                break;
            case "[#]":
                jsx_to_return.push(
                 <li key={spl_ranger}>
                    <ol>{filterList(spl_text[spl_ranger+2])}</ol>
                 </li>
               );
               spl_ranger+=3;
                break;
            default:
                for(let [index, one_item] of spl_text[spl_ranger].split("[>]").entries()) {
                    if(one_item!=="") {
                         jsx_to_return.push(
                          <li key={spl_ranger+"_"+index}>
                              {filterText(one_item)}
                          </li>
                        );
                    }
                }
                spl_ranger+=1;
                break;
        }
    }
    return jsx_to_return;
}

export function getTopTitle(entry) {
    for(let contentPart of entry.content) {
        switch(contentPart.type) {
            case "text":
                if(contentPart.title !== "") {
                    return contentPart.title;
                }
                break;
            default:
                break;
        }
    };
    return "";
}

export function getHash() {
    if(window.location.hash.length > 0) {
        return window.location.hash;
    }
    return "";
}

export const FOLLOWERS = {
    "Schala": "1",
    "Cookie": "2",
    "Grunko": "3",
    "Gopher": "4",
    "Pascal": "5",
    "Strider": "6",
    "Porter": "7",
    "Tink": "8",
    "Scav": "9",
    "Harold": "10",
    "Clara": "11",
    "Cruncher": "12",
    "Lazy Bones": "13",
    "Maverick": "14",
    "Trent": "15",
    "Gabby": "16",
    "Tik-Ton": "17",
    "Flora": "18",
    "Wilma": "19",
    "Serpa": "20",
    "Doji": "21",
    "Rookie": "22",
    "Sir Croakly": "23",
    "Otto": "24",
    "Dawn Chorus": "25",
    "Mysterious Stranger": "26",
}

export function filterText(text, info={}, save_code="none", showTerm=null, showingTerm=null) {
    if(!text) return null;
    let mods = /(\[(bo|it|me|la|#|li|_|nl)\])(.*?)\[\/\2\]/g;
    text = text.replace(/(?:\r\n|\r|\n|\u2028)/g, '[>]');
    if(showTerm && str.getLanguage()==="en") {
        text = text.replace(/(10 Sided Die|12 Sided Die|20 Sided Die|4 Sided Die|6 Sided Die|8 Sided Die|Action|Action Phase|Agility|Armor|Bonus Die|Bookkeeper|Camp Action|Campsite|Cartographer|Chaotic|Chaotic Temple|Character|Corruption|Crossroads|Duel|Dungeon|End of Round Phase|Entry|Follower|Fortune Teller|Game Master|Gold|Heal|HP|Project Manager|Influence|Influencer|Job|Lawful|Lawful Shrine|Location Book|Location Dial|Lock|Lookout|Luck|Map|Map Space|Medic|Might|Modifier|Planning Phase|Quest|Risk|Round|Scout|Sense|Skill|Skill Check|Smarts|Species|Story Card|Stress|Supplies|Tavern|Threat|Timer|Title|Trading Post|Travel|Treasure|Treasure Golem|Trinket|Weapon|Weapon Augment|Wound|XP)(s?)([ :.,"])/gi, (c, d, e, f)=>{
            return "[_]"+d+e+"[/_]"+f;
        });
    }
    let jsx_to_return = [];
    let gotterms = [];
    let spl_text = text.split(mods);
    let spl_ranger = 0;
    while(spl_ranger<spl_text.length) {
        switch(spl_text[spl_ranger]) {
            case "[_]":
                let term = spl_text[spl_ranger+2];
                if(
                    showTerm
                    && (!showingTerm || !areTermsSame(showingTerm, getEnglishGlossaryTerm(term)))
                    && !gotterms.some(g=>areTermsSame(term,g))
                ) {
                    jsx_to_return.push(
                        <span className="link" key={spl_ranger+'t'} onClick={()=>showTerm(getEnglishGlossaryTerm(term))}>
                            {term}
                        </span>
                    );
                    gotterms.push(term);
                } else {
                    jsx_to_return.push(<span key={spl_ranger+'tn'}>{term}</span>)
                }
                spl_ranger+=3;
                break;
            case "[bo]":
                jsx_to_return.push(
                    <strong key={spl_ranger}>
                        {filterText(spl_text[spl_ranger+2], info, save_code, showTerm, showingTerm)}
                    </strong>
                );
                spl_ranger+=3;
                break;
            case "[nl]":
                jsx_to_return.push(
                    <span key={spl_ranger}>
                        {filterText(spl_text[spl_ranger+2], info, save_code)}
                    </span>
                );
                spl_ranger+=3;
                break;
            case "[it]":
                jsx_to_return.push(
                    <em key={spl_ranger}>
                        {filterText(spl_text[spl_ranger+2], info, save_code)}
                    </em>
                );
                spl_ranger+=3;
                break;
            case "[me]":
                jsx_to_return.push(
                    <span className="medium-weight" key={spl_ranger}>
                        {filterText(spl_text[spl_ranger+2], info, save_code, showTerm, showingTerm)}
                    </span>
                );
                spl_ranger+=3;
                break;
            case "[la]":
                jsx_to_return.push(
                    <span className="large" key={spl_ranger}>
                        {filterText(spl_text[spl_ranger+2], info, save_code, showTerm, showingTerm)}
                    </span>
                );
                spl_ranger+=3;
                break;
            case "[#]":
                jsx_to_return.push(
                    <ol key={spl_ranger}>
                        {filterList(spl_text[spl_ranger+2], info, save_code, showTerm, showingTerm)}
                    </ol>
                );
                spl_ranger+=3;
                break;
            case "[li]":
                jsx_to_return.push(
                    <ul key={spl_ranger}>
                        {filterList(spl_text[spl_ranger+2], info, save_code, showTerm, showingTerm)}
                    </ul>
                );
                spl_ranger+=3;
                break;
            default:
                let txt = spl_text[spl_ranger];
                if(txt!=="") {
                    let items_jsx = [];
                    let split_for_items = txt.split(/\[(\$|r|h|wi|mi|a|se|sm|i|gp|d|w|g|k|l|f|s|st|x|m|t|T|c|ch|la|d4|d6|d8|d10|d12|d20|>|fr|fi|co|\*|ra|img [a-zA-Z_0-9]+)\]/);
                    for(let [index, one_item] of split_for_items.entries()) {
                        if(index%2===0) {
                            if(one_item!=="") items_jsx.push(
                                <span key={index+"_e_"+spl_ranger}>{one_item}</span>
                            );
                        } else {
                            switch(one_item.split(" ")[0]) {
                                case "img":
                                    items_jsx.push(<span class="glossary-img-container" key={spl_ranger+"_"+index}><img
                                        src={IMAGES.glossary[one_item.split(" ")[1]]}
                                        alt={one_item.split(" ")[1]}
                                        className="glossry-img"
                                    /></span>);
                                    break;
                                case "$":
                                    items_jsx.push(<img
                                        src={IMAGES.icons.treasure}
                                        alt="treasure"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "fi":
                                    items_jsx.push(<img
                                        src={IMAGES.icons.fire}
                                        alt="fire"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "m":
                                    items_jsx.push(<span key={spl_ranger+"_"+index}>{info.map}</span>);
                                    break;
                                case "co":
                                    items_jsx.push(<span key={spl_ranger+"_"+index}>{save_code}</span>);
                                    break;
                                case "fr":
                                    items_jsx.push(<img
                                        src={IMAGES.icons.frost}
                                        alt="frost"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "ch": 
                                    items_jsx.push(<img
                                        src={IMAGES.icons.chaos}
                                        alt="chaotic"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "ra":
                                    items_jsx.push(<img
                                        src={IMAGES.icons.range}
                                        alt="range"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "*":
                                    items_jsx.push(<img
                                        src={IMAGES.icons.lightning}
                                        alt="lightning"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "wi": 
                                    items_jsx.push(<img
                                        src={IMAGES.icons.will}
                                        alt="will"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "mi": 
                                    items_jsx.push(<img
                                        src={IMAGES.icons.might}
                                        alt="might"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "se": 
                                    items_jsx.push(<img
                                        src={IMAGES.icons.sense}
                                        alt="sense"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "a": 
                                    items_jsx.push(<img
                                        src={IMAGES.icons.agility}
                                        alt="agility"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "sm": 
                                    items_jsx.push(<img
                                        src={IMAGES.icons.smarts}
                                        alt="smarts"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "d4": 
                                    items_jsx.push(<img
                                        src={IMAGES.icons.d4}
                                        alt="d4"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "d6": 
                                    items_jsx.push(<img
                                        src={IMAGES.icons.d6}
                                        alt="d6"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "d8": 
                                    items_jsx.push(<img
                                        src={IMAGES.icons.d8}
                                        alt="d8"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "d10": 
                                    items_jsx.push(<img
                                        src={IMAGES.icons.d10}
                                        alt="d10"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "d12": 
                                    items_jsx.push(<img
                                        src={IMAGES.icons.d12}
                                        alt="d12"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "d20": 
                                    items_jsx.push(<img
                                        src={IMAGES.icons.d20}
                                        alt="d20"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "la": 
                                    items_jsx.push(<img
                                        src={IMAGES.icons.law}
                                        alt="lawful"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "g":
                                    items_jsx.push(<img
                                        src={IMAGES.icons.gold}
                                        alt="GP"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "gp":
                                    items_jsx.push(<img
                                        src={IMAGES.icons.gold}
                                        alt="GP"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "k":
                                    items_jsx.push(<img
                                        src={IMAGES.icons.risk}
                                        alt="risk"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "w":
                                    items_jsx.push(<img
                                        src={IMAGES.icons.wound}
                                        alt="wound"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "r":
                                    items_jsx.push(<img
                                        src={IMAGES.icons.luck}
                                        alt="luck"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "h":
                                    items_jsx.push(<img
                                        src={IMAGES.icons.hp}
                                        alt="hp"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "i":
                                    items_jsx.push(<img
                                        src={IMAGES.icons.influence}
                                        alt="influence"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "d":
                                    items_jsx.push(<img
                                        src={IMAGES.icons.threat}
                                        alt="threat"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "l":
                                    items_jsx.push(<img
                                        src={IMAGES.icons.lock}
                                        alt="lock"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "f":
                                    items_jsx.push(<img
                                        src={IMAGES.icons.follower}
                                        alt="follower"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "s":
                                    items_jsx.push(<img
                                        src={IMAGES.icons.supply}
                                        alt="supply"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "st":
                                    items_jsx.push(<img
                                        src={IMAGES.icons.stress}
                                        alt="stress"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "x":
                                    items_jsx.push(<img
                                        src={IMAGES.icons.xp}
                                        alt="xp"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "T":
                                    items_jsx.push(<img
                                        src={IMAGES.icons.threat}
                                        alt="threat"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "t":
                                    items_jsx.push(<img
                                        src={IMAGES.icons.threat}
                                        alt="threat"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case "c":
                                    items_jsx.push(<img
                                        src={IMAGES.icons.corruption}
                                        alt="corruption"
                                        className="fw-icon"
                                        key={spl_ranger+"_"+index}
                                    />);
                                    break;
                                case ">":
                                    items_jsx.push(<p key={spl_ranger+"_"+index} className="br-line"></p>);
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                    if(items_jsx.length>1) {
                        jsx_to_return.push(
                            <span key={spl_ranger}>
                                {items_jsx}
                            </span>
                        );
                    } else if(items_jsx.length===1) {
                        jsx_to_return.push(items_jsx[0]);
                    }

                }
                spl_ranger++;
                break;
        }
    }

    return jsx_to_return;
}
