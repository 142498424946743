import React from 'react';
import { IMAGES } from '../../image-data';

export function TextHr(props) {
    return (
        <div className="text-hr">
            <img className="text-hr-line" alt="line" src={IMAGES.interface.divider_mall} />
            <div className="text-hr-text">{props.children}</div>
            <img className="text-hr-line" alt="line" src={IMAGES.interface.divider_mall} />
        </div>
    );
}