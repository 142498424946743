import React from "react";
import {str} from "../../Scenarios";
import "./Continue.scss";
import { BUTTON_STYLES, WideButton } from "../WideButton";
import { IMAGES } from "../../../image-data";
import { flLoadPromise } from "../../../api";
export class ContinueScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleting: false,
            code: "",
            loading: false,
            loading_input: "",
            error: null,
        };
    }

    loadGame() {
        if(this.state.loading_input==="") {
            this.setState({error: str["Code is a required field."]});
        } else if(this.props.manager.historyManager.getLoadHistory(this.state.loading_input) && this.props.manager.historyManager.historySaver.getSaveCodes().includes(this.state.loading_input)) {
            this.setState({error: str["Game is already on this device."]});
        } else {
            this.setState({loading: true, error: null});
            flLoadPromise(this.state.loading_input).then(d=>{
                if(d.ok) {
                    this.props.manager.historyManager.historySaver.saveHistory(d.data, false);
                    this.setState({error: null, loading: false, loading_input: "",});
                } else {
                    this.setState({error: str["Code not found."], loading: false,});
                }
            });
        }
    }

    render() {
        let quests = this.props.manager.historyManager.historySaver.getSaveCodes();
        return(
            <>
                {this.state.deleting ?
                    <div className="delete-quest">
                        <p>{str.formatString(str["Are you sure you want to delete quest {0}?"], this.state.code)}</p>
                        <WideButton style={BUTTON_STYLES.SUBTITLE} onClick={()=>this.setState({deleting: false, code: ""})}>{str["Cancel"]}</WideButton>
                        <WideButton style={BUTTON_STYLES.SUBTITLE} onClick={()=>{
                            this.props.manager.historyManager.historySaver.deleteQuest(this.state.code);
                            this.setState({deleting: false, code: ""});
                        }}>{str["Delete"]}</WideButton>
                    </div>
                : 
                    <div className="quest-list">
                        {quests.map((quest, index) => {
                            let scen_data = this.props.manager.historyManager.historySaver.loadHistory(quest, false);
                            if(!scen_data) return null;
                            let matching_scens = this.props.manager.scenarios.filter(s=>s.code===scen_data.scenario_code);
                            if(!matching_scens.length) return null;
                            let match = matching_scens[0];
                            return <><div className="one-quest" key={index}>
                                <span className="one-quest-date">{new Date(Date.parse(scen_data.date_updated)).toLocaleDateString()}</span>
                                <span className="one-quest-title">
                                    {match.title}&nbsp;-&nbsp; 
                                    {scen_data.page ? str.formatString(str["Page {0}"], scen_data.page) : str["Map"]}
                                </span>
                                <span className="one-quest-code">{str["Game Code:"]} {quest}</span>
                                <WideButton style={BUTTON_STYLES.SUBTITLE} onClick={()=>this.props.onSelected(match, quest)}>{str["Load"]}</WideButton>
                                <button className="one-quest-delete" onClick={()=>this.setState({deleting: true, code: quest,})}><img src={IMAGES.interface.trash} alt="delete" /></button>
                            </div><hr /></>;
                        })}
                        <div className="one-quest">
                            <span className="one-quest-load">{str["Load game from another device:"]}</span>
                            {this.state.loading ?
                                <span className="one-quest-loading">{str["Loading..."]}</span>
                            :<>
                                <input maxLength={8} type="text" placeholder={str["Enter Game Code"]} className="one-quest-input poppins" value={this.state.loading_input} onChange={e=>this.setState({loading_input: e.target.value.toUpperCase(), error: null,})} />
                                <WideButton style={BUTTON_STYLES.SUBTITLE} onClick={()=>this.loadGame()}>{str["Load"]}</WideButton>   
                                {this.state.error ? <span className="one-quest-error">{this.state.error}</span> : null}
                            </>}
                        </div>
                    </div>
                }
            </>
        );
    }
}