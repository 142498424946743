import React from "react";

export const BUTTON_STYLES = {
    TITLE: "title",
    SUBTITLE: "subtitle",
    ENTRY: "entry",
    ENTRY_PURPLE: "entry-purple",
    ENTRY_BLUE: "entry-blue",
    ENTRY_GREEN: "entry-green",
    SMALL: "small",
    JOB: 'job',
    JOB_SELECTED: 'job-selected',
    SMALL_EXTENDED: "small-extended",
}

export const ENTRY_BUTTONS = [
    BUTTON_STYLES.ENTRY,
    BUTTON_STYLES.ENTRY_PURPLE,
]

export function WideButton(props) {
    if(props.href && !props.disabled) {
        return <a
            className={
                "wide-button " +
                "wide-button-"+(props.style || BUTTON_STYLES.ENTRY)+
                (ENTRY_BUTTONS.includes(props.style) ? " poppins" : " cheddar")+
                (props.tutorial ? " tutorial-button" : "")+
                " "+(props.classit || "")
            }
            target="_blank"
            type={props.type}
            href={props.href}
            rel="noopener noreferrer"
        >
            {props.children}
        </a>;
    }

    return (
        <button
            className={
                "wide-button " +
                "wide-button-"+(props.style || BUTTON_STYLES.ENTRY)+
                (ENTRY_BUTTONS.includes(props.style) ? " poppins" : " cheddar")+
                (props.tutorial ? " tutorial-button" : "")+
                " "+(props.classit || "")
            }
            disabled={props.disabled}
            type={props.type}
            onClick={() => props.onClick()}
        >
            {props.children}
        </button>
    );
}